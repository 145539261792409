import React from 'react';
import { Container, Row, Col, ButtonGroup, Button } from 'react-bootstrap';
import CreateOrJoin from './setup/createorjoin';
import Header from './helpers/header';
import Footer from './helpers/footer';
import { uiState } from '../redux/_constants';
import { heroLogo } from './helpers/logos';
import { useHistory } from 'react-router-dom';
import { useUser } from '../user-provider';
import MatchupGrid from './helpers/matchupgrid';

function Start() {
  const { isFan } = useUser();
  const history = useHistory();

  if (isFan && history.location.pathname === uiState.CREATE) {
    history.push(uiState.START);
  }

  const handleMode = (mode) => {
    history.push(mode);
  };

  return (
    <div className="main">
      <Header />
      <CreateOrJoin />
      <Container>
        <Row style={{ height: 'calc(15vh - 72px)' }} />
        <Row
          style={{
            position: 'sticky',
            top: '72px',
            background: '#fff',
            padding: '1rem',
            zIndex: '1000',
          }}
        >
          <Col className="center" style={{ display: 'flex', flexWrap: 'wrap' }}>
            <div className="heroLogo"> {heroLogo} </div>
            <div className="heroMessage" style={{ fontStyle: 'italic' }}>
              Select a match
            </div>
          </Col>
        </Row>
        <Row style={{ height: '50vh' }}>
          <Col className="center" style={{ display: 'flex', flexWrap: 'wrap' }}>
            <MatchupGrid />
          </Col>
        </Row>
      </Container>
      <Footer />
    </div>
  );
}

export default Start;
