export const adminConstants = {
  GET_TEAMS_REQUEST: 'GET_TEAMS_ADMIN_REQUEST',
  GET_TEAMS_SUCCESS: 'GET_TEAMS_ADMIN_SUCCESS',
  GET_TEAMS_FAILURE: 'GET_TEAMS_ADMIN_FAILURE',
  GET_LEAGUES_REQUEST: 'GET_LEAGUES_ADMIN_REQUEST',
  GET_LEAGUES_SUCCESS: 'GET_LEAGUES_ADMIN_SUCCESS',
  GET_LEAGUES_FAILURE: 'GET_LEAGUES_ADMIN_FAILURE',
  GET_SESSIONS_REQUEST: 'GET_SESSIONS_ADMIN_REQUEST',
  GET_SESSIONS_SUCCESS: 'GET_SESSIONS_ADMIN_SUCCESS',
  GET_SESSIONS_FAILURE: 'GET_SESSIONS_ADMIN_FAILURE',
  GET_ATHLETES_REQUEST: 'GET_ATHLETES_ADMIN_REQUEST',
  GET_ATHLETES_SUCCESS: 'GET_ATHLETES_ADMIN_SUCCESS',
  GET_ATHLETES_FAILURE: 'GET_ATHLETES_ADMIN_FAILURE',
  GET_USERS_REQUEST: 'GET_USERS_ADMIN_REQUEST',
  GET_USERS_SUCCESS: 'GET_USERS_ADMIN_SUCCESS',
  GET_USERS_FAILURE: 'GET_USERS_ADMIN_FAILURE',
  SORT_NAME_ASC: 'SORT_NAME_ASC',
  SORT_NAME_DESC: 'SORT_NAME_DESC',
  SORT_CREATED_ASC: 'SORT_CREATED_ASC',
  SORT_CREATED_DESC: 'SORT_CREATED_DESC',
  CREATE_LEAGUE_REQUEST: 'CREATE_LEAGUE_ADMIN_REQUEST',
  CREATE_LEAGUE_SUCCESS: 'CREATE_LEAGUE_ADMIN_SUCCESS',
  CREATE_LEAGUE_FAILURE: 'CREATE_LEAGUE_ADMIN_FAILURE',
  EDIT_LEAGUE_REQUEST: 'EDIT_LEAGUE_ADMIN_REQUEST',
  EDIT_LEAGUE_SUCCESS: 'EDIT_LEAGUE_ADMIN_SUCCESS',
  EDIT_LEAGUE_FAILURE: 'EDIT_LEAGUE_ADMIN_FAILURE',
  CREATE_TEAM_REQUEST: 'CREATE_TEAM_ADMIN_REQUEST',
  CREATE_TEAM_SUCCESS: 'CREATE_TEAM_ADMIN_SUCCESS',
  CREATE_TEAM_FAILURE: 'CREATE_TEAM_ADMIN_FAILURE',
  EDIT_TEAM_REQUEST: 'EDIT_TEAM_ADMIN_REQUEST',
  EDIT_TEAM_SUCCESS: 'EDIT_TEAM_ADMIN_SUCCESS',
  EDIT_TEAM_FAILURE: 'EDIT_TEAM_ADMIN_FAILURE',
  CREATE_SESSION_REQUEST: 'CREATE_SESSION_ADMIN_REQUEST',
  CREATE_SESSION_SUCCESS: 'CREATE_SESSION_ADMIN_SUCCESS',
  CREATE_SESSION_FAILURE: 'CREATE_SESSION_ADMIN_FAILURE',
  EDIT_SESSION_REQUEST: 'EDIT_SESSION_ADMIN_REQUEST',
  EDIT_SESSION_SUCCESS: 'EDIT_SESSION_ADMIN_SUCCESS',
  EDIT_SESSION_FAILURE: 'EDIT_SESSION_ADMIN_FAILURE',
  CREATE_ATHLETE_REQUEST: 'CREATE_ATHLETE_ADMIN_REQUEST',
  CREATE_ATHLETE_SUCCESS: 'CREATE_ATHLETE_ADMIN_SUCCESS',
  CREATE_ATHLETE_FAILURE: 'CREATE_ATHLETE_ADMIN_FAILURE',
  EDIT_ATHLETE_REQUEST: 'EDIT_ATHLETE_ADMIN_REQUEST',
  EDIT_ATHLETE_SUCCESS: 'EDIT_ATHLETE_ADMIN_SUCCESS',
  EDIT_ATHLETE_FAILURE: 'EDIT_ATHLETE_ADMIN_FAILURE',
  GET_TEAM_ATHLETES_REQUEST: 'GET_TEAM_ATHLETES_ADMIN_REQUEST',
  GET_TEAM_ATHLETES_SUCCESS: 'GET_TEAM_ATHLETES_ADMIN_SUCCESS',
  GET_TEAM_ATHLETES_FAILURE: 'GET_TEAM_ATHLETES_ADMIN_FAILURE',
  CREATE_ROSTER_REQUEST: 'CREATE_ROSTER_ADMIN_REQUEST',
  CREATE_ROSTER_SUCCESS: 'CREATE_ROSTER_ADMIN_SUCCESS',
  CREATE_ROSTER_FAILURE: 'CREATE_ROSTER_ADMIN_FAILURE',
  EDIT_ROSTER_REQUEST: 'EDIT_ROSTER_ADMIN_REQUEST',
  EDIT_ROSTER_SUCCESS: 'EDIT_ROSTER_ADMIN_SUCCESS',
  EDIT_ROSTER_FAILURE: 'EDIT_ROSTER_ADMIN_FAILURE',
  UPDATE_TABLE_DATA: 'UPDATE_TABLE_DATA_ADMIN',
  EDIT_SESSION_REQUEST: 'EDIT_SESSION_ADMIN_REQUEST',
  EDIT_SESSION_SUCCESS: 'EDIT_SESSION_ADMIN_SUCCESS',
  EDIT_SESSION_FAILURE: 'EDIT_SESSION_ADMIN_FAILURE',
  CHECK_STREAM_POSTGAME_REQUEST: 'CHECK_STREAM_POSTGAME_ADMIN_REQUEST',
  CHECK_STREAM_POSTGAME_SUCCESS: 'CHECK_STREAM_POSTGAME_ADMIN_SUCCESS',
  CHECK_STREAM_POSTGAME_FAILURE: 'CHECK_STREAM_POSTGAME_ADMIN_FAILURE',
  UPDATE_STREAM_URLS_REQUEST: 'UPDATE_STREAM_URLS_ADMIN_REQUEST',
  UPDATE_STREAM_URLS_SUCCESS: 'UPDATE_STREAM_URLS_ADMIN_SUCCESS',
  UPDATE_STREAM_URLS_FAILURE: 'UPDATE_STREAM_URLS_ADMIN_FAILURE',
  EDIT_USER_REQUEST: 'EDIT_USER_ADMIN_REQUEST',
  EDIT_USER_SUCCESS: 'EDIT_USER_ADMIN_SUCCESS',
  EDIT_USER_FAILURE: 'EDIT_USER_ADMIN_FAILURE',
};

export const adminModeType = {
  ATHLETE: 'ATHLETE',
  JUDGE: 'JUDGE',
  USER: 'USER',
  SESSION: 'SESSION',
  TEAM: 'TEAM',
  LEAGUE: 'LEAGUE',
};

export const athleteYearType = {
  PFR: 'PRE_FRESHMAN',
  FR: 'FRESHMAN',
  RFR: 'REDSHIRT_FRESHMAN',
  SO: 'SOPHOMORE',
  RSO: 'REDSHIRT_SOPHOMORE',
  JR: 'JUNIOR',
  RJR: 'REDSHIRT_JUNIOR',
  SR: 'SENIOR',
  RSR: 'REDSHIRT_SENIOR',
  ELT: 'ELITE',
  RET: 'RETIRED',
};

export const leagueType = {
  CLUB: 'CLUB',
  FIG: 'FIG',
  JO: 'JO',
  NCAA: 'NCAA',
  OTHER: 'OTHER',
};

export const initialAdminState = {
  mode: null,
  filter: [], // list of athleteIds for team related operations
  filterId: null,
  table: {
    initialData: null,
    initialSelected: null,
    newData: null,
    newSelected: null,
  }, // Keeps editing table data, & also selected row state
};

export const FILE_SIZE = 5000000;

export const imgType = ['image/png', 'image/gif', 'image/jpeg'];
