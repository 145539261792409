import React from 'react';
import { Link } from 'react-router-dom';
import { uiState } from '../../redux/_constants';

function Footer({ children }) {
  const { TERMS, PRIVACY } = uiState;

  return (
    <div className="footer">
      {children}
      <ul>
        <li>
          <Link to={PRIVACY}>Privacy</Link>
        </li>
        <li>© 2021 Virtius</li>
        <li>
          <Link to={TERMS}>Terms</Link>
        </li>
      </ul>
    </div>
  );
}

export default Footer;
