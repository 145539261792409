import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import TeamAdmin from './teamadmin';
import LeagueAdmin from './leagueadmin';
import JudgeAdmin from './judgeadmin';
import SessionAdmin from './sessionadmin';
import UserAdmin from './useradmin';
import AthleteAdmin from './athleteadmin';
import { useDispatch, useSelector } from 'react-redux';
import { alertActions } from '../../redux/_actions';

function SetupAdmin(props) {
  const { modeType, mode, handleMode } = props;
  const [isForm, setIsForm] = useState(false);
  const { alert } = useSelector((state) => state);
  const dispatch = useDispatch();

  const formType = () => {
    switch (mode) {
      case modeType.ATHLETE:
        return <AthleteAdmin setIsForm={setIsForm} />;
      case modeType.JUDGE:
        return <JudgeAdmin setIsForm={setIsForm} handleMode={handleMode} />;
      case modeType.USER:
        return <UserAdmin setIsForm={setIsForm} handleMode={handleMode} />;
      case modeType.SESSION:
        return <SessionAdmin setIsForm={setIsForm} />;
      case modeType.TEAM:
        return <TeamAdmin setIsForm={setIsForm} />;
      case modeType.LEAGUE:
        return <LeagueAdmin setIsForm={setIsForm} />;
      default:
        break;
    }
  };

  const reset = () => {
    if (!alert.clear) {
      dispatch(alertActions.clear());
    }
    handleMode(null);
    setIsForm(false);
  };

  return (
    <Modal
      show={mode !== null}
      onHide={reset}
      centered
      dialogClassName="setupModal"
      animation={false}
      enforceFocus={false}
      size={isForm ? null : 'lg'}
      scrollable={true}
    >
      {formType()}
    </Modal>
  );
}

export default SetupAdmin;
