import {
  eventConstants,
} from '../_constants';
import { store } from '../store';

import { eventService } from '../_services';
import { alertActions } from '../_actions';

export const eventActions = {
  createRoutine
};

function createRoutine(data) {
  return (dispatch) => {
    dispatch(request());

    eventService
      .createRoutine(data)
      .then((res) => {
        console.log(res);
        if (res.errors) {
          throw res.errors[0].message;
        }

        dispatch(success(res.data.createRoutine));
      })
      .catch((error) => {
        dispatch(failure());
        console.log(error);
        switch (error.code) {
          default:
            error.message = 'Create routine error.';
            break;
        }
        dispatch(alertActions.error(error.message));
      });
  };

  function request() {
    return { type: eventConstants.CREATE_ROUTINE_REQUEST };
  }
  function success(event) {
    return { type: eventConstants.CREATE_ROUTINE_SUCCESS, event };
  }
  function failure() {
    return { type: eventConstants.CREATE_ROUTINE_FAILURE };
  }
}

