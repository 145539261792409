import { API, graphqlOperation } from 'aws-amplify';
import { eventActions } from '../_actions';
import {
  createRoutineGQL,
  createClipGQL,
  createScoreGQL,
  updateRoutineGQL,
  updateClipGQL,
  updateScoreGQL,
  syncScoreById,
  syncNewScoresByRoutine,
  syncRoutineById,
  syncNewRoutinesByLineup,
  syncClipById,
  syncNewClipsByRoutine,
} from '../../graphql/graphql';

export const eventService = {
  createRoutine,
  createScore,
  createClip,
  updateRoutine,
  updateScore,
  updateClip,
  subscribeNewRoutines,
  subscribeRoutine,
  subscribeNewScores,
  subscribeScore,
  subscribeNewClips,
  subscribeClip,
};

async function createRoutine(payload) {
  console.log(payload);
  return await API.graphql(
    graphqlOperation(createRoutineGQL, { input: payload })
  );
}

async function createScore(payload) {
  console.log(payload);
  return await API.graphql(
    graphqlOperation(createScoreGQL, { input: payload })
  );
}

async function createClip(payload) {
  console.log(payload);
  return await API.graphql(
    graphqlOperation(createClipGQL, { input: payload })
  );
}

async function updateRoutine(payload) {
  console.log(payload);
  return await API.graphql(
    graphqlOperation(updateRoutineGQL, { input: payload })
  );
}

async function updateScore(payload) {
  console.log(payload);
  return await API.graphql(
    graphqlOperation(updateScoreGQL, { input: payload })
  );
}

async function updateClip(payload) {
  console.log(payload);
  return await API.graphql(
    graphqlOperation(updateClipGQL, { input: payload })
  );
}

async function subscribeNewRoutines(id, dispatch) {
  return await API.graphql(
    graphqlOperation(syncNewRoutinesByLineup, { lineupId: id })
  ).subscribe({
    next: (routineData) => {
      dispatch(
        eventActions.syncRoutineData(
          routineData.value.data.subscribeToNewRoutine,
        )
      );
    },
    error: (error) => {
      console.warn(error);
    },
  });
}

async function subscribeRoutine(id, dispatch) {
  return await API.graphql(
    graphqlOperation(syncRoutineById, { id: id })
  ).subscribe({
    next: (routineData) => {
      dispatch(
        eventActions.syncRoutineData(
          routineData.value.data.subscribeToRoutine,
        )
      );
    },
    error: (error) => {
      console.warn(error);
    },
  });
}

async function subscribeNewClips(id, dispatch) {
  return await API.graphql(
    graphqlOperation(syncNewClipsByRoutine, { routineId: id })
  ).subscribe({
    next: (clipData) => {
      dispatch(
        eventActions.syncClipData(
          clipData.value.data.subscribeToNewClip,
        )
      );
    },
    error: (error) => {
      console.warn(error);
    },
  });
}

async function subscribeClip(id, dispatch) {
  return await API.graphql(
    graphqlOperation(syncClipById, { id: id })
  ).subscribe({
    next: (clipData) => {
      dispatch(
        eventActions.syncClipData(
          clipData.value.data.subscribeToClip,
        )
      );
    },
    error: (error) => {
      console.warn(error);
    },
  });
}

async function subscribeNewScores(id, dispatch) {
  return await API.graphql(
    graphqlOperation(syncNewScoresByRoutine, { routineId: id })
  ).subscribe({
    next: (scoreData) => {
      dispatch(
        eventActions.syncScoreData(
          scoreData.value.data.subscribeToNewScore,
        )
      );
    },
    error: (error) => {
      console.warn(error);
    },
  });
}

async function subscribeScore(id, dispatch) {
  return await API.graphql(
    graphqlOperation(syncScoreById, { id: id })
  ).subscribe({
    next: (scoreData) => {
      dispatch(
        eventActions.syncScoreData(
          scoreData.value.data.subscribeToScore,
        )
      );
    },
    error: (error) => {
      console.warn(error);
    },
  });
}
