import React, { useEffect, useState, useMemo } from 'react';
import { Modal, Button, Row, Spinner, Alert, Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { alertActions, adminActions } from '../../redux/_actions';
import TableComponent from '../helpers/tablecomponent';
import { dateTime } from '../../utilities/conversions';
import SessionDetail from './sessiondetail';
import { sessionStatus, uiState } from '../../redux/_constants';
import { modeType } from '../../utilities/constants';
import { useHistory } from 'react-router-dom';

function SessionAdmin(props) {
  const { setIsForm } = props;
  const [isLoading, setIsLoading] = useState(true);
  const { LIST, DETAIL, EDIT, DELETE } = modeType;
  const [mode, setMode] = useState(LIST);
  const [sessionId, setSessionId] = useState(null);
  const { alert } = useSelector((state) => state);
  const { sessions, teams } = useSelector((state) => state.admin);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    if (sessions.allIds.length === 0) {
      setIsLoading(true);
      dispatch(adminActions.getSessions());
    }
  }, [sessions, dispatch]);

  useEffect(() => {
    if (teams.allIds.length === 0) {
      setIsLoading(true);
      dispatch(adminActions.getTeams());
    }
  }, [teams, dispatch]);

  useEffect(() => {
    if (sessions.allIds.length && teams.allIds.length) {
      setIsLoading(false);
    }
  }, [teams, sessions]);

  useEffect(() => {
    if (!alert.clear) {
      setIsLoading(false);
    }
  }, [alert.clear]);

  useEffect(() => {
    dispatch(alertActions.clear());
  }, [dispatch]);

  const sessionDetail = (e, row) => {
    if (!alert.clear) {
      dispatch(alertActions.clear());
    }
    setSessionId(row.id);
    setMode(DETAIL);
    setIsForm(true);
  };

  const back = () => {
    setMode(LIST);
    setIsForm(false);
  };

  const loading = () => {
    return (
      <Row
        style={{ margin: '1rem -15px', minHeight: '20vh', textAlign: 'center' }}
        className="vCenter"
      >
        <div className="vCenter" style={{ margin: '0 auto' }}>
          <Spinner
            variant="secondary"
            role="status"
            animation="border"
            size="md"
          />
        </div>
      </Row>
    );
  };

  const emptyList = () => {
    return (
      <Row
        style={{ margin: '1rem -15px', minHeight: '20vh', textAlign: 'center' }}
        className="vCenter"
      >
        <span>No sessions created.</span>
      </Row>
    );
  };

  const headers = useMemo(
    () => [
      { Header: '#', accessor: 'col1' },
      { Header: 'Name', accessor: 'col2', maxWidth: 50 },
      { Header: 'Type', accessor: 'col3' },
      { Header: 'Gender', accessor: 'col4' },
      {
        Header: 'Key',
        accessor: 'col5',
        Cell: ({ row }) => (
          <Link
            to={{
              pathname: '/session',
              search: `?s=${row.values.col5}`,
              state: { fromAdmin: true },
            }}
          >
            {row.values.col5}
          </Link>
        ),
      },
      { Header: 'Created', accessor: 'col6' },
      {
        Header: 'Status',
        accessor: 'status',
        Cell: (r) => {
          const { row, column, cell, updateMyData } = r;

          return (
            <Form.Control
              as="select"
              label={'Status'}
              id="status"
              value={cell.value}
              onClick={(e) => {
                e.stopPropagation();
              }}
              onChange={(e) => {
                const payload = {
                  ...sessions.byId[row.original.id],
                  status: e.target.value,
                };
                dispatch(adminActions.editSession(payload));
                updateMyData(
                  row.index,
                  column.id,
                  e.target.value === '-' ? '' : e.target.value
                );
              }}
              //disabled={isLoading}
            >
              {Object.keys(sessionStatus).map((statusType) => {
                return (
                  <option key={statusType} value={statusType}>
                    {statusType}
                  </option>
                );
              })}
            </Form.Control>
          );
        },
      },
    ],
    [dispatch, sessions]
  );

  const data = useMemo(() => {
    return sessions.allIds.map((id, i) => {
      const {
        name,
        type,
        gender,
        sessionKey,
        createdAt,
        status /*athletes, league*/,
      } = sessions.byId[id];

      return {
        col1: i + 1,
        col2: name,
        col3: type,
        col4: gender,
        col5: sessionKey,
        col6: dateTime(createdAt),
        status: status,
        id: id,
      };
    });
  }, [sessions]);

  const table = () => {
    const initialState = {
      sortBy: [{ id: 'col6', desc: true }],
    };

    return sessions.allIds.length === 0 ? (
      emptyList()
    ) : (
      <TableComponent
        columns={headers}
        data={data}
        onClick={sessionDetail}
        initialState={initialState}
        textSort={false}
      />
    );
  };

  const adminBody = () => {
    return (
      <>
        {!alert.clear ? (
          <Row style={{ padding: '0 15px', margin: '1rem -15px' }}>
            <Alert
              dismissible
              onClose={() => dispatch(alertActions.clear())}
              variant={alert.type === 'alert-danger' ? 'danger' : 'success'}
              style={{ textAlign: 'center', width: '100%' }}
            >
              {alert.message}
            </Alert>
          </Row>
        ) : null}
        {isLoading ? loading() : table()}
      </>
    );
  };

  const footer = () => {
    return (
      <>
        <Row className="vCenter" style={{ margin: '0 auto' }}>
          <Button
            variant="outline-primary"
            className="formButton"
            onClick={() => history.push(uiState.CREATE)}
            disabled={isLoading}
          >
            Add session
          </Button>
        </Row>
        <Row />
      </>
    );
  };

  const headerMsg = () => {
    return <span>Session Admin</span>;
  };

  const listModal = () => {
    return (
      <>
        <Modal.Header className="setupHeader">{headerMsg()}</Modal.Header>
        <Modal.Body className="setupForm" style={{ width: '100%' }}>
          {adminBody()}
        </Modal.Body>
        <Modal.Footer className="setupFooter">{footer()}</Modal.Footer>
      </>
    );
  };

  const modal = () => {
    switch (mode) {
      //case modeType.ADD:
      //  return (<SessionForm mode={mode} modeType={modeType} setMode={setMode} />);
      case EDIT:
        break;
      case DELETE:
        break;
      case LIST:
        return listModal();
      case DETAIL:
        return <SessionDetail back={back} sessionId={sessionId} />;
      default:
        return null;
    }
  };

  return modal();
}

export default SessionAdmin;
