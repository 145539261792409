import { videoPlayerConstants } from '../_constants';
import { store } from '../store';

export const videoPlayerActions = {
  load,
  seek,
  initialize,
  sync,
  play,
  pause,
  currentTime,
  vod,
  setRef,
};

function load(url, player = 'AB') {
  return { type: videoPlayerConstants.RESET };
}

function seek(time, player = 'AB', autoplay = false) {
  const data = {};

  if (player.includes('A')) {
    data['playerA'] = {};
    data['playerA']['seekTime'] = time;
    data['playerA']['autoplay'] = autoplay;
  }

  if (player.includes('B')) {
    data['playerB'] = {};
    data['playerB']['seekTime'] = time;
    data['playerB']['autoplay'] = autoplay;
  }

  return { type: videoPlayerConstants.SEEK, data };
}

function initialize(data) {
  return { type: videoPlayerConstants.INITIALIZE, data };
}

function sync() {
  return { type: videoPlayerConstants.SYNC };
}

function pause(player = 'AB') {
  const { controller, playerA, playerB } = store.getState().videoPlayer;

  const data = {
    playerA: {
      play: player.includes('A') || controller.sync ? false : playerA.play,
    },
    playerB: {
      play: player.includes('B') || controller.sync ? false : playerB.play,
    },
  };

  return { type: videoPlayerConstants.PAUSE, data };
}

function play(player = 'AB') {
  const { controller, playerA, playerB } = store.getState().videoPlayer;

  const data = {
    playerA: {
      play: player.includes('A') || controller.sync ? true : playerA.play,
    },
    playerB: {
      play: player.includes('B') || controller.sync ? true : playerB.play,
    },
  };

  return { type: videoPlayerConstants.PLAY, data };
}

function currentTime(player, time) {
  const data = {
    player: player,
    time: time,
  };

  return { type: videoPlayerConstants.CURRENT_TIME, data };
}

function vod(vod) {
  return { type: videoPlayerConstants.VOD, vod };
}

function setRef(ref, player = 'AB') {
  const { playerA, playerB } = store.getState().videoPlayer;

  const data = {
    playerA: {
      ref: player.includes('A') ? ref : playerA.ref,
    },
    playerB: {
      ref: player.includes('B') ? ref : playerB.ref,
    },
  };

  return { type: videoPlayerConstants.SET_REF, data };
}
