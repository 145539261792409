import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { uiState } from '../../redux/_constants';
import { menuIcon } from './icons';
import { useHistory, useLocation } from 'react-router-dom';
import { useUser } from '../../user-provider';

function MenuBubble() {
  const { cognitoGroups } = useUser();
  const { sessionKey } = useSelector((state) => state.session);
  const { user } = useSelector((state) => state.user);
  const { ADMIN, SETUP, SESSION, START, CREATE } = uiState;
  const history = useHistory();
  const location = useLocation();

  const showAdminOption = () => {
    return cognitoGroups.includes('admin') ? (
      <Dropdown.Item
        as="button"
        variant="outline"
        onSelect={() => history.push(ADMIN)}
      >
        Admin
      </Dropdown.Item>
    ) : null;
  };

  const showCreateOption = () => {
    return cognitoGroups.includes('admin') ? (
      <Dropdown.Item
        as="button"
        variant="outline"
        onSelect={() => history.push(CREATE)}
      >
        Create
      </Dropdown.Item>
    ) : null;
  };

  const showSetupSessionOption = () => {
    return cognitoGroups.includes('admin') ? (
      [SESSION, SETUP].includes(location.pathname) ? (
        <Dropdown.Item as="button" variant="outline" onSelect={handleSetup}>
          {mainButtonText()}
        </Dropdown.Item>
      ) : null
    ) : null;
  };

  const handleSetup = () => {
    let target = null;
    let params = null;

    switch (location.pathname) {
      case SESSION:
        target = SETUP;
        params = sessionKey ? `?s=${sessionKey}` : null;
        break;
      case SETUP:
        target = SESSION;
        params = sessionKey ? `?s=${sessionKey}` : null;
        break;
      default:
        return;
    }

    history.push(`${target}${params}`);
  };

  const mainButtonText = () => {
    switch (location.pathname) {
      case SESSION:
        return 'Setup';
      case SETUP:
        return 'Session';
      default:
        return;
    }
  };

  return (
    <Dropdown
      className="menuBubble"
      //onMouseEnter = { () => setShow(true) }
      //onMouseLeave = { () => setShow(false) }
    >
      <Dropdown.Toggle
        id="dropdown-menuBubble"
        className="vGray"
        variant="outline"
      >
        {menuIcon}
      </Dropdown.Toggle>
      <Dropdown.Menu className="menuBubble-menu">
        <Dropdown.Item
          as="button"
          variant="outline"
          onSelect={() => history.push(START)}
        >
          Start
        </Dropdown.Item>
        {showAdminOption()}
        {showCreateOption()}
        {/*
        <Dropdown.Item as="button" variant="outline"
          onSelect={() => history.push(SESSIONS)} >
          Sessions
        </Dropdown.Item>
        */}
        {showSetupSessionOption()}
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default MenuBubble;
