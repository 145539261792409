import { teamScoreType } from '../redux/_constants';

// Allows for lazy input of single numeric characters, i.e. 5 -> 5.0
export const scoreCheck = (score) => {
  const re = /[0123456789.]+/g;
  let cleanScore = '';
  let points = '';
  let tenths = '';

  if (score === null) {
    return score;
  }

  // remove non-allowed characters
  cleanScore = (score.match(re) || []).join('');

  // handle empty entry
  if (cleanScore.length === 0) {
    return '';
  }

  // check perfect 10
  if (
    cleanScore.substring(0, 4) === '10.0' ||
    cleanScore.substring(0, 3) === '100' ||
    cleanScore.substring(0, 3) === '10.'
  ) {
    return '10.0';
  }

  // start from first char
  if (cleanScore[0] === '.') {
    points = '0';
  } else {
    points = cleanScore[0];
    if (cleanScore.length === 1) {
      tenths = '0';
    }
  }

  // start with second char
  if (cleanScore.length > 1 && cleanScore[1] !== '.') {
    tenths = cleanScore[1];
  } else if (cleanScore.length > 2 && cleanScore[2] !== '.') {
    tenths = cleanScore[2];
  }

  return points + '.' + tenths;
};

// Requires full consistent input of numeric characters, i.e. 5 -> 5. not 5.0
export const scoreCheckStrict = (score) => {
  const re = /[0123456789.]+/g;
  let cleanScore = '';
  let points = '';
  let tenths = '';

  if (score === null) {
    return score;
  }

  // remove non-allowed characters
  cleanScore = (score.match(re) || []).join('');

  // handle empty entry
  if (cleanScore.length === 0) {
    return '';
  }

  // check perfect 10
  if (
    cleanScore.substring(0, 4) === '10.0' ||
    cleanScore.substring(0, 3) === '100' ||
    cleanScore.substring(0, 3) === '10.'
  ) {
    return '10.0';
  }

  // start from first char
  if (cleanScore[0] === '.') {
    points = '0';
  } else {
    points = cleanScore[0];
  }

  // start with second char
  if (cleanScore.length > 1 && cleanScore[1] !== '.') {
    tenths = cleanScore[1];
  } else if (cleanScore.length > 2 && cleanScore[2] !== '.') {
    tenths = cleanScore[2];
  }

  return points + '.' + tenths;
};

// Requires full input of significant digits i.e. 5 is not good, need 5.0
export const scoreValidStrict = (score) => {
  if (
    score === null ||
    (typeof score !== 'number' && typeof score !== 'string')
  ) {
    return false;
  }

  // Score comes in as String
  const numScore = Number(score);
  const strScore =
    typeof score === 'number' ? score.toFixed(1) : score.toString();
  return numScore >= 0 && numScore <= 10.0 && strScore.length > 2;
};

// Lazy input of single digit numbers, i.e. 5 is === 5.0
export const scoreValid = (score) => {
  if (
    score === null ||
    (typeof score !== 'number' && typeof score !== 'string')
  ) {
    return false;
  }

  // Score comes in as String
  const numScore = Number(score);
  const strScore =
    typeof score === 'number' ? score.toFixed(1) : score.toString();
  return numScore >= 0 && numScore <= 10.0 && strScore.length >= 1;
};

// Returns -1 if Score cannot be calculated
// Rules:
//  - D scores must be the same if more than 1
//  - Average of E scores taken from 10 will be truncated to thousandths (ie no rounding)
//  - if

export const calculateScore = (subScores) => {
  let score = 0;
  let dScore = null;
  let eScores = [];
  let eScore = null;
  let ND = 0;
  let SB = 0;

  if (
    subScores === null ||
    (Object.keys(subScores).length === 0 && subScores.constructor === Object)
  ) {
    return null;
  } // empty

  // Note: Calculate only if score is locked
  for (const item in subScores) {
    let data = subScores[item];
    //console.log(data)

    if (data === null || data === void 0 || data.length === void 0) {
      return null;
    } // bad data

    let len = data.length;
    //console.log(len)

    if (len === 0 || !data[len - 1].locked) {
      if ('DE'.includes(item[0])) {
        return null; // Cannot have missing D/E score
      }
      continue;
    }

    let curScore = data[len - 1].score;
    //console.log(curScore)

    if (item[0] === 'D') {
      if (dScore === null) {
        dScore = curScore;
      } else {
        if (dScore !== curScore) {
          return null; // D-scores must match
        }
      }
    } else if (item[0] === 'E') {
      eScores.push(curScore);
    } else if (item === 'ND') {
      ND = curScore;
    } else if (item === 'SB') {
      SB = curScore;
    }
  }

  // Check for valid E/D scores
  if (eScores.length === 0 || dScore === null) {
    return null; // missing Escores or Dscore
  }

  // E score cleanup, remove high / low scores for 5x or above Es
  if (eScores.length > 4) {
    eScore =
      (10000 -
        (1000 * eScores.reduce((a, b) => a + b, 0) -
          1000 * Math.max(...eScores) -
          1000 * Math.min(...eScores)) /
          (eScores.length - 2)) /
      1000;
  } else {
    eScore =
      (10000 - (1000 * eScores.reduce((a, b) => a + b, 0)) / eScores.length) /
      1000;
  }
  eScore = Math.trunc(eScore * 1000) / 1000;

  // To prevent strange rounding errors in Javascript
  score = (1000 * dScore + 1000 * eScore - 1000 * ND + 1000 * SB) / 1000;

  // Check if score below 0 then return 0
  if (score < 0) {
    score = 0;
  }

  //console.log(eScore)
  //console.log(dScore)
  //console.log(score)
  //consoel.log(score.toFixed(3))
  const scoreData = {
    score: score.toFixed(3),
    dScore: dScore.toFixed(1),
    eScore: eScore.toFixed(3),
    ND: ND.toFixed(1),
    SB: SB.toFixed(1),
  };

  return scoreData;
};

// Helper function to check subscores complete (E & D)
export const checkSubScoresComplete = (subScores) => {
  const requiredSubScores = 'DE'; // ND & SB optional

  if (
    subScores === null ||
    (Object.keys(subScores).length === 0 && subScores.constructor === Object)
  ) {
    return false;
  } // empty

  for (const item in subScores) {
    let data = subScores[item];
    if (data === null || data === void 0 || data.length === void 0) {
      return false;
    }
    let len = data.length;

    if (
      requiredSubScores.includes(item[0]) &&
      (len === 0 || !subScores[item][len - 1].locked)
    ) {
      return false;
    }
  }

  return true;
};

// Final score calculator function for team score, if round is null will calculate all rounds
export const sessionScore = (lineup, round = null, teamScoring = 'All') => {
  let score = 0;

  if (round === null) {
    round = Math.max(...Object.keys(lineup));
  }

  for (let i = 1; i <= round; i++) {
    score += lineup?.[i] ? roundScore(lineup?.[i], teamScoring) : 0;
  }

  return score;
};

// Team round score calculator for single round single size (based on team score type)
export const roundScore = (lineup, teamScoring = 'ALL') => {
  const { TOP5OF6, TOP5, TOP4, ALL } = teamScoreType;
  let score = 0;
  let filteredLineup = JSON.parse(JSON.stringify(lineup));

  if (!lineup) {
    return score;
  }

  switch (teamScoring) {
    case TOP5OF6:
      // need to drop extra scores, then sort then take the top 5
      filteredLineup = filteredLineup
        .slice(0, 6)
        .sort((a, b) => b.score - a.score)
        .slice(0, 5);
      break;
    case TOP5:
      filteredLineup = filteredLineup.slice(0, 5);
      break;
    case TOP4:
      filteredLineup = filteredLineup.slice(0, 4);
      break;
    case ALL:
    default:
      break;
  }

  score = filteredLineup.reduce((total, athlete) => {
    return total + Number(athlete.score);
  }, 0);

  return score;
};

export const winLoseTie = (lineupA, lineupB) => {
  const scoreA = sessionScore(lineupA);
  const scoreB = sessionScore(lineupB);

  if (scoreA === scoreB) {
    return 'TIE';
  }

  if (scoreA > scoreB) {
    return 'WIN';
  }
  return 'LOSE';
};
