import React from 'react';
import UserBubble from './userbubble';
import MenuBubble from './menububble';

function Header({ children }) {
  return (
    <div className="header">
      <MenuBubble />
      <UserBubble />
      {children}
    </div>
  );
}

export default Header;
