import React, { useEffect } from 'react';
import './App.css';
import Admin from './components/admin';
import Start from './components/start';
import Home from './components/home';
import Setup from './components/setup';
import Session from './components/session';
import { useDispatch } from 'react-redux';
import { alertActions } from './redux/_actions';
import { Switch, Route, Redirect, useHistory } from 'react-router-dom';
import { useUser } from './user-provider';
import 'bootstrap/dist/css/bootstrap.min.css';

const analytics = (conduit) => {
  if (window.gtag) {
    window.gtag('send', 'page_view', {
      page_location: window.location.href,
      page_path: window.location.pathname,
    });
  }
};

function App() {
  const { user, isAuthenticating } = useUser();
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    const unlisten = history.listen((location) => {
      analytics();
      dispatch(alertActions.clear());
    });
    return unlisten;
  }, [dispatch, history]);

  const AuthPage = ({ comp: Component, redirect }) => {
    return (
      <Route
        render={(props) =>
          !(user && user.signInUserSession) ? (
            <Redirect to={redirect} />
          ) : (
            <Component {...props} user={user} />
          )
        }
      />
    );
  };

  const PublicPage = ({ comp: Component }) => {
    return <Route render={(props) => <Component {...props} user={user} />} />;
  };

  const HomePage = ({ comp: Component, redirect, path }) => {
    let target = null;

    if (
      user &&
      user.signInUserSession &&
      !['/privacy', '/terms'].includes(path)
    ) {
      target = <Redirect to={redirect} />;
    }

    return (
      <Route render={(props) => (target ? target : <Component {...props} />)} />
    );
  };

  return (
    !isAuthenticating && (
      <Switch>
        <AuthPage exact path="/admin" comp={Admin} redirect="/" />
        <AuthPage exact path="/setup" comp={Setup} redirect="/" />
        <AuthPage exact path="/start" comp={Start} redirect="/" />
        <AuthPage exact path="/create" comp={Start} redirect="/" />
        <AuthPage exact path="/join" comp={Start} redirect="/" />
        <AuthPage exact path="/sessions" comp={Start} redirect="/" />
        <PublicPage exact path="/session" comp={Session} />
        <HomePage exact path="/verify" comp={Home} redirect="/start" />
        <HomePage exact path="/forgotpw" comp={Home} redirect="/start" />
        <HomePage exact path="/resetpw" comp={Home} redirect="/start" />
        <HomePage exact path="/signup" comp={Home} redirect="/start" />
        <HomePage exact path="/privacy" comp={Home} />
        <HomePage exact path="/terms" comp={Home} />
        <HomePage exact path="/login" comp={Home} redirect="/start" />
        <HomePage exact path="/" comp={Home} redirect="/start" />
        <Redirect from="*" to="/" />
      </Switch>
    )
  );
}

export default App;
