import React, { useState, useEffect } from 'react';
import { Modal, Button, Row, Alert, Spinner, Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { sessionActions, alertActions } from '../../redux/_actions';
import { uiState } from '../../redux/_constants';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import InputAutofocus from '../helpers/inputautofocus';
import { useHistory } from 'react-router-dom';
import { useUser } from '../../user-provider';

function Join(props) {
  const { isFan } = useUser();
  const { id, setId } = props;
  const alert = useSelector((state) => state.alert);
  const [isJoining, setIsJoining] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    if (!alert.clear) {
      setIsJoining(false);
    }
  }, [alert.clear]);

  const handleMode = (mode) => {
    history.push(mode);
  };

  const handleChangeId = (e) => {
    handleChange(e);
    setId(e.target.value);
  };

  const validationSchema = Yup.object().shape({
    code: Yup.string().required('Required'),
  });

  const { handleSubmit, handleChange, values, errors } = useFormik({
    initialValues: {
      code: id === null ? '' : id,
    },
    validationSchema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit(values) {
      setIsJoining(true);
      dispatch(alertActions.clear());
      dispatch(sessionActions.join(values.code, history));
    },
  });

  const joinSessionBody = () => {
    return (
      <Form onSubmit={handleSubmit}>
        {!alert.clear ? (
          <Row>
            <Alert
              dismissible
              onClose={() => dispatch(alertActions.clear())}
              variant={alert.type === 'alert-danger' ? 'danger' : 'success'}
            >
              {alert.message}
            </Alert>
          </Row>
        ) : null}
        <Row>
          <div className="setupFormLine">
            <label style={{ width: '100%' }}>
              Invite Code
              {errors.code ? (
                <span className="form-error">{errors.code}</span>
              ) : null}
            </label>
            {InputAutofocus({
              name: 'code',
              value: values.code,
              handler: handleChangeId,
              disabled: isJoining,
            })}
            <div className="codeSpecs">Contact session organizer for code.</div>
          </div>
        </Row>
        <Row>
          <Button
            variant="outline-primary"
            className="joinButton"
            onClick={handleSubmit}
            disabled={isJoining}
          >
            {isJoining ? (
              <>
                {'Joining session... '}
                <Spinner
                  variant="primary"
                  role="status"
                  animation="border"
                  size="sm"
                />
              </>
            ) : (
              'Join Session'
            )}
          </Button>
        </Row>
        <Row>
          {!isFan ? (
            <div className="modalLink">
              <span>Or ready to</span>
              <button
                type="button"
                className="linkButton"
                disabled={isJoining}
                onClick={() => handleMode(uiState.CREATE)}
              >
                Create Session?
              </button>
            </div>
          ) : null}
        </Row>
      </Form>
    );
  };

  const headerMsg = () => {
    const virtius = (
      <>
        <span className={'vBlue'}>V</span>
        <span className={'vGray'}>irtius</span>
      </>
    );

    return (
      <>
        <span>Join&nbsp;</span>
        {virtius}
        <span>&nbsp;session</span>
      </>
    );
  };

  return (
    <>
      <Modal.Header className="createOrJoinHeader">{headerMsg()}</Modal.Header>
      <Modal.Body className="createOrJoinForm">{joinSessionBody()}</Modal.Body>
    </>
  );
}

export default Join;
