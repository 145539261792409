import { alertConstants } from '../_constants';

export const alertActions = {
  success,
  error,
  clear,
  notification,
  sync,
};

function success(message) {
  return { type: alertConstants.SUCCESS, message };
}

function error(message) {
  return { type: alertConstants.ERROR, message };
}

function clear() {
  return { type: alertConstants.CLEAR };
}

function notification(message) {
  return { type: alertConstants.NOTIFICATION, message };
}

function sync(message) {
  return { type: alertConstants.SYNC, message };
}
