import React, { useState, useEffect, useCallback, useRef } from 'react';
import {
  Modal,
  Button,
  Row,
  Col,
  Alert,
  Spinner,
  Form,
  Popover,
  OverlayTrigger,
} from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { adminActions, alertActions } from '../../redux/_actions';
import {
  genderType,
  leagueType,
  imgType,
  FILE_SIZE,
} from '../../redux/_constants';
import * as Yup from 'yup';
import { SketchPicker } from 'react-color';
import * as Slugify from 'slugify';
import { useDropzone } from 'react-dropzone';
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { MEDIAURL } from '../../utilities/constants';

function AthleteForm(props) {
  const { back } = props;
  const [colors, setColors] = useState([
    '#00A2FF',
    '#5E5E5E',
    '#FFFFFF',
    '#000000',
  ]);
  const [photo, setPhoto] = useState(null);
  const [isCreating, setIsCreating] = useState(false);
  const dispatch = useDispatch();
  const { alert } = useSelector((state) => state);
  const { teams } = useSelector((state) => state.admin);
  const hiddenFileInput = useRef(null);
  const [changedPhoto, setChangedPhoto] = useState(false);
  let typeaheadRef = useRef();

  useEffect(() => {
    if (!alert.clear) {
      setIsCreating(false);
    }
  }, [alert.clear]);

  useEffect(() => {
    if (alert.type === 'alert-success') {
      back();
    }
  }, [alert.type, back]);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Required'),
    slug: Yup.string().required('Required'),
    colors: Yup.array().of(Yup.string()),
    altNames: Yup.string().nullable(),
    profileImg: Yup.mixed()
      .nullable()
      .notRequired()
      .test(
        'FILE_SIZE',
        'Uploaded file is too big.',
        (value) => !value || (value && value.size <= FILE_SIZE)
      )
      .test(
        'FILE_FORMAT',
        'Uploaded file has unsupported format.',
        (value) => !value || (value && imgType.includes(value.type))
      ),
    type: Yup.string().required('Required'),
    gender: Yup.string().required('Required'),
    teamAffiliations: Yup.string(),
  });

  const {
    setFieldValue,
    handleSubmit,
    handleChange,
    values,
    errors,
  } = useFormik({
    initialValues: {
      name: '',
      slug: '',
      altNames: '',
      profileImg: null,
      type: '',
      gender: '',
      teamAffiliations: [],
      colors: colors,
    },
    validationSchema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit(values) {
      setIsCreating(true);
      dispatch(alertActions.clear());
      dispatch(adminActions.createAthlete(values));
    },
  });

  //console.log(errors)
  //console.log(values)

  const calcSlug = (name) => {
    const slug = Slugify(name, { lower: true, strict: true });
    return slug;
  };

  const checkBase64 = (string) => {
    return string.substr(0, 5) === 'data:';
  };

  const colorButton = (index) => {
    return (
      <OverlayTrigger
        placement="bottom"
        //trigger={["click", "focus"]}
        trigger="click"
        rootClose
        overlay={
          <Popover id={`popover-colorpicker-${index}`}>
            <SketchPicker
              disableAlpha
              presetColors={[]}
              color={colors[index]}
              onChange={(value) => {
                setFieldValue(`colors[${index}]`, value.hex);
                colors[index] = value.hex;
                setColors(colors);
              }}
            />
          </Popover>
        }
      >
        <Button
          className="colorSelect"
          disabled={isCreating}
          style={{
            marginLeft: index === 0 ? '0' : null,
            background: colors[index],
          }}
        ></Button>
      </OverlayTrigger>
    );
  };

  const onDrop = useCallback(
    (acceptedFiles) => {
      const handleLogoChange = (files) => {
        const fileUploaded = files;
        if (!fileUploaded) {
          return false;
        }

        if (!alert.clear) {
          dispatch(alertActions.clear());
        }

        setChangedPhoto(true);

        let reader = new FileReader();
        reader.onload = (e) => {
          const img = new Image();
          img.onload = () => {
            if (fileUploaded.size > FILE_SIZE) {
              dispatch(alertActions.error('Image exceeds max 5MB.'));
              return false;
            }
            setPhoto(reader.result);
          };
          img.onerror = () => {
            dispatch(alertActions.error('Could not load image.'));
            return false;
          };
          img.src = e.target.result;
        };

        setFieldValue(`profileImg`, fileUploaded);
        reader.readAsDataURL(fileUploaded);
      };

      handleLogoChange(acceptedFiles);
    },
    [alert.clear, dispatch, setFieldValue]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const teamOptions = () => {
    let result = [];

    if (values.gender !== '') {
      result = teams.allIds.filter((id) => {
        return teams.byId[id].gender === values.gender;
      });
    } else {
      result = teams.allIds;
    }

    return result.map((id) => {
      const { name, gender } = teams.byId[id];
      let genderTxt = '';

      if (values.gender === '') {
        if (gender === 'MALE') {
          genderTxt = ' (Mens)';
        }
        if (gender === 'FEMALE') {
          genderTxt = ' (Womens)';
        }
      }

      return {
        name: `${name}${genderTxt}`,
        id: id,
      };
    });
  };

  const logoButton = () => {
    const handleClick = (event) => {
      hiddenFileInput.current.click();
    };

    return (
      <>
        <label>Photo</label>
        <Button
          variant="outline-light"
          disabled={isCreating}
          className={'logoSelect'}
          onClick={handleClick}
        >
          {photo ? (
            <img
              className="img-thumbnail"
              src={checkBase64(photo) ? photo : `${MEDIAURL}${photo}`}
            />
          ) : isDragActive ? (
            '+'
          ) : (
            '?'
          )}
        </Button>
        <input
          type="file"
          {...getInputProps()}
          ref={hiddenFileInput}
          onChange={(e) => onDrop(e.target.files[0])}
          style={{ display: 'none' }}
          accept="image/png,image/gif,image/jpeg"
        />
      </>
    );
  };

  const onKeyDown = useCallback((e) => {
    if (e.key === 'Enter') {
      setFieldValue('altNames', [...values.altNames, e.target.value]);
      typeaheadRef.clear();
    }
  });

  const setupBody = () => {
    return (
      <>
        {!alert.clear ? (
          <Row style={{ padding: '0 15px' }}>
            <Alert
              dismissible
              onClose={() => dispatch(alertActions.clear())}
              variant={alert.type === 'alert-danger' ? 'danger' : 'success'}
            >
              {alert.message}
            </Alert>
          </Row>
        ) : null}
        <Row>
          <div className="setupFormLine">
            <Col {...getRootProps()}>{logoButton()}</Col>
            <Col sm={8}>
              <Row>
                <label>Colors</label>
                {colorButton(0)}
                {colorButton(1)}
                {colorButton(2)}
                {colorButton(3)}
              </Row>
              <Row>
                <div className="setupFormLine">
                  <Col style={{ padding: '0 2% 0 0' }}>
                    <label>
                      Gender
                      {errors.gender ? (
                        <span className="form-error">{errors.gender}</span>
                      ) : null}
                    </label>
                    <Form.Control
                      as="select"
                      label={'Required'}
                      id="gender"
                      defaultValue="Select..."
                      onChange={handleChange}
                      disabled={isCreating}
                    >
                      <option value="Select..." disabled hidden>
                        Select...
                      </option>
                      {Object.values(genderType).map((type, i) => {
                        return <option key={i}>{type}</option>;
                      })}
                    </Form.Control>
                  </Col>
                  <Col style={{ padding: '0 0 0 2%' }}>
                    <label>
                      Type
                      {errors.type ? (
                        <span className="form-error">{errors.type}</span>
                      ) : null}
                    </label>
                    <Form.Control
                      as="select"
                      label={'Required'}
                      id="type"
                      defaultValue="Select..."
                      onChange={handleChange}
                      disabled={isCreating}
                    >
                      <option value="Select..." disabled hidden>
                        Select...
                      </option>
                      {Object.values(leagueType).map((type, i) => {
                        return <option key={i}>{type}</option>;
                      })}
                    </Form.Control>
                  </Col>
                </div>
              </Row>
            </Col>
          </div>
        </Row>
        <Row>
          <Col>
            <label>
              Name
              {errors.name ? (
                <span className="form-error">{errors.name}</span>
              ) : null}
            </label>
            <Form.Control
              name="name"
              type="text"
              autoComplete="off"
              onChange={(e) => {
                setFieldValue('name', e.target.value);
                setFieldValue('slug', calcSlug(e.target.value));
              }}
              value={values.name}
              disabled={isCreating}
              placeholder="Enter Full Name"
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <label>Slug</label>
            <Form.Control
              readOnly
              plaintext
              name="slug"
              type="text"
              autoComplete="off"
              value={values.slug}
              disabled={isCreating}
              placeholder="Autogenerated"
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <label>Other Names (Use #hashtag for display name)</label>
            <Typeahead
              onChange={(selected) => {
                setFieldValue('altNames', selected);
              }}
              allowNew
              options={[]}
              id="teamAltNames"
              className="typeahead"
              multiple
              autoComplete="off"
              disabled={isCreating}
              placeholder={'Optional'}
              selected={values.altNames ? values.altNames : []}
              renderMenu={(results, menuProps) => null}
              onKeyDown={onKeyDown}
              ref={(a) => (typeaheadRef = a)}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <label>Team Affiliations</label>
            <Typeahead
              onChange={(selected) => {
                // filter out the names and leave ID list only
                const cleanList = selected.map((e) => e.id);
                setFieldValue('teamAffiliations', cleanList);
              }}
              options={teamOptions()}
              id="athleteTeamSelector"
              multiple
              labelKey="name"
              dropup
              autoComplete="off"
              disabled={isCreating || !values.gender}
              className="typeahead"
              placeholder={
                values.gender ? 'Choose teams...' : 'Select gender...'
              }
              filterBy={['name', 'id']}
              renderMenuItemChildren={(option) => option.name}
            />
          </Col>
        </Row>
      </>
    );
  };

  const setupFooter = () => {
    return (
      <>
        <Row>
          <Button
            variant="outline-primary"
            className="formButton"
            onClick={handleSubmit}
            disabled={isCreating}
          >
            {isCreating ? (
              <>
                {'Adding athlete... '}
                <Spinner
                  variant="primary"
                  role="status"
                  animation="border"
                  size="sm"
                />
              </>
            ) : (
              'Add athlete'
            )}
          </Button>
        </Row>
        <Row>
          <div className="modalLink">
            <span>Back to</span>
            <button
              type="button"
              className="linkButton"
              disabled={isCreating}
              onClick={back}
            >
              Athlete list
            </button>
          </div>
        </Row>
      </>
    );
  };

  const headerMsg = () => {
    const virtius = (
      <>
        <span className={'vBlue'}>V</span>
        <span className={'vGray'}>irtius</span>
      </>
    );

    return (
      <>
        <span>Add&nbsp;</span>
        {virtius}
        <span>&nbsp;Athlete</span>
      </>
    );
  };

  return (
    <>
      <Modal.Header className="adminHeader">{headerMsg()}</Modal.Header>
      <Form onSubmit={handleSubmit}>
        <Modal.Body className="adminForm">{setupBody()}</Modal.Body>
        <Modal.Footer className="adminForm">{setupFooter()}</Modal.Footer>
      </Form>
    </>
  );
}

export default AthleteForm;
