import React, {
  useState,
  useCallback,
  useImperativeHandle,
  useRef,
} from 'react';
import {
  Button,
  Row,
  Col,
  Form,
  Popover,
  OverlayTrigger,
} from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { adminActions, alertActions } from '../../redux/_actions';
import {
  genderType,
  leagueType,
  imgType,
  FILE_SIZE,
} from '../../redux/_constants';
import * as Yup from 'yup';
import { SketchPicker } from 'react-color';
import { useDropzone } from 'react-dropzone';
import { Typeahead } from 'react-bootstrap-typeahead';
import { MEDIAURL } from '../../utilities/constants';

export const LeagueProfile = React.forwardRef((props, ref) => {
  const { leagueId, isEditing, isSaving, reset, setIsLoading } = props;
  const { leagues } = useSelector((state) => state.admin);
  const league = leagues.byId[leagueId];
  const [colors, setColors] = useState(
    league.colors
      ? JSON.parse(league.colors)
      : ['#00A2FF', '#5E5E5E', '#FFFFFF', '#000000']
  );
  const [logo, setLogo] = useState(
    league.logos ? JSON.parse(league.logos)?.metaData.filename : null
  );
  const dispatch = useDispatch();
  const { alert } = useSelector((state) => state);
  const hiddenFileInput = React.useRef(null);
  const [changedLogo, setChangedLogo] = useState(false);
  let typeaheadRef = useRef();

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Required'),
    slug: Yup.string().required('Required'),
    colors: Yup.array().of(Yup.string()),
    altNames: Yup.string().nullable(),
    logos: Yup.mixed()
      .nullable()
      .notRequired()
      .test(
        'FILE_SIZE',
        'Uploaded file is too big.',
        (value) => !changedLogo || !value || (value && value.size <= FILE_SIZE)
      )
      .test(
        'FILE_FORMAT',
        'Uploaded file has unsupported format.',
        (value) =>
          !changedLogo || !value || (value && imgType.includes(value.type))
      ),
  });

  const {
    setFieldValue,
    handleSubmit,
    handleChange,
    values,
    errors,
  } = useFormik({
    initialValues: {
      name: league.name,
      slug: league.slug,
      colors: JSON.parse(league.colors),
      altNames: league.altNames ? JSON.parse(league.altNames) : [],
      logos: league.logos ? JSON.parse(league.logos) : '',
      id: league.id,
      _version: league._version,
    },
    validationSchema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit(values) {
      // change out empty array
      let newAltNames =
        values.altNames.length === 0 ? null : JSON.stringify(values.altNames);

      // Check if no change
      if (
        values.name === league.name &&
        values.slug === league.slug &&
        JSON.stringify(values.colors) === league.colors &&
        newAltNames === league.altNames &&
        !changedLogo
      ) {
        dispatch(alertActions.success('No changes made.'));
        reset();
        return;
      }

      setIsLoading(true);
      dispatch(alertActions.clear());
      dispatch(adminActions.editLeague(values, changedLogo));
      setChangedLogo(false);
    },
  });

  //console.log(errors)
  //console.log(values)

  useImperativeHandle(ref, () => ({
    submit() {
      handleSubmit();
    },
  }));

  const checkBase64 = (string) => {
    return string.substr(0, 5) === 'data:';
  };

  const colorButton = (index) => {
    return (
      colors && (
        <OverlayTrigger
          placement="bottom"
          //trigger={["click", "focus"]}
          trigger="click"
          rootClose
          overlay={
            <Popover id={`popover-colorpicker-${index}`}>
              <SketchPicker
                disableAlpha
                presetColors={[]}
                color={colors[index]}
                onChange={(value) => {
                  setFieldValue(`colors[${index}]`, value.hex);
                  colors[index] = value.hex;
                  setColors(colors);
                }}
              />
            </Popover>
          }
        >
          <Button
            className="colorSelect"
            disabled={isSaving || !isEditing}
            style={{
              marginLeft: index === 0 ? '0' : null,
              background: colors[index],
            }}
          ></Button>
        </OverlayTrigger>
      )
    );
  };

  const onDrop = useCallback(
    (acceptedFiles) => {
      const handleLogoChange = (files) => {
        const fileUploaded = files;
        if (!fileUploaded) {
          return false;
        }

        if (!alert.clear) {
          dispatch(alertActions.clear());
        }

        let reader = new FileReader();
        reader.onload = (e) => {
          const img = new Image();
          img.onload = () => {
            if (fileUploaded.size > FILE_SIZE) {
              dispatch(alertActions.error('Image exceeds max 5MB.'));
              return false;
            }

            setChangedLogo(true);
            setLogo(reader.result);
            //console.log(reader.result)
          };
          img.onerror = () => {
            dispatch(alertActions.error('Could not load image.'));
            return false;
          };
          img.src = e.target.result;
        };

        setFieldValue(`logos`, fileUploaded);
        reader.readAsDataURL(fileUploaded);
      };

      handleLogoChange(acceptedFiles);
    },
    [alert.clear, dispatch, setFieldValue]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const logoButton = () => {
    const handleClick = (event) => {
      hiddenFileInput.current.click();
    };

    return (
      <>
        <label>Logo</label>
        <Button
          variant="outline-light"
          disabled={isSaving || !isEditing}
          className={'logoSelect'}
          onClick={handleClick}
          style={{ flexDirection: logo ? 'column' : null }}
        >
          {logo ? (
            <img
              className="img-thumbnail"
              alt={`${league.name} Logo`}
              src={checkBase64(logo) ? logo : `${MEDIAURL}${logo}`}
            />
          ) : isDragActive ? (
            '+'
          ) : (
            '?'
          )}
        </Button>
        <input
          type="file"
          {...getInputProps()}
          ref={hiddenFileInput}
          onChange={(e) => onDrop(e.target.files[0])}
          style={{ display: 'none' }}
          accept="image/png,image/gif,image/jpeg"
        />
      </>
    );
  };

  const onKeyDown = useCallback((e) => {
    if (e.key === 'Enter') {
      setFieldValue('altNames', [...values.altNames, e.target.value]);
      typeaheadRef.clear();
    }
  });

  const setupBody = () => {
    return (
      <>
        <Row>
          <div className="setupFormLine">
            <Col {...getRootProps()}>{logoButton()}</Col>
            <Col sm={8}>
              <Row>
                <label>Colors</label>
                {colorButton(0)}
                {colorButton(1)}
                {colorButton(2)}
                {colorButton(3)}
              </Row>
              <Row>
                <div className="setupFormLine">
                  <Col style={{ padding: '0 2% 0 0' }}>
                    <label>
                      Gender
                      {errors.gender ? (
                        <span className="form-error">{errors.gender}</span>
                      ) : null}
                    </label>
                    <Form.Control
                      plaintext
                      as="select"
                      label={'Required'}
                      id="gender"
                      defaultValue={league.gender}
                      disabled={true}
                    >
                      <option value="Select..." disabled hidden>
                        Select...
                      </option>
                      {Object.values(genderType).map((type, i) => {
                        return <option key={i}>{type}</option>;
                      })}
                    </Form.Control>
                  </Col>
                  <Col style={{ padding: '0 0 0 2%' }}>
                    <label>
                      Type
                      {errors.type ? (
                        <span className="form-error">{errors.type}</span>
                      ) : null}
                    </label>
                    <Form.Control
                      plaintext
                      as="select"
                      label={'Required'}
                      id="type"
                      defaultValue={league.type}
                      disabled={true}
                    >
                      <option value="Select..." disabled hidden>
                        Select...
                      </option>
                      {Object.values(leagueType).map((type, i) => {
                        return <option key={i}>{type}</option>;
                      })}
                    </Form.Control>
                  </Col>
                </div>
              </Row>
            </Col>
          </div>
        </Row>
        <Row>
          <Col>
            <label>
              Name
              {errors.name ? (
                <span className="form-error">{errors.name}</span>
              ) : null}
            </label>
            <Form.Control
              plaintext
              name="name"
              type="text"
              autoComplete="off"
              value={values.name}
              onChange={handleChange}
              disabled={isSaving || !isEditing}
              placeholder="Enter league name"
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <label>Slug</label>
            <Form.Control
              plaintext
              name="slug"
              type="text"
              autoComplete="off"
              value={values.slug}
              onChange={handleChange}
              disabled={isSaving || !isEditing}
              placeholder="Autogenerated"
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <label>Other Names (Use #hashtag for display name)</label>
            <Typeahead
              onChange={(selected) => {
                setFieldValue('altNames', selected);
              }}
              allowNew
              options={[]}
              id="leagueAltNames"
              className="typeahead"
              multiple
              dropup
              disabled={isSaving || !isEditing}
              placeholder={'Optional'}
              selected={values.altNames ? values.altNames : []}
              renderMenu={(results, menuProps) => null}
              onKeyDown={onKeyDown}
              ref={(a) => (typeaheadRef = a)}
            />
          </Col>
        </Row>
      </>
    );
  };

  return (
    <Form onSubmit={handleSubmit} className="leagueProfile">
      {setupBody()}
    </Form>
  );
});
