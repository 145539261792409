import React, { useEffect, useState, useRef, useMemo, forwardRef } from 'react';
import { Table } from 'react-bootstrap';
import {
  useTable,
  useSortBy,
  useRowSelect,
  useMountedLayoutEffect,
} from 'react-table';
import { useDispatch } from 'react-redux';
import { adminActions } from '../../redux/_actions';

const IndeterminateCheckbox = forwardRef(({ indeterminate, ...rest }, ref) => {
  const defaultRef = useRef();
  const resolvedRef = ref || defaultRef;

  useEffect(() => {
    resolvedRef.current.indeterminate = indeterminate;
  }, [resolvedRef, indeterminate]);

  return (
    <>
      <input className="checkbox" type="checkbox" ref={resolvedRef} {...rest} />
    </>
  );
});

const compareIgnoreCase = (a, b) => {
  let r1 = String(a).toLowerCase();
  let r2 = String(b).toLowerCase();

  if (r1 < r2) {
    return -1;
  }
  if (r1 > r2) {
    return 1;
  }
  return 0;
};

const sortText = {
  alphanumeric: (row1, row2, columnName) => {
    return compareIgnoreCase(row1.values[columnName], row2.values[columnName]);
  },
};

function TableComponent(props) {
  const {
    columns,
    data,
    onClick,
    initialState,
    textSort,
    editable,
    checkbox,
    updateable,
    disabled,
  } = props;
  const skipPageResetRef = useRef(false);
  const [newData, setNewData] = useState(data); // required for first read
  const dispatch = useDispatch();

  useEffect(() => {
    setNewData(data);

    if (updateable) {
      dispatch(
        adminActions.updateTable({
          initialData: data,
          initialSelected: initialState.selectedRowIds,
          newData: data,
          newSelected: initialState.selectedRowIds,
        })
      );
    }
  }, [updateable]);

  const handleClick = (e, rowData) => {
    if (onClick) {
      onClick(e, rowData);
    } else {
      return;
    }
  };

  const updateMyData = (rowIndex, columnID, value) => {
    // We also turn on the flag to not reset the page
    skipPageResetRef.current = true;

    const changedData = (old) =>
      old.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...row,
            [columnID]: value, /// added this to check data updates
          };
        }
        return row;
      });

    const updatedData = changedData(newData);
    setNewData(updatedData);
    dispatch(adminActions.updateTable({ newData: updatedData }));
    skipPageResetRef.current = false;
  };

  const EditableCell = ({
    cell: { value: initialValue },
    row: { index },
    column: { id },
    updateMyData, // This is a custom function that we supplied to our table instance
    editable,
  }) => {
    // We need to keep and update the state of the cell normally
    const [value, setValue] = useState(initialValue);

    const onChange = (e) => {
      setValue(e.target.value);
    };

    // We'll only update the external data when the input is blurred
    const onBlur = () => {
      updateMyData(index, id, value);
    };

    // If the initialValue is changed externally, sync it up with our state
    useEffect(() => {
      setValue(initialValue);
    }, [initialValue]);

    if (!editable) {
      return `${initialValue}`;
    }

    return <input value={value} onChange={onChange} onBlur={onBlur} />;
  };

  const defaultColumn = useMemo(
    () => ({
      // And also our default editable cell
      minWidth: 5,
      width: 100,
      maxWidth: 200,
      Cell: EditableCell,
    }),
    []
  );

  //Note: columns and data must remain that variable name
  const tableInstance = useTable(
    {
      columns,
      data: newData,
      initialState,
      sortTypes: textSort ? sortText : null,
      updateMyData,
      //disablePageResetOnDataChange: skipPageResetRef.current,
      autoResetPage: !skipPageResetRef.current,
      autoResetSelectedRows: !skipPageResetRef.current,
      defaultColumn,
    },
    useSortBy,
    useRowSelect,
    (hooks) => {
      checkbox &&
        hooks.visibleColumns.push((columns) => [
          // Let's make a column for selection
          {
            id: 'selection',
            // The header can use the table's getToggleAllRowsSelectedProps method
            // to render a checkbox
            Header: ({ getToggleAllRowsSelectedProps }) => (
              <div>
                <IndeterminateCheckbox
                  {...getToggleAllRowsSelectedProps({
                    onChange: (e) => {
                      const newSelected = {};

                      for (let r = 0; r < tableInstance.rows.length; r++) {
                        newSelected[r] = e.target.checked;
                      }

                      dispatch(
                        adminActions.updateTable({ newSelected: newSelected })
                      );
                      tableInstance.toggleAllRowsSelected(e.target.checked);
                    },
                  })}
                  disabled={disabled}
                />
              </div>
            ),
            // The cell can use the individual row's getToggleRowSelectedProps method
            // to the render a checkbox
            Cell: ({ row }) => (
              <div>
                <IndeterminateCheckbox
                  {...row.getToggleRowSelectedProps({
                    onChange: (e) => {
                      const newSelected = {
                        ...tableInstance.state.selectedRowIds,
                        [row.index]: e.target.checked,
                      };
                      dispatch(
                        adminActions.updateTable({ newSelected: newSelected })
                      );

                      // Since following function is callback will update at end of render exectuion
                      row.toggleRowSelected(e.target.checked);
                    },
                  })}
                  disabled={disabled}
                />
              </div>
            ),
          },
          ...columns,
        ]);
    }
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    selectedFlatRows,
    state: { selectedRowIds },
  } = tableInstance;

  const generateSortingIndicator = (column) => {
    return column.isSorted ? (column.isSortedDesc ? ' ↓' : ' ↑') : '';
  };

  return (
    <Table responsive hover size="sm" {...getTableProps()}>
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                {column.render('Header')}
                {generateSortingIndicator(column)}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row, i) => {
          prepareRow(row);
          return (
            <tr
              {...row.getRowProps()}
              onClick={(e) => handleClick(e, row.original)}
            >
              {row.cells.map((cell, j) => {
                return (
                  <td {...cell.getCellProps()}>
                    {j === (checkbox ? 1 : 0)
                      ? i + 1
                      : cell.render('Cell', { editable: editable })}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
}

export default TableComponent;
