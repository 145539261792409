import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import VideoPlayer from './videoplayer';
import TriggerPanel from './triggerpanel';
import CompetitorList from './competitorlist';
import ScoreDeltas from './scoredeltas';
import RoundSummary from './roundsummary';
import SessionFooter from './sessionfooter';
import SessionHeader from './sessionheader';
import TeamStripes from './teamstripes';
import { useSelector } from 'react-redux';
import Header from '../helpers/header';
import { sessionStatus, sessionViewType } from '../../redux/_constants';
import CalendarDropdown from '../helpers/calendar-dropdown';
import './main.css';

function Main(props) {
  const teamA = useSelector((state) => state.producer.teamA);
  const teamB = useSelector((state) => state.producer.teamB);
  const round = useSelector((state) => state.producer.round);
  const loaded = useSelector((state) => state.producer.loaded);
  const view = useSelector((state) => state.session.view);

  const [teamAScore, setTeamAScore] = useState(0);
  const [teamBScore, setTeamBScore] = useState(0);

  const videoRow = (
    <Row className="players">
      <Col className="left">
        <VideoPlayer team={teamA} />
      </Col>
      <Col className="center" xs={1}>
        <TriggerPanel />
      </Col>
      <Col className="right">
        <VideoPlayer team={teamB} />
      </Col>
    </Row>
  );

  return (
    <div className="main">
      <Header>
        <SessionHeader />
      </Header>
      <TeamStripes colorA={teamA.color} colorB={teamB.color} />
      <Container>
        {view !== sessionViewType.SCOREBOARD ? videoRow : null}
        <Row className="lineups">
          <Col className="left">
            <CompetitorList team={teamA} left={true} />
            {
              <>
                <hr />
                <RoundSummary
                  team={teamA}
                  round={round}
                  left={true}
                  setTeamScore={setTeamAScore}
                />
              </>
            }
          </Col>
          <Col className="center" xs={1}>
            <ScoreDeltas teamAScore={teamAScore} teamBScore={teamBScore} />
          </Col>
          <Col className="right">
            <CompetitorList team={teamB} right={true} />
            {
              <>
                <hr />
                <RoundSummary
                  team={teamB}
                  round={round}
                  right={true}
                  setTeamScore={setTeamBScore}
                />
              </>
            }
          </Col>
        </Row>
        <SessionFooter teamA={teamA} teamB={teamB} />
      </Container>
    </div>
  );
}

export default Main;
