import { evaluatorConstants, initialEvaluatorState } from '../_constants';

export function evaluator(state = initialEvaluatorState, action) {
  switch (action.type) {
    case evaluatorConstants.INITIALIZE:
      return {
        ...state,
        evalPanelA: action.data.evalPanelA,
        evalPanelB: action.data.evalPanelB,
        config: action.config,
      };
    case evaluatorConstants.LOCK_SUB_SCORE:
      return {
        ...state,
        [action.data.panel]: {
          ...state[action.data.panel],
          finalScore: action.data.finalScore,
          subScoreComplete: action.data.subScoreComplete,
          subScores: {
            ...state[action.data.panel].subScores,
            [action.data.type]: action.data.subScoreData,
          },
        },
      };
    case evaluatorConstants.UNLOCK_SUB_SCORE:
      return {
        ...state,
        [action.data.panel]: {
          ...state[action.data.panel],
          subScoreComplete: action.data.subScoreComplete,
          finalScore: action.data.finalScore,
          subScores: {
            ...state[action.data.panel].subScores,
            [action.data.type]: action.data.subScoreData,
          },
        },
      };
    case evaluatorConstants.LOCK_FINAL_SCORE:
      return {
        ...state,
        [action.data.panel]: {
          ...state[action.data.panel],
          finalScore: action.data.finalScore,
          locked: action.data.locked,
          finishTime: action.data.finishTime,
          flags: {
            ...state[action.data.panel].flags,
            resolved: action.data.resolved,
          },
        },
      };
    case evaluatorConstants.UNLOCK_FINAL_SCORE:
      return {
        ...state,
        [action.data.panel]: {
          ...state[action.data.panel],
          finalScore: action.data.finalScore,
          locked: action.data.locked,
        },
      };
    case evaluatorConstants.RESET:
    case evaluatorConstants.RESOLVE_INQUIRY:
      return {
        ...state,
        [action.data.panel]: action.data.data,
      };
    default:
      return state;
  }
}
