export const eventConstants = {
  CREATE_ROUTINE_REQUEST: 'CREATE_ROUTINE_EVENT_REQUEST',
  CREATE_ROUTINE_SUCCESS: 'CREATE_ROUTINE_EVENT_SUCCESS',
  CREATE_ROUTINE_FAILURE: 'CREATE_ROUTINE_EVENT_FAILURE',
};

export const routineStatus = {
  CREATED: 'CREATED',
  ON_DECK: 'ON_DECK',
  ACTIVE: 'ACTIVE',
  JUDGING: 'JUDGING',
  COMPLETE: 'COMPLETE'
}

export const initialEventState = {
  routines: null,
  scores: null,
  clips: null,
};
