import React, { useMemo } from 'react';
import { Row, Col } from 'react-bootstrap';
import { numToApparatusM, numToApparatusW } from '../../utilities/conversions';
import { useSelector } from 'react-redux';
import * as Icons from '../helpers/icons';
import { roundScore } from '../../utilities/scoring';
import { createSelector } from 'reselect';

const getLineupData = (state, lineup) => lineup;
const getScores = (state) => state.producer.scores;
const getTeamScoring = (state) => state.session.teamScoring;
const createScoreSelector = () =>
  createSelector(
    [getLineupData, getScores, getTeamScoring],
    (lineupData, scores, teamScoring) => {
      const score = scores ? roundScore(lineupData, teamScoring) : 0;
      return score.toFixed(3);
    }
  );

function RoundSummary(props) {
  const { team, round, left, right, setTeamScore } = props;
  const { color, lineup } = team || {};
  const gender = useSelector((state) => state.session.gender);
  const selectScore = useMemo(createScoreSelector, []);
  const score = useSelector((state) => selectScore(state, lineup));
  setTeamScore(score);

  let background = '#f8f8f8';

  if (left) {
    background = `linear-gradient(90deg, ${
      color ? color : background
    } 0% 3%, #f8f8f8 3% 100%)`;
  }
  if (right) {
    background = `linear-gradient(90deg, #f8f8f8 0% 97%, ${
      color ? color : background
    } 97% 100%)`;
  }

  const apparatus = () => {
    if (gender === 'MALE') {
      return numToApparatusM(round, true);
    }

    if (gender === 'FEMALE') {
      return numToApparatusW(round, true);
    }
  };

  return (
    <div className="roundSummary">
      <Row style={{ height: '100%' }}>
        <Col
          className="vCenter logo"
          style={{ background: background }}
          xs={2}
        ></Col>
        <Col className="vCenter icon" xs={2}>
          {Icons?.[`${apparatus()}noText`] ?? apparatus.toLowerCase()}
        </Col>
        <Col className="vCenter team" xs={5}>
          {`Event Total`}
        </Col>
        <Col className="vCenter score" xs={3}>
          {score}
        </Col>
      </Row>
    </div>
  );
}

export default RoundSummary;
