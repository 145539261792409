import React, {
  useEffect,
  useState,
  useMemo,
  useImperativeHandle,
  useRef,
} from 'react';
import {
  Row,
  Col,
  Form,
  ToggleButton,
  ToggleButtonGroup,
} from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { adminActions, alertActions } from '../../redux/_actions';
import { athleteYearType } from '../../redux/_constants';
import * as Yup from 'yup';
import TableComponent from '../helpers/tablecomponent';
import * as Defaults from '../../utilities/constants';
import {
  strApparatusToArray,
  arrayToStrApparatus,
  dateTime,
} from '../../utilities/conversions';
import { dataUtilities } from '../../utilities/data';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const RosterTitleForm = React.forwardRef((props, ref) => {
  const {
    isEditing,
    isSaving,
    mode,
    setMode,
    roster,
    teamId,
    setIsLoading,
    reset,
  } = props;
  const { table } = useSelector((state) => state.admin.status);
  const dispatch = useDispatch();
  const { modeType } = Defaults;
  const { LIST, CREATE, DETAIL } = modeType;

  useImperativeHandle(ref, () => ({
    submit() {
      handleSubmit();
    },
  }));

  const validationSchema = Yup.object().shape({
    title: Yup.string().required('Required'),
    activeDate: Yup.string().required('Required'),
  });

  const {
    setFieldValue,
    handleSubmit,
    handleChange,
    values,
    errors,
  } = useFormik({
    initialValues: {
      teamId: teamId,
      activeDate:
        mode === CREATE || mode === LIST
          ? Defaults.rosterDefault.activeDate
          : new Date(roster.activeDate),
      title:
        mode === CREATE || mode === LIST
          ? Defaults.rosterDefault.title
          : roster.title,
      id: roster ? roster.id : null,
      _version: roster ? roster._version : null,
    },
    //enableReinitialize: true,
    validationSchema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit(values) {
      const titleChanged =
        values.activeDate.toJSON() !== roster.activeDate ||
        values.title !== roster.title;
      const dataChanges = dataUtilities.checkTableChange(
        table.initialData,
        table.newData,
        table.initialSelected,
        table.newSelected
      );
      const dataChanged =
        dataChanges.updates.length !== 0 || dataChanges.creates.length !== 0;

      if (!titleChanged && !dataChanged) {
        setMode(DETAIL);
        dispatch(alertActions.success('No changes made.'));
        reset();
        return;
      } else {
        dispatch(
          adminActions.editRoster({
            title: titleChanged ? values : null,
            table: dataChanged ? dataChanges : null,
            roster: roster,
            newData: table.newData,
          })
        );
        setIsLoading(true);
      }
    },
  });

  return (
    <Row className="rosterTitles">
      <Form onSubmit={handleSubmit}>
        <div className="setupFormLine">
          <Col sm={8}>
            <label>
              Title
              {errors.title ? (
                <span className="form-error">{errors.type}</span>
              ) : null}
            </label>
            <Form.Control
              plaintext
              name="title"
              type="text"
              autoComplete="off"
              value={values.title}
              onChange={handleChange}
              disabled={isSaving || !isEditing}
              placeholder="Enter Roster Title"
            />
          </Col>
          <Col>
            <label>
              Active Date
              {errors.activeDate ? (
                <span className="form-error">{errors.name}</span>
              ) : null}
            </label>
            <DatePicker
              name="activeDate"
              disabled={isSaving || !isEditing}
              selected={values.activeDate}
              onChange={(value) => setFieldValue('activeDate', value)}
              dateFormat="MM/dd/yyyy"
              popperPlacement="top-end"
            />
          </Col>
        </div>
      </Form>
    </Row>
  );
});

export const TeamRoster = React.forwardRef((props, ref) => {
  const {
    teamId,
    isEditing,
    isSaving,
    reset,
    setIsLoading,
    setIsEditing,
    setTabMode,
    tabMode,
  } = props;
  const { rosters, status, athletes, teams, rosterLinks } = useSelector(
    (state) => state.admin
  );
  const { alert } = useSelector((state) => state);
  const team = teams.byId[teamId];
  const [roster, setRoster] = useState(null);
  const { modeType, mApparatusAbbv, wApparatusAbbv } = Defaults;
  const { LIST, DETAIL, CREATE, EDIT } = modeType;
  let abbv = { MALE: mApparatusAbbv, FEMALE: wApparatusAbbv };
  const dispatch = useDispatch();
  const formRef = useRef();

  const rosterDetail = (e, row) => {
    if (!alert.clear) {
      dispatch(alertActions.clear());
    }
    setRoster(rosters.byId[row.id]);

    if (rosters.byId[row.id].athletes.items.length === 0) {
      setTabMode(EDIT);
      setIsEditing(true);
    } else {
      setTabMode(DETAIL);
      setIsEditing(false);
    }
  };

  useEffect(() => {
    if (!alert.clear && alert.type === 'alert-success') {
      // Update to see if need to add new roster or selected roster
      if (tabMode === CREATE) {
        // assign last on AllIds array since it was added by push
        const id = rosters.allIds[rosters.allIds.length - 1];
        setRoster(rosters.byId[id]);
        setTabMode(EDIT);
      }

      // Handle the effects of a save operation and data updates
      if (tabMode === EDIT) {
        setTabMode(DETAIL);
        setRoster(rosters.byId[roster.id]); // need to set to new roster
        reset();
      }
    }
  }, [
    alert,
    setTabMode,
    rosters,
    tabMode,
    CREATE,
    EDIT,
    DETAIL,
    reset,
    roster,
  ]);

  useEffect(() => {
    if (teamId !== status.filterId) {
      dispatch(adminActions.getTeamAthletes(teamId));
    }
  }, [teamId, dispatch, status.filterId]);

  useImperativeHandle(ref, () => ({
    submit() {
      if (!alert.clear) {
        dispatch(alertActions.clear());
      }
      formRef.current.submit();
    },
    create() {
      if (!alert.clear) {
        dispatch(alertActions.clear());
      }
      const input = { ...Defaults.rosterDefault, teamId: teamId };
      dispatch(adminActions.createRoster(input));
      setTabMode(CREATE);
    },
    back() {
      if (!alert.clear) {
        dispatch(alertActions.clear());
      }
      setIsEditing(false);
      setTabMode(LIST);
    },
    edit() {
      if (!alert.clear) {
        dispatch(alertActions.clear());
      }
      setTabMode(EDIT);
    },
  }));

  const rosterHeaders = useMemo(
    () => [
      { Header: '#', accessor: 'order' },
      { Header: 'Name', accessor: 'name' },
      {
        Header: 'Class',
        accessor: 'classYear',
        Cell: (r) => {
          const { row, column, cell, updateMyData } = r;

          return (
            <Form.Control
              as="select"
              label={'Class'}
              id="class"
              value={cell.value ? cell.value : '-'}
              onChange={(e) => {
                updateMyData(
                  row.index,
                  column.id,
                  e.target.value === '-' ? '' : e.target.value
                );
              }}
              disabled={isSaving || !isEditing}
            >
              <option value="-">-</option>
              {Object.keys(athleteYearType).map((classYear) => {
                return (
                  <option key={classYear} value={classYear}>
                    {classYear}
                  </option>
                );
              })}
            </Form.Control>
          );
        },
      },
      {
        Header: 'Position',
        accessor: 'position',
        Cell: (r) => {
          const { row, column, cell, updateMyData } = r;

          return (
            <ToggleButtonGroup
              name="apparatus"
              className="apparatusToggle"
              type="checkbox"
              value={strApparatusToArray(cell.value ? cell.value : '000000')}
              onChange={(selection) => {
                updateMyData(
                  row.index,
                  column.id,
                  arrayToStrApparatus(selection)
                );
              }}
            >
              {abbv[team.gender].map((a, i) => {
                return (
                  <ToggleButton
                    variant="outline-secondary"
                    className="vCenter"
                    value={i}
                    key={i}
                    disabled={isSaving || !isEditing}
                  >
                    {a}
                  </ToggleButton>
                );
              })}
            </ToggleButtonGroup>
          );
        },
      },
    ],
    [isSaving, isEditing, abbv, team.gender]
  );

  const rosterData = useMemo(() => {
    // if saving need to incorporate new changes
    // Take new table data and do quick filter and return
    // Need to check if creating blank

    if (isSaving && status.table.newData) {
      return status.table.newData.filter(
        (el, i) => status.table.newSelected[i]
      );
    }

    const rosterLinkAthletes =
      roster &&
      roster.athletes &&
      roster.athletes.items &&
      roster.athletes.items.length > 0 &&
      Object.assign(
        {},
        ...roster.athletes.items.map((id) => ({
          [rosterLinks.byId[id].athleteId]: rosterLinks.byId[id],
        }))
      ); // transposes to athleteId: rosterLinkId, will overwrite dupes

    //console.log(roster)
    //console.log(rosterLinkAthletes)
    //console.log(team)

    return !isEditing && rosterLinkAthletes
      ? Object.keys(rosterLinkAthletes)
          .map((athleteId, i) => {
            const {
              active,
              classYear,
              position,
              _version,
              id,
            } = rosterLinkAthletes[athleteId];
            const { name } = athletes.byId[athleteId];

            return {
              order: i + 1,
              name: name ? name : '',
              classYear: classYear ? classYear : '',
              position: position ? position : '000000',
              athleteId: athleteId,
              rosterLinkId: id,
              update: false,
              active: active,
              _version: _version,
            };
          })
          .filter((el) => el.active)
      : // No existing roster create first one
        status.filter &&
          status.filter.map((athleteId, i) => {
            const athleteRosterDetails =
              rosterLinkAthletes && rosterLinkAthletes[athleteId];
            const { active, classYear, position, _version, id } =
              athleteRosterDetails || {};
            const { name } = athletes.byId[athleteId];

            return {
              order: i + 1,
              name: name ? name : '',
              classYear: classYear ? classYear : '',
              position: position ? position : '000000',
              athleteId: athleteId,
              rosterLinkId: id ? id : null,
              update: false,
              active: active ? active : false,
              _version: _version ? _version : null,
            };
          });
  }, [
    roster,
    status,
    isSaving,
    isEditing,
    athletes.byId,
    rosterLinks.byId,
    team,
  ]);

  const rosterForm = () => {
    let existsSelectedRow = false;
    const initialState = rosterData && {
      sortBy: [{ id: 'name', desc: false }],
      selectedRowIds: Object.assign(
        {},
        ...rosterData.map((x, i) => {
          if (x.active && !existsSelectedRow) {
            existsSelectedRow = true;
          }
          return { [i]: x.active };
        })
      ),
    };

    //console.log(rosterData)

    return (
      rosterData && (
        <div style={{ minHeight: '40vh' }} className="rosterSelection">
          {!existsSelectedRow && !isEditing && !isSaving ? (
            emptyRoster()
          ) : (
            <Row className={isSaving || !isEditing ? 'disabled' : null}>
              <TableComponent
                columns={rosterHeaders}
                data={rosterData}
                onClick={false}
                initialState={initialState}
                textSort={false}
                editable={false}
                updateable={isEditing && !isSaving}
                checkbox={isEditing}
                disabled={!isEditing || isSaving}
                hideRows={isSaving}
              />
            </Row>
          )}
          <RosterTitleForm
            isEditing={isEditing}
            isSaving={isSaving}
            roster={roster}
            mode={tabMode}
            teamId={team.id}
            setIsLoading={setIsLoading}
            ref={formRef}
            reset={reset}
            setMode={setTabMode}
          />
        </div>
      )
    );
  };

  const emptyList = () => {
    return (
      <Row
        style={{ minHeight: '40vh', textAlign: 'center' }}
        className="vCenter"
      >
        <span>No rosters created.</span>
      </Row>
    );
  };

  const emptyRoster = () => {
    return (
      <Row
        style={{ minHeight: '40vh', textAlign: 'center' }}
        className="vCenter"
      >
        <span>No athletes in roster.</span>
      </Row>
    );
  };

  const headers = useMemo(
    () => [
      { Header: '#', accessor: 'col1' },
      { Header: 'Title', accessor: 'col2' },
      { Header: 'Athletes', accessor: 'col3' },
      { Header: 'Active Date', accessor: 'col4' },
      { Header: 'Updated', accessor: 'col5' },
    ],
    []
  );

  const rosterListData = useMemo(() => {
    return (
      team.rosters &&
      team.rosters.items.length !== 0 &&
      team.rosters.items.map((id, i) => {
        const { title, updatedAt, athletes, activeDate } = rosters.byId[id];
        const count = athletes.items
          .map((el) => rosterLinks.byId[el])
          .filter((el) => el.active).length;

        return {
          col1: i + 1,
          col2: title,
          col3: athletes && athletes.items ? count : 0,
          col4: dateTime(activeDate, false),
          col5: dateTime(updatedAt, false),
          id: id,
        };
      })
    );
  }, [team.rosters, rosters.byId, rosterLinks.byId]);

  const rosterList = () => {
    const initialState = {
      sortBy: [{ id: 'col4', desc: true }],
    };

    return (
      <Row style={{ minHeight: '40vh' }}>
        <TableComponent
          columns={headers}
          data={rosterListData}
          onClick={rosterDetail}
          initialState={initialState}
          textSort={false}
        />
      </Row>
    );
  };

  const setupBody = () => {
    switch (tabMode) {
      case EDIT:
        return rosterForm();
      case LIST:
      case CREATE:
        if (team.rosters.items.length === 0) {
          return emptyList();
        } else {
          return rosterList();
        }
      case DETAIL:
        return rosterForm();
      default:
        return null;
    }
  };

  return <div className="teamRoster">{setupBody()}</div>;
});
