import { producerConstants, initialProducerState } from '../_constants';

export function producer(state = initialProducerState, action) {
  switch (action.type) {
    case producerConstants.RESET:
      return {
        ...state,
        teamA: action.data.teamA,
        teamB: action.data.teamB,
        round: action.data.round,
        loaded: true,
        onAir: false,
        perTeam: action.data.perTeam,
        onAirAthlete: 1,
        onEvalAthletes: { A: null, B: null },
        evaluatorConfig: action.data.evaluatorConfig,
        exportHeader: action.data.exportHeader,
        streamingConfig: action.data.streamingConfig,
        live: action.data.live,
      };
    case producerConstants.INITIALIZE:
      return {
        ...state,
        ...action.data,
        /*
        teamA: action.data.teamA,
        teamB: action.data.teamB,
        round: action.data.round,
        loaded: true,
        onAir: false,
        perTeam: action.data.perTeam,
        onAirAthlete: action.data.onAirAthlete,
        onEvalAthletes: action.data.onEvalAthletes,
        evaluatorConfig: action.data.evaluatorConfig,
        exportData: action.data.exportData,
        exportHeader: action.data.exportHeader,
        streamingConfig: action.data.streamingConfig,
        live: action.data.live,*/
      };
    case producerConstants.SYNC_PRODUCER:
      return {
        ...state,
        ...action.data,
        teamA: {
          ...state.teamA,
          ...action.data.teamA,
        },
        teamB: {
          ...state.teamB,
          ...action.data.teamB,
        },
      };
    case producerConstants.JUDGE:
      return {
        ...state,
        judge: action.judge,
        edit: false,
        clip: false,
      };
    case producerConstants.EDIT:
      return {
        ...state,
        edit: action.edit,
        clip: false,
        judge: false,
      };
    case producerConstants.CLIP:
      return {
        ...state,
        clip: action.clip,
        edit: false,
        judge: false,
      };
    case producerConstants.LIVE:
      return {
        ...state,
        live: action.live,
        completedRounds: action.completedRounds,
      };
    case producerConstants.RESET_LINEUP:
      return {
        ...state,
        onAir: false,
        onAirAthlete: 1,
        onEvalAthletes: { A: null, B: null },
        teamA: {
          ...state.teamA,
          onAir: false,
          onEval: false,
        },
        teamB: {
          ...state.teamB,
          onAir: false,
          onEval: false,
        },
        doneEvalAthletes: { A: [], B: [] }
      }
    case producerConstants.ON_AIR:
      return {
        ...state,
        onAir: true,
        [action.data.team]: {
          ...state[action.data.team],
          onAir: true,
        },
      };
    case producerConstants.ON_EVAL:
      return {
        ...state,
        onAir: false,
        onAirAthlete: action.data.onAirAthlete,
        onEvalAthletes: action.data.onEvalAthletes,
        [action.data.team]: {
          ...state[action.data.team],
          onAir: false,
          onEval: true,
        },
      };
    case producerConstants.CANCEL_EVAL:
      return {
        ...state,
        onAir: false,
        onEvalAthletes: action.data.onEvalAthletes,
        [action.data.team]: {
          ...state[action.data.team],
          onAir: false,
          onEval: false,
        },
      };
    case producerConstants.OFF_EVAL:
      return {
        ...state,
        [action.data.team]: {
          ...state[action.data.team],
          lineup: action.data.lineup,
          onEval: false,
        },
        onEvalAthletes: action.data.onEvalAthletes,
        doneEvalAthletes: action.data.doneEvalAthletes,
      };
    case producerConstants.SET_END:
    case producerConstants.REQUEST_INQUIRY:
      return {
        ...state,
        [action.data.team]: {
          ...state[action.data.team],
          lineup: action.data?.lineup ?? state[action.data.team].lineup,
        },
      };
    case producerConstants.EXPORT_DATA:
      return {
        ...state,
        exportData: action.data,
      };
    case producerConstants.RESOLVE_INQUIRY:
      return {
        ...state,
        [action.data.team]: {
          ...state[action.data.team],
          onEval: true,
        },
        onEvalAthletes: action.data.onEvalAthletes,
      };
    case producerConstants.CHANGE_ROUND:
      return {
        ...state,
        round: action.data.round,
        onAirAthlete: action.data.onAirAthlete,
        doneEvalAthletes: action.data.doneEvalAthletes,
        teamA: {
          ...state.teamA,
          lineup: action.data.teamALineup ?? state.teamA.lineup,
        },
        teamB: {
          ...state.teamB,
          lineup: action.data.teamBLineup ?? state.teamB.lineup,
        },
      };
    case producerConstants.UPDATE_LINEUP_SUCCESS:
      return {
        ...state,
        [`team${action.data.team}`]: {
          ...state[`team${action.data.team}`],
          lineup:
            action.data.newLineup ?? state[`team${action.data.team}`].lineup,
        },
      };
    case producerConstants.SHOW_SCORES:
      return {
        ...state,
        scores: !state.scores,
      };
    case producerConstants.FORCE:
      return {
        ...state,
        force: !state.force,
      };
    case producerConstants.FORCE_SELECT:
      return {
        ...state,
        force: action.data.force,
        onEvalAthletes: action.data.onEvalAthletes,
        onAirAthlete: action.data.onAirAthlete,
        onAir: action.data.onAir,
        teamA: {
          ...state.teamA,
          onAir: false,
          onEval: false,
        },
        teamB: {
          ...state.teamB,
          onAir: false,
          onEval: false,
        },
      };
    default:
      return state;
  }
}
