import React, { useState, useImperativeHandle } from 'react';
import {
  Button,
  Row,
  Col,
  Form,
  ToggleButtonGroup,
  ToggleButton,
} from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { adminActions, alertActions } from '../../redux/_actions';
import * as Yup from 'yup';
import { sessionType, genderType } from '../../redux/_constants';
import * as Defaults from '../../utilities/constants';
import {
  strApparatusToArray,
  arrayToStrApparatus,
} from '../../utilities/conversions';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import TeamSelector from '../setup/teamselector';
import { dataUtilities } from '../../utilities/data';

export const SessionSetup = React.forwardRef((props, ref) => {
  const {
    sessionId,
    isEditing,
    isSaving,
    reset,
    setIsLoading,
    footerToggle,
  } = props;
  const { sessions, sessionTeams } = useSelector((state) => state.admin);
  const session = sessions.byId[sessionId];
  const setup = session.type ? session.type : null;
  const [teamSelector, setTeamSelector] = useState(null);
  const dispatch = useDispatch();
  const { teams } = useSelector((state) => state.admin);
  const { wApparatusAbbv, mApparatusAbbv, MEDIAURL } = Defaults;
  const abbv =
    session.gender === genderType.FEMALE ? wApparatusAbbv : mApparatusAbbv;

  const validationSchema = Yup.object().shape({
    judging: Yup.boolean(),
    judgingRequired: Yup.boolean(),
    now: Yup.boolean(),
    name: Yup.string(),
    judgeCount: Yup.number(),
    teamA: Yup.string().nullable(),
    teamB: Yup.string().nullable(),
    lineup: Yup.boolean(),
    competition: Yup.boolean(),
    apparatus: Yup.string(),
    startAt: Yup.string(),
  });

  const getDefault = () => {
    const result = {
      id: session.id,
      judging: session.judging,
      judgingRequired: session.judgingRequired,
      name: session.name,
      teamA:
        session.sessionTeams.items.length > 0
          ? sessionTeams.byId[session.sessionTeams.items[0]].teamId
          : '',
      teamB:
        session.sessionTeams.items.length > 1
          ? sessionTeams.byId[session.sessionTeams.items[1]].teamId
          : '',
      status: session.status,
      competition: session.competition,
      apparatus: strApparatusToArray(session.apparatus),
      gender: session.gender,
      sessionKey: session.sessionKey,
      startAt: new Date(session.startAt), // have to convert to Date
      endAt: session.endAt ? new Date(session.endAt) : '',
      type: session.type,
      now: false,
      lineup: false,
    };

    return result;
  };

  const {
    setFieldValue,
    handleSubmit,
    handleChange,
    values /*errors*/,
  } = useFormik({
    initialValues: getDefault(),
    validationSchema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit(values) {
      const teamChange = dataUtilities.checkSessionTeamChange(
        values,
        session,
        sessionTeams
      );

      // Check to see if there are any changes
      if (
        values.name === session.name &&
        values.judging === session.judging &&
        values.judgingRequired === session.judgingRequired &&
        values.status === session.status &&
        values.competition === session.competition &&
        arrayToStrApparatus(values.apparatus) === session.apparatus &&
        values.gender === session.gender &&
        values.startAt.toJSON() === session.startAt &&
        (values.endAt ? values.endAt.toJSON() : null) === session.endAt &&
        values.type === session.type &&
        values.teamA ===
          (session.sessionTeams.items.length > 0
            ? sessionTeams.byId[session.sessionTeams.items[0]].teamId
            : '') &&
        values.teamB ===
          (session.sessionTeams.items.length > 1
            ? sessionTeams.byId[session.sessionTeams.items[1]].teamId
            : '') &&
        teamChange.updates.length === 0 &&
        teamChange.creates.length === 0
      ) {
        dispatch(alertActions.success('No changes made.'));
        reset();
        return;
      }

      const { teamA, teamB, now, lineup, ...rest } = values;
      const input = {
        ...rest,
        apparatus: arrayToStrApparatus(values.apparatus),
        endAt: values.endAt ? values.endAt : null,
        _version: session._version,
      };

      setIsLoading(true);
      dispatch(alertActions.clear());
      dispatch(adminActions.editSession(input, teamChange));
    },
  });

  //console.log(errors)
  //console.log(values)

  useImperativeHandle(ref, () => ({
    submit() {
      handleSubmit();
    },
  }));

  const setTeam = (side, team = null) => {
    if (side !== null) {
      setFieldValue(`team${side}`, team ? team.id : '');

      // Upgrade to Dual format
      if (side === 'B' && team && values.type !== sessionType.DUAL) {
        setFieldValue('type', sessionType.DUAL);
      }
    }
    setTeamSelector(null);
    footerToggle(false);
  };

  const getLogo = (side) => {
    const index = side.charCodeAt(0) - 65; // convert A to 0
    const sessionTeam = session.sessionTeams.items.filter((e) => {
      return e.order === index;
    });
    const teamId = sessionTeam.length && sessionTeam.teamId;

    const selectedTeamId = values[`team${side}`];
    let logo = '';

    if (selectedTeamId) {
      logo =
        teams.byId[selectedTeamId].logos &&
        JSON.parse(teams.byId[selectedTeamId].logos).metaData.filename;
    } else if (teamId) {
      logo =
        teams.byId[teamId].logos &&
        JSON.parse(teams.byId[teamId].logos).metaData.filename;
    }

    return logo ? (
      <img
        className="img-thumbnail"
        src={`${MEDIAURL}${logo}`}
        alt={`Team Logo`}
      />
    ) : (
      '?'
    );
  };

  const goTeamSelector = (side) => {
    setTeamSelector(side);
    footerToggle(true);
  };

  const setupBody = () => {
    const logoA = getLogo('A');
    const logoB = getLogo('B');

    return (
      <>
        <Row>
          <div className="setupFormLine">
            <Col>
              <label>Team A</label>
              <Button
                variant={logoA === '?' ? 'light' : 'outline-light'}
                disabled={isSaving || !isEditing}
                onClick={() => goTeamSelector('A')}
                className="teamSelect"
                style={{ fontSize: logoA === '?' ? '5rem' : null }}
              >
                {logoA}
              </Button>
            </Col>
            <Col className="vsCol">
              <label>&nbsp;</label>
              <div
                className={[
                  'vs',
                  setup !== sessionType.DUAL ? 'disabled' : null,
                ].join(' ')}
              >
                <span className={'vBlue'}>V</span>
                <span className={'vGray'}>S</span>
              </div>
            </Col>
            <Col>
              <label className={setup !== sessionType.DUAL ? 'disabled' : null}>
                Team B
              </label>
              <Button
                variant={logoB === '?' ? 'light' : 'outline-light'}
                disabled={isSaving || !isEditing}
                onClick={() => goTeamSelector('B')}
                className="teamSelect"
                style={{ fontSize: logoB === '?' ? '5rem' : null }}
              >
                {logoB}
              </Button>
            </Col>
          </div>
        </Row>
        <Row>
          <div className="setupFormLine">
            <Col>
              <label>When</label>
              <Form.Check
                type="switch"
                label={'Now'}
                id="now"
                checked={values.now}
                onChange={handleChange}
                disabled={isSaving || !isEditing}
              />
            </Col>
            <Col>
              <label>Date / Time</label>
              <DatePicker
                name="startAt"
                disabled={isSaving || !isEditing}
                selected={values.startAt}
                onChange={(value) => setFieldValue('startAt', value)}
                showTimeSelect
                //minDate={new Date()}  Allow backdating in admin
                dateFormat="MM/dd/yyyy h:mm aa"
                popperPlacement="auto"
              />
            </Col>
          </div>
        </Row>
        <Row>
          <div className="setupFormLine">
            <Col>
              <label>Format</label>
              <Form.Check
                type="switch"
                label={'Competition'}
                id="competition"
                checked={values.competition}
                onChange={handleChange}
                disabled={isSaving || !isEditing}
              />
            </Col>
            <Col>
              <label></label>
              <Form.Check
                type="switch"
                label={'Lineup'}
                id="lineup"
                checked={values.lineup}
                onChange={handleChange}
                disabled={isSaving || !isEditing}
              />
            </Col>
          </div>
        </Row>
        <Row>
          <div className="setupFormLine">
            <Col>
              <label>Apparatus</label>
              <ToggleButtonGroup
                name="apparatus"
                className="apparatusToggle"
                type="checkbox"
                value={values.apparatus}
                onChange={(value) => {
                  if (value.length > 0) {
                    setFieldValue('apparatus', value);
                  }
                }}
              >
                {abbv.map((a, i) => {
                  return (
                    <ToggleButton
                      variant="outline-primary"
                      className="vCenter"
                      value={i}
                      key={i}
                      disabled={isSaving || !isEditing}
                    >
                      {a}
                    </ToggleButton>
                  );
                })}
              </ToggleButtonGroup>
            </Col>
          </div>
        </Row>
        <Row>
          <div className="setupFormLine">
            <Col>
              <label>Judging</label>
              <Form.Check
                type="switch"
                label={'Enabled'}
                id="judging"
                onChange={handleChange}
                checked={values.judging}
                disabled={isSaving || !isEditing}
              />
            </Col>
            <Col>
              <label></label>
              <Form.Check
                type="switch"
                label={'Required'}
                id="judgingRequired"
                onChange={handleChange}
                checked={values.judgingRequired}
                disabled={isSaving || !isEditing}
              />
            </Col>
          </div>
        </Row>
        <Row>
          <div className="setupFormLine">
            <Col>
              <label>Session Name</label>
              <input
                name="name"
                type="text"
                autoComplete="off"
                onChange={handleChange}
                value={values.name}
                disabled={isSaving || !isEditing}
              />
            </Col>
          </div>
        </Row>
      </>
    );
  };

  return (
    <Form onSubmit={handleSubmit} className="createOrJoinForm">
      {setupBody()}
      {teamSelector ? (
        <TeamSelector
          side={teamSelector}
          setTeam={setTeam}
          session={session}
          values={values}
        />
      ) : null}
    </Form>
  );
});
