import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { userActions } from '../../redux/_actions';

function UserBubble() {
  const { user } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(userActions.logout());
  };

  const getInitials = (name) => {
    let names = name.split(' '),
      initials = names[0].substring(0, 1).toUpperCase();

    if (names.length > 1) {
      initials += names[names.length - 1].substring(0, 1).toUpperCase();
    }
    return initials;
  };

  const initials = user && user.attributes && getInitials(user.attributes.name);

  return (
    <Dropdown className="userBubble">
      <Dropdown.Toggle
        id="dropdown-userBubble"
        className="vGray"
        variant="outline"
      >
        {initials}
      </Dropdown.Toggle>
      <Dropdown.Menu className="userBubble-menu">
        <Dropdown.Item as="button" variant="outline" onSelect={handleLogout}>
          Logout
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default UserBubble;
