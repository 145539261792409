import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { producerActions } from '../../redux/_actions';
import { mApparatusAbbv, wApparatusAbbv } from '../../utilities/constants';
import { genderType } from '../../redux/_constants';
import * as Icons from '../helpers/icons';

function RoundCount() {
  const gender = useSelector((state) => state.session.gender);
  const sessionApparatus = useSelector((state) => state.session.apparatus);
  const round = useSelector((state) => state.producer.round);

  const dispatch = useDispatch();

  const handleChangeRound = (selectedRound) => {
    if (round !== selectedRound) {
      dispatch(producerActions.changeRound(selectedRound));
    }
  };

  const showRoundOptions = () => {
    let options = [];

    switch (gender) {
      case genderType.MALE:
        options = mApparatusAbbv;
        break;
      case genderType.FEMALE:
        options = wApparatusAbbv;
        break;
      default:
        break;
    }

    return options.map((apparatus, i) => {
      return (
        <Dropdown.Item
          as="button"
          variant="outline"
          key={apparatus}
          onSelect={() => handleChangeRound(i + 1)}
          disabled={round === i + 1 || parseInt(sessionApparatus[i]) === 0}
          className={
            round === i + 1
              ? 'selectedRound'
              : parseInt(sessionApparatus[i]) === 0
              ? 'notAvailable'
              : null
          }
        >
          {/*apparatus.toLowerCase() */}
          {Icons?.[apparatus] ?? apparatus.toLowerCase()}
        </Dropdown.Item>
      );
    });
  };

  return (
    <div className="roundcount vCenter">
      <Dropdown className="roundBubble">
        <Dropdown.Toggle id="dropdown-roundBubble" variant="outline">
          R{round}
        </Dropdown.Toggle>
        <Dropdown.Menu className="roundBubble-menu" data-display={'static'}>
          {showRoundOptions()}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
}

export default RoundCount;
