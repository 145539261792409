/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_cognito_identity_pool_id": "us-west-2:0dd3adb6-a005-4f15-87e7-be3e3d969d80",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_pAR26BF4U",
    "aws_user_pools_web_client_id": "mlulnihmlma34ir0t0d1qrlrd",
    "oauth": {
        "domain": "virtius-dev.auth.us-west-2.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "http://localhost:3001/",
        "redirectSignOut": "http://localhost:3001/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_appsync_graphqlEndpoint": "https://fwtxzqtdzreixjyi64p4iqphb4.appsync-api.us-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-west-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-ts2qgsdlm5bzzn27ggz7ljsj44",
    "aws_user_files_s3_bucket": "virtiuswebappstorage213210-dev",
    "aws_user_files_s3_bucket_region": "us-west-2",
    "aws_mobile_analytics_app_id": "c8c455ab67ba47c7b6d2083038661f3f",
    "aws_mobile_analytics_app_region": "us-west-2"
};


export default awsmobile;
