import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import TeamBanner from '../session/teambanner';
import RoundCount from '../session/roundcount';

function SessionHeader() {
  return (
    <Container>
      <Row className="headers">
        <Col className="left">
          <TeamBanner teamSide="teamA" left={true} />
        </Col>
        <Col className="center" xs={1}>
          <RoundCount />
        </Col>
        <Col className="right">
          <TeamBanner teamSide="teamB" right={true} />
        </Col>
      </Row>
    </Container>
  );
}

export default SessionHeader;
