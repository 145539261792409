import React from 'react';
import { useHistory } from 'react-router-dom';
import { useUser } from '../../user-provider';
import { Button } from 'react-bootstrap';
import CalendarDropdown from './calendar-dropdown';
import { generateSessionUrl } from '../../utilities/generate-session-url';
import { format } from 'date-fns';

function MatchUp({ match }) {
  const { sessionId, teamA, teamALogo, teamB, teamBLogo, gameTime } = match;
  const vsTitle = `${teamA} vs ${teamB}`;
  const matchUpTitle = `${vsTitle}`;
  const matchUpDate = `${format(new Date(gameTime), "EEE M'/'dd'/'yyyy @ p")}`;
  const logoPath = 'https://media.virti.us/upload/images/team/';
  const history = useHistory();
  const user = useUser();
  const calendarEventTitle = `Virtius: ${vsTitle}`;
  const calendarEventDescription = generateSessionUrl(sessionId, true);

  const handler = () => {
    history.push(
      user.user
        ? user.user?.signInUserSession
          ? generateSessionUrl(sessionId)
          : '/login'
        : '/signup'
    );
  };

  return (
    <>
      <div
        className="heroMessage"
        style={{
          padding: '0',
          margin: '1.5rem auto 1rem auto',
        }}
      >
        <p
          style={{
            fontSize: '1.2rem',
            color: '#666',
            fontWeight: '600',
            margin: '0',
          }}
        >
          {matchUpTitle}
        </p>
        <p style={{ color: '#666', fontWeight: '300', margin: '0' }}>
          {matchUpDate}
        </p>
        {/*<p style={{ margin: '0' }}>Click to join:</p>*/}
        <div style={{ width: '100%' }}>
          <Button
            variant="outline-light"
            style={{ border: 'none', borderRadius: '1rem' }}
            onClick={handler}
            className="matchButton"
          >
            <img
              style={{ height: '80px', border: 'none', margin: '1rem' }}
              src={`${logoPath}${teamALogo}`}
              alt={teamA}
            />
            <span className="vBlue">V</span>
            <span className="vGray">S</span>
            <img
              style={{ height: '80px', border: 'none', margin: '1rem' }}
              src={`${logoPath}${teamBLogo}`}
              alt={teamB}
            />
          </Button>
        </div>

        <div style={{ width: '100%' }}>
          <CalendarDropdown
            eventTitle={calendarEventTitle}
            eventDescription={calendarEventDescription}
            sessionId={sessionId}
            startAt={gameTime}
          />
        </div>
      </div>
    </>
  );
}

export default MatchUp;
