import React, { useState, useEffect, useCallback } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import Evaluator from './evaluator';
import ExportData from './exportdata';
import JudgingFooter from './judgingfooter';
import ToastStack from './toaststack';
import { chevronUpIcon, chevronDownIcon } from '../helpers/icons';
import { useSelector } from 'react-redux';

function SessionFooter(props) {
  const { teamA, teamB } = props;
  const { judge, live } = useSelector((state) => state.producer);
  const [enabled, setEnabled] = useState(judge);

  useEffect(() => {
    if (live || judge) {
      setEnabled(true);
    } else {
      setEnabled(false);
    }
  }, [live, judge]);

  const FooterToggle = useCallback(() => {
    return (
      <Button
        variant="light"
        className="footerToggle vCenter"
        onClick={() => setEnabled(!enabled)}
      >
        {enabled ? chevronDownIcon : chevronUpIcon}
      </Button>
    );
  }, [enabled]);

  return (
    <div
      className={['sessionFooter', enabled ? '' : 'sessionFooterHidden'].join(
        ' '
      )}
    >
      <Container>
        <ToastStack />
        <FooterToggle />
        <JudgingFooter teamA={teamA} teamB={teamB} />
      </Container>
    </div>
  );
}

export default SessionFooter;
