import React from 'react';

function DeltaIndicator(props) {
  const { delta, colorA, colorB } = props;
  let styleLeft = null;
  let styleRight = null;
  let offset = 50 - (delta * 50) / 10;

  if (offset < 0) {
    offset = 0;
  }
  if (offset > 100) {
    offset = 100;
  }

  styleLeft = { background: colorA, left: '0', width: 100 - offset + '%' };
  styleRight = { background: colorB, right: '0', width: offset + '%' };

  return (
    <div className="deltaIndicator vCenter">
      <div className="deltaBarLeft" style={styleLeft} />
      <div className="deltaBarRight" style={styleRight} />
    </div>
  );
}

export default DeltaIndicator;
