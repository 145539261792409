import React, { useEffect, useState, useRef } from 'react';
import { Modal, Tabs, Tab, Button, Row, Spinner, Alert } from 'react-bootstrap';
//import { plusIcon } from '../helpers/icons';
import { useDispatch, useSelector } from 'react-redux';
import { alertActions } from '../../redux/_actions';
import { AthleteProfile } from './athleteprofile';

function AthleteDetail(props) {
  const { back, athleteId } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [key, setKey] = useState('profile');
  const { alert } = useSelector((state) => state);
  const { athletes } = useSelector((state) => state.admin);
  const dispatch = useDispatch();
  const formRef = useRef();

  useEffect(() => {
    if (!alert.clear) {
      setIsLoading(false);
      setIsEditing(false);
    }
  }, [alert.clear, isEditing]);

  useEffect(() => {
    dispatch(alertActions.clear());
  }, [dispatch]);

  const handleClick = (e) => {
    if (!alert.clear) {
      dispatch(alertActions.clear());
    }
    // Handle Save & Edit button
    //console.log(isEditing)
    if (isEditing) {
      //console.log("save")
      save();
    } else {
      setIsEditing(true);
    }

    switch (key) {
      case 'profile':
        break;
      case 'user':
        break;
      default:
        break;
    }
  };

  const changeTab = (k) => {
    if (!alert.clear) {
      dispatch(alertActions.clear());
    }
    setIsEditing(false);
    setKey(k);
  };

  const buttonText = () => {
    if (isLoading) {
      return (
        <>
          {'Saving... '}
          <Spinner
            variant="primary"
            role="status"
            animation="border"
            size="sm"
          />
        </>
      );
    }

    if (isEditing) {
      return 'Save';
    }

    if (key === 'profile') {
      return 'Edit';
    } else {
      return 'Create';
    }
  };

  const save = () => {
    if (!alert.clear) {
      dispatch(alertActions.clear());
    }
    formRef && formRef.current && formRef.current.submit();
  };

  const reset = () => {
    setIsEditing(false);
    setIsLoading(false);
  };

  const body = () => {
    return (
      <>
        {!alert.clear ? (
          <Row style={{ margin: '0 0 1rem 0' }}>
            <Alert
              dismissible
              onClose={() => dispatch(alertActions.clear())}
              variant={alert.type === 'alert-danger' ? 'danger' : 'success'}
              style={{ textAlign: 'center', width: '100%' }}
            >
              {alert.message}
            </Alert>
          </Row>
        ) : null}
        <Row>
          <Tabs
            id="athleteDetailTabs"
            transition={false}
            activeKey={key}
            onSelect={(k) => changeTab(k)}
            disabled={isLoading}
          >
            <Tab eventKey="profile" title="Profile">
              <AthleteProfile
                ref={formRef}
                athleteId={athleteId}
                isEditing={isEditing}
                isSaving={isLoading}
                reset={reset}
                setIsLoading={setIsLoading}
              />
            </Tab>
          </Tabs>
        </Row>
      </>
    );
  };

  const footer = () => {
    return (
      <>
        <Row className="vCenter" style={{ margin: '0 auto' }}>
          <Button
            variant="outline-primary"
            className="formButton"
            onClick={handleClick}
            disabled={isLoading}
          >
            {buttonText()}
          </Button>
        </Row>
        <Row>
          <div className="modalLink">
            <span>Back to</span>
            <button
              type="button"
              className="linkButton"
              disabled={isLoading}
              onClick={back}
            >
              Athlete list
            </button>
          </div>
        </Row>
      </>
    );
  };

  const headerMsg = () => {
    return (
      <>
        <span>Athlete Admin:&nbsp;</span>
        <span style={{ fontWeight: '400' }}>
          {athletes.byId[athleteId].name}
        </span>
      </>
    );
  };

  const modal = () => {
    return (
      <>
        <Modal.Header className="adminHeader">{headerMsg()}</Modal.Header>
        <Modal.Body className="adminForm">{body()}</Modal.Body>
        <Modal.Footer className="adminForm">{footer()}</Modal.Footer>
      </>
    );
  };

  return modal();
}

export default AthleteDetail;
