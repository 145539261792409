import {
  sessionConstants,
  initialSessionState,
  streamStatus,
  sessionRoleType,
} from '../_constants';

export function session(state = initialSessionState, action) {
  switch (action.type) {
    case sessionConstants.CREATE_REQUEST:
      return {
        ...initialSessionState,
      };
    case sessionConstants.CREATE_SUCCESS:
      return {
        ...state,
      };
    case sessionConstants.CREATE_FAILURE:
      return {
        ...state,
      };
    case sessionConstants.UPDATE_SUCCESS:
      return {
        ...state,
        ...action.session,
      };
    case sessionConstants.LOAD_REQUEST:
      return {
        ...state,
        request: action.key,
      };
    case sessionConstants.LOAD_FAILURE:
      return {
        ...state,
        load: sessionConstants.LOAD_FAILURE,
      };
    case sessionConstants.LOAD_SUCCESS:
      //console.log(action)
      return {
        ...state,
        ...action.session,
        load: sessionConstants.LOAD_SUCCESS,
        request: null,
        streamA: action.session.streams.items.find((e) => e.index === 0)
          ? action.session.streams.items.find((e) => e.index === 0)
          : null,
        streamB: action.session.streams.items.find((e) => e.index === 1)
          ? action.session.streams.items.find((e) => e.index === 1)
          : null,
        streamAStatus: action.session.streams.items.find((e) => e.index === 0)
          ? action.session.streams.items.find((e) => e.index === 0).status
          : streamStatus.OFF,
        streamBStatus: action.session.streams.items.find((e) => e.index === 1)
          ? action.session.streams.items.find((e) => e.index === 1).status
          : streamStatus.OFF,
        sessionData: action.sessionData,
        role: action.role ?? sessionRoleType.FAN,
      };
    case sessionConstants.SYNC_SESSION_SUCCESS:
      return {
        ...state,
        name: action.data.name,
        status: action.data.status,
        producer: JSON.parse(action.data.producer),
        _version: action.data._version,
      };
    case sessionConstants.CREATE_STREAM_REQUEST:
      return {
        ...state,
        [`stream${action.channel}Status`]: action.status,
      };
    case sessionConstants.START_STREAM_REQUEST:
    case sessionConstants.STOP_STREAM_REQUEST:
    case sessionConstants.DELETE_STREAM_REQUEST:
    case sessionConstants.RESET_STREAM_REQUEST:
      // Do we want to update the status in the stream object also?
      return {
        ...state,
        [`stream${action.channel}Status`]: action.status,
        [`stream${action.channel}`]: {
          ...state[`stream${action.channel}`],
          status: action.status,
        },
      };
    case sessionConstants.STOP_STREAM_FAILURE:
    case sessionConstants.RESET_STREAM_FAILURE:
      return {
        ...state,
        [`stream${action.channel}Status`]: streamStatus.STARTED,
      };
    case sessionConstants.START_STREAM_FAILURE:
    case sessionConstants.DELETE_STREAM_FAILURE:
      return {
        ...state,
        [`stream${action.channel}Status`]: streamStatus.STOPPED,
      };
    case sessionConstants.CREATE_STREAM_SUCCESS:
    case sessionConstants.CHECK_STREAM_SUCCESS:
    case sessionConstants.DELETE_STREAM_SUCCESS:
    case sessionConstants.SYNC_NEW_STREAM_SUCCESS:
      return {
        ...state,
        [`stream${action.channel}`]: action.stream,
        [`stream${action.channel}Status`]: action.stream.status,
      };
    case sessionConstants.CREATE_STREAM_FAILURE:
      return {
        ...state,
        [`stream${action.channel}Status`]: streamStatus.OFF,
      };
    case sessionConstants.CHECK_STREAM_FAILURE:
      return {
        ...state,
        [`stream${action.channel}`]:
          action.status === streamStatus.OFF
            ? null
            : state[`stream${action.channel}`],
        [`stream${action.channel}Status`]: action.status,
      };
    case sessionConstants.SUBSCRIBE_STREAM_SUCCESS:
      return {
        ...state,
        [`stream${action.channel}sub`]: action.sub,
      };
    case sessionConstants.UNSUBSCRIBE_STREAM_SUCCESS:
      return {
        ...state,
        [`stream${action.channel}sub`]: null,
      };
    case sessionConstants.SYNC_STREAM_STATUS_SUCCESS:
      return {
        ...state,
        [`stream${action.channel}`]: {
          ...state[`stream${action.channel}`],
          ...action.stream,
        },
        [`stream${action.channel}Status`]: action.stream.status,
      };
    case sessionConstants.SUBSCRIBE_SUCCESS:
      return {
        ...state,
        sub: action.sub,
      };
    case sessionConstants.UNSUBSCRIBE_SUCCESS:
      return {
        ...state,
        sub: null,
      };
    case sessionConstants.SUBSCRIBE_NEW_STREAMS_SUCCESS:
      return {
        ...state,
        newStreamsSub: action.sub,
      };
    case sessionConstants.UNSUBSCRIBE_NEW_STREAMS_SUCCESS:
      return {
        ...state,
        newStreamsSub: null,
      };
    case sessionConstants.CREATE_LINEUP_SUCCESS:
      return {
        ...state,
        sessionData: {
          ...state.sessionData,
          lineups: {
            allIds: [
              ...(state.sessionData.lineups?.allIds ?? []),
              ...action.data.allIds,
            ],
            byId: {
              ...state.sessionData.lineups?.byId,
              ...action.data.byId,
            },
          },
        },
        lineups: {
          items: [...state.lineups.items, ...action.data.lineups],
        },
      };
    case sessionConstants.UPDATE_LINEUP_REQUEST:
      return {
        ...state,
        sessionData: {
          ...state.sessionData,
          lineups: {
            ...state.sessionData.lineups,
            byId: {
              ...state.sessionData.lineups.byId,
              [action.data.id]: {
                ...state.sessionData.lineups.byId[action.data.id],
                lineupData: action.data.lineupData,
                _version: action.data._version + 1, // optimistic update to increment version
              },
            },
          },
        },
        lineups: {
          items: state.lineups.items.map((lineup) => {
            return lineup.id === action.data.id
              ? {
                  ...lineup,
                  lineupData: action.data.lineupData,
                  _version: action.data._version + 1,
                }
              : lineup;
          }),
        },
      };
    case sessionConstants.SYNC_LINEUP_SUCCESS:
    case sessionConstants.UPDATE_LINEUP_SUCCESS:
      return {
        ...state,
        sessionData: {
          ...state.sessionData,
          lineups: {
            ...state.sessionData.lineups,
            byId: {
              ...state.sessionData.lineups.byId,
              [action.data.id]: action.data,
            },
          },
        },
        lineups: {
          items: state.lineups.items.map((lineup) => {
            return lineup.id === action.data.id ? action.data : lineup;
          }),
        },
      };
    case sessionConstants.SUBSCRIBE_LINEUP_SUCCESS:
      return {
        ...state,
        [`lineup${action.channel}sub`]: action.sub,
      };
    case sessionConstants.UNSUBSCRIBE_LINEUP_SUCCESS:
      return {
        ...state,
        [`lineup${action.channel}sub`]: null,
      };
    case sessionConstants.UPDATE_PRODUCER_SUCCESS:
      return {
        ...state,
        producer: action.data,
      };
    case sessionConstants.CHANGE_VIEW:
      return {
        ...state,
        view: action.view,
      };
    case sessionConstants.GET_TEAMS_SUCCESS:
    case sessionConstants.JOIN_REQUEST:
    case sessionConstants.JOIN_SUCCESS:
    case sessionConstants.JOIN_FAILURE:
    case sessionConstants.CHECK_STREAM_REQUEST:
    case sessionConstants.START_STREAM_SUCCESS:
    case sessionConstants.RESET_STREAM_SUCCESS:
    case sessionConstants.STOP_STREAM_SUCCESS:
    case sessionConstants.SYNC_NEW_STREAM_BYPASS:
    default:
      return state;
  }
}
