import React, { useEffect } from 'react';
import Amplify from 'aws-amplify';
import awsconfig from './aws-exports';
import { useSelector, useDispatch } from 'react-redux';
import { userActions } from './redux/_actions';
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api';
import { userConstants } from './redux/_constants';

export const UserContext = React.createContext(null);

export const UserProvider = ({ children }) => {
  const user = useSelector((state) => state.user.user);
  const isAuthenticating = useSelector((state) => state.user.initialLoad);
  const dispatch = useDispatch();

  useEffect(() => {
    // Override Cognito Settings
    awsconfig.oauth.domain = 'auth.virti.us';
    awsconfig.oauth.redirectSignIn = `${window.location.origin}/`;
    awsconfig.oauth.redirectSignOut = `${window.location.origin}/`;
    const appSyncAuthType = user
      ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
      : GRAPHQL_AUTH_MODE.API_KEY;
    Amplify.configure({
      ...awsconfig,
      aws_appsync_authenticationType: appSyncAuthType,
    });
    const userAction = user
      ? userActions.getUser()
      : { type: userConstants.GETUSER_SUCCESS, user: null };

    dispatch(userAction);
  }, [dispatch, user]);

  const cognitoGroups =
    user?.signInUserSession?.accessToken?.payload?.['cognito:groups'] || [];

  const isFan = ['admin', 'poweruser'].reduce(
    (isFan, group) => isFan && !cognitoGroups.includes(group),
    true
  );

  const values = { user, isAuthenticating, cognitoGroups, isFan };

  return <UserContext.Provider value={values}>{children}</UserContext.Provider>;
};

export const useUser = () => {
  const context = React.useContext(UserContext);

  if (context === undefined) {
    throw new Error(
      '`useUser` hook must be used within a `UserProvider` component'
    );
  }
  return context;
};
