import React, { useEffect, useState } from 'react';
import { Container, Button, Row, Col, Card, ListGroup } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { adminActions } from '../../redux/_actions'; // will need to change this for app part
import { MEDIAURL } from '../../utilities/constants';

function TeamSelector(props) {
  const { side, setTeam, session, values } = props;
  const { teams, sessionTeams } = useSelector((state) => state.admin);
  const dispatch = useDispatch();

  // Will need to filter this list by league / gender later on
  useEffect(() => {
    if (teams.byId.length === 0) {
      dispatch(adminActions.getTeams());
    }
  }, [teams, dispatch]);

  const handleSelect = (team) => {
    setTeam(side, team);
  };

  const handleCancel = () => {
    setTeam(null);
  };

  const selectedTeamVariant = (side, id) => {
    const selected = values[`team${side}`];
    const index = side.charCodeAt(0) - 65;
    const previous =
      session &&
      session.sessionTeams.items.length > index &&
      sessionTeams.byId[session.sessionTeams.items[index]].teamId;

    if (id === null) {
      if (selected || previous) {
        return 'light';
      }
      return 'primary';
    }

    if (selected === id) {
      return 'primary';
    }

    if (previous === id) {
      return 'primary';
    }

    return 'light';
  };

  const teamList = () => {
    const list =
      teams &&
      teams.allIds.filter((id) => teams.byId[id].gender === values.gender);
    list.unshift(null); // Add empty team to beginning

    return (
      <ListGroup>
        {list.map((id) => {
          const logoSrc = id
            ? teams.byId[id].logos
              ? `${MEDIAURL}${
                  JSON.parse(teams.byId[id].logos).metaData.filename
                }`
              : `v_logo.svg`
            : null;

          return (
            <ListGroup.Item key={id}>
              <Button
                variant={selectedTeamVariant(side, id)}
                onClick={() => handleSelect(id ? teams.byId[id] : null)}
              >
                <Card>
                  {id ? (
                    <Card.Img variant="top" src={logoSrc} />
                  ) : (
                    <span style={{ fontSize: '5rem', color: '#212529' }}>
                      {id ? null : '?'}
                    </span>
                  )}
                  <Card.Body>
                    <Card.Title>
                      {id ? teams.byId[id].name : 'No Team'}
                    </Card.Title>
                  </Card.Body>
                </Card>
              </Button>
            </ListGroup.Item>
          );
        })}
      </ListGroup>
    );
  };

  return (
    <Container className="teamSelector">
      <Row>
        <Button
          variant="light"
          className="teamSelectorReturn"
          onClick={handleCancel}
        ></Button>
        <Col className={side === 'A' ? 'sideleft' : 'empty'}>
          {side === 'A' ? teamList() : null}
        </Col>
        <Col className={side === 'B' ? 'sideright' : 'empty'}>
          {side === 'B' ? teamList() : null}
        </Col>
      </Row>
    </Container>
  );
}

export default TeamSelector;
