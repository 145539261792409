import React, { useState } from 'react';
import { Row, Col, Button, OverlayTrigger, Popover } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { evaluatorActions, producerActions } from '../../redux/_actions';
import {
  caretUpIcon,
  caretDownIcon /* plusIcon, minusIcon */,
} from '../helpers/icons';
import { checkSubScoresComplete } from '../../utilities/scoring';

function EvaluatorFinalize(props) {
  const [inputLock, setInputLock] = useState(false);
  const { evaluator, session, producer } = useSelector((state) => state);
  const { team, inquiry } = props;
  const evalPanel = evaluator[team.home ? 'evalPanelA' : 'evalPanelB'];
  const { finalScore, subScores } = evalPanel;
  const disabled = !team.onEval;
  const dispatch = useDispatch();
  const fs =
    finalScore && finalScore.length > 0
      ? finalScore[finalScore.length - 1].score
      : null;
  const subScoresComplete = checkSubScoresComplete(subScores);

  const submitScore = (e) => {
    if (!inputLock && finalScore.length !== 0) {
      const scoreData = {
        evaluator: 'D_Panel_' + (team.home ? 'A' : 'B'), // TODO: Need to update to actual judge role
        time: Date.now(),
        panel: team.home ? 'A' : 'B',
      };

      dispatch(evaluatorActions.lockFinalScore(scoreData, inquiry));
      dispatch(
        producerActions.offEval(
          team.home,
          fs,
          inquiry,
          session.competition && producer.live
        )
      ); // requiring proper sequence
      dispatch(evaluatorActions.reset(team.home ? 'A' : 'B'));
    }

    // TODO: I think this is unreachable code, because of the reset above
    if (inputLock) {
      const scoreData = {
        panel: team.home ? 'A' : 'B',
      };

      dispatch(evaluatorActions.unlockFinalScore(scoreData));
    }

    setInputLock(!inputLock);
  };

  const popover = (
    <Popover id={`popover-finalize`}>
      <Popover.Title as="h3">
        <Row style={{ margin: '0' }}>
          <Col xs={6} className="vCenter score">
            Score Card
          </Col>
          <Col xs={3} className="vCenter score"></Col>
          <Col xs={3} className="vCenter">
            {inquiry ? (
              <span className="inquiryHeader unresolved">Inquiry</span>
            ) : null}
          </Col>
        </Row>
      </Popover.Title>
      <Popover.Content>
        <Row style={{ margin: '0' }}>
          <Col xs={6} className="vCenter" style={{ fontWeight: '600' }}>
            Difficulty:
          </Col>
          <Col xs={3} className="vCenter" style={{ fontWeight: '600' }}>
            {fs?.dScore}
          </Col>
          <Col xs={3} className="vCenter green"></Col>
        </Row>
        <Row style={{ margin: '0' }}>
          <Col xs={6} className="vCenter" style={{ fontWeight: '600' }}>
            Execution:
          </Col>
          <Col xs={3} className="vCenter" style={{ fontWeight: '600' }}>
            {fs?.eScore}
          </Col>
          <Col
            xs={3}
            className="vCenter"
            style={{ textAlign: 'center', fontStyle: 'italic' }}
          >
            average
          </Col>
        </Row>
        <Row style={{ margin: '0', padding: '0' }}>
          <Col xs={2} style={{ fontStyle: 'italic', padding: '0 0 0 15px' }}>
            E1:
          </Col>
          <Col xs={2} className="vCenter">
            {(10 - subScores['E1'][subScores['E1'].length - 1].score).toFixed(
              1
            )}
          </Col>
          <Col xs={5} />
          <Col xs={3} />
        </Row>
        <Row style={{ margin: '0', padding: '0' }}>
          <Col xs={2} style={{ fontStyle: 'italic', padding: '0 0 0 15px' }}>
            E2:
          </Col>
          <Col xs={2} className="vCenter">
            {(10 - subScores['E2'][subScores['E2'].length - 1].score).toFixed(
              1
            )}
          </Col>
          <Col xs={5} />
          <Col
            xs={3}
            style={{
              textAlign: 'center',
              fontStyle: 'italic',
              marginTop: '-0.8rem',
            }}
          >
            {Math.abs(
              subScores['E1'][subScores['E1'].length - 1].score -
                subScores['E2'][subScores['E2'].length - 1].score
            ) !== 0
              ? 'Δ ' +
                Math.abs(
                  subScores['E1'][subScores['E1'].length - 1].score -
                    subScores['E2'][subScores['E2'].length - 1].score
                ).toFixed(1)
              : null}
          </Col>
        </Row>
        <Row style={{ margin: '0' }}>
          <Col xs={6} className="vCenter">
            Neutral Deduction:
          </Col>
          <Col xs={3} className="vCenter">
            {fs?.ND === '0.0' ? '--' : fs?.ND}
          </Col>
          <Col xs={3} className="vCenter red">
            {fs?.ND === '0.0' ? '' : caretDownIcon}
          </Col>
        </Row>
        <Row style={{ margin: '0' }}>
          <Col xs={6} className="vCenter">
            Stick Bonus:
          </Col>
          <Col xs={3} className="vCenter">
            {fs?.SB === '0.0' ? '--' : fs?.SB}
          </Col>
          <Col xs={3} className="vCenter green">
            {fs?.SB === '0.0' ? '' : caretUpIcon}
          </Col>
        </Row>
        <hr style={{ width: '100%' }} />
        <Row style={{ margin: '0' }}>
          <Col xs={6} className="vCenter score">
            Final Score:
          </Col>
          <Col xs={3} className="vCenter score">
            {fs?.score}
          </Col>
          <Col xs={3} className="vCenter">
            <Button
              variant="warning"
              disabled={!subScoresComplete}
              onClick={submitScore}
            >
              Submit
            </Button>
          </Col>
        </Row>
      </Popover.Content>
    </Popover>
  );

  return (
    <OverlayTrigger
      trigger="click"
      placement={team.home ? 'top-end' : 'top-start'}
      key="top"
      overlay={popover}
      rootClose
    >
      <Button
        className="finalize"
        variant="outline-warning"
        disabled={disabled}
      >
        {inquiry ? 'Resolve' : 'Finalize'}
      </Button>
    </OverlayTrigger>
  );
}

export default EvaluatorFinalize;
