import React from 'react';

function Terms() {
  return (
    <div className="homeText">
      <p>Last Updated: January 28, 2021</p>
      <ol>
        <li>
          Introduction
          <p>
            Welcome to Virtius! Your use of Virtius’s services, including the
            services Virtius makes available through this website and all
            related web sites, mobile sites, data files, visualizations and
            applications which link to these terms of service (the “Site”) and
            to all software or services offered by Virtius in connection with
            any of those (the “Services”), is governed by these terms of service
            (the “Terms”), so please carefully read them before using the
            Services. For the purposes of these Terms, “we,” “our,” “us,” and
            “Virtius” refer to Virtius Inc., the providers and operators of the
            Services.
          </p>
          <p>
            In order to use the Services, you must first agree to these Terms.
            If you are registering for or using the Services on behalf of an
            organization, you are agreeing to these Terms for that organization
            and promising that you have the authority to bind that organization
            to these Terms. In that case, “you” and “your” will also refer to
            that organization, wherever possible.
          </p>
          <p>
            You must be over 13 years of age to use the Services, and children
            under the age of 13 cannot use or register for the Services. If you
            are over 13 years of age but are not yet of legal age to form a
            binding contract (in many jurisdictions, this age is 18), then you
            must get your parent or guardian to read these Terms and agree to
            them for you before you use the Services. If you are a parent or
            guardian and you provide your consent to your child's registration
            with the Services, you agree to be bound by these Terms with respect
            of your child’s use of the Services.{' '}
          </p>
          <p>
            You agree your purchases and/or use of the Services are not
            contingent on the delivery of any future functionality or features
            or dependent on any oral or written public comments made by Virtius
            or any of its affiliates regarding future functionality or features.
          </p>
          <p>
            If you have entered into a separate written agreement with Virtius
            for use of the Services, the terms and conditions of such other
            agreement shall prevail over any conflicting terms or conditions in
            these Terms with respect to the Services specified in such
            agreement.
          </p>
          <p>
            ARBITRATION NOTICE: EXCEPT FOR CERTAIN TYPES OF DISPUTES DESCRIBED
            IN THE ARBITRATION CLAUSE BELOW, YOU AGREE THAT DISPUTES BETWEEN YOU
            AND VIRTIUS WILL BE RESOLVED BY MANDATORY BINDING ARBITRATION AND
            YOU WAIVE ANY RIGHT TO PARTICIPATE IN A CLASS-ACTION LAWSUIT OR
            CLASS-WIDE ARBITRATION.
          </p>
          <p>
            BY USING, DOWNLOADING, INSTALLING, OR OTHERWISE ACCESSING THE
            SERVICES OR ANY MATERIALS INCLUDED IN OR WITH THE SERVICES, YOU
            HEREBY AGREE TO BE BOUND BY THESE TERMS. IF YOU DO NOT ACCEPT THESE
            TERMS, THEN YOU MAY NOT USE, DOWNLOAD, INSTALL, OR OTHERWISE ACCESS
            THE SERVICES.{' '}
          </p>
          <p>
            CERTAIN FEATURES OF THE SERVICES OR SITE MAY BE SUBJECT TO
            ADDITIONAL GUIDELINES, TERMS, OR RULES, WHICH WILL BE POSTED ON THE
            SERVICE OR SITE IN CONNECTION WITH SUCH FEATURES. TO THE EXTENT SUCH
            TERMS, GUIDELINES, AND RULES CONFLICT WITH THESE TERMS, SUCH TERMS
            SHALL GOVERN SOLELY WITH RESPECT TO SUCH FEATURES. IN ALL OTHER
            SITUATIONS, THESE TERMS SHALL GOVERN.
          </p>
        </li>
        <li>
          Description of Services
          <p></p>
        </li>
        <li>
          Your Account
          <p>
            In the course of registering for or using the Services, you may be
            required to provide Virtius with certain information, including your
            name, contact information, username and password (“Credentials”).
            Virtius handles such information with the utmost attention, care and
            security. Nonetheless, you, not Virtius, shall be responsible for
            maintaining and protecting your Credentials in connection with the
            Services. If your contact information or other information relating
            to your account changes, you must notify Virtius promptly and keep
            such information current. You are solely responsible for any
            activity using your Credentials, whether or not you authorized that
            activity. You should immediately notify Virtius of any unauthorized
            use of your Credentials or if your email or password has been hacked
            or stolen. If you discover that someone is using your Credentials
            without your consent, or you discover any other breach of security,
            you agree to notify Virtius immediately.
          </p>
        </li>
        <li>
          Content
          <p>
            A variety of information, reviews, recommendations, messages,
            comments, posts, text, graphics, software, photographs, videos,
            data, and other materials (“Content”) may be made available through
            the Services by Virtius or its suppliers (“Virtius-Supplied
            Content”). While Virtius strives to keep the Content that it
            provides through the Services accurate, complete, and up-to-date,
            Virtius cannot guarantee, and is not responsible for the accuracy,
            completeness, or timeliness of any Virtius-Supplied Content.{' '}
          </p>
          <p>
            Virtius-Supplied Content may include scoring or other performance
            feedback regarding athletic competitions (such athletic
            competitions, “Meets” and the scoring or other performance feedback,
            “Scoring Data”) provided by judges (“Judges”). You agree that
            Virtius is not responsible for the accuracy, completeness,
            timeliness, or fairness of any Scoring Data. You further acknowledge
            that Virtius is not responsible for choosing or vetting any Judges
            selected for any Meets and Virtius shall have no liability for the
            actions or inactions of any Judges.
          </p>
          <p>
            Virtius-Supplied Content may also include streamed video of Meets
            (“Meet Video”). Meet Video, featuring athletes (“Athletes”) and
            other persons present at any Meets (together with Athletes,
            “Individuals”). Without limiting any other provision of these Terms,
            you agree that Virtius is not responsible for the content of any
            Meet Video and/or the actions or conduct of Individuals in any Meet
            Video.{' '}
          </p>
          <p>
            You acknowledge that you may also be able to create, transmit,
            publish or display information (such as data files, written text,
            computer software, music, audio files or other sounds, photographs,
            videos or other images) through use of the Services. All such
            information is referred to below as “User Content.”{' '}
          </p>
          <p>
            You agree that you are solely responsible for (and that Virtius has
            no responsibility to you or to any third party for) any User
            Content, and for the consequences of your actions (including any
            loss or damage which Virtius may suffer) in connection with such
            User Content. If you are registering for these Services on behalf of
            an organization, you also agree that you are also responsible for
            the actions of associated Users and for any User Content that such
            associated Users might upload, record, publish, post, link to, or
            otherwise transmit or distribute through use of the Services.
            Furthermore, you acknowledge that Virtius does not control or
            actively monitor Content uploaded by users and, as such, does not
            guarantee the accuracy, integrity or quality of such Content. You
            acknowledge that by using the Services, you may be exposed to
            materials that are offensive, indecent or objectionable. Under no
            circumstances will Virtius be liable in any way for any such
            Content.
          </p>
          <p>
            Virtius may refuse to store, provide, or otherwise maintain your
            User Content for any or no reason. Virtius may remove your User
            Content from the Services at any time if you violate these Terms or
            if the Services are canceled or suspended. If User Content is stored
            using the Services with an expiration date, Virtius may also delete
            the User Content as of that date. User Content that is deleted may
            be irretrievable. You agree that Virtius has no responsibility or
            liability for the deletion or failure to store any User Content or
            other communications maintained or transmitted through use of the
            Services.{' '}
          </p>
          <p>
            Virtius reserves the right (but shall have no obligation) to monitor
            and remove User Content from the Services, in its discretion. You
            agree to immediately take down any Content that violates these
            Terms, including pursuant to a takedown request from Virtius. In the
            event that you elect not to comply with a request from Virtius to
            take down certain Content, Virtius reserves the right to directly
            take down such Content.
          </p>
          <p>
            By submitting, posting or otherwise uploading User Content on or
            through the Services you give Virtius a worldwide, nonexclusive,
            perpetual, fully sub-licensable, royalty-free right and license as
            set below:
          </p>
          <p>
            with respect to User Content that you submit, post or otherwise make
            publicly or generally available via the Services (e.g. Meet Videos,
            Scores, public forum posts), the license to use, reproduce, modify,
            adapt, publish, translate, create derivative works from, distribute,
            publicly perform, and publicly display such User Content (in whole
            or part) worldwide via the Services or otherwise, and/or to
            incorporate it in other works in any form, media, or technology now
            known or later developed for any legal business purpose; and
          </p>
          <p>
            with respect to User Content that you submit, post or otherwise
            transmit privately via the Services (e.g. via private chat messages
            with other Users), the license to use, reproduce, modify, adapt,
            publish, translate, create derivative works from, distribute,
            publicly perform and publicly display such User Content for the
            purpose of enabling Virtius to provide you with the Services, and
            for the limited purposes stated in our Privacy Policy.
          </p>
        </li>
        <li>
          Proprietary Rights
          <p>
            You acknowledge and agree that Virtius (or Virtius’s licensors) own
            all legal right, title and interest in and to the Services and
            Virtius-Supplied Content and that the Services and Virtius-Supplied
            Content are protected by copyrights, trademarks, patents, or other
            proprietary rights and laws (whether those rights happen to be
            registered or not, and wherever in the world those rights may
            exist).
          </p>
          <p>
            Except as provided in Section 4, Virtius acknowledges and agrees
            that it obtains no right, title or interest from you (or your
            licensors) under these Terms in or to any Content that you create,
            upload, submit, post, transmit, share or display on, or through, the
            Services, including any intellectual property rights which subsist
            in that Content (whether those rights happen to be registered or
            not, and wherever in the world those rights may exist). Unless you
            have agreed otherwise in writing with Virtius, you agree that you
            are responsible for protecting and enforcing those rights and that
            Virtius has no obligation to do so on your behalf.
          </p>
        </li>
        <li>
          License from Virtius and Restrictions on Use
          <p>
            Virtius gives you a personal, worldwide, royalty-free,
            non-assignable and non-exclusive license to use the Site and
            Services for the sole purpose of allowing you to access the Services
            for your non-commercial or internal business purposes, in the manner
            permitted by these Terms.
          </p>
          <p>
            You may not (and you may not permit anyone else to): (i) copy,
            modify, create a derivative work of, reverse engineer, decompile or
            otherwise attempt to extract the source code of the Services or any
            part thereof, unless this is expressly permitted or required by law,
            or unless you have been specifically told that you may do so by
            Virtius, in writing (e.g., through an open source software license);
            (ii) attempt to disable or circumvent any security mechanisms used
            by the Services or any applications running on the Services; or
            (iii) attempt to download, locally save, or share any
            Virtius-Supplied Content or Meet Videos (except Meet Videos
            representing your own supplied User Content).
          </p>
          <p>
            You may not engage in any activity that interferes with or disrupts
            the Services (or the servers and networks which are connected to the
            Services).
          </p>
          <p>
            You may not rent, lease, provide access to or sublicense any
            elements of the Services to a third party or use the Services on
            behalf of or to provide services to third parties
          </p>
          <p>
            You may not access the Services in a manner intended to avoid
            incurring fees or exceeding usage limits or quotas.
          </p>
          <p>
            You may not access the Services for the purpose of bringing an
            intellectual property infringement claim against Virtius or for the
            purpose of creating a product or service competitive with the
            Services. You may not use any robot, spider, site search/retrieval
            application or other manual or automatic program or device to
            retrieve, index, “scrape,” “data mine” or in any way gather Content
            from the Services.
          </p>
          <p>
            You agree that you will not upload, record, publish, post, link to,
            transmit or distribute User Content, or otherwise utilize the
            Services in a manner that: (i) advocates, promotes, incites,
            instructs, informs, assists or otherwise encourages violence or any
            illegal activities; (ii) infringes or violates the copyright,
            patent, trademark, service mark, trade name, trade secret, or other
            intellectual property rights of any third party or Virtius, or any
            rights of publicity or privacy of any party; (iii) attempts to
            mislead others about your identity or the origin of a message or
            other communication, or impersonates or otherwise misrepresents your
            affiliation with any other person or entity, or is otherwise
            materially false, misleading, or inaccurate; (iv) promotes, solicits
            or comprises inappropriate, harassing, abusive, profane, hateful,
            defamatory, libelous, threatening, obscene, indecent, vulgar,
            pornographic or otherwise objectionable or unlawful content or
            activity; (v) is harmful to minors; (vi) utilizes or contains any
            viruses, Trojan horses, worms, time bombs, or any other similar
            software, data, or programs that may damage, detrimentally interfere
            with, surreptitiously intercept, or expropriate any system, data,
            personal information, or property of another; (vii) violates any
            law, statute, ordinance, regulation (including without limitation
            the laws and regulations governing export control, unfair
            competition, anti-discrimination, or false advertising), or
            contractual obligation (whether your own obligation or that of any
            other person or entity); or (viii) violates any rules or codes of
            conduct relevant to any applicable educational institution, sport,
            league, or governing organization.
          </p>
          <p>
            If you are accessing the Services as a Judge, you acknowledge and
            agree that you will act in an impartial manner, provide any judging
            services and Scores in accordance with any rules or codes of conduct
            relevant to any applicable educational institution, sport, league,
            or governing organization, that you have not or will not accept any
            direct or indirect bribes or other unlawful or unethical
            compensation relating to your judging services, and will not act as
            a Judge if operating under any undue influence or undisclosed
            partiality. If you are accessing the Services in any capacity, you
            acknowledge and agree that you will not, directly or indirectly,
            induce or assist any person in inducing a Judge to act in a manner
            in violation of these Terms, including this paragraph.
          </p>
          <p>If you appear in any Meet Video:</p>
          <p>
            You hereby irrevocably permit, authorize and license Virtiu and its
            affiliates, successors and assigns, and their respective licensees
            and agents (“Authorized Persons”), to display, publicly perform,
            exhibit, transmit, broadcast, reproduce, record, photograph,
            digitize, modify, alter, edit, adapt, create derivative works,
            exploit, sell, rent, license, otherwise use and permit others to use
            you name, image, likeness, appearance, voice, and other personal
            information included within any Meet Video (“Materials”) on a
            perpetual basis throughout the world and in any medium or format
            whatsoever now existing or hereafter created for any purpose,
            including but not limited to advertising, public relations,
            publicity, and promotion of the Company and its affiliates and their
            businesses, products and services, without further consent from or
            royalty, payment or other compensation to you.
          </p>
          <p>
            You hereby irrevocably transfer and assign to Virtius my entire
            right, title and interest, if any, in and to the Materials and all
            copyrights in the Materials arising in any jurisdiction throughout
            the world. You acknowledge and agree that you have no right to
            review or approve Materials before they are used by Virtius, and
            that the Virtius has no liability to me for any editing or
            alteration of the Materials or for any distortion or other effects
            resulting from the Virtius’s editing, alteration or use of the
            Materials. Virtius has no obligation to use the Materials or to
            exercise any rights given by this Agreement.{' '}
          </p>
          <p>
            To the fullest extent permitted by applicable law, you hereby
            irrevocably waive all legal and equitable rights relating to all
            liabilities, claims, demands, actions, suits, damages and expenses,
            including but not limited to claims for copyright or trademark
            infringement, infringement of moral rights, defamation, invasion of
            rights of privacy, rights of publicity, intrusion, false light,
            public disclosure of private facts, physical or emotional injury or
            distress or any similar claim or cause of action in tort, contract
            or any other legal theory, now known or hereafter known in any
            jurisdiction throughout the world (collectively, “Claims”) arising
            directly or indirectly from the Authorized Persons’ exercise of
            their rights hereunder and the use and exploitation of the
            Materials, and whether resulting in whole or in part by the
            negligence of Virtius or any other person, and covenant not to make
            or bring any such Claim against any Authorized Person and forever
            releases and discharges the Authorized Persons from liability under
            such Claims.
          </p>
          <p>
            You represent and warrant to Virtius that the Authorized Persons’
            use of the Materials and the rights and license granted hereunder do
            not, and will not, violate any right of, or conflict with or violate
            any contract with or commitment made to, any person or entity, and
            that no consent or authorization from any third party is required in
            connection herewith. You agree to defend, indemnify and hold
            harmless the Authorized Persons from and against all Claims by third
            parties resulting from my breach of any of the foregoing
            representations and warranties.
          </p>
          <p>
            You may not use the Services if you are a person barred from
            receiving the Services under the laws of the United States or other
            countries, including the country in which you are resident or from
            which you use the Services. You affirm that you are over the age of
            13, as the Services are not intended for children under 13.
          </p>
        </li>
        <li>
          Pricing Terms
          <p>
            The Services are currently provided to you free of charge. However,
            we may charge a fee for some parts of the Services at some point in
            the future. If we do so, we will provide you with information of the
            pricing for those portions of the Services at that point.
          </p>
        </li>
        <li>
          Privacy Policies
          <p>
            These Services are provided in accordance with our Privacy Policy,
            which can be found at [link]. You agree to the use of your User
            Content and personal information in accordance with these Terms and
            Virtius’s Privacy Policy.
          </p>
          <p>
            You agree to protect the privacy and legal rights of your End Users.
            If your End Users provide you with user names, passwords, or other
            login information or personal information, you agree make such End
            Users aware that such information may be made available to Virtius
            and to refer such End Users to our Privacy Policy linked above.
          </p>
          <p>
            Notwithstanding anything to the contrary, you agree to permit
            Virtius to identify you as a customer and to use your name and/or
            logo in Virtius’s website and marketing materials.
          </p>
        </li>
        <li>
          Modification and Termination of Services
          <p>
            Virtius is constantly innovating in order to provide the best
            possible experience for its users. You acknowledge and agree that
            the form and nature of the Services which Virtius provides may
            change from time to time without prior notice to you, subject to the
            terms in its Privacy Policy. Changes to the form and nature of the
            Services will be effective with respect to all versions of the
            Services; examples of changes to the form and nature of the Services
            include without limitation changes to fee and payment policies,
            security patches, added functionality, automatic updates, and other
            enhancements. Any new features that may be added to the website or
            the Services from time to time will be subject to these Terms,
            unless stated otherwise.
          </p>
          <p>
            You may terminate these Terms at any time by canceling your account
            on the Services. You will not receive any refunds if you cancel your
            account.
          </p>
          <p>
            You agree that Virtius, in its sole discretion and for any or no
            reason, may terminate your account or any part thereof. You agree
            that any termination of your access to the Services may be without
            prior notice, and you agree that Virtius will not be liable to you
            or any third party for such termination.
          </p>
          <p>
            You are solely responsible for exporting your User Content from the
            Services prior to termination of your account for any reason,
            provided that if we terminate your account, we will endeavor to
            provide you a reasonable opportunity to retrieve your User Content.
          </p>
          <p>
            Upon any termination of the Services or your account these Terms
            will also terminate, but all provisions of these Terms which, by
            their nature, should survive termination, shall survive termination,
            including, without limitation, ownership provisions, warranty
            disclaimers, and limitations of liability.
          </p>
        </li>
        <li>
          Changes to the Terms
          <p>
            These Terms may be amended or updated from time to time without
            notice and may have changed since your last visit to the website or
            use of the Services. It is your responsibility to review these Terms
            for any changes. By continuing to access or use the Services after
            revisions become effective, you agree to be bound by the revised
            Terms. If you do not agree to the new Terms, please stop using the
            Services. Please visit this page regularly to review these Terms for
            any changes.
          </p>
        </li>
        <li>
          DISCLAIMER OF WARRANTY
          <p>
            YOU EXPRESSLY UNDERSTAND AND AGREE THAT YOUR USE OF THE SERVICES ARE
            AT YOUR SOLE RISK AND THAT THE SERVICES ARE PROVIDED “AS IS” AND “AS
            AVAILABLE.”
          </p>
          <p>
            VIRTIUS, ITS SUBSIDIARIES AND AFFILIATES, AND ITS LICENSORS MAKE NO
            EXPRESS WARRANTIES AND DISCLAIM ALL IMPLIED WARRANTIES REGARDING THE
            SERVICES, INCLUDING IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS
            FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT. WITHOUT LIMITING THE
            GENERALITY OF THE FOREGOING, VIRTIUS, ITS SUBSIDIARIES AND
            AFFILIATES, AND ITS LICENSORS DO NOT REPRESENT OR WARRANT TO YOU
            THAT: (A) YOUR USE OF THE SERVICES WILL MEET YOUR REQUIREMENTS, (B)
            YOUR USE OF THE SERVICES WILL BE UNINTERRUPTED, TIMELY, SECURE OR
            FREE FROM ERROR, AND (C) USAGE DATA PROVIDED THROUGH THE SERVICES
            WILL BE ACCURATE.
          </p>
          <p>
            NOTHING IN THESE TERMS, INCLUDING SECTIONS 11 AND 12, SHALL EXCLUDE
            OR LIMIT VIRTIUS’S WARRANTY OR LIABILITY FOR LOSSES WHICH MAY NOT BE
            LAWFULLY EXCLUDED OR LIMITED BY APPLICABLE LAW.
          </p>
        </li>
        <li>
          LIMITATION OF LIABILITY
          <p>
            SUBJECT TO SECTION 11 ABOVE, YOU EXPRESSLY UNDERSTAND AND AGREE THAT
            VIRTIUS, ITS SUBSIDIARIES AND AFFILIATES, AND ITS LICENSORS SHALL
            NOT BE LIABLE TO YOU FOR ANY INDIRECT, INCIDENTAL, SPECIAL,
            CONSEQUENTIAL, OR EXEMPLARY DAMAGES WHICH MAY BE INCURRED BY YOU,
            HOWEVER CAUSED AND UNDER ANY THEORY OF LIABILITY. THIS SHALL
            INCLUDE, BUT NOT BE LIMITED TO, ANY LOSS OF PROFIT (WHETHER INCURRED
            DIRECTLY OR INDIRECTLY), ANY LOSS OF GOODWILL OR BUSINESS
            REPUTATION, ANY LOSS OF DATA SUFFERED, COST OF PROCUREMENT OF
            SUBSTITUTE GOODS OR SERVICES, OR OTHER INTANGIBLE LOSS. THESE
            LIMITATIONS SHALL APPLY NOTWITHSTANDING THE FAILURE OF ESSENTIAL
            PURPOSE OF ANY LIMITED REMEDY.
          </p>
          <p>
            THE LIMITATIONS ON VIRTIUS’S LIABILITY TO YOU IN THIS SECTION SHALL
            APPLY WHETHER OR NOT VIRTIUS HAS BEEN ADVISED OF OR SHOULD HAVE BEEN
            AWARE OF THE POSSIBILITY OF ANY SUCH LOSSES ARISING.
          </p>
          <p>
            SOME STATES AND JURISDICTIONS MAY NOT ALLOW THE LIMITATION OR
            EXCLUSION OF LIABILITY FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO
            THE ABOVE LIMITATION OR EXCLUSION MAY NOT APPLY TO YOU. IN NO EVENT
            SHALL VIRTIUS’S TOTAL LIABILITY TO YOU FOR ALL DAMAGES, LOSSES, AND
            CAUSES OF ACTION (WHETHER IN CONTRACT, TORT (INCLUDING NEGLIGENCE),
            OR OTHERWISE) EXCEED THE AMOUNT THAT YOU HAVE ACTUALLY PAID FOR THE
            SERVICES IN THE PAST TWELVE MONTHS, OR ONE HUNDRED DOLLARS
            ($100.00), WHICHEVER IS GREATER.
          </p>
        </li>
        <li>
          Indemnification
          <p>
            You agree to hold harmless and indemnify Virtius, and its
            subsidiaries, affiliates, officers, agents, employees, advertisers,
            licensors, suppliers or partners (collectively “Virtius and
            Partners”) from and against any third party claim arising from or in
            any way related to (a) your breach of the Terms, (b) your use of the
            Services, (c) your violation of applicable laws, rules or
            regulations in connection with the Services, or (d) your User
            Content, including any liability or expense arising from all claims,
            losses, damages (actual and consequential), suits, judgments,
            litigation costs and attorneys’ fees, of every kind and nature.{' '}
          </p>
        </li>
        <li>
          Copyright Policy
          <p>
            We respect the intellectual property rights of others and expect our
            users to do the same. In accordance with the Digital Millennium
            Copyright Act, Title 17, United States Code, Section 512(c)(2) (the
            “DMCA”), we will respond expeditiously to claims of copyright
            infringement committed using the Services if such claims are
            reported to our Designated Copyright Agent identified in the sample
            notice below.
          </p>
          <h6>DMCA Notice of Alleged Infringement (“Notice”)</h6>
          <p>
            Identify the copyrighted work that you claim has been infringed, or
            if multiple copyrighted works are covered by this Notice, you may
            provide a representative list of the copyrighted works that you
            claim have been infringed.{' '}
          </p>
          <p>
            Identify the material or link you claim is infringing (or the
            subject of infringing activity) and to which access is to be
            disabled, including at a minimum, if applicable, the URL of the link
            or the exact location where such material may be found.
          </p>
          <p>
            Provide your company affiliation (if applicable), mailing address,
            telephone number, and, if available, email address.
          </p>
          <p>
            Include both of the following statements in the body of the Notice:
          </p>
          <p>
            “I hereby state that I have a good faith belief that the disputed
            use of the copyrighted material is not authorized by the copyright
            owner, its agent, or the law (e.g., as a fair use).”{' '}
          </p>
          <p>
            “I hereby state that the information in this Notice is accurate and,
            under penalty of perjury, that I am the owner, or authorized to act
            on behalf of, the owner, of the copyright or of an exclusive right
            under the copyright that is allegedly infringed.”
          </p>
          <p>
            Provide your full legal name and your electronic or physical
            signature. Deliver this Notice, with all items completed, to our
            Designated Copyright Agent:{' '}
          </p>
          <p></p>
          <p style={{ margin: '0' }}>Copyright Agent, </p>
          <p style={{ margin: '0' }}>[company name]</p>
          <p style={{ margin: '0' }}>[address]</p>
          <p style={{ margin: '0' }}>[email]</p>
          <p></p>
        </li>
        <li>
          Third-Party Content
          <p>
            The Services may include references or hyperlinks to other web sites
            or content or resources or email content. Virtius has no control
            over any web sites or resources which are provided by companies or
            persons other than Virtius.
          </p>
          <p>
            You acknowledge and agree that Virtius is not responsible for the
            availability of any such external sites or resources, and does not
            endorse any advertising, products or other materials on or available
            from such web sites or resources.
          </p>
          <p>
            You acknowledge and agree that Virtius is not liable for any loss or
            damage which may be incurred by you or other users as a result of
            the availability of those external sites or resources, or as a
            result of any reliance placed by you on the completeness, accuracy
            or existence of any advertising, products or other materials on, or
            available from, such web sites or resources.
          </p>
        </li>
        <li>
          Third Party Software
          <p>
            The Services may incorporate certain third party software (“Third
            Party Software”), which is licensed subject to the terms and
            conditions of the third party licensing such Third Party Software.
            Nothing in these Terms limits your rights under, or grants you
            rights that supersede, the terms and conditions of any applicable
            license for such Third Party Software.
          </p>
        </li>
        <li>
          Feedback
          <p>
            You may choose to or we may invite you to submit comments or ideas
            about the Services, including without limitation about how to
            improve the Services or our products. By submitting any feedback,
            you agree that your disclosure is gratuitous, unsolicited and
            without restriction and will not place Virtius under any fiduciary
            or other obligation, and that we are free to use such feedback
            without any additional compensation to you, and/or to disclose such
            feedback on a non-confidential basis or otherwise to anyone.
          </p>
        </li>
        <li>
          Disputes
          <p>
            Please read the following section carefully because it requires you
            to arbitrate certain disputes and claims with Virtius and limits the
            manner in which you can seek relief from us.
          </p>
          <p>
            These Terms and any action related thereto will be governed by the
            laws of the State of California without regard to its conflict of
            laws provisions. Except for small claims disputes in which you or
            Virtius seek to bring an individual action in small claims court
            located in the county of your billing address or claims for
            injunctive relief by either party, any dispute or controversy
            arising out of, in relation to, or in connection with these Terms or
            your use of the Services shall be finally settled by binding
            arbitration in San Francisco County, California under the Federal
            Arbitration Act (9 U.S.C. §§ 1-307) and the then current rules of
            JAMS (formerly known as Judicial Arbitration & Mediation Services)
            by one (1) arbitrator appointed in accordance with such rules. Where
            arbitration is not required by these Terms, the exclusive
            jurisdiction and venue of any action with respect to the subject
            matter of these Terms will be the state and federal courts located
            in San Francisco, California, and each of the parties hereto waives
            any objection to jurisdiction and venue in such courts. ANY DISPUTE
            RESOLUTION PROCEEDING ARISING OUT OF OR RELATED TO THESE TERMS OR
            THE SALES TRANSACTIONS BETWEEN YOU AND VIRTIUS, WHETHER IN
            ARBITRATION OR OTHERWISE, SHALL BE CONDUCTED ONLY ON AN INDIVIDUAL
            BASIS AND NOT IN A CLASS, CONSOLIDATED OR REPRESENTATIVE ACTION, AND
            YOU EXPRESSLY AGREE THAT CLASS ACTION AND REPRESENTATIVE ACTION
            PROCEDURES SHALL NOT BE ASSERTED IN NOR APPLY TO ANY ARBITRATION
            PURSUANT TO THESE TERMS AND CONDITIONS. YOU ALSO AGREE NOT TO BRING
            ANY LEGAL ACTION, BASED UPON ANY LEGAL THEORY INCLUDING CONTRACT,
            TORT, EQUITY OR OTHERWISE, AGAINST VIRTIUS THAT IS MORE THAN ONE
            YEAR AFTER THE DATE OF THE APPLICABLE ORDER.
          </p>
          <p>
            You have the right to opt out of binding arbitration within 30 days
            of the date you first accepted the terms of this Section by emailing
            us at [insert email]. In order to be effective, the opt out notice
            must include your full name and clearly indicate your intent to opt
            out of binding arbitration.{' '}
          </p>
        </li>
        <li>
          Miscellaneous
          <p>
            These Terms, together with our Privacy Policy, constitutes the
            entire agreement between the parties relating to the Services and
            all related activities. These Terms shall not be modified except in
            writing signed by both parties or by a new posting of these Terms
            issued by us. If any part of these Terms is held to be unlawful,
            void, or unenforceable, that part shall be deemed severed and shall
            not affect the validity and enforceability of the remaining
            provisions. The failure of Virtius to exercise or enforce any right
            or provision under these Terms shall not constitute a waiver of such
            right or provision. Any waiver of any right or provision by Virtius
            must be in writing and shall only apply to the specific instance
            identified in such writing. You may not assign these Terms, or any
            rights or licenses granted hereunder, whether voluntarily, by
            operation of law, or otherwise without our prior written consent.{' '}
          </p>
        </li>
        <li>
          Contact Us
          <p>
            If you have any questions about these Terms or if you wish to make
            any complaint or claim with respect to the Services, please contact
            us at: <a href="mailto:support@virti.us">support@virti.us</a>
          </p>
          <p>
            When submitting a complaint, please provide a brief description of
            the nature of your complaint and the specific services to which your
            complaint relates.
          </p>
        </li>
      </ol>
    </div>
  );
}

export default Terms;
