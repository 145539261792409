import React, { useEffect, useState, useMemo } from 'react';
import { Modal, Button, Row, /*Col,*/ Spinner, Alert } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { alertActions, adminActions } from '../../redux/_actions';
import LeagueForm from './leagueform';
import LeagueDetail from './leaguedetail';
import TableComponent from '../helpers/tablecomponent';

const modeType = {
  ADD: 'ADD',
  EDIT: 'EDIT',
  DELETE: 'DELETE',
  LIST: 'LIST',
  DETAIL: 'DETAIL',
};

function LeagueAdmin(props) {
  const { setIsForm } = props;
  const [isLoading, setIsLoading] = useState(true);
  const [mode, setMode] = useState(modeType.LIST);
  const [leagueId, setLeagueId] = useState(null);
  const { alert } = useSelector((state) => state);
  const { leagues } = useSelector((state) => state.admin);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!alert.clear) {
      setIsLoading(false);
    }
  }, [alert.clear]);

  useEffect(() => {
    dispatch(alertActions.clear());
  }, [dispatch]);

  useEffect(() => {
    if (leagues.byId.length === 0) {
      setIsLoading(true);
      dispatch(adminActions.getLeagues());
    } else {
      setIsLoading(false);
    }
  }, [leagues, dispatch]);

  const leagueDetail = (e, row) => {
    if (!alert.clear) {
      dispatch(alertActions.clear());
    }
    setLeagueId(row.id);
    setMode(modeType.DETAIL);
    setIsForm(true);
  };

  const back = () => {
    setMode(modeType.LIST);
    setIsForm(false);
  };

  const add = () => {
    setMode(modeType.ADD);
    setIsForm(true);
  };

  const loading = () => {
    return (
      <Row
        style={{ margin: '1rem -15px', minHeight: '20vh', textAlign: 'center' }}
        className="vCenter"
      >
        <div className="vCenter" style={{ margin: '0 auto' }}>
          <Spinner
            variant="secondary"
            role="status"
            animation="border"
            size="md"
          />
        </div>
      </Row>
    );
  };

  const emptyList = () => {
    return (
      <Row
        style={{ margin: '1rem -15px', minHeight: '20vh', textAlign: 'center' }}
        className="vCenter"
      >
        <span>No leagues created.</span>
      </Row>
    );
  };

  const headers = useMemo(
    () => [
      { Header: '#', accessor: 'col1' },
      { Header: 'Name', accessor: 'col2' },
      { Header: 'Type', accessor: 'col3' },
      { Header: 'Gender', accessor: 'col4' },
      { Header: 'Teams', accessor: 'col5' },
    ],
    []
  );

  const data = useMemo(() => {
    return leagues.allIds.map((id, i) => {
      const { name, type, gender /*teams*/ } = leagues.byId[id];

      return {
        col1: i + 1,
        col2: name,
        col3: type,
        col4: gender,
        col5: 'TBD', //teams.items.length,
        id: id,
      };
    });
  }, [leagues]);

  const table = () => {
    const initialState = {
      sortBy: [{ id: 'col2', desc: false }],
    };

    return leagues.allIds.length === 0 ? (
      emptyList()
    ) : (
      <TableComponent
        columns={headers}
        data={data}
        onClick={leagueDetail}
        initialState={initialState}
        textSort={true}
      />
    );
  };

  const adminBody = () => {
    return (
      <>
        {!alert.clear ? (
          <Row style={{ padding: '0 15px', margin: '1rem -15px' }}>
            <Alert
              dismissible
              onClose={() => dispatch(alertActions.clear())}
              variant={alert.type === 'alert-danger' ? 'danger' : 'success'}
              style={{ textAlign: 'center', width: '100%' }}
            >
              {alert.message}
            </Alert>
          </Row>
        ) : null}
        {isLoading ? loading() : table()}
      </>
    );
  };

  const footer = () => {
    return (
      <>
        <Row className="vCenter" style={{ margin: '0 auto' }}>
          <Button
            variant="outline-primary"
            className="formButton"
            onClick={add}
            disabled={isLoading}
          >
            Add league
          </Button>
        </Row>
        <Row />
      </>
    );
  };

  const headerMsg = () => {
    return <span>League Admin</span>;
  };

  const listModal = () => {
    return (
      <>
        <Modal.Header className="setupHeader">{headerMsg()}</Modal.Header>
        <Modal.Body className="setupForm" style={{ width: 'auto' }}>
          {adminBody()}
        </Modal.Body>
        <Modal.Footer className="setupFooter">{footer()}</Modal.Footer>
      </>
    );
  };

  const modal = () => {
    switch (mode) {
      case modeType.ADD:
        return <LeagueForm back={back} />;
      case modeType.EDIT:
        break;
      case modeType.DELETE:
        break;
      case modeType.LIST:
        return listModal();
      case modeType.DETAIL:
        return <LeagueDetail back={back} leagueId={leagueId} />;
      default:
        return null;
    }
  };

  return modal();
}

export default LeagueAdmin;
