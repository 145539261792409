import {
  evaluatorConstants,
  initialEvaluatorState,
  initialPanelState,
  evaluatorConfigs,
} from '../_constants';
import { store } from '../store';
import {
  calculateScore,
  checkSubScoresComplete,
} from '../../utilities/scoring';

export const evaluatorActions = {
  initialize,
  reset,
  lockSubScore,
  unlockSubScore,
  lockFinalScore,
  unlockFinalScore,
  resolveInquiry,
};

function initialize(
  data = initialEvaluatorState,
  config = evaluatorConfigs.DEFAULT
) {
  // Use config details to customize evaluator settings
  const configuredData = { ...data };
  const dScore = {};
  const eScore = {};
  const ND = config.nDeduct ? { ND: [] } : null;
  const SB = config.stickBonus ? { SB: [] } : null;

  for (let i = 0; i < config['dPanel'].length; i++) {
    dScore[config['dPanel'].length > 1 ? `D${i + 1}` : 'D'] = [];
  }

  for (let i = 0; i < config['ePanel'].length; i++) {
    eScore[config['ePanel'].length > 1 ? `E${i + 1}` : 'E'] = [];
  }

  const subScores = { ...dScore, ...eScore, ...ND, ...SB };
  configuredData['evalPanelA']['subScores'] = subScores;
  configuredData['evalPanelB']['subScores'] = subScores;

  return {
    type: evaluatorConstants.INITIALIZE,
    data: configuredData,
    config: config,
  };
}

function lockSubScore(scoreData) {
  const panel = 'evalPanel' + scoreData.panel;
  const evalPanel = { ...store.getState().evaluator[panel] };
  const newFinalScore = [...JSON.parse(JSON.stringify(evalPanel.finalScore))];
  const newSubScoreData = [
    ...JSON.parse(JSON.stringify(evalPanel.subScores[scoreData.type])),
  ];
  newSubScoreData.push(JSON.parse(JSON.stringify(scoreData)));
  const newSubScores = {
    ...JSON.parse(JSON.stringify(evalPanel.subScores)),
    [scoreData.type]: newSubScoreData,
  };
  const panelComplete = checkSubScoresComplete(newSubScores);

  if (panelComplete) {
    const finalScoreData = {
      evaluator: null,
      score: calculateScore(newSubScores),
      time: scoreData.time,
      panel: scoreData.panel,
      locked: false,
    };
    newFinalScore.push(finalScoreData);
  }

  const data = {
    panel: panel,
    type: scoreData.type,
    subScoreComplete: panelComplete,
    finalScore: newFinalScore,
    subScoreData: newSubScoreData,
  };

  return { type: evaluatorConstants.LOCK_SUB_SCORE, data };
}

function unlockSubScore(scoreData) {
  const panel = 'evalPanel' + scoreData.panel;
  const evalPanel = { ...store.getState().evaluator[panel] };
  const newFinalScore = [...JSON.parse(JSON.stringify(evalPanel.finalScore))];
  const newSubScoreData = [
    ...JSON.parse(JSON.stringify(evalPanel.subScores[scoreData.type])),
  ];
  const len = newSubScoreData.length;
  const newSubScores = {
    ...JSON.parse(JSON.stringify(evalPanel.subScores)),
    [scoreData.type]: newSubScoreData,
  };
  let panelComplete = false;

  // Need to check panelComplete in case Optional score removed

  console.log('scoreData: ', scoreData);
  console.log('evalPanel: ', evalPanel);
  console.log('newSubScoreData: ', newSubScoreData);

  if (len !== 0) {
    const lastSubScoreData = { ...newSubScoreData[len - 1] };
    lastSubScoreData.locked = false;
    newSubScoreData[len - 1] = lastSubScoreData;
    panelComplete = checkSubScoresComplete(newSubScores);
  }

  if (panelComplete) {
    const finalScoreData = {
      evaluator: null,
      score: calculateScore(newSubScores),
      time: scoreData.time,
      panel: scoreData.panel,
      locked: false,
    };
    newFinalScore.push(finalScoreData);
  }

  const data = {
    panel: panel,
    type: scoreData.type,
    subScoreComplete: panelComplete,
    subScoreData: newSubScoreData,
    finalScore: newFinalScore,
  };

  return { type: evaluatorConstants.UNLOCK_SUB_SCORE, data };
}

function reset(panel) {
  const panelName = 'evalPanel' + panel;
  const evalPanel = { ...store.getState().evaluator[panelName] };
  const cleanState = { ...initialPanelState };
  const cleanSubScores = { ...evalPanel.subScores };

  for (const item in cleanSubScores) {
    cleanSubScores[item] = [];
  }

  cleanState.subScores = cleanSubScores;
  const data = { data: cleanState, panel: panelName };

  return { type: evaluatorConstants.RESET, data };
}

function lockFinalScore(scoreData, inquiry = false) {
  const panel = 'evalPanel' + scoreData.panel;
  const evalPanel = JSON.parse(
    JSON.stringify(store.getState().evaluator[panel])
  );
  const newFinalScore = JSON.parse(JSON.stringify(evalPanel.finalScore));
  const len = newFinalScore.length;
  let locked = evalPanel.locked;
  let finishTime = evalPanel.finishTime;

  // Lock final score from previous subscores (normal)
  if (len !== 0) {
    const lastFinalScoreData = { ...newFinalScore[len - 1] };
    lastFinalScoreData.evaluator = scoreData.evaluator;
    lastFinalScoreData.locked = true;
    locked = true;
    finishTime = scoreData.time;
    lastFinalScoreData.time = scoreData.time;

    // Inquiry case w/ no score change
    if (inquiry && newFinalScore[len - 1].locked) {
      newFinalScore.push(lastFinalScoreData);
    } else {
      newFinalScore[len - 1] = lastFinalScoreData;
    }
  }

  const data = {
    panel: panel,
    finalScore: newFinalScore,
    locked: locked,
    finishTime: finishTime,
    resolved: inquiry,
  };

  return { type: evaluatorConstants.LOCK_FINAL_SCORE, data };
}

function unlockFinalScore(scoreData) {
  const panel = 'evalPanel' + scoreData.panel;
  const evalPanel = { ...store.getState().evaluator[panel] };
  const newFinalScore = [...evalPanel.finalScore];
  let locked = evalPanel.locked;

  if (newFinalScore.length !== 0) {
    newFinalScore[newFinalScore.length - 1].locked = false;
    locked = false;
  }

  const data = {
    panel: panel,
    finalScore: newFinalScore,
    locked: locked,
  };

  return { type: evaluatorConstants.UNLOCK_FINAL_SCORE, data };
}

function resolveInquiry(home, index, evalPanel) {
  const panel = `evalPanel${home ? 'A' : 'B'}`;
  const resolvePanel = { ...evalPanel };
  const newFinalScore = [...evalPanel.finalScore];
  //newFinalScore[newFinalScore.length-1].locked = false;   // do not unlock last submitted score
  resolvePanel.finalScore = newFinalScore;
  resolvePanel.locked = false;

  const data = {
    panel: panel,
    data: resolvePanel,
  };

  return { type: evaluatorConstants.RESOLVE_INQUIRY, data };
}
