// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';

const SessionType = {
  "SOLO": "SOLO",
  "DUAL": "DUAL",
  "MULTI": "MULTI"
};

const GenderType = {
  "MALE": "MALE",
  "FEMALE": "FEMALE",
  "COED": "COED"
};

const SessionAccess = {
  "PRIVATE": "PRIVATE",
  "PUBLIC": "PUBLIC",
  "KEY": "KEY",
  "GUESTLIST": "GUESTLIST"
};

const SessionStatus = {
  "CREATED": "CREATED",
  "PREGAME": "PREGAME",
  "LIVE": "LIVE",
  "POSTGAME": "POSTGAME",
  "ARCHIVED": "ARCHIVED",
  "CANCELED": "CANCELED",
  "DELETED": "DELETED"
};

const GymApparatus = {
  "FX": "FX",
  "PH": "PH",
  "SR": "SR",
  "VT": "VT",
  "PB": "PB",
  "HB": "HB",
  "UB": "UB",
  "BB": "BB"
};

const RoutineStatus = {
  "ON_AIR": "ON_AIR",
  "ON_EVAL": "ON_EVAL",
  "COMPLETE": "COMPLETE"
};

const ScoreType = {
  "D": "D",
  "E": "E",
  "E1": "E1",
  "E2": "E2",
  "SB": "SB",
  "ND": "ND"
};

const UserStatus = {
  "CREATED": "CREATED",
  "VERIFIED": "VERIFIED",
  "WAITLIST": "WAITLIST",
  "ACTIVE": "ACTIVE",
  "DELETED": "DELETED"
};

const LeagueType = {
  "FIG": "FIG",
  "JO": "JO",
  "NCAA": "NCAA",
  "CLUB": "CLUB",
  "OTHER": "OTHER"
};

const ClassYear = {
  "RFR": "RFR",
  "RSO": "RSO",
  "RJR": "RJR",
  "RSR": "RSR",
  "FR": "FR",
  "SO": "SO",
  "JR": "JR",
  "SR": "SR",
  "ELT": "ELT",
  "PFR": "PFR",
  "RET": "RET"
};

const JudgeType = {
  "FIG_MENS": "FIG_MENS",
  "FIG_WOMENS": "FIG_WOMENS",
  "NCAA_MENS": "NCAA_MENS",
  "NCAA_WOMENS": "NCAA_WOMENS",
  "JO_MENS": "JO_MENS",
  "JO_WOMENS": "JO_WOMENS"
};

const RotationStatus = {
  "CREATED": "CREATED",
  "ACTIVE": "ACTIVE",
  "COMPLETE": "COMPLETE"
};

const EventStatus = {
  "CREATED": "CREATED",
  "ACTIVE": "ACTIVE",
  "COMPLETE": "COMPLETE"
};

const StreamStatus = {
  "OFF": "OFF",
  "STARTING": "STARTING",
  "STARTED": "STARTED",
  "STOPPING": "STOPPING",
  "STOPPED": "STOPPED",
  "RESETTING": "RESETTING",
  "DELETING": "DELETING",
  "DELETED": "DELETED",
  "CREATING": "CREATING",
  "ERROR": "ERROR",
  "ARCHIVED": "ARCHIVED"
};

const { Stream, Session, Lineup, Routine, Clip, Score, SessionJudge, User, TeamCoach, Team, Roster, RosterLink, Athlete, LeagueTeam, League, SessionTeam, Rotation, Event } = initSchema(schema);

export {
  Stream,
  Session,
  Lineup,
  Routine,
  Clip,
  Score,
  SessionJudge,
  User,
  TeamCoach,
  Team,
  Roster,
  RosterLink,
  Athlete,
  LeagueTeam,
  League,
  SessionTeam,
  Rotation,
  Event,
  SessionType,
  GenderType,
  SessionAccess,
  SessionStatus,
  GymApparatus,
  RoutineStatus,
  ScoreType,
  UserStatus,
  LeagueType,
  ClassYear,
  JudgeType,
  RotationStatus,
  EventStatus,
  StreamStatus
};