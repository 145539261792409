import React from 'react';
import './theoplayer.css';

class Player extends React.Component {
  _player = null;
  _wowzaSession = null;
  _el = React.createRef();

  onNetworkError(response, callback) {
    if (response.status >= 400 && response.status <= 599) {
      let errorDetails = {
        status: response.status,
        fileType: response.request.type,
        url: response.request.url,
      };
      let networkErrorEvent = new CustomEvent('THEOnetworkerror', {
        detail: errorDetails,
      });
      callback(networkErrorEvent);
    }
  }

  attachABRResetLogic(player) {
    if (player.buffered && player.buffered.length > 0) {
      // switch to normal ABR when THEO buffered beyond 10 seconds
      if (player.buffered.end(player.buffered.length - 1) > 10) {
        player.videoTracks[0].targetQuality = null;
        player.removeEventListener('progress', this.attachABRResetLogic);
      }
    }
  }

  componentDidMount() {
    // props like the source, or the sourcedesciption as a whole can be passed through
    // the can then be used to be called on the player itself
    const {
      source,
      onPlay,
      onPause,
      onReady,
      onError,
      onSeek,
      muted,
    } = this.props;

    if (this._el.current) {
      this._player = new window.THEOplayer.Player(this._el.current, {
        hlsDateRange: true,
        mutedAutoplay: 'all',
        libraryLocation:
          'https://cdn.myth.theoplayer.com/36434bcd-1733-49a6-9661-53467f4165a5',
      });
      //this._wowzaSession = theoplayerWowza.registerPlayer(this._player);
      this._player.source = source;
      //this._player.preload = 'auto';
      this._player.preload = 'metadata';
      this._player.autoplay = true;
      this._player.abr.strategy = { type: 'performance', metadata: 3000 };
      this._player.abr.targetBuffer = 6;
      this._player.muted = muted;
      this._player.addEventListener('readystatechange', onReady);
      this._player.addEventListener('play', onPlay);
      this._player.addEventListener('pause', onPause);
      this._player.addEventListener('seeking', onSeek);
      this._player.addEventListener('error', onError);
      //this._player.addEventListener('playing', () => console.log("theoplayer playing"))
      this._player.addEventListener('loadedmetadata', (e) =>
        console.log('loaded metadata: ', e)
      );
      this._player.network.addResponseInterceptor((e) =>
        this.onNetworkError(e, onError)
      );

      /*
      this._player.videoTracks.addEventListener('addtrack', (e) => {
        if (!this._player.videoTracks || this._player.videoTracks.length === 0) {
          return;
        }
        this._player.videoTracks[0].targetQuality = this._player.videoTracks[0].qualities[0]; // start with a specific quality
        this._player.addEventListener('progress', () =>
          this.attachABRResetLogic(this._player)
        );
      });
        */
      //console.log(this._wowzaSession.getState())
      //console.log(this._wowzaSession.getStreamData())
    }
  }

  componentWillUnmount() {
    if (this._player) {
      this._player.destroy();
    }
  }

  render() {
    return (
      <div
        // vjs-16-9 THEOplayer are not necessary, but just added for demo purposes
        className={
          'videoPlayer theoplayer-container video-js theoplayer-skin vjs-16-9 THEOplayer'
        }
        // The ref prop here is key it returns the actual dom element and not the virtual react dom elements
        // Which is need by the player
        ref={this._el}
      />
    );
  }
}

export default Player;

const theoplayerWowza = {
  registerPlayer: function (player) {
    let wowzaData = null;
    let wowzaSource = null;
    let streamIsUnavailable = false;
    let wowzaStreamInterval;
    let countdownTimerInterval;
    let isWaiting = false;
    let hasInterval = false;
    let statechangeCallback = null;
    let datachangeCallback = null;

    function handleWowzaOffline() {
      statechangeCallback({
        state: 'unavailable',
        info: 'The configured stream is currently unavailable.',
      });
      streamIsUnavailable = true;
      createStreamStatusDiv();
    }

    function getRetryTimeout() {
      return (wowzaSource && wowzaSource.retryTimeout) || 3000;
    }

    function getOfflineText() {
      return (
        (wowzaSource && wowzaSource.offlineText) ||
        'The stream is currently not available.'
      );
    }

    function setupCountdown(countDownDate) {
      const countDownTime = countDownDate.getTime();
      countdownTimerInterval = setInterval(function () {
        let now = new Date().getTime();
        let distance = countDownTime - now;
        let days = Math.floor(distance / (1000 * 60 * 60 * 24));
        let hours = Math.floor(
          (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        let seconds = Math.floor((distance % (1000 * 60)) / 1000);
        getContainer().querySelector('.countdown').innerHTML =
          days + 'd ' + hours + 'h ' + minutes + 'm ' + seconds + 's ';
        if (distance < 0) {
          clearInterval(countdownTimerInterval);
          getContainer().querySelector('.countdown').innerHTML = 'Starting...';
        }
      }, 1000);
    }

    function createStreamStatusDiv() {
      let streamStatusDiv = getContainer().querySelector('.stream-status');
      if (!streamStatusDiv) {
        streamStatusDiv = document.createElement('div');
        streamStatusDiv.className = 'stream-status';
        let poster = getPoster();
        if (poster) {
          streamStatusDiv.style.background = 'url("' + poster + '")';
          streamStatusDiv.style.backgroundSize = 'cover';
        }

        if (wowzaData) {
          let countdown = wowzaData['countdown_timestamp'];
          if (countdown) {
            countdown = new Date(parseInt(countdown) * 1000);
            if (countdown.getTime() > Date.now()) {
              // in the future
              let countdownDiv = document.createElement('div');
              countdownDiv.className = 'stream-status-overlay';
              let date = countdown.toDateString();
              let hhmm = countdown.toISOString().substr(11, 5);
              let title = wowzaData['title'] + '<br />' || '';
              countdownDiv.innerHTML =
                title + 'Stream will start on:<br />' + date + ' @ ' + hhmm;
              streamStatusDiv.appendChild(countdownDiv);

              let countdownTimer = document.createElement('div');
              countdownTimer.className = 'countdown';
              countdownTimer.innerHTML = '&nbsp;';
              countdownDiv.appendChild(countdownTimer);
              setupCountdown(countdown);
            }
          }
        } else {
          streamStatusDiv.textContent = getOfflineText();
          streamStatusDiv.classList.add('no-data');
        }

        getContainer().appendChild(streamStatusDiv);
      }
    }
    function removeStreamStatusDiv() {
      let streamStatusDiv = getContainer().querySelector('.stream-status');
      if (streamStatusDiv) {
        streamStatusDiv.parentNode.removeChild(streamStatusDiv);
      }
    }
    function handleWowzaOnline() {
      statechangeCallback({
        state: 'available',
        info: 'The configured stream is available.',
      });
      streamIsUnavailable = false;
      removeStreamStatusDiv();
    }

    function getContainer() {
      return player.element.parentNode.parentNode;
    }

    function streamInterval() {
      let currentSrc = player.src;
      let xhttp = new XMLHttpRequest();
      xhttp.onreadystatechange = function () {
        if (
          this.readyState == 4 &&
          this.status != 200 &&
          isWaiting &&
          !streamIsUnavailable
        ) {
          handleWowzaOffline();
        } else if (
          this.readyState == 4 &&
          this.status == 200 &&
          streamIsUnavailable
        ) {
          reloadCurrentStream();
          handleWowzaOnline();
        }
      };
      xhttp.open('GET', currentSrc, true);
      xhttp.send();
    }

    function checkStreamStatus() {
      isWaiting = true;
      if (!hasInterval) {
        hasInterval = true;
        wowzaStreamInterval = setInterval(streamInterval, getRetryTimeout());
      }
    }

    function setPlaying() {
      isWaiting = false;
    }

    function registerWowzaEvents() {
      if (player) {
        player.network.addEventListener('offline', handleWowzaOffline);
        player.network.addEventListener('online', handleWowzaOnline);
        player.addEventListener('waiting', checkStreamStatus);
        player.addEventListener('playing', setPlaying);
      }
    }

    function unregisterWowzaEvents() {
      if (player) {
        wowzaData = null;
        wowzaSource = null;
        streamIsUnavailable = false;
        clearInterval(wowzaStreamInterval);
        clearInterval(countdownTimerInterval);
        isWaiting = false;
        hasInterval = false;
        statechangeCallback = null;
        datachangeCallback = null;
        player.network.removeEventListener('offline', handleWowzaOffline);
        player.network.removeEventListener('online', handleWowzaOnline);
        player.removeEventListener('waiting', checkStreamStatus);
        player.removeEventListener('playing', setPlaying);
      }
    }

    function getPoster() {
      return (
        (wowzaData && wowzaData.image) ||
        (player.source.metadata &&
          player.source.metadata.wowza &&
          player.source.metadata.wowza.placeholderImageUrl)
      );
    }

    function sourceHandler(e) {
      unregisterWowzaEvents();
      wowzaSource = e.source.metadata && e.source.metadata.wowza;
      if (wowzaSource) {
        registerWowzaEvents();
        let wowzaJson = wowzaSource.jsonUrl;
        if (wowzaJson) {
          console.log('in sourceHandler registering wowzadata');
          registerWowzaData(wowzaJson);
        }
        statechangeCallback = wowzaSource.statechangeCallback;
        datachangeCallback = wowzaSource.datachangeCallback;
      }
    }

    function registerWowzaData(url) {
      let xhttp = new XMLHttpRequest();
      xhttp.onreadystatechange = function () {
        console.log('In registerWowzaData');
        console.log(xhttp.responseText);

        if (this.readyState == 4 && this.status == 200) {
          wowzaData = JSON.parse(xhttp.responseText);
          datachangeCallback({ data: wowzaData, status: 'available' });
          player.poster = getPoster();
        }
      };
      xhttp.open('GET', url, true);
      xhttp.send();
    }

    function reloadCurrentStream() {
      console.log('reloading wowza stream');
      player.autoplay = true;
      player.source = player.source;
    }

    function getWowzaData() {
      return wowzaData;
    }

    function getState() {
      return streamIsUnavailable ? 'offline' : 'online';
    }

    player.addEventListener('sourcechange', sourceHandler);

    return {
      getStreamData: getWowzaData,
      getState: getState,
    };
  },
};
