import React from 'react';
import { Row, Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import {
  producerActions,
  videoPlayerActions,
  sessionActions,
} from '../../redux/_actions';
import {
  streamStatus,
  sessionStatus,
  sessionRoleType,
  sessionViewType,
} from '../../redux/_constants';
import { dateTime } from '../../utilities/conversions';

function TriggerPanel() {
  const { producer, session, videoPlayer, user } = useSelector(
    (state) => state
  );
  const dispatch = useDispatch();
  const { STARTED } = streamStatus;
  const { CREATED, PREGAME, LIVE, POSTGAME } = sessionStatus;
  const { ADMIN, JUDGE, PRODUCER, FAN, COACH, ANNOUNCER } = sessionRoleType;
  const { streamAStatus, streamBStatus } = session;

  const handleInfo = () => {
    return;
  };

  const handleHint = () => {
    dispatch(producerActions.showScores());
  };

  const handleJudge = () => {
    dispatch(producerActions.judge(!producer.judge));
  };

  const handleClip = () => {
    dispatch(producerActions.clip(!producer.clip));
  };

  const handleSync = () => {
    dispatch(videoPlayerActions.sync());
  };

  const handleVod = () => {
    dispatch(videoPlayerActions.vod(!videoPlayer.controller.vod));
  };

  const handleLive = () => {
    dispatch(producerActions.live(!producer.live));
  };

  const handleEdit = () => {
    dispatch(producerActions.edit(!producer.edit));
  };

  const handleSort = () => {
    dispatch(producerActions.sortLineup()); // TODO;
  };

  const handleView = (view) => {
    dispatch(sessionActions.changeView(view));
  };

  const handleForce = () => {
    dispatch(producerActions.force());
  };
  
  const handleReset = () => {
    dispatch(producerActions.resetLineup());
  };

  const vodButton = (
    <Button
      variant={
        videoPlayer.controller.vod ? 'outline-primary' : 'outline-secondary'
      }
      onClick={handleVod}
    >
      MODE
    </Button>
  );

  const syncButton = (
    <Button
      variant={
        videoPlayer.controller.sync ? 'outline-primary' : 'outline-secondary'
      }
      onClick={handleSync}
    >
      SYNC
    </Button>
  );

  const resetButton = (
    <OverlayTrigger
      placement="top"
      delay={{ show: 700, hide: 0 }}
      overlay={<Tooltip id="popover-contained">Reset Lineup</Tooltip>}
    >
      <Button
        variant={'outline-secondary'}
        onClick={handleReset}
        disabled={false}
      >
        RESET
      </Button>
    </OverlayTrigger>
  );

  const infoButton = (
    <OverlayTrigger
      placement="top"
      delay={{ show: 700, hide: 0 }}
      arrowProps={false}
      trigger="click"
      overlay={
        <Tooltip id="popover-contained" className="sessionInfo">
          <p className="title">{session.name}</p>
          <p className="date">{dateTime(session.startAt, true)}</p>
        </Tooltip>
      }
    >
      {/*<span className="tPanelDud">*/}
      <Button
        variant={'outline-secondary'}
        onClick={handleInfo}
        disabled={false}
      >
        INFO
      </Button>
      {/*</span> */}
    </OverlayTrigger>
  );

  const streamButton = (
    <Button
      variant={producer.live ? 'outline-danger' : 'outline-secondary'}
      onClick={handleLive}
      className={producer.live ? 'blinkingLive' : 'notLive'}
      //disabled={streamAStatus !== STARTED && streamBStatus !== STARTED}
      disabled={true}
    >
      {session.type}
    </Button>
  );

  const editButton = (
    <OverlayTrigger
      placement="top"
      delay={{ show: 700, hide: 0 }}
      overlay={<Tooltip id="popover-contained">Modify Lineups</Tooltip>}
    >
      <Button
        variant={producer.edit ? 'outline-primary' : 'outline-secondary'}
        onClick={handleEdit}
        className={producer.edit ? 'blinkingEdit' : 'notLive'}
        disabled={producer.live} // will tie later in with producer mode
      >
        EDIT
      </Button>
    </OverlayTrigger>
  );

  const sortButton = (
    <OverlayTrigger
      placement="top"
      delay={{ show: 700, hide: 0 }}
      overlay={<Tooltip id="popover-contained">Judge Routines</Tooltip>}
    >
      <Button
        variant={producer.judge ? 'outline-danger' : 'outline-secondary'}
        onClick={handleSort}
        className={producer.judge ? 'blinkingLive' : 'notLive'}
        disabled={true}
      >
        SORT
      </Button>
    </OverlayTrigger>
  );

  const viewButton = (
    <OverlayTrigger
      placement="top"
      delay={{ show: 700, hide: 0 }}
      overlay={<Tooltip id="popover-contained">Change View</Tooltip>}
    >
      <Button
        variant={producer.judge ? 'outline-danger' : 'outline-secondary'}
        onClick={() => handleView(sessionViewType.SCOREBOARD)}
        className={producer.judge ? 'blinkingLive' : 'notLive'}
        disabled={false}
      >
        VIEW
      </Button>
    </OverlayTrigger>
  );

  const judgeButton = (
    <OverlayTrigger
      placement="top"
      delay={{ show: 700, hide: 0 }}
      overlay={<Tooltip id="popover-contained">Judge Routines</Tooltip>}
    >
      <Button
        variant={producer.judge ? 'outline-warning' : 'outline-secondary'}
        onClick={handleJudge}
        className={producer.judge ? 'blinkingWarning' : 'notLive'}
      >
        EVAL
      </Button>
    </OverlayTrigger>
  );

  const forceButton = (
    <OverlayTrigger
      placement="top"
      delay={{ show: 700, hide: 0 }}
      overlay={<Tooltip id="popover-contained">Force Order</Tooltip>}
    >
      <Button
        variant={producer.force ? 'outline-warning' : 'outline-secondary'}
        onClick={handleForce}
        className={producer.force ? 'blinkingWarning' : 'notLive'}
      >
        FORCE
      </Button>
    </OverlayTrigger>
  );

  const hintButton = (
    <OverlayTrigger
      placement="top"
      delay={{ show: 700, hide: 0 }}
      overlay={
        <Tooltip id="popover-contained">
          {producer.scores ? 'Hide ' : 'Show '}Scores
        </Tooltip>
      }
    >
      <Button
        variant={'outline-secondary'}
        onClick={handleHint}
        disabled={false}
      >
        {producer.scores ? 'HIDE' : 'SHOW'}
      </Button>
    </OverlayTrigger>
  );

  const clipButton = (
    <OverlayTrigger
      placement="top"
      delay={{ show: 700, hide: 0 }}
      overlay={
        <Tooltip id="popover-contained">Mark Routine Start/Finish</Tooltip>
      }
    >
      <Button
        variant={producer.clip ? 'outline-success' : 'outline-secondary'}
        onClick={handleClip}
        className={producer.clip ? 'blinkingClip' : 'notLive'}
      >
        CLIP
      </Button>
    </OverlayTrigger>
  );

  const liveButton = (
    <OverlayTrigger
      placement="top"
      delay={{ show: 700, hide: 0 }}
      overlay={
        <Tooltip id="popover-contained">
          {!producer.live ? 'Start Round' : 'End Round'}
        </Tooltip>
      }
    >
      <Button
        variant={producer.live ? 'outline-danger' : 'outline-secondary'}
        onClick={handleLive}
        className={producer.live ? 'blinkingLive' : 'notLive'}
        disabled={session.status !== LIVE}
      >
        {session.type}
      </Button>
    </OverlayTrigger>
  );

  const placeHolder = <div className="tPanelPlaceholder" />;

  const adminPanel = (
    <>
      {infoButton}
      {session.status === POSTGAME ? hintButton : null}
      {session.status === POSTGAME ? null : liveButton}
      {editButton}
      {session.status === POSTGAME ? clipButton : forceButton}
      {session.status === POSTGAME ? judgeButton : viewButton}
    </>
  );

  const userPanel = (
    <>
      {infoButton}
      {placeHolder}
      {session.status === POSTGAME ? placeHolder : streamButton}
      {/* session.status === POSTGAME ? sortButton : placeHolder */}
      {/*[POSTGAME].includes(session.status) ? judgeButton : placeHolder*/}
      {placeHolder}
      {[POSTGAME].includes(session.status) ? hintButton : placeHolder}
    </>
  );

  const coachPanel = (
    <>
      {infoButton}
      {placeHolder}
      {streamButton}
      {session.status === PREGAME ? editButton : placeHolder}
      {[POSTGAME].includes(session.status)
        ? hintButton
        : session.status === LIVE
        ? viewButton
        : placeHolder}
    </>
  );

  const producerPanel = (
    <>
      {infoButton}
      {[LIVE, PREGAME].includes(session.status) ? streamButton : placeHolder}
      {[LIVE].includes(session.status) ? editButton : placeHolder}
      {[LIVE, POSTGAME].includes(session.status) ? clipButton : placeHolder}
      {[POSTGAME].includes(session.status) ? judgeButton : placeHolder}
    </>
  );

  const judgePanel = (
    <>
      {infoButton}
      {placeHolder}
      {streamButton}
      {placeHolder}
      {[POSTGAME].includes(session.status) ? hintButton : placeHolder}
      {placeHolder}
    </>
  );

  const panelSwitcher = () => {
    //return userPanel;
    switch (session.role) {
      case ADMIN:
        return adminPanel;
      case PRODUCER:
        return producerPanel;
      case COACH:
        return coachPanel;
      case JUDGE:
        return judgePanel;
      default:
        return userPanel;
    }
  };

  const userType = () => {
    //return "FAN";
    switch (session.role) {
      case ADMIN:
        return ADMIN;
      case PRODUCER:
        return 'DIRECTOR';
      case COACH:
        return COACH;
      case JUDGE:
        return JUDGE;
      default:
        return FAN;
    }
  };

  const sessionStatusLabel = () => {
    switch (session.status) {
      case CREATED:
      case PREGAME:
        return 'PRE';
      case LIVE:
        return 'LIVE';
      case POSTGAME:
        return 'POST';
      default:
        return 'POST';
    }
  };

  return (
    <div className="triggerPanel">
      <Row className="tPanelTopRow">
        <span
          className={[
            'vCenter',
            producer.live ? 'blinkingLiveText' : null,
          ].join(' ')}
        >
          {sessionStatusLabel()}
        </span>
      </Row>
      <Row className="tPanelMiddleRow">{panelSwitcher()}</Row>
      <Row className="tPanelBottomRow">
        <span className="vCenter">{userType()}</span>
      </Row>
    </div>
  );
}

export default TriggerPanel;
