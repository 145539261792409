import { adminConstants, streamRequestType } from '../_constants';
import { alertActions } from '../_actions';
import { normalize, schema } from 'normalizr';
import { adminService, sessionService } from '../_services';
import { dataUtilities } from '../../utilities/data';
import { store } from '../store';

export const adminActions = {
  getTeams,
  getLeagues,
  getAthletes,
  getSessions,
  getUsers,
  sort,
  createLeague,
  editLeague,
  createTeam,
  editTeam,
  createAthlete,
  editAthlete,
  getTeamAthletes,
  createRoster,
  editRoster,
  updateTable,
  editSession,
  checkStreamPostgame,
  updateStreamURLs,
  editUser,
  //createSession,
  //createAthlete,
  //editAthletes
};

// Normalized data schema
const athlete = new schema.Entity('athletes');
const rosterLink = new schema.Entity('rosterLinks');
const roster = new schema.Entity('rosters', {
  athletes: {
    items: [rosterLink],
  },
});
const stream = new schema.Entity('streams');
const lineup = new schema.Entity('lineups');
const sessionTeam = new schema.Entity('sessionTeams');
const session = new schema.Entity('sessions', {
  sessionTeams: {
    items: [sessionTeam],
  },
  streams: {
    items: [stream],
  },
});
const coach = new schema.Entity('coaches');
const user = new schema.Entity('users');
const teamLeague = new schema.Entity('leagues');
const league = new schema.Entity('leagues', {});
const team = new schema.Entity('teams', {
  rosters: {
    items: [roster],
  },
  lineups: {
    items: [lineup],
  },
  coaches: {
    items: [coach],
  },
  leagues: {
    items: [league],
  },
});

league.define({
  teams: {
    items: [team],
  },
});

function sort(sortBy, byId, allIds, newItem = null) {
  const sortedResult = dataUtilities.sort(sortBy, byId, allIds, newItem);

  return { type: sortBy, result: sortedResult };
}

function getUsers() {
  return (dispatch) => {
    dispatch(request());
    adminService
      .loadUsers()
      .then((res) => {
        console.log(res);
        const normalizedUsers = normalize(res.data.listUsers.items, [user]);
        //const normalizedAthletes = normalize(res[1], [athlete]);
        const sortedResult = dataUtilities.sort(
          adminConstants.SORT_NAME_DESC,
          normalizedUsers.entities.users,
          normalizedUsers.result
        );
        const sortedUsers = { ...normalizedUsers, result: sortedResult };
        dispatch(success({ users: sortedUsers }));
      })
      .catch((error) => {
        console.log(error);
        dispatch(failure(error.toString()));
        dispatch(alertActions.error('Error loading users.'));
      });
  };

  function request() {
    return { type: adminConstants.GET_USERS_REQUEST };
  }
  function success(data) {
    return { type: adminConstants.GET_USERS_SUCCESS, data };
  }
  function failure() {
    return { type: adminConstants.GET_USERS_FAILURE };
  }
}

function getAthletes() {
  return (dispatch) => {
    dispatch(request());
    adminService
      .loadAthletes()
      .then((res) => {
        console.log(res);
        const normalizedAthletes = normalize(res.data.listAthletes.items, [
          athlete,
        ]);
        //const normalizedAthletes = normalize(res[1], [athlete]);
        const sortedResult = dataUtilities.sort(
          adminConstants.SORT_NAME_DESC,
          normalizedAthletes.entities.athletes,
          normalizedAthletes.result
        );
        const sortedAthletes = { ...normalizedAthletes, result: sortedResult };
        dispatch(success({ athletes: sortedAthletes }));
      })
      .catch((error) => {
        console.log(error);
        dispatch(failure(error.toString()));
        dispatch(alertActions.error('Error loading athletes.'));
      });
  };

  function request() {
    return { type: adminConstants.GET_ATHLETES_REQUEST };
  }
  function success(data) {
    return { type: adminConstants.GET_ATHLETES_SUCCESS, data };
  }
  function failure() {
    return { type: adminConstants.GET_ATHLETES_FAILURE };
  }
}

function getSessions() {
  return (dispatch) => {
    dispatch(request());
    adminService
      .loadSessions()
      .then((res) => {
        console.log(res);

        const normalizedSessions = normalize(res.data.listSessions.items, [
          session,
        ]);
        const { sessionTeams, streams } = normalizedSessions.entities; // note teams here are SessionTeams
        const normalizedSessionTeams = sessionTeams
          ? normalize(sessionTeams, [sessionTeam])
          : null;
        const normalizedStreams = streams ? normalize(streams, [stream]) : null;

        console.log(normalizedSessions);
        console.log(normalizedSessionTeams);
        console.log(normalizedStreams);

        const sortedResult = dataUtilities.sort(
          adminConstants.SORT_NAME_DESC,
          normalizedSessions.entities.sessions,
          normalizedSessions.result
        );
        const sortedSessions = { ...normalizedSessions, result: sortedResult };
        dispatch(
          success({
            sessions: sortedSessions,
            sessionTeams: normalizedSessionTeams,
            streams: normalizedStreams,
          })
        );
      })
      .catch((error) => {
        console.log(error);
        dispatch(failure(error.toString()));
        dispatch(alertActions.error('Error loading sessions.'));
      });
  };

  function request() {
    return { type: adminConstants.GET_SESSIONS_REQUEST };
  }
  function success(data) {
    return { type: adminConstants.GET_SESSIONS_SUCCESS, data };
  }
  function failure() {
    return { type: adminConstants.GET_SESSIONS_FAILURE };
  }
}

function editSession(input, teamChange) {
  return (dispatch) => {
    dispatch(request());
    sessionService
      .update(input, teamChange)
      .then((res) => {
        console.log(res);
        if (res.errors) {
          throw res.errors[0].message;
        }

        // Handle Session updates
        const sessionData =
          res && res.length > 0 && res[0] ? res[0].data.updateSession : null;
        const normalizedSessions = normalize(sessionData, session);
        const newSessionById = {
          ...store.getState().admin.sessions.byId,
          ...(normalizedSessions.entities.sessions
            ? { [input.id]: normalizedSessions.entities.sessions[input.id] }
            : {}),
        };

        // Handle creates and updates
        const creates =
          res && res.length > 1 && res[1]
            ? res[1].map((e) => {
                return e.data.createSessionTeam;
              })
            : [];
        const updates =
          res && res.length > 2 && res[2]
            ? res[2].map((e) => {
                return e.data.updateSessionTeam;
              })
            : [];

        const normalizedCreates =
          creates.length > 0 ? normalize(creates, [sessionTeam]) : null;
        const normalizedUpdates =
          updates.length > 0 ? normalize(updates, [sessionTeam]) : null;

        const { allIds, byId } = store.getState().admin.sessionTeams;
        const sessionTeamsData = {
          allIds: [
            ...allIds,
            ...((normalizedCreates && normalizedCreates.result) || []),
          ],
          byId: {
            ...byId,
            ...((normalizedUpdates &&
              normalizedUpdates.entities.sessionTeams) ||
              {}),
            ...((normalizedCreates &&
              normalizedCreates.entities.sessionTeams) ||
              {}),
          },
        };

        dispatch(
          success({ byId: newSessionById, sessionTeams: sessionTeamsData })
        );
        dispatch(alertActions.success('Session updated successfully.'));
      })
      .catch((error) => {
        dispatch(failure());
        console.log(error);
        switch (error.code) {
          default:
            error.message = 'Update session error.';
            break;
        }
        dispatch(alertActions.error(error.message));
      });
  };

  function request() {
    return { type: adminConstants.EDIT_SESSION_REQUEST };
  }
  function success(data) {
    return { type: adminConstants.EDIT_SESSION_SUCCESS, data };
  }
  function failure() {
    return { type: adminConstants.EDIT_SESSION_FAILURE };
  }
}

function getTeams(league = null) {
  return (dispatch) => {
    dispatch(request());
    adminService
      .loadTeams()
      .then((res) => {
        console.log(res);
        const teams = res.data.listTeams.items;
        const normalizedTeams = normalize(teams, [team]);
        const {
          lineups,
          rosters,
          coaches,
          leagues,
          rosterLinks,
        } = normalizedTeams.entities;
        const normalizedRosters = rosters ? normalize(rosters, [roster]) : null;
        const normalizedLeagues = leagues
          ? normalize(leagues, [teamLeague])
          : null;
        const normalizedLineups = lineups ? normalize(lineups, [lineup]) : null;
        const normalizedCoaches = coaches ? normalize(coaches, [coach]) : null;
        const normalizedRosterLinks = rosterLinks
          ? normalize(rosterLinks, [rosterLink])
          : null;

        const sortedResult = dataUtilities.sort(
          adminConstants.SORT_NAME_DESC,
          normalizedTeams.entities.teams,
          normalizedTeams.result
        );
        const sortedTeams = { ...normalizedTeams, result: sortedResult };

        dispatch(
          success({
            teams: sortedTeams,
            rosters: normalizedRosters,
            leagues: normalizedLeagues,
            coaches: normalizedCoaches,
            lineups: normalizedLineups,
            rosterLinks: normalizedRosterLinks,
          })
        );
      })
      .catch((error) => {
        console.log(error);
        dispatch(failure(error.toString()));
        dispatch(alertActions.error('Error loading teams.'));
      });
  };

  function request() {
    return { type: adminConstants.GET_TEAMS_REQUEST };
  }
  function success(data) {
    return { type: adminConstants.GET_TEAMS_SUCCESS, data };
  }
  function failure() {
    return { type: adminConstants.GET_TEAMS_FAILURE };
  }
}

function getLeagues() {
  return (dispatch) => {
    dispatch(request());
    adminService
      .loadLeagues()
      .then((res) => {
        console.log(res);
        const normalizedLeagues = normalize(res.data.listLeagues.items, [
          league,
        ]);
        //const normalizedTeams = normalize(res[1], [team]);
        //console.log(normalizedLeagues)
        const sortedResult = dataUtilities.sort(
          adminConstants.SORT_NAME_DESC,
          normalizedLeagues.entities.leagues,
          normalizedLeagues.result
        );
        const sortedLeagues = { ...normalizedLeagues, result: sortedResult };
        dispatch(success({ leagues: sortedLeagues }));
      })
      .catch((error) => {
        console.log(error);
        dispatch(failure(error.toString()));
        dispatch(alertActions.error('Error loading leagues.'));
      });
  };

  function request() {
    return { type: adminConstants.GET_LEAGUES_REQUEST };
  }
  function success(data) {
    return { type: adminConstants.GET_LEAGUES_SUCCESS, data };
  }
  function failure(error) {
    return { type: adminConstants.GET_LEAGUES_FAILURE };
  }
}

function createLeague(input) {
  return (dispatch) => {
    dispatch(request());
    // Todo: add to existing loaded leages opportunistically?
    adminService
      .addLeague(input)
      .then((res) => {
        console.log(res);
        if (res.errors) {
          throw res.errors[0].message;
        }

        // Add new item to normalized data & sort
        const data = res[0].data.createLeague;
        const { allIds, byId } = store.getState().admin.leagues;
        const newAllIds = dataUtilities.sort(
          adminConstants.SORT_NAME_DESC,
          byId,
          allIds,
          data
        );
        dispatch(success({ allIds: newAllIds, byId: byId }));
        dispatch(alertActions.success('League created successfully.'));
      })
      .catch((error) => {
        console.log(error);
        dispatch(failure(error.toString()));
        dispatch(alertActions.error('Error creating league.'));
      });
  };

  function request() {
    return { type: adminConstants.CREATE_LEAGUE_REQUEST };
  }
  function success(data) {
    return { type: adminConstants.CREATE_LEAGUE_SUCCESS, data };
  }
  function failure(error) {
    return { type: adminConstants.CREATE_LEAGUE_FAILURE };
  }
}

function editLeague(input, changedImage = false) {
  return (dispatch) => {
    dispatch(request());
    adminService
      .editLeague(input, changedImage)
      .then((res) => {
        console.log(res);
        if (res.errors) {
          throw res.errors[0].message;
        }

        // Add new item to normalized data & sort
        const data = res[0].data.updateLeague;
        const { byId } = store.getState().admin.leagues;
        const newById = { ...byId, [input.id]: { ...byId[input.id], ...data } };
        dispatch(success({ byId: newById }));
        dispatch(alertActions.success('League updated successfully.'));
      })
      .catch((error) => {
        console.log(error);
        dispatch(failure(error.toString()));
        dispatch(alertActions.error('Error updating league.'));
      });
  };

  function request() {
    return { type: adminConstants.EDIT_LEAGUE_REQUEST };
  }
  function success(data) {
    return { type: adminConstants.EDIT_LEAGUE_SUCCESS, data };
  }
  function failure(error) {
    return { type: adminConstants.EDIT_LEAGUE_FAILURE };
  }
}

function createTeam(input) {
  return (dispatch) => {
    dispatch(request());
    adminService
      .addTeam(input)
      .then((res) => {
        console.log(res);
        if (res.errors) {
          throw res.errors[0].message;
        }

        // Add new item to normalized data & sort
        const data = res[0].data.createTeam;
        const { allIds, byId } = store.getState().admin.teams;
        const newAllIds = dataUtilities.sort(
          adminConstants.SORT_NAME_DESC,
          byId,
          allIds,
          data
        );
        dispatch(success({ allIds: newAllIds, byId: byId }));
        dispatch(alertActions.success('Team created successfully.'));
      })
      .catch((error) => {
        console.log(error);
        dispatch(failure(error.toString()));
        dispatch(alertActions.error('Error creating team.'));
      });
  };

  function request() {
    return { type: adminConstants.CREATE_TEAM_REQUEST };
  }
  function success(data) {
    return { type: adminConstants.CREATE_TEAM_SUCCESS, data };
  }
  function failure(error) {
    return { type: adminConstants.CREATE_TEAM_FAILURE };
  }
}

function editTeam(input, changedImage = false) {
  return (dispatch) => {
    dispatch(request());
    adminService
      .editTeam(input, changedImage)
      .then((res) => {
        console.log(res);
        if (res.errors) {
          throw res.errors[0].message;
        }

        // Add new item to normalized data & sort
        const data = res[0].data.updateTeam;
        const { byId } = store.getState().admin.teams;
        const newById = { ...byId, [input.id]: { ...byId[input.id], ...data } };
        dispatch(success({ byId: newById }));
        dispatch(alertActions.success('Team updated successfully.'));
      })
      .catch((error) => {
        console.log(error);
        dispatch(failure(error.toString()));
        dispatch(alertActions.error('Error updating team.'));
      });
  };

  function request() {
    return { type: adminConstants.EDIT_TEAM_REQUEST };
  }
  function success(data) {
    return { type: adminConstants.EDIT_TEAM_SUCCESS, data };
  }
  function failure(error) {
    return { type: adminConstants.EDIT_TEAM_FAILURE };
  }
}

function createAthlete(input) {
  return (dispatch) => {
    dispatch(request());
    adminService
      .addAthlete(input)
      .then((res) => {
        console.log(res);
        if (res.errors) {
          throw res.errors[0].message;
        }

        // Add new item to normalized data & sort
        const data = res[0].data.createAthlete;
        const { allIds, byId } = store.getState().admin.athletes;
        const newAllIds = dataUtilities.sort(
          adminConstants.SORT_NAME_DESC,
          byId,
          allIds,
          data
        );
        dispatch(success({ allIds: newAllIds, byId: byId }));
        dispatch(alertActions.success('Athlete created successfully.'));
      })
      .catch((error) => {
        console.log(error);
        dispatch(failure(error.toString()));
        dispatch(alertActions.error('Error creating athlete.'));
      });
  };

  function request() {
    return { type: adminConstants.CREATE_ATHLETE_REQUEST };
  }
  function success(data) {
    return { type: adminConstants.CREATE_ATHLETE_SUCCESS, data };
  }
  function failure(error) {
    return { type: adminConstants.CREATE_ATHLETE_FAILURE };
  }
}

function editAthlete(input, changedImage = false) {
  return (dispatch) => {
    dispatch(request());
    adminService
      .editAthlete(input, changedImage)
      .then((res) => {
        console.log(res);
        if (res.errors) {
          throw res.errors[0].message;
        }

        // Add new item to normalized data & sort
        const data = res[0].data.updateAthlete;
        const { byId } = store.getState().admin.athletes;
        const newById = { ...byId, [input.id]: { ...byId[input.id], ...data } };
        dispatch(success({ byId: newById }));
        dispatch(alertActions.success('Athlete updated successfully.'));
      })
      .catch((error) => {
        console.log(error);
        dispatch(failure(error.toString()));
        dispatch(alertActions.error('Error updating athlete.'));
      });
  };

  function request() {
    return { type: adminConstants.EDIT_ATHLETE_REQUEST };
  }
  function success(data) {
    return { type: adminConstants.EDIT_ATHLETE_SUCCESS, data };
  }
  function failure(error) {
    return { type: adminConstants.EDIT_ATHLETE_FAILURE };
  }
}

function getTeamAthletes(teamId) {
  const { allIds, byId } = store.getState().admin.athletes;

  return (dispatch) => {
    dispatch(request());
    if (allIds.length === 0) {
      // need to fetch all athletes (this might be problematic when athletes # >>>)
      adminService
        .loadAthletes()
        .then((res) => {
          console.log(res);
          const normalizedAthletes = normalize(res.data.listAthletes.items, [
            athlete,
          ]);
          //const normalizedAthletes = normalize(res[1], [athlete]);
          const sortedResult = dataUtilities.sort(
            adminConstants.SORT_NAME_DESC,
            normalizedAthletes.entities.athletes,
            normalizedAthletes.result
          );
          const sortedAthletes = {
            ...normalizedAthletes,
            result: sortedResult,
          };

          const teamFilteredAthletes = dataUtilities.filterNested(
            teamId,
            normalizedAthletes.entities.athletes,
            sortedResult,
            'teamAffiliations'
          );

          dispatch(
            success({
              athletes: sortedAthletes,
              teamAthletes: teamFilteredAthletes,
              id: teamId,
            })
          );
        })
        .catch((error) => {
          console.log(error);
          dispatch(failure(error.toString()));
          dispatch(alertActions.error('Error loading athletes.'));
        });
    } else {
      const teamFilteredAthletes = dataUtilities.filterNested(
        teamId,
        byId,
        allIds,
        'teamAffiliations'
      );

      dispatch(success({ teamAthletes: teamFilteredAthletes, id: teamId }));
    }
  };

  function request() {
    return { type: adminConstants.GET_TEAM_ATHLETES_REQUEST };
  }
  function success(data) {
    return { type: adminConstants.GET_TEAM_ATHLETES_SUCCESS, data };
  }
  function failure(error) {
    return { type: adminConstants.GET_TEAM_ATHLETES_FAILURE };
  }
}

function createRoster(input) {
  return (dispatch) => {
    dispatch(request());
    adminService
      .addRoster(input)
      .then((res) => {
        console.log(res);
        if (res.errors) {
          throw res.errors[0].message;
        }

        // Add new item to normalized data & sort
        const data = res.data.createRoster;
        const { allIds, byId } = store.getState().admin.rosters;
        dispatch(
          success({
            allIds: [...allIds, data.id],
            byId: { ...byId, [data.id]: data },
            roster: data,
          })
        );
        dispatch(alertActions.success('Roster created successfully.'));
      })
      .catch((error) => {
        console.log(error);
        dispatch(failure(error.toString()));
        dispatch(alertActions.error('Error creating roster.'));
      });
  };

  function request() {
    return { type: adminConstants.CREATE_ROSTER_REQUEST };
  }
  function success(data) {
    return { type: adminConstants.CREATE_ROSTER_SUCCESS, data };
  }
  function failure(error) {
    return { type: adminConstants.CREATE_ROSTER_FAILURE };
  }
}

// EditRoster will update 2x items separately, first roster itself, then loop of RosterLinks
function editRoster(input) {
  return (dispatch) => {
    dispatch(request());
    adminService
      .editRoster(input)
      .then((res) => {
        console.log(res);
        // Res is formatted for 3x returns [rosterChange, [createRosterLinks], [updateRosterLinks] ]
        if (res.errors) {
          throw res.errors[0].message;
        }

        // Handle Roster title / time updates, need to normalize the rosterLinks
        const rosterData =
          res && res.length > 0 && res[0] ? res[0].data.updateRoster : null;
        const normalizedRoster = rosterData
          ? normalize(rosterData, roster)
          : null;

        // Handle combine of createRosterLinks and updateRosterLinks
        const creates =
          res && res.length > 1 && res[1]
            ? res[1].map((e) => {
                return e.data.createRosterLink;
              })
            : [];
        const updates =
          res && res.length > 2 && res[2]
            ? res[2].map((e) => {
                return e.data.updateRosterLink;
              })
            : [];

        const normalizedCreates =
          creates.length > 0 ? normalize(creates, [rosterLink]) : null;
        const normalizedUpdates =
          updates.length > 0 ? normalize(updates, [rosterLink]) : null;

        const { allIds, byId } = store.getState().admin.rosterLinks;
        const rosterLinksData = {
          allIds: [
            ...allIds,
            ...((normalizedCreates && normalizedCreates.result) || []),
          ],
          byId: {
            ...byId,
            ...((normalizedUpdates && normalizedUpdates.entities.rosterLinks) ||
              {}),
            ...((normalizedCreates && normalizedCreates.entities.rosterLinks) ||
              {}),
          },
        };

        dispatch(
          success({
            roster: normalizedRoster.entities.rosters[rosterData.id],
            rosterLinks: rosterLinksData,
          })
        );
        dispatch(alertActions.success('Roster updated successfully.'));
      })
      .catch((error) => {
        console.log(error);
        dispatch(failure(error.toString()));
        dispatch(alertActions.error('Error updating roster.'));
      });
  };

  function request() {
    return { type: adminConstants.EDIT_ROSTER_REQUEST };
  }
  function success(data) {
    return { type: adminConstants.EDIT_ROSTER_SUCCESS, data };
  }
  function failure(error) {
    return { type: adminConstants.EDIT_ROSTER_FAILURE };
  }
}

function updateTable(data) {
  return { type: adminConstants.UPDATE_TABLE_DATA, data };
}

function checkStreamPostgame(id, type = streamRequestType.CHECKVOD) {
  const { streams } = store.getState().admin;
  const { streamId, _version } = streams?.byId[id];

  return (dispatch) => {
    if (streams.allIds.length === 0) {
      throw new Error('Streams data have not been loaded.');
    }

    const req = {
      type: type,
      id: id,
      _version: _version,
      streamId: streamId, // where this streamId is the Wowza StreamId
    };

    dispatch(request());
    sessionService
      .streamRequest(req)
      .then((res) => {
        console.log(res);
        const data = res.data.createStreamWowza;
        dispatch(success(data));
      })
      .catch((error) => {
        console.log(error);
        const errorDetail =
          error?.errors?.length > 0 ? error.errors[0].message : error;
        dispatch(failure());
        dispatch(alertActions.error(errorDetail));
      });
  };

  function request() {
    return { type: adminConstants.CHECK_STREAM_POSTGAME_REQUEST };
  }
  function success(data) {
    return { type: adminConstants.CHECK_STREAM_POSTGAME_SUCCESS, data };
  }
  function failure() {
    return { type: adminConstants.CHECK_STREAM_POSTGAME_FAILURE };
  }
}

function updateStreamURLs(payload) {
  return (dispatch) => {
    dispatch(request());
    const filteredPayload = payload.filter((el) => el !== false);

    adminService
      .updateStreams(filteredPayload)
      .then((res) => {
        console.log(res);
        const data = Object.assign(
          {},
          ...res.map((x) => ({ [x.data.updateStream.id]: x.data.updateStream }))
        );
        dispatch(success(data));
        dispatch(
          alertActions.success(
            `Stream${
              filteredPayload.length === 1 ? '' : 's'
            } updated successfully.`
          )
        );
      })
      .catch((error) => {
        console.log(error);
        dispatch(failure());
        dispatch(alertActions.error(error));
      });
  };

  function request() {
    return { type: adminConstants.UPDATE_STREAM_URLS_REQUEST };
  }
  function success(data) {
    return { type: adminConstants.UPDATE_STREAM_URLS_SUCCESS, data };
  }
  function failure() {
    return { type: adminConstants.UPDATE_STREAM_URLS_FAILURE };
  }
}

function editUser(input, changedImage = false) {
  return (dispatch) => {
    dispatch(request());
    adminService
      .editUser(input, changedImage)
      .then((res) => {
        console.log(res);
        if (res.errors) {
          throw res.errors[0].message;
        }

        // Add new item to normalized data & sort
        const data = res[0].data.updateUser;
        const { byId } = store.getState().admin.users;
        const newById = { ...byId, [input.id]: { ...byId[input.id], ...data } };
        dispatch(success({ byId: newById }));
        dispatch(alertActions.success('User updated successfully.'));
      })
      .catch((error) => {
        console.log(error);
        dispatch(failure(error.toString()));
        dispatch(alertActions.error('Error updating user.'));
      });
  };

  function request() {
    return { type: adminConstants.EDIT_USER_REQUEST };
  }
  function success(data) {
    return { type: adminConstants.EDIT_USER_SUCCESS, data };
  }
  function failure(error) {
    return { type: adminConstants.EDIT_USER_FAILURE };
  }
}
