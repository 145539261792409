import React, { useEffect } from 'react';
import { Modal, Button, Row, Col, Spinner, Alert } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { sessionActions, alertActions } from '../../redux/_actions';
import { streamStatus } from '../../redux/_constants';
import { useInterval } from '../../utilities/hooks';
import { POLLING_INTERVAL, MEDIAURL } from '../../utilities/constants';
import { larixURL } from '../../utilities/conversions';
import VideoQR from './videoqr';

function VideoSetup() {
  const { alert } = useSelector((state) => state);
  const { session } = useSelector((state) => state);
  const dispatch = useDispatch();
  const {
    streamAStatus,
    streamA,
    streamBStatus,
    streamB,
    sessionTeams,
  } = session;
  const {
    OFF,
    STARTING,
    STARTED,
    STOPPING,
    STOPPED,
    RESETTING,
    DELETING,
    DELETED,
    ERROR,
    CREATING,
  } = streamStatus;
  const channelA = streamAStatus;
  const channelB = streamBStatus;
  const teamALogo =
    sessionTeams.items.length > 0
      ? sessionTeams.items[0].team.logos &&
        MEDIAURL +
          JSON.parse(sessionTeams.items[0].team.logos).metaData.filename
      : null;
  const teamBLogo =
    sessionTeams.items.length > 1
      ? sessionTeams.items[1].team.logos &&
        MEDIAURL +
          JSON.parse(sessionTeams.items[1].team.logos).metaData.filename
      : null;

  const initialCheck = () => {
    console.log('Load check Wowza API for status...');
    if (streamA && streamAStatus !== streamStatus.DELETED) {
      dispatch(sessionActions.checkStream('A'));
    }
    if (streamB && streamBStatus !== streamStatus.DELETED) {
      dispatch(sessionActions.checkStream('B'));
    }
  };

  // On first render update the stream status
  useEffect(initialCheck, []);

  // handle polling for channel A and B for status update
  useInterval(
    async () => {
      console.log('Polling Wowza API for status...');
      dispatch(sessionActions.checkStream('A'));
    },
    channelA === STOPPING ? POLLING_INTERVAL.FAST : POLLING_INTERVAL.MED,
    [STARTING, STOPPING, RESETTING].includes(channelA)
  );

  useInterval(
    async () => {
      console.log('Polling Wowza API for status...');
      dispatch(sessionActions.checkStream('B'));
    },
    channelB === STOPPING ? POLLING_INTERVAL.FAST : POLLING_INTERVAL.MED,
    [STARTING, STOPPING, RESETTING].includes(channelB)
  );

  const handleChannel = (channel) => {
    const streamState = channel === 'A' ? channelA : channelB;

    dispatch(alertActions.clear());

    switch (streamState) {
      case OFF:
      case DELETED:
        dispatch(sessionActions.createStream(channel));
        break;
      case STOPPED:
        dispatch(sessionActions.startStream(channel));
        break;
      case STARTED:
        dispatch(sessionActions.stopStream(channel));
        break;
      default:
        break;
    }
  };

  const showSpinner = (channelState) => {
    return [STARTING, STOPPING, CREATING, RESETTING, DELETING].includes(
      channelState
    ) ? (
      <Spinner variant="primary" role="status" animation="border" size="sm" />
    ) : null;
  };

  const deleteChannel = (channel) => {
    dispatch(alertActions.clear());
    dispatch(sessionActions.deleteStream(channel));
  };

  const resetChannel = (channel) => {
    dispatch(alertActions.clear());
    dispatch(sessionActions.resetStream(channel));
  };

  const buttonMsg = (state) => {
    switch (state) {
      case OFF:
      case DELETED:
        return 'Create';
      case STARTING:
        return 'Starting';
      case RESETTING:
        return 'Resetting';
      case STOPPED:
        return 'Start';
      case STARTED:
        return 'Stop';
      case STOPPING:
        return 'Stopping';
      case ERROR:
        return 'Error';
      case CREATING:
        return 'Creating';
      case DELETING:
        return 'Deleting';
      default:
        return '';
    }
  };

  const videoSetupBody = () => {
    return (
      <>
        {!alert.clear ? (
          <Row style={{ padding: '0 15px', margin: '1rem -15px' }}>
            <Alert
              dismissible
              onClose={() => dispatch(alertActions.clear())}
              variant={alert.type === 'alert-danger' ? 'danger' : 'success'}
            >
              {alert.message}
            </Alert>
          </Row>
        ) : null}
        <Row style={{ margin: '1rem -15px' }}>
          <Col>
            <VideoQR
              channel={'A'}
              link={
                streamA &&
                streamAStatus !== DELETED &&
                larixURL({
                  url: streamA.ingestURL,
                  name: `${session.name} (A)`,
                })
              }
              status={channelA}
              logo={teamALogo}
            />
          </Col>
          <Col>
            <VideoQR
              channel={'B'}
              link={
                streamB &&
                streamBStatus !== DELETED &&
                larixURL({
                  url: streamB.ingestURL,
                  name: `${session.name} (B)`,
                })
              }
              status={channelB}
              logo={teamBLogo}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Button
              variant="outline-primary"
              className="streamButtonLG"
              onClick={() => handleChannel('A')}
              disabled={[
                STARTING,
                STOPPING,
                CREATING,
                RESETTING,
                DELETING,
              ].includes(channelA)}
            >
              <span>{`${buttonMsg(channelA)} `}</span>
              {showSpinner(channelA)}
            </Button>
          </Col>
          <Col>
            <Button
              variant="outline-primary"
              className="streamButtonLG"
              onClick={() => handleChannel('B')}
              disabled={[
                STARTING,
                STOPPING,
                CREATING,
                RESETTING,
                DELETING,
              ].includes(channelB)}
            >
              <span>{`${buttonMsg(channelB)} `}</span>
              {showSpinner(channelB)}
            </Button>
          </Col>
        </Row>
        <Row>
          <Col>
            <Button
              variant="outline-secondary"
              className="streamButtonSM"
              onClick={() => resetChannel('A')}
              disabled={channelA !== STARTED}
            >
              <span>{`Reset`}</span>
            </Button>
          </Col>
          <Col>
            <Button
              variant="outline-danger"
              className="streamButtonSM"
              onClick={() => deleteChannel('A')}
              disabled={channelA !== STOPPED}
            >
              <span>{`Delete`}</span>
            </Button>
          </Col>
          <Col>
            <Button
              variant="outline-secondary"
              className="streamButtonSM"
              onClick={() => resetChannel('B')}
              disabled={channelB !== STARTED}
            >
              <span>{`Reset`}</span>
            </Button>
          </Col>
          <Col>
            <Button
              variant="outline-danger"
              className="streamButtonSM"
              onClick={() => deleteChannel('B')}
              disabled={channelB !== STOPPED}
            >
              <span>{`Delete`}</span>
            </Button>
          </Col>
        </Row>
      </>
    );
  };

  const footer = () => {
    return (
      <>
        <Col className="vCenter">
          <span className="sessionDesc">Stream A: {channelA}</span>
        </Col>
        <Col className="vCenter">
          <span className="sessionDesc">Stream B: {channelB}</span>
        </Col>
      </>
    );
  };

  const headerMsg = () => {
    return (
      <>
        <span>Video streaming setup</span>
      </>
    );
  };

  return (
    <>
      <Modal.Header className="setupHeader">{headerMsg()}</Modal.Header>
      <Modal.Body className="setupForm">{videoSetupBody()}</Modal.Body>
      <Modal.Footer className="setupFooter">{footer()}</Modal.Footer>
    </>
  );
}

export default VideoSetup;
