import React, { useState, useCallback, useImperativeHandle } from 'react';
import {
  Button,
  Row,
  Col,
  Image as ImageBS,
  Form,
  Popover,
  OverlayTrigger,
} from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { adminActions, alertActions } from '../../redux/_actions';
import {
  genderType,
  leagueType,
  imgType,
  FILE_SIZE,
} from '../../redux/_constants';
import * as Yup from 'yup';

export const LeagueTeams = React.forwardRef((props, ref) => {
  const { leagueId, isEditing, isSaving, reset, setIsLoading } = props;
  const { leagues } = useSelector((state) => state.admin);
  const league = leagues.byId[leagueId];

  const emptyList = () => {
    return (
      <Row
        style={{ minHeight: '40vh', textAlign: 'center' }}
        className="vCenter"
      >
        <span>No teams assigned.</span>
      </Row>
    );
  };

  const setupBody = () => {
    if (league.teams.items.length === 0) {
      return emptyList();
    } else {
      return null;
    }
  };

  return (
    <Form onSubmit={null} className="teamCoaches">
      {setupBody()}
    </Form>
  );
});
