import React from 'react';
import { Row, Col } from 'react-bootstrap';
import Evaluator from './evaluator';
import ExportData from './exportdata';

function JudgingFooter(props) {
  const { teamA, teamB } = props;

  return (
    <Row className="footers">
      <Col className="left">
        <Evaluator team={teamA} />
      </Col>
      <Col className="center" xs={1}>
        <ExportData />
      </Col>
      <Col className="right">
        <Evaluator team={teamB} />
      </Col>
    </Row>
  );
}

export default JudgingFooter;
