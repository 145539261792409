export const alertConstants = {
  SUCCESS: 'ALERT_SUCCESS',
  ERROR: 'ALERT_ERROR',
  CLEAR: 'ALERT_CLEAR',
  NOTIFICATION: 'ALERT_NOTIFICATION',
  SYNC: 'ALERT_SYNC',
};

export const alertType = {
  SUCCESS: 'alert-success',
  ERROR: 'alert-danger',
  NOTIFICATION: 'alert-warning',
  SYNC: 'alert-light',
};
