import React, {
  useState,
  useCallback,
  useImperativeHandle,
  useRef,
} from 'react';
import {
  Button,
  Row,
  Col,
  Form,
  Popover,
  OverlayTrigger,
} from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { adminActions, alertActions } from '../../redux/_actions';
import {
  genderType,
  leagueType,
  imgType,
  FILE_SIZE,
} from '../../redux/_constants';
import * as Yup from 'yup';
import { SketchPicker } from 'react-color';
import { useDropzone } from 'react-dropzone';
import { Typeahead } from 'react-bootstrap-typeahead';
import { MEDIAURL } from '../../utilities/constants';

export const AthleteProfile = React.forwardRef((props, ref) => {
  const { athleteId, isEditing, isSaving, reset, setIsLoading } = props;
  const { athletes } = useSelector((state) => state.admin);
  const athlete = athletes.byId[athleteId];
  const [colors, setColors] = useState(
    athlete.colors
      ? JSON.parse(athlete.colors)
      : ['#00A2FF', '#5E5E5E', '#FFFFFF', '#000000']
  );
  const [profileImg, setProfileImg] = useState(
    athlete.profileImg ? JSON.parse(athlete.profileImg).metaData.filename : null
  );
  const dispatch = useDispatch();
  const { alert } = useSelector((state) => state);
  const { teams } = useSelector((state) => state.admin);
  const hiddenFileInput = useRef(null);
  const [changedImg, setChangedImg] = useState(false);
  let typeaheadRef = useRef();

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Required'),
    slug: Yup.string().required('Required'),
    colors: Yup.array().of(Yup.string()),
    altNames: Yup.string().nullable(),
    profileImg: Yup.mixed()
      .nullable()
      .notRequired()
      .test(
        'FILE_SIZE',
        'Uploaded file is too big.',
        (value) => !changedImg || !value || (value && value.size <= FILE_SIZE)
      )
      .test(
        'FILE_FORMAT',
        'Uploaded file has unsupported format.',
        (value) =>
          !changedImg || !value || (value && imgType.includes(value.type))
      ),
    teamAffiliations: Yup.string(),
  });

  const {
    setFieldValue,
    handleSubmit,
    handleChange,
    values,
    errors,
  } = useFormik({
    initialValues: {
      name: athlete.name,
      slug: athlete.slug,
      colors: JSON.parse(athlete.colors),
      altNames: athlete.altNames ? JSON.parse(athlete.altNames) : [],
      profileImg: athlete.profileImg ? JSON.parse(athlete.profileImg) : '',
      teamAffiliations: athlete.teamAffiliations
        ? JSON.parse(athlete.teamAffiliations)
        : [],
      id: athlete.id,
      _version: athlete._version,
    },
    validationSchema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit(values) {
      // Change out empty array
      let newAffiliations =
        values.teamAffiliations.length === 0
          ? null
          : JSON.stringify(values.teamAffiliations);
      let newAltNames =
        values.altNames.length === 0 ? null : JSON.stringify(values.altNames);

      // Check if no change
      if (
        values.name === athlete.name &&
        values.slug === athlete.slug &&
        JSON.stringify(values.colors) === athlete.colors &&
        newAltNames === athlete.altNames &&
        !changedImg &&
        newAffiliations === athlete.teamAffiliations
      ) {
        dispatch(alertActions.success('No changes made.'));
        reset();
        return;
      }

      setIsLoading(true);
      dispatch(alertActions.clear());
      dispatch(adminActions.editAthlete(values, changedImg));
      setChangedImg(false);
    },
  });

  //console.log(errors)
  //console.log(values)

  useImperativeHandle(ref, () => ({
    submit() {
      handleSubmit();
    },
  }));

  const checkBase64 = (string) => {
    return string.substr(0, 5) === 'data:';
  };

  const colorButton = (index) => {
    return (
      colors && (
        <OverlayTrigger
          placement="bottom"
          trigger="click"
          rootClose
          overlay={
            <Popover id={`popover-colorpicker-${index}`}>
              <SketchPicker
                disableAlpha
                presetColors={[]}
                color={colors[index]}
                onChange={(value) => {
                  setFieldValue(`colors[${index}]`, value.hex);
                  colors[index] = value.hex;
                  setColors(colors);
                }}
              />
            </Popover>
          }
        >
          <Button
            className="colorSelect"
            disabled={isSaving || !isEditing}
            style={{
              marginLeft: index === 0 ? '0' : null,
              background: colors[index],
            }}
          ></Button>
        </OverlayTrigger>
      )
    );
  };

  const onDrop = useCallback(
    (acceptedFiles) => {
      const handleLogoChange = (files) => {
        const fileUploaded = files;
        if (!fileUploaded) {
          return false;
        }

        if (!alert.clear) {
          dispatch(alertActions.clear());
        }

        let reader = new FileReader();
        reader.onload = (e) => {
          const img = new Image();
          img.onload = () => {
            if (fileUploaded.size > FILE_SIZE) {
              dispatch(alertActions.error('Image exceeds max 5MB.'));
              return false;
            }
            setChangedImg(true);
            setProfileImg(reader.result);
          };
          img.onerror = () => {
            dispatch(alertActions.error('Could not load image.'));
            return false;
          };
          img.src = e.target.result;
        };

        setFieldValue(`profileImg`, fileUploaded);
        reader.readAsDataURL(fileUploaded);
      };

      handleLogoChange(acceptedFiles);
    },
    [alert.clear, dispatch, setFieldValue]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const teamOptions = () => {
    let result = [];

    if (athlete.gender !== '') {
      result = teams.allIds.filter((id) => {
        return teams.byId[id].gender === athlete.gender;
      });
    } else {
      result = teams.allIds;
    }

    return result.map((id) => {
      const { name, gender } = teams.byId[id];
      let genderTxt = '';

      if (athlete.gender === '') {
        if (gender === 'MALE') {
          genderTxt = ' (Mens)';
        }
        if (gender === 'FEMALE') {
          genderTxt = ' (Womens)';
        }
      }

      return {
        name: `${name}${genderTxt}`,
        id: id,
      };
    });
  };

  const logoButton = () => {
    const handleClick = (event) => {
      hiddenFileInput.current.click();
    };

    return (
      <>
        <label>Photo</label>
        <Button
          variant="outline-light"
          disabled={isSaving || !isEditing}
          className={'logoSelect'}
          onClick={handleClick}
          style={{ flexDirection: profileImg ? 'column' : null }}
        >
          {profileImg ? (
            <img
              className="img-thumbnail"
              alt={`${athlete.name} Profile`}
              src={
                checkBase64(profileImg)
                  ? profileImg
                  : `${MEDIAURL}${profileImg}`
              }
            />
          ) : isDragActive ? (
            '+'
          ) : (
            '?'
          )}
        </Button>
        <input
          type="file"
          {...getInputProps()}
          ref={hiddenFileInput}
          onChange={(e) => onDrop(e.target.files[0])}
          style={{ display: 'none' }}
          accept="image/png,image/gif,image/jpeg"
        />
      </>
    );
  };

  const onKeyDown = useCallback((e) => {
    if (e.key === 'Enter') {
      setFieldValue('altNames', [...values.altNames, e.target.value]);
      typeaheadRef.clear();
    }
  });

  const setupBody = () => {
    return (
      <>
        <Row>
          <div className="setupFormLine">
            <Col {...getRootProps()}>{logoButton()}</Col>
            <Col sm={8}>
              <Row>
                <label>Colors</label>
                {colorButton(0)}
                {colorButton(1)}
                {colorButton(2)}
                {colorButton(3)}
              </Row>
              <Row>
                <div className="setupFormLine">
                  <Col style={{ padding: '0 2% 0 0' }}>
                    <label>
                      Gender
                      {errors.gender ? (
                        <span className="form-error">{errors.gender}</span>
                      ) : null}
                    </label>
                    <Form.Control
                      plaintext
                      as="select"
                      label={'Required'}
                      id="gender"
                      defaultValue={athlete.gender}
                      disabled={true}
                    >
                      <option value="Select..." disabled hidden>
                        Select...
                      </option>
                      {Object.values(genderType).map((type, i) => {
                        return <option key={i}>{type}</option>;
                      })}
                    </Form.Control>
                  </Col>
                  <Col style={{ padding: '0 0 0 2%' }}>
                    <label>
                      Type
                      {errors.type ? (
                        <span className="form-error">{errors.type}</span>
                      ) : null}
                    </label>
                    <Form.Control
                      plaintext
                      as="select"
                      label={'Required'}
                      id="type"
                      defaultValue={athlete.type}
                      disabled={true}
                    >
                      <option value="Select..." disabled hidden>
                        Select...
                      </option>
                      {Object.values(leagueType).map((type, i) => {
                        return <option key={i}>{type}</option>;
                      })}
                    </Form.Control>
                  </Col>
                </div>
              </Row>
            </Col>
          </div>
        </Row>
        <Row>
          <Col>
            <label>
              Name
              {errors.name ? (
                <span className="form-error">{errors.name}</span>
              ) : null}
            </label>
            <Form.Control
              plaintext
              name="name"
              type="text"
              autoComplete="off"
              value={values.name}
              onChange={handleChange}
              disabled={isSaving || !isEditing}
              placeholder="Enter full name"
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <label>Slug</label>
            <Form.Control
              plaintext
              name="slug"
              type="text"
              autoComplete="off"
              value={values.slug}
              onChange={handleChange}
              disabled={isSaving || !isEditing}
              placeholder="Autogenerated"
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <label>Other Names (Use #hashtag for display name)</label>
            <Typeahead
              onChange={(selected) => {
                setFieldValue('altNames', selected);
              }}
              allowNew
              options={[]}
              id="athleteAltNames"
              className="typeahead"
              multiple
              autoComplete="off"
              disabled={isSaving || !isEditing}
              placeholder={'Optional'}
              selected={values.altNames ? values.altNames : []}
              renderMenu={(results, menuProps) => null}
              onKeyDown={onKeyDown}
              ref={(a) => (typeaheadRef = a)}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <label>Team Affiliations</label>
            <Typeahead
              onChange={(selected) => {
                // filter out the names and leave ID list only
                const cleanList = selected.map((e) => e.id);
                setFieldValue('teamAffiliations', cleanList);
              }}
              options={teamOptions()}
              id="athleteTeamSelector"
              multiple
              labelKey="name"
              selected={
                values.teamAffiliations
                  ? values.teamAffiliations.map((id) => {
                      return {
                        name: teams.byId[id].name,
                        id: id,
                      };
                    })
                  : []
              }
              dropup
              autoComplete="off"
              disabled={isSaving || !isEditing || !athlete.gender}
              className="typeahead"
              placeholder={
                values.gender ? 'Choose teams...' : 'Select gender...'
              }
              filterBy={['name', 'id']}
              renderMenuItemChildren={(option) => option.name}
            />
          </Col>
        </Row>
      </>
    );
  };

  return (
    <Form onSubmit={handleSubmit} className="athleteProfile">
      {setupBody()}
    </Form>
  );
});
