import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

function HighlightShadow(props) {
  const { videoPlayer } = useSelector((state) => state);
  const { athlete, id } = props;
  const [highlight, setHighlight] = useState('');
  const playerRef = videoPlayer[`player${id}`];

  //console.log(playerRef);

  useEffect(() => {
    // Set within timerange
    // TODO: Right now this is crashing the new video player and need to dig into it to understand how to keep reading the player values

    //console.log(playerRef);

    if (
      playerRef?.currentTime < athlete.endTime &&
      playerRef?.currentTime >= athlete.startTime
      //playerRef?._player?.currentTime < athlete.endTime &&
      //playerRef?._player?.currentTime >= athlete.startTime
    ) {
      setHighlight('highlight');
    } else {
      setHighlight('');
    }
  }, [playerRef?.currentTime, athlete.endTime, athlete.startTime]);
  //}, [playerRef?._player?.currentTime, athlete.endTime, athlete.startTime]);

  return <div className={['highlightshadow', highlight].join(' ')} />;
}

export default HighlightShadow;
