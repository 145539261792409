import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import EvaluatorInput from './evaluatorinput';
import EvaluatorFinalize from './evaluatorfinalize';
import { subScoreTypeAbbv } from '../../redux/_constants';

function Evaluator(props) {
  const { /*producer,*/ evaluator } = useSelector((state) => state);
  const { config } = evaluator;
  const { dPanel, ePanel, nDeduct, stickBonus } = config;
  const { team } = props;
  const evalPanel = team && evaluator[team.home ? 'evalPanelA' : 'evalPanelB'];
  let inquiryScores = null;
  const inquiry =
    evalPanel && evalPanel.flags.inquiry && !evalPanel.flags.resolved;
  const { D, E, ND, SB } = subScoreTypeAbbv;

  const scoreField = () => {
    if (team && team.onAir) {
      return <span>On Air</span>;
    } else if (evalPanel && evalPanel.locked) {
      return <span>evalPanel.finalScore</span>;
    } else if (
      inquiry ||
      (evalPanel && team && evalPanel.subScoreComplete && team.onEval)
    ) {
      return (
        <EvaluatorFinalize
          team={team}
          evaluator={dPanel.length ? dPanel[0].name : null}
          inquiry={inquiry}
        />
      );
    } else if (
      evalPanel &&
      team &&
      !evalPanel.subScoreComplete &&
      team.onEval
    ) {
      return <span>Evaluation</span>;
    } else {
      return <span>Standby</span>;
    }
  };

  // Load data for inquiry
  if (inquiry) {
    inquiryScores = { ...evalPanel.subScores };
  }

  return (
    <div className="evaluator">
      <Row style={{ height: '100%' }}>
        <Col className="evalPanel" xs={6}>
          {ePanel.map((evaluator, i) => {
            const type = `${E}${ePanel.length === 1 ? '' : i + 1}`;
            const value =
              inquiry && inquiryScores[type].length > 0
                ? inquiryScores[type][inquiryScores[type].length - 1].score
                : null;

            return (
              <EvaluatorInput
                evaluator={evaluator.name}
                type={type}
                key={type}
                team={team}
                value={value}
                inquiry={inquiry}
              />
            );
          })}
          {dPanel.map((evaluator, i) => {
            const type = `${D}${dPanel.length === 1 ? '' : i + 1}`;
            const value =
              inquiry && inquiryScores[type].length > 0
                ? inquiryScores[type][inquiryScores[type].length - 1].score
                : null;

            return (
              <EvaluatorInput
                evaluator={evaluator.name}
                type={type}
                key={type}
                team={team}
                value={value}
                inquiry={inquiry}
              />
            );
          })}
        </Col>
        <Col className="evalExtras" xs={3}>
          {!nDeduct || dPanel.length === 0 ? null : (
            <EvaluatorInput
              evaluator={dPanel[0].name}
              type={ND}
              team={team}
              key={ND}
              inquiry={inquiry}
              value={
                inquiry && inquiryScores[ND].length > 0
                  ? inquiryScores[ND][inquiryScores[ND].length - 1].score
                  : null
              }
            />
          )}
          {!stickBonus || dPanel.length === 0 ? null : (
            <EvaluatorInput
              evaluator={dPanel[0].name}
              type={SB}
              team={team}
              key={SB}
              inquiry={inquiry}
              value={
                inquiry && inquiryScores[SB].length > 0
                  ? inquiryScores[SB][inquiryScores[SB].length - 1].score
                  : null
              }
            />
          )}
        </Col>
        <Col className="vCenter tscore" xs={3}>
          {scoreField()}
        </Col>
      </Row>
    </div>
  );
}

export default Evaluator;
