export const evaluatorConstants = {
  INITIALIZE: 'EVALUATOR_INITIALIZE',
  LOCK_SUB_SCORE: 'EVALUATOR_LOCK_SUB_SCORE',
  UNLOCK_SUB_SCORE: 'EVALUATOR_UNLOCK_SUB_SCORE',
  RESET: 'EVALUATOR_RESET',
  LOCK_FINAL_SCORE: 'EVALUATOR_LOCK_FINAL_SCORE',
  UNLOCK_FINAL_SCORE: 'EVALUATOR_UNLOCK_FINAL_SCORE',
  RESOLVE_INQUIRY: 'EVALUATOR_RESOLVE_INQUIRY',
};

export const defaultEvaluatorConfig = {
  dPanel: [{ name: 'D Judge' }],
  ePanel: [{ name: 'E1 Judge' }, { name: 'E2 Judge' }],
  nDeduct: true,
  stickBonus: true,
};

export const fiveEvaluatorConfig = {
  dPanel: [{ name: 'D Judge' }],
  ePanel: [{ name: 'E1 Judge' }, { name: 'E2 Judge' }, { name: 'E3 Judge' }],
  nDeduct: true,
  stickBonus: true,
};

export const singleEvaluatorConfig = {
  dPanel: [{ name: 'D Judge' }],
  ePanel: [{ name: 'E Judge' }],
  nDeduct: true,
  stickBonus: true,
};

export const fanEvaluatorConfig = {
  dPanel: [],
  ePanel: [{ name: 'F Judge' }],
  nDeduct: false,
  stickBonus: true,
};

export const evaluatorConfigs = {
  DEFAULT: defaultEvaluatorConfig,
  FIVE: fiveEvaluatorConfig,
  SINGLE: singleEvaluatorConfig,
  FAN: fanEvaluatorConfig,
};

export const subScoreType = {
  D: 'DIFFICULTY',
  E: 'EXECUTION',
  ND: 'NEUTRAL DEDUCTION',
  SB: 'STICK BONUS',
};

export const subScoreTypeAbbv = {
  D: 'D',
  E: 'E',
  ND: 'ND',
  SB: 'SB',
};

export const initialPanelState = {
  evalId: null,
  subScores: {},
  finalScore: [],
  flags: {
    scratch: false,
    injury: false,
    inquiry: false,
    resolved: false,
  },
  startTime: null,
  finishTime: null,
  subScoreComplete: false,
  locked: false,
};

export const initialEvaluatorState = {
  evalPanelA: initialPanelState,
  evalPanelB: initialPanelState,
  config: defaultEvaluatorConfig,
};
