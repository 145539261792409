import React, { useState, useCallback, useImperativeHandle } from 'react';
import {
  Button,
  Row,
  Col,
  Image as ImageBS,
  Form,
  Popover,
  OverlayTrigger,
} from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { adminActions, alertActions } from '../../redux/_actions';
import { Typeahead } from 'react-bootstrap-typeahead';
import {
  genderType,
  leagueType,
  imgType,
  FILE_SIZE,
} from '../../redux/_constants';
import * as Yup from 'yup';

export const SessionJudges = React.forwardRef((props, ref) => {
  const { sessionId, isEditing, isSaving, reset, setIsLoading } = props;
  const { sessions, users } = useSelector((state) => state.admin);
  const session = sessions.byId[sessionId];
  const [ judgeList, setJudgeList ] = useState(session.judges);

  const emptyList = () => {
    return (
      <Row
        style={{ minHeight: '40vh', textAlign: 'center' }}
        className="vCenter"
      >
        <span>No judges assigned.</span>
      </Row>
    );
  };

  console.log(judgeList);

  const setupBody = () => {
    if (!judgeList || judgeList.length === 0) {
      return emptyList();
    } else {
      return (
        <ul>
          {
            judgeList.map((id, i) => {
              return (
                <li>
                  <Row className="sessionJudgeLine">
                    <Col className="sessionJudgeName">
                      <Typeahead
                        id={'judgeTypeahead'}
                        className={'judgeTypeahead'}
                        clearButton
                        onChange={(selected) => {
                          const newList = Array.from(judgeList);
                          newList[i] = selected;
                          setJudgeList(newList);
                        }}
                        labelKey="name"
                        placeholder={'Add judge...'}
                        selected={
                          users.byId[id]?.name ? [users.byId[id].name] : []
                        }
                      />
                    </Col>
                    <Col className="sessionJudgeEmail">
                      TBD
                    </Col>
                    <Col className="sessionJudgeCredentials">
                      TBD
                    </Col>
                  </Row>
                </li>
              );
            })
          }
        </ul>
      );
    }
  };

  return (
    <Form onSubmit={null} className="sessionJudges">
      {setupBody()}
    </Form>
  );
});
