import { CAMERA_DEFAULT_SETTINGS } from './constants';

// 1-6, short is abbreviated
export const numToApparatusM = (num, short = false) => {
  switch (num) {
    case 1:
      return short ? 'FX' : 'Floor Exercise';
    case 2:
      return short ? 'PH' : 'Pommel Horse';
    case 3:
      return short ? 'SR' : 'Still Rings';
    case 4:
      return short ? 'VT' : 'Vault';
    case 5:
      return short ? 'PB' : 'Parallel Bars';
    case 6:
      return short ? 'HB' : 'Horizontal Bar';
    default:
      return '';
  }
};

// 1-4, short is abbreviated
export const numToApparatusW = (num, short = false) => {
  switch (num) {
    case 1:
      return short ? 'VT' : 'Vault';
    case 2:
      return short ? 'UB' : 'Uneven Bars';
    case 3:
      return short ? 'BB' : 'Balance Beam';
    case 4:
      return short ? 'FX' : 'Floor Exercise';
    default:
      return '';
  }
};

export const strApparatusToArray = (str) => {
  if (str === null) {
    return [];
  }
  let result = [];

  const temp = str.split('').map((char, i) => {
    if (parseInt(char) > 0) {
      result.push(i);
    }
  });

  return result;
};

export const arrayToStrApparatus = (array) => {
  if (array === null) {
    return '';
  }
  let result = '000000';

  if (array.length > 0) {
    array.forEach((i) => {
      if (i < result.length) {
        result = result.substring(0, i) + '1' + result.substring(i + 1);
      }
    });
  }

  return result;
};

export const larixURL = (
  settings = null,
  defaults = CAMERA_DEFAULT_SETTINGS
) => {
  let result = '';

  if (settings === null) {
    return result;
  }

  const {
    url,
    port,
    name,
    mode,
    latency,
    maxbw,
    pass,
    streamId,
    camera,
    res,
    fps,
    bitrate,
    format,
    keyframe,
    audiobit,
    audiochannels,
    audiosamples,
  } = settings;

  if (url) {
    result += `larix://set/v1`;
    result += `?conn[][url]=${url}:${port ? port : defaults.port}`;
    result += `&conn[][name]=${
      name ? encodeURI(name) : encodeURI(defaults.name)
    }`;
    result += `&conn[][mode]=${mode ? mode : defaults.mode}`;
    result += `&conn[][srtlatency]=${latency ? latency : defaults.latency}`;
    result += `&conn[][srtmaxbw]=${maxbw ? maxbw : defaults.maxbw}`;
    result += pass ? `&conn[][srtpass]=${pass}` : '';
    result += streamId ? `&conn[][srtstreamid]=${streamId}` : '';
    result += `&enc[vid][camera]=${camera ? camera : defaults.camera}`;
    result += `&enc[vid][res]=${res ? res : defaults.res}`;
    result += `&enc[vid][fps]=${fps ? fps : defaults.fps}`;
    result += `&enc[vid][bitrate]=${bitrate ? bitrate : defaults.bitrate}`;
    result += `&enc[vid][format]=${format ? format : defaults.format}`;
    result += `&enc[vid][keyframe]=${keyframe ? keyframe : defaults.keyframe}`;
    result += `&enc[aud][bitrate]=${audiobit ? audiobit : defaults.audiobit}`;
    result += `&enc[aud][channels]=${
      audiochannels ? audiochannels : defaults.audiochannels
    }`;
    result += `&enc[aud][samples]=${
      audiosamples ? audiosamples : defaults.audiosamples
    }`;
  }

  return result;
};

export const dateTime = (dateObject, showTime = true) => {
  const dateOptions = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
  };
  const timeOptions = {
    hour: 'numeric',
    minute: 'numeric',
  };

  if (!dateObject) {
    return '';
  }
  dateObject = new Date(dateObject);

  const date = dateObject.toLocaleString('en-US', dateOptions);
  const time = dateObject.toLocaleString('en-US', timeOptions);

  return showTime ? `${date} @ ${time}` : date;
};

export const timeWithSeconds = (dateObject) => {
  if (!dateObject) {
    return '';
  }
  dateObject = new Date(dateObject);
  const time = dateObject.toLocaleTimeString('en-US');

  return time;
};

// Searches for #hashtag and then returns that for the name
export const displayName = (name, altNames) => {
  if (!altNames) {
    return name;
  }

  // Check to see if need to parse altNames
  const parsedAltNames = JSON.parse(altNames);
  if (!parsedAltNames || parsedAltNames.length === 0) {
    return name;
  }

  for (let i = 0; i < parsedAltNames.length; i++) {
    if (parsedAltNames[i][0] === '#') {
      return parsedAltNames[i].substring(1);
    }
  }

  return name;
};

export const sec2time = (timeInSeconds, ms = false) => {
  if (timeInSeconds === null || timeInSeconds === undefined) {
    return null;
  }

  let pad = function (num, size) {
      return ('000' + num).slice(size * -1);
    },
    time = parseFloat(timeInSeconds).toFixed(3),
    hours = Math.floor(time / 60 / 60),
    minutes = Math.floor(time / 60) % 60,
    seconds = Math.floor(time - minutes * 60),
    milliseconds = time.slice(-3);

  return (
    (hours ? `${pad(hours, 2)}:` : '') +
    pad(minutes, 2) +
    ':' +
    pad(seconds, 2) +
    (ms ? `.${pad(milliseconds, 3)}` : '')
  );
};
