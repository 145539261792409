import {
  sessionConstants,
  uiState,
  streamRequestType,
  streamStatus,
} from '../_constants';
import {
  alertActions,
  userActions,
  producerActions,
  evaluatorActions,
  videoPlayerActions,
} from '../_actions';
import { sessionService, analyticsService } from '../_services';
import { store } from '../store';
import { normalize, schema } from 'normalizr';
import { checkRole, getEvalConfig } from '../../utilities/session';

export const sessionActions = {
  create,
  join,
  load,
  update,
  createStream,
  startStream,
  stopStream,
  resetStream,
  deleteStream,
  checkStream,
  subscribe,
  unsubscribe,
  subscribeStream,
  unsubscribeStream,
  subscribeNewStream,
  unsubscribeNewStream,
  syncStreamStatus,
  syncNewStream,
  createLineup,
  updateLineup,
  subscribeLineup,
  unsubscribeLineup,
  syncLineup,
  syncSession,
  updateProducer,
  changeView,
};

// Normalized data schema (TODO: might want to pull this out since same as admin)
const athlete = new schema.Entity('athletes');
const rosterLink = new schema.Entity('rosterLinks', {
  athlete: athlete,
});
const roster = new schema.Entity('rosters', {
  athletes: {
    items: [rosterLink],
  },
});
const lineup = new schema.Entity('lineups');
const sessionTeam = new schema.Entity('sessionTeams');
const session = new schema.Entity('sessions', {
  sessionTeams: {
    items: [sessionTeam],
  },
  lineups: {
    items: [lineup],
  },
});
const coach = new schema.Entity('coaches');
const user = new schema.Entity('users');
const teamLeague = new schema.Entity('leagues');
const league = new schema.Entity('leagues', {});
const team = new schema.Entity('teams', {
  rosters: {
    items: [roster],
  },
  coaches: {
    items: [coach],
  },
});

league.define({
  teams: {
    items: [team],
  },
});

function checkStream(channel) {
  const stream = store.getState().session[`stream${channel}`];
  const { streamId, id, _version } = stream;
  const status = store.getState().session[`stream${channel}Status`];

  const req = {
    type: streamRequestType.CHECK,
    id: id,
    _version: _version,
    streamId: streamId,
    status: status,
  };

  return (dispatch) => {
    dispatch(request());
    sessionService
      .streamRequest(req)
      .then((res) => {
        console.log(res);
        const data = res.data.createStreamWowza;
        //if (data.error) { throw JSON.parse(data.error); }

        if (data.status === status) {
          // no change but need to increment _version
          dispatch(success(data, channel));
        } else {
          let newStatus = '';
          switch (data.status) {
            case streamStatus.STARTED:
              if (status === streamStatus.RESETTING) {
                newStatus = 'reset';
              }
              if (status === streamStatus.STARTING) {
                newStatus = 'started';
              }
              break;
            case streamStatus.STOPPED:
              newStatus = 'stopped';
              break;
            default:
              break;
          }

          dispatch(success(data, channel));
          dispatch(alertActions.success(`Stream ${channel} ${newStatus}.`));
        }
      })
      .catch((error) => {
        console.log(error);
        const errorDetail =
          error && error.errors && error.errors.length > 0
            ? error.errors[0].message
            : null;
        let newStatus = null;

        switch (errorDetail) {
          case 'ERR-404-RecordNotFound':
            error.message = 'Stream was not found.';
            newStatus = streamStatus.OFF;
            break;
          case 'ERR-410-RecordDeleted':
            error.message = 'Stream was deleted.';
            newStatus = streamStatus.OFF;
            break;
          case 'ERR-000-NoStatusChange':
            return dispatch(success(stream, channel)); // Hack way of handling no change
          default:
            error.message = 'Check stream error.';
            break;
        }
        dispatch(failure(newStatus, channel));
        dispatch(alertActions.error(error.message));
      });
  };

  function request() {
    return { type: sessionConstants.CHECK_STREAM_REQUEST };
  }
  function success(stream, channel) {
    return { type: sessionConstants.CHECK_STREAM_SUCCESS, stream, channel };
  }
  function failure(status, channel) {
    return { type: sessionConstants.CHECK_STREAM_FAILURE, status, channel };
  }
}

function startStream(channel) {
  const { streamId, id, _version } = store.getState().session[
    `stream${channel}`
  ];

  const req = {
    type: streamRequestType.START,
    id: id,
    _version: _version,
    streamId: streamId,
  };

  return (dispatch) => {
    dispatch(request(streamStatus.STARTING, channel));
    sessionService
      .streamRequest(req)
      .then((res) => {
        console.log(res);
        const data = res.data.createStreamWowza;
        if (data.error) {
          throw JSON.parse(data.error);
        }
        dispatch(success(data.status, channel));
      })
      .catch((error) => {
        dispatch(failure(channel));
        console.log(error);
        switch (error.code) {
          default:
            error.message = 'Start stream error.';
            break;
        }
        dispatch(alertActions.error(error.message));
      });
  };

  function request(status, channel) {
    return { type: sessionConstants.START_STREAM_REQUEST, status, channel };
  }
  function success(status, channel) {
    return { type: sessionConstants.START_STREAM_SUCCESS, status, channel };
  }
  function failure(channel) {
    return { type: sessionConstants.START_STREAM_FAILURE, channel };
  }
}

function stopStream(channel) {
  const { streamId, id, _version } = store.getState().session[
    `stream${channel}`
  ];

  const req = {
    type: streamRequestType.STOP,
    id: id,
    _version: _version,
    streamId: streamId,
  };

  return (dispatch) => {
    dispatch(request(streamStatus.STOPPING, channel));
    sessionService
      .streamRequest(req)
      .then((res) => {
        console.log(res);
        const data = res.data.createStreamWowza;
        if (data.error) {
          throw JSON.parse(data.error);
        }
        dispatch(success(data.status, channel));
      })
      .catch((error) => {
        dispatch(failure(channel));
        console.log(error);
        switch (error.code) {
          default:
            error.message = 'Stop stream error.';
            break;
        }
        dispatch(alertActions.error(error.message));
      });
  };

  function request(status, channel) {
    return { type: sessionConstants.STOP_STREAM_REQUEST, status, channel };
  }
  function success(status, channel) {
    return { type: sessionConstants.STOP_STREAM_SUCCESS, status, channel };
  }
  function failure(channel) {
    return { type: sessionConstants.STOP_STREAM_FAILURE, channel };
  }
}

function resetStream(channel) {
  const { streamId, id, _version } = store.getState().session[
    `stream${channel}`
  ];

  const req = {
    type: streamRequestType.RESET,
    id: id,
    _version: _version,
    streamId: streamId,
  };

  return (dispatch) => {
    dispatch(request(streamStatus.RESETTING, channel));
    sessionService
      .streamRequest(req)
      .then((res) => {
        console.log(res);
        const data = res.data.createStreamWowza;
        if (data.error) {
          throw JSON.parse(data.error);
        }
        dispatch(success(data.status, channel));
      })
      .catch((error) => {
        dispatch(failure(channel));
        console.log(error);
        switch (error.code) {
          default:
            error.message = 'Reset stream error.';
            break;
        }
        dispatch(alertActions.error(error.message));
      });
  };

  function request(status, channel) {
    return { type: sessionConstants.RESET_STREAM_REQUEST, status, channel };
  }
  function success(status, channel) {
    return { type: sessionConstants.RESET_STREAM_SUCCESS, status, channel };
  }
  function failure(channel) {
    return { type: sessionConstants.RESET_STREAM_FAILURE, channel };
  }
}

function deleteStream(channel) {
  const { streamId, id, _version } = store.getState().session[
    `stream${channel}`
  ];
  const { session } = store.getState();

  const req = {
    sessionId: session.id,
    sessionKey: session.sessionKey,
    sessionVersion: session._version,
    type: streamRequestType.DELETE,
    id: id,
    _version: _version,
    streamId: streamId,
  };

  return (dispatch) => {
    dispatch(request(streamStatus.DELETING, channel));
    sessionService
      .streamRequest(req)
      .then((res) => {
        console.log(res);
        const data = res.data.createStreamWowza;
        if (data.error) {
          throw JSON.parse(data.error);
        }
        dispatch(success(data, channel));
        dispatch(alertActions.success(`Stream ${channel} deleted.`));
      })
      .catch((error) => {
        dispatch(failure(channel));
        switch (error.code) {
          default:
            error.message = 'Delete stream error.';
            break;
        }
        dispatch(alertActions.error(error.message));
      });
  };

  function request(status, channel) {
    return { type: sessionConstants.DELETE_STREAM_REQUEST, status, channel };
  }
  function success(stream, channel) {
    return { type: sessionConstants.DELETE_STREAM_SUCCESS, stream, channel };
  }
  function failure(channel) {
    return { type: sessionConstants.DELETE_STREAM_FAILURE, channel };
  }
}

function createStream(channel) {
  const { id, sessionKey, _version } = store.getState().session;

  return (dispatch) => {
    const req = {
      sessionId: id,
      sessionKey: sessionKey,
      sessionVersion: _version,
      type: streamRequestType.CREATE,
      config: null,
      index: channel.toUpperCase().charCodeAt(0) - 65,
    };

    dispatch(request(streamStatus.CREATING, channel));
    sessionService
      .streamRequest(req)
      .then((res) => {
        console.log(res);
        const data = res.data.createStreamWowza;
        if (data.error) {
          throw JSON.parse(data.error);
        }
        data.meta = JSON.parse(data.meta);
        dispatch(success(data, channel));
        dispatch(alertActions.success(`Stream ${channel} created.`));
      })
      .catch((error) => {
        dispatch(failure(channel));
        console.log(error);
        switch (error.code) {
          default:
            error.message = 'Create stream error.';
            break;
        }
        dispatch(alertActions.error(error.message));
      });
  };

  function request(status, channel) {
    return { type: sessionConstants.CREATE_STREAM_REQUEST, status, channel };
  }
  function success(stream, channel) {
    return { type: sessionConstants.CREATE_STREAM_SUCCESS, stream, channel };
  }
  function failure(channel) {
    return { type: sessionConstants.CREATE_STREAM_FAILURE, channel };
  }
}

function load(key) {
  const { user } = store.getState().user;

  return (dispatch) => {
    dispatch(request(key));
    sessionService
      .load(key)
      .then((res) => {
        console.log(res);
        if (res.data.SessionByKey.items.length === 0) {
          throw new Error({ code: 'SessionKeyNotFound' });
        }

        // Get teams in the right place
        const sessionData = res.data.SessionByKey.items[0];
        const normalizedSession = normalize(sessionData, session);
        const { lineups, sessionTeams } = normalizedSession.entities;
        const normalizedLineups = lineups ? normalize(lineups, [lineup]) : null;
        const normalizedSessionTeams = sessionTeams
          ? normalize(sessionTeams, [sessionTeam])
          : null;

        // Sorting might not be needed since Query already sorts by order
        const sortedSessionTeams = sessionData.sessionTeams.items.sort(
          (a, b) => {
            return a.order - b.order;
          }
        );
        const teamData = sortedSessionTeams.map((e) => e.team);

        // Normalize team data (teams, rosters, athletes, lineups, rosterlinks?)
        const normalizedTeams = normalize(teamData, [team]);
        const {
          rosters,
          coaches,
          leagues,
          rosterLinks,
          athletes,
          teams,
        } = normalizedTeams.entities;
        const normalizedRosters = rosters ? normalize(rosters, [roster]) : null;
        const normalizedLeagues = leagues
          ? normalize(leagues, [teamLeague])
          : null;
        const normalizedCoaches = coaches ? normalize(coaches, [coach]) : null;
        const normalizedRosterLinks = rosterLinks
          ? normalize(rosterLinks, [rosterLink])
          : null;
        const normalizedAthletes = athletes
          ? normalize(athletes, [athlete])
          : null;

        // Get user role in session, evaluator config
        const role = checkRole(user, sessionData);
        //const role = 'JUDGE';
        const evalConfig = getEvalConfig(role);
        //const evalConfig = undefined;

        dispatch(
          success(
            sessionData,
            {
              teams: {
                byId: normalizedTeams.entities.teams,
                allIds: normalizedTeams.result,
              },
              lineups: lineups
                ? {
                    byId: normalizedLineups.entities.lineups,
                    allIds: normalizedLineups.result,
                  }
                : null,
              rosters: rosters
                ? {
                    byId: normalizedRosters.entities.rosters,
                    allIds: normalizedRosters.result,
                  }
                : null,
              leagues: leagues
                ? {
                    byId: normalizedLeagues.entities.leagues,
                    allIds: normalizedLeagues.result,
                  }
                : null,
              coaches: coaches
                ? {
                    byId: normalizedCoaches.entities.coaches,
                    allIds: normalizedCoaches.result,
                  }
                : null,
              rosterLinks: rosterLinks
                ? {
                    byId: normalizedRosterLinks.entities.rosterLinks,
                    allIds: normalizedRosterLinks.result,
                  }
                : null, // is this needed?
              athletes: athletes
                ? {
                    byId: normalizedAthletes.entities.athletes,
                    allIds: normalizedAthletes.result,
                  }
                : null,
              sessionTeams: sessionTeams
                ? {
                    byId: normalizedSessionTeams.entities.sessionTeams,
                    allIds: normalizedSessionTeams.result,
                  }
                : null,
            },
            role
          )
        );

        // Makes lineup(s) on first load (should be part of create sequence)
        // Needs to be able to upgrade a Solo to add a lineup for new Dual
        if (
          !lineups ||
          normalizedLineups.result.length < normalizedTeams.result.length
        ) {
          dispatch(
            sessionActions.createLineup(
              teamData
                .map((el, i) => {
                  if (
                    normalizedLineups?.entities?.lineups[
                      normalizedLineups.result[i]
                    ]?.teamId === el.id
                  ) {
                    return null;
                  }
                  return {
                    sessionId: sessionData.id,
                    title: sessionData.name,
                    teamId: el.id,
                    sessionTeamId: sortedSessionTeams[i].id,
                    order: i,
                  };
                })
                .filter((el) => el !== null)
            )
          ); // solo or dual create 1 or 2 lineups
        }

        dispatch(
          producerActions.initialize(
            null,
            teamData,
            sessionData.producer ? JSON.parse(sessionData.producer) : null
          )
        );
        dispatch(evaluatorActions.initialize(undefined, evalConfig));
        dispatch(videoPlayerActions.vod(sessionData.status === 'POSTGAME')); // for vod or not
      })
      .catch((error) => {
        dispatch(failure());
        console.log(error);
        switch (error.code) {
          default:
            error.message = 'Load session error.';
            break;
        }
        dispatch(alertActions.error(error.message));
      });
  };

  function request(key) {
    return { type: sessionConstants.LOAD_REQUEST, key };
  }
  function success(session, sessionData, role) {
    return { type: sessionConstants.LOAD_SUCCESS, session, sessionData, role };
  }
  function failure() {
    return { type: sessionConstants.LOAD_FAILURE };
  }
}

function create(input, teamChange, history) {
  return (dispatch) => {
    dispatch(request(input));

    sessionService
      .create(input, teamChange)
      .then((sessionData) => {
        dispatch(success());
        dispatch(userActions.addSession(sessionData));
        history.push(`${uiState.SESSION}?s=${sessionData.sessionKey}`);
      })
      .catch((error) => {
        dispatch(failure());
        console.log(error);
        switch (error.code) {
          default:
            error.message = 'Create session error.';
            break;
        }
        dispatch(alertActions.error(error.message));
      });
  };

  function request() {
    return { type: sessionConstants.CREATE_REQUEST };
  }
  function success() {
    return { type: sessionConstants.CREATE_SUCCESS };
  }
  function failure() {
    return { type: sessionConstants.CREATE_FAILURE };
  }
}

function join(key, history) {
  const { profile } = store.getState().user;

  return (dispatch) => {
    dispatch(request());
    sessionService
      .join(key)
      .then((res) => {
        console.log(res);
        analyticsService.join(profile.id, key);
        if (res.data.SessionByKey.items.length === 0) {
          throw new Error({ code: 'SessionKeyNotFound' });
        }
        dispatch(success()); // throw away graphQL since will load with redirect
        history.push(`${uiState.SESSION}?s=${key}`);
      })
      .catch((error) => {
        dispatch(failure());
        switch (error.code) {
          case 'SessionKeyNotFound':
            error.message = 'Session not found.';
            break;
          default:
            error.message = 'Join session error.';
            break;
        }
        dispatch(alertActions.error(error.message));
      });
  };

  function request() {
    return { type: sessionConstants.JOIN_REQUEST };
  }
  function success() {
    return { type: sessionConstants.JOIN_SUCCESS };
  }
  function failure(error) {
    return { type: sessionConstants.JOIN_FAILURE };
  }
}

function update(input) {
  return (dispatch) => {
    dispatch(request());
    sessionService
      .update(input)
      .then((res) => {
        console.log(res);
        dispatch(success(res.data.updateSession));
        dispatch(alertActions.success('Session updated successfully.'));
      })
      .catch((error) => {
        dispatch(failure());
        console.log(error);
        switch (error.code) {
          default:
            error.message = 'Update session error.';
            break;
        }
        dispatch(alertActions.error(error.message));
      });
  };

  function request() {
    return { type: sessionConstants.UPDATE_REQUEST };
  }
  function success(session) {
    return { type: sessionConstants.UPDATE_SUCCESS, session };
  }
  function failure() {
    return { type: sessionConstants.UPDATE_FAILURE };
  }
}

function subscribe(id) {
  return (dispatch) => {
    dispatch(request());
    sessionService
      .subscribe(id, dispatch) // subscribes to session changes & new streams
      .then((res) => {
        dispatch(success(res));
        console.log(res);
      })
      .catch((error) => {
        dispatch(failure());
        console.log(error);
        dispatch(alertActions.error(error.message));
      });
  };

  function request() {
    return { type: sessionConstants.SUBSCRIBE_REQUEST };
  }
  function success(sub) {
    return { type: sessionConstants.SUBSCRIBE_SUCCESS, sub };
  }
  function failure(error) {
    return { type: sessionConstants.SUBSCRIBE_FAILURE };
  }
}

function unsubscribe() {
  const { session } = store.getState();

  return (dispatch) => {
    dispatch(request());
    sessionService
      .unsubscribe(session.sub)
      .then((res) => {
        dispatch(success());
        console.log(res);
      })
      .catch((error) => {
        dispatch(failure());
        console.log(error);
        dispatch(alertActions.error(error.message));
      });
  };

  function request() {
    return { type: sessionConstants.UNSUBSCRIBE_REQUEST };
  }
  function success() {
    return { type: sessionConstants.UNSUBSCRIBE_SUCCESS };
  }
  function failure(error) {
    return { type: sessionConstants.UNSUBSCRIBE_FAILURE };
  }
}

function subscribeStream(id, channel) {
  return (dispatch) => {
    dispatch(request());
    sessionService
      .subscribeStream(id, channel, dispatch)
      .then((res) => {
        dispatch(success(res, channel));
        //console.log(res);
      })
      .catch((error) => {
        dispatch(failure());
        console.log(error);
        dispatch(alertActions.error(error.message));
      });
  };

  function request() {
    return { type: sessionConstants.SUBSCRIBE_STREAM_REQUEST };
  }
  function success(sub, channel) {
    return { type: sessionConstants.SUBSCRIBE_STREAM_SUCCESS, sub, channel };
  }
  function failure(error) {
    return { type: sessionConstants.SUBSCRIBE_STREAM_FAILURE };
  }
}

function unsubscribeStream(channel) {
  const { session } = store.getState();

  return (dispatch) => {
    dispatch(request());
    sessionService
      .unsubscribe(session[`stream${channel}sub`])
      .then((res) => {
        dispatch(success());
      })
      .catch((error) => {
        dispatch(failure());
        dispatch(alertActions.error(error.message));
      });
  };

  function request() {
    return { type: sessionConstants.UNSUBSCRIBE_STREAM_REQUEST };
  }
  function success() {
    return { type: sessionConstants.UNSUBSCRIBE_STREAM_SUCCESS };
  }
  function failure(error) {
    return { type: sessionConstants.UNSUBSCRIBE_STREAM_FAILURE };
  }
}

function subscribeNewStream(id) {
  return (dispatch) => {
    dispatch(request());
    sessionService
      .subscribeNewStream(id, dispatch)
      .then((res) => {
        if (res) dispatch(success(res));
      })
      .catch((error) => {
        dispatch(failure());
        console.log(error);
        dispatch(alertActions.error(error.message));
      });
  };

  function request() {
    return { type: sessionConstants.SUBSCRIBE_NEW_STREAM_REQUEST };
  }
  function success(sub, channel) {
    return {
      type: sessionConstants.SUBSCRIBE_NEW_STREAM_SUCCESS,
      sub,
      channel,
    };
  }
  function failure(error) {
    return { type: sessionConstants.SUBSCRIBE_NEW_STREAM_FAILURE };
  }
}

function unsubscribeNewStream() {
  const { session } = store.getState();

  return (dispatch) => {
    dispatch(request());
    sessionService
      .unsubscribe(session.newStreamsSub)
      .then((res) => {
        dispatch(success());
      })
      .catch((error) => {
        dispatch(failure());
        dispatch(alertActions.error(error.message));
      });
  };

  function request() {
    return { type: sessionConstants.UNSUBSCRIBE_NEW_STREAM_REQUEST };
  }
  function success() {
    return { type: sessionConstants.UNSUBSCRIBE_NEW_STREAM_SUCCESS };
  }
  function failure(error) {
    return { type: sessionConstants.UNSUBSCRIBE_NEW_STREAM_FAILURE };
  }
}

function syncStreamStatus(stream, channel) {
  return { type: sessionConstants.SYNC_STREAM_STATUS_SUCCESS, stream, channel };
}

function syncNewStream(stream) {
  const { session } = store.getState();
  const channel = String.fromCharCode(stream.index + 65);

  return (dispatch) => {
    // Check if it was this session that launched the stream create request
    if (session[`stream${channel}Status`] === streamStatus.STOPPED) {
      dispatch(bypass());
    } else {
      // Need to parse the stream meta data
      stream.meta = JSON.parse(stream.meta);
      dispatch(alertActions.success(`Stream ${channel} created.`));

      dispatch(success(stream, channel));
      dispatch(alertActions.success(`Stream ${channel} created.`));
    }
  };

  function success() {
    return { type: sessionConstants.SYNC_NEW_STREAM_SUCCESS, stream, channel };
  }
  function bypass() {
    return { type: sessionConstants.SYNC_NEW_STREAM_BYPASS };
  }
}

function createLineup(input) {
  return (dispatch) => {
    dispatch(request());
    sessionService
      .addLineup(input)
      .then((res) => {
        // Need to add it back into the Session data structure, receives array of added lineups
        console.log(res);
        const data = res.map((el) => el.data.createLineup);
        const normalizedLineups = normalize(data, [lineup]);

        console.log(normalizedLineups);

        dispatch(
          success({
            byId: normalizedLineups.entities.lineups,
            allIds: normalizedLineups.result,
            lineups: data,
          })
        );
      })
      .catch((error) => {
        dispatch(failure());
        dispatch(alertActions.error(error.message));
      });
  };

  function request() {
    return { type: sessionConstants.CREATE_LINEUP_REQUEST };
  }
  function success(data) {
    return { type: sessionConstants.CREATE_LINEUP_SUCCESS, data };
  }
  function failure(error) {
    return { type: sessionConstants.CREATE_LINEUP_FAILURE };
  }
}

function updateLineup(input) {
  return (dispatch) => {
    // Since changes can be fast need to handle the double send with the same version (preventing automerge)
    // strategy make it optimistic update so next request is next version and then overwrite when data arrives
    dispatch(request(input));
    sessionService
      .editLineup(input)
      .then((res) => {
        console.log(res);
        const data = res.data.updateLineup;
        dispatch(success(data));
        dispatch(producerActions.updateLineup(data));
      })
      .catch((error) => {
        console.log(error);

        const topError = error?.errors?.[0].errorType;

        switch (topError) {
          case 'ConditionalCheckFailedException':
            error.message = 'Update lineup permission denied.';
            break;
          default:
            error.message = error?.errors?.[0].message;
            break;
        }

        dispatch(failure());
        dispatch(alertActions.error(error.message));
      });
  };

  function request(data) {
    return { type: sessionConstants.UPDATE_LINEUP_REQUEST, data };
  }
  function success(data) {
    return { type: sessionConstants.UPDATE_LINEUP_SUCCESS, data };
  }
  function failure(error) {
    return { type: sessionConstants.UPDATE_LINEUP_FAILURE };
  }
}

function syncLineup(lineup) {
  // Determine if lineup is Team A / Team B
  const { producer } = store.getState();
  const { teamA, teamB } = producer;
  const newLineup = JSON.parse(lineup.lineupData)[producer.round];
  let team = null;
  if (lineup.id === teamA?.lineupId) {
    team = teamA;
  }
  if (lineup.id === teamB?.lineupId) {
    team = teamB;
  }

  // Check if score change
  let scoreChange = false;
  let i = 0;
  if (team.lineup.length === newLineup.length) {
    for (i; i < team.lineup.length; i++) {
      if (
        newLineup[i].score !== null &&
        team.lineup[i].score !== newLineup[i].score
      ) {
        scoreChange = true;
        break;
      }
    }
  }

  return (dispatch) => {
    dispatch(success(lineup));
    dispatch(producerActions.updateLineup(lineup));
    if (scoreChange) {
      dispatch(
        alertActions.sync(
          `${newLineup[i].name}'s score is ${newLineup[i].score}`
        )
      );
    } else {
      dispatch(alertActions.sync(`${team.name} updated.`));
    }
  };

  function success(data) {
    return { type: sessionConstants.SYNC_LINEUP_SUCCESS, data };
  }
}

function subscribeLineup(id, channel) {
  return (dispatch) => {
    dispatch(request());
    sessionService
      .subscribeLineup(id, dispatch)
      .then((res) => {
        dispatch(success(res, channel));
        //console.log(res);
      })
      .catch((error) => {
        dispatch(failure());
        console.log(error);
        dispatch(alertActions.error(error.message));
      });
  };

  function request() {
    return { type: sessionConstants.SUBSCRIBE_LINEUP_REQUEST };
  }
  function success(sub, channel) {
    return { type: sessionConstants.SUBSCRIBE_LINEUP_SUCCESS, sub, channel };
  }
  function failure(error) {
    return { type: sessionConstants.SUBSCRIBE_LINEUP_FAILURE };
  }
}

function unsubscribeLineup(channel) {
  const { session } = store.getState();

  return (dispatch) => {
    dispatch(request());
    sessionService
      .unsubscribe(session[`lineup${channel}sub`])
      .then((res) => {
        dispatch(success());
      })
      .catch((error) => {
        dispatch(failure());
        dispatch(alertActions.error(error.message));
      });
  };

  function request() {
    return { type: sessionConstants.UNSUBSCRIBE_LINEUP_REQUEST };
  }
  function success() {
    return { type: sessionConstants.UNSUBSCRIBE_LINEUP_SUCCESS };
  }
  function failure(error) {
    return { type: sessionConstants.UNSUBSCRIBE_LINEUP_FAILURE };
  }
}

function syncSession(session) {
  return (dispatch) => {
    console.log(session);
    dispatch(success(session));
    dispatch(producerActions.syncProducer(JSON.parse(session.producer)));
  };

  function success(data) {
    return { type: sessionConstants.SYNC_SESSION_SUCCESS, data };
  }
}

function updateProducer(payload) {
  const {
    onAirAthlete,
    onEvalAthletes,
    doneEvalAthletes,
    round,
    live,
    onAir,
    completedRounds,
  } = store.getState().producer;
  const { id, _version } = store.getState().session;

  const producerSync = {
    onAirAthlete,
    onEvalAthletes,
    doneEvalAthletes,
    round,
    live,
    onAir,
    completedRounds,
    ...payload,
  };

  console.log(payload);
  console.log(producerSync);

  return (dispatch) => {
    dispatch(request());

    sessionService
      .updateProducer({ id, _version, producer: producerSync })
      .then((res) => {
        console.log(res);
        dispatch(success(res.data.updateSession));
      })
      .catch((error) => {
        console.log(error);
        dispatch(failure());
        dispatch(alertActions.error(error.message));
      });
  };

  function request() {
    return { type: sessionConstants.UPDATE_PRODUCER_REQUEST };
  }
  function success(data) {
    return { type: sessionConstants.UPDATE_PRODUCER_SUCCESS, data };
  }
  function failure() {
    return { type: sessionConstants.UPDATE_PRODUCER_FAILURE };
  }
}

function changeView(view) {
  return { type: sessionConstants.CHANGE_VIEW, view };
}
