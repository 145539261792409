import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Row, Col, Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import ScoreCard from './scorecard';
import {
  emptyLineup,
  sessionRoleType,
  sessionStatus,
  routineStatus,
} from '../../redux/_constants';
import {
  producerActions,
  videoPlayerActions,
  alertActions,
  evaluatorActions,
  eventActions,
} from '../../redux/_actions';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Typeahead } from 'react-bootstrap-typeahead';
import { MEDIAURL } from '../../utilities/constants';
import { sessionActions } from '../../redux/_actions';
import { sec2time } from '../../utilities/conversions';
import { playIcon, xIcon, plusIcon } from '../helpers/icons';
import HighlightShadow from '../helpers/highlightshadow';
import Hover from '../helpers/hover';
import { nanoid } from 'nanoid';

const filterRoster = (roster, lineup, isCompetition = true) => {
  // assuming properly formed roster and lineup
  if (!roster || !lineup) {
    return [];
  }
  if (!isCompetition) {
    return roster;
  }

  return roster.filter((el) => {
    return !lineup.some((i) => i.athleteId === el.id);
  });
};

function CompetitorList(props) {
  const { producer, videoPlayer, session } = useSelector((state) => state);
  const { onAirAthlete, onEvalAthletes, teamA, teamB, round } = producer;
  const { ADMIN, JUDGE, PRODUCER, FAN, COACH, ANNOUNCER } = sessionRoleType;
  const { CREATED, PREGAME, LIVE, POSTGAME } = sessionStatus;
  const { team, left, right } = props;
  const [lineup, setLineup] = useState(
    team && team.lineup ? team.lineup : JSON.parse(JSON.stringify(emptyLineup))
  );
  const [clipping, setClipping] = useState(false);
  const [judging, setJudging] = useState(false);
  const [focusIndex, setFocusIndex] = useState(null);
  const { color, home, roster } = team || {};
  const [rosterAlloc, setRosterAlloc] = useState(
    filterRoster(roster, lineup, session.competition)
  );
  const { athletes, lineups, sessionTeams } = session.sessionData;
  const dispatch = useDispatch();
  const typeaheadRef = useRef([]);
  const AorB = home ? 'A' : 'B';

  //console.log(team)
  //console.log(lineup)

  // Updates lineup with rotation change
  useEffect(() => {
    setLineup(JSON.parse(JSON.stringify(team.lineup)));
  }, [team.lineup]);

  // Handles changes in roster, lineup, competition status to filter roster
  useEffect(() => {
    // Need to net out the people on the lineup if competition and not if practice
    setRosterAlloc(filterRoster(roster, lineup, session.competition));
  }, [roster, lineup, session.competition]);

  useEffect(() => {
    // resets the state of clip editing if clip is turned off
    if (!producer.clip) {
      if (clipping) {
        // undo startTime changes
        setLineup(JSON.parse(JSON.stringify(team.lineup)));
      }

      setFocusIndex(null);
      setClipping(false);
    }
  }, [producer.clip, team.lineup, clipping]);

  useEffect(() => {
    // resets the state of judging if clip is turned off
    if (!producer.judge || producer.onEvalAthletes[AorB] === null) {
      setFocusIndex(null);
      setJudging(false);
    }
  }, [producer.judge, producer.onEvalAthletes, AorB]);

  const handleClick = (home, index) => {
    const currentTeam = home ? teamA : teamB;
    const id = home ? 'A' : 'B';
    let items = null;

    if (producer.live) {
      if (producer.force) {
        dispatch(producerActions.forceSelect(id, index));
        return;
      }

      if (currentTeam.onAir) {
        dispatch(
          producerActions.onEval(home, onAirAthlete, session.competition)
        );
        dispatch(producerActions.setEnd(home, onAirAthlete)); // no action
      } else {
        dispatch(producerActions.onAir(home, index, session.competition));
        dispatch(
          eventActions.createRoutine({
            id: lineup[index].key,
            athleteId: lineup[index].athleteId,
            lineupId: team.lineupId,
            order: index,
            status: routineStatus.CREATED,
            rotation: producer.round,
          })
        );
        //window.alert(videoPlayer[`player${id}`].ref?._player.currentTime)
      }
    } else if (producer.clip) {
      if (!clipping) {
        // Add start time lineup and need to reset endTime
        items = JSON.parse(JSON.stringify(lineup));
        items[index]['startTime'] = videoPlayer[`player${id}`].currentTime;

        // Using redux videoplayer refs
        //console.log(videoPlayer[`player${id}`].ref);

        //items[index]['startTime'] = videoPlayer[`player${id}`].ref?._player.currentTime;
        items[index]['endTime'] = null;
        setLineup(items);
        setClipping(true);
        setFocusIndex(index);
      } else {
        // need to do some error checking here of start/endTimes
        let tempEndTime = videoPlayer[`player${id}`].currentTime;

        console.log(videoPlayer[`player${id}`].ref);

        // Using redux videoplayer refs
        //let tempEndTime = videoPlayer[`player${id}`].ref?._player.currentTime; // for TheoPlayer
        if (tempEndTime <= lineup[index].startTime) {
          dispatch(alertActions.error('Clip end must be after clip start.'));
          return;
        } else {
          items = lineup;
          items[index]['endTime'] = tempEndTime;
          changeLineup(items);
          setClipping(false);
          setFocusIndex(null);
          //dispatch(alertActions.success("Clip saved."));  // or dispatch from redux
        }
      }
    } else if (producer.judge) {
      if (!judging) {
        // start player if it has startTime, if not assign it

        setJudging(true);
        setFocusIndex(index);
        dispatch(producerActions.onEval(home, index, false)); // false is for competition

        dispatch(videoPlayerActions.seek(lineup[index].startTime, id, true)); // for VOD postgame
      } else {
        // stop player and activate the judging panel, if it has endTime stop player automatically?
        // this is to handle the cancelation of judging mode as eval submit closes out this correctly
        setJudging(false);
        setFocusIndex(null);
        dispatch(producerActions.cancelEval(home));
        dispatch(evaluatorActions.reset(home));
        dispatch(alertActions.notification('Evaluation canceled.'));
      }
    } else if (producer.edit) {
      items = JSON.parse(JSON.stringify(lineup));

      if (index === -1) {
        // Add line operation
        const blankAthlete = Object.assign({}, emptyLineup[0]);
        items.push({ ...blankAthlete, key: nanoid() });
      } else {
        // Delete line operation
        items.splice(index, 1);
      }

      //setLineup(items.map((el,i) => { return ({...el, order: i+1})}));
      changeLineup(
        items.map((el, i) => {
          return { ...el, order: i + 1 };
        })
      ); // update order index
    } else {
      // For all VOD operations
      dispatch(videoPlayerActions.seek(lineup[index].startTime, id, true));
    }
  };

  const changeLineup = (items) => {
    setLineup(items);

    // extra check in case producer was initialized before lineups created
    const lineupId =
      team.lineupId ??
      session.lineups.items.find(
        (el) => sessionTeams.byId[el.sessionTeamId]?.order === (home ? 0 : 1)
      )?.id ??
      null;

    if (!lineupId) {
      dispatch(
        alertActions.error('Lineups not loaded correctly, refresh session.')
      );
      return;
    }

    const prevData = lineups.byId?.[lineupId].lineupData
      ? JSON.parse(lineups.byId?.[lineupId].lineupData)
      : {};
    const newData = { ...prevData, [round]: items };

    // should we dispatch a db change here?
    dispatch(
      sessionActions.updateLineup({
        id: lineupId,
        lineupData: JSON.stringify(newData),
        _version: lineups.byId[lineupId]._version,
        order: lineups.byId[lineupId].order,
      })
    );
  };

  const handleOnDragEnd = (result) => {
    //console.log("in handleOnDragEnd")
    if (!result.destination) return;

    //const items = Array.from(lineup);
    const items = JSON.parse(JSON.stringify(lineup));
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    changeLineup(
      items.map((el, i) => {
        return { ...el, order: i + 1 };
      })
    ); // update order index
  };

  const onKeyDown = (e, index) => {
    e.stopPropagation();
    const ref = typeaheadRef.current[index];

    if (e.key === 'Enter') {
      if (ref.items.length > 0) {
        const { name, id } = ref.items[0]; // pick top of list
        const items = JSON.parse(JSON.stringify(lineup));
        items[index].name = name;
        items[index].athleteId = id;
        changeLineup(items);
        ref.state.text = name;
        ref.state.selected = [ref.items[0]];
        ref.hideMenu();
        ref.blur();
      }
    }
  };

  const scoreField = (athlete, i) => {
    const currentTeam = home ? teamA : teamB;
    const currentTeamLetter = home ? 'A' : 'B';
    let score = '--';

    // Hidden scores
    if (!producer.scores) {
      return score;
    }

    if (producer.live && session.competition) {
      if (athlete.score) {
        return <ScoreCard team={team} index={i} lineup={lineup} />;
      } else {
        const disabled = onAirAthlete !== 2 * (i + 1) - (home ? 1 : 0);
        const evalAthlete =
          onEvalAthletes[currentTeamLetter] === 2 * (i + 1) - (home ? 1 : 0);
        if (currentTeam && currentTeam.onAir && !disabled) {
          score = 'On Air';
        }
        if (currentTeam && currentTeam.onEval && evalAthlete) {
          score = 'Evaluation';
        }
      }
    } else if (producer.clip) {
      score = `${sec2time(lineup[i].startTime) ?? '--:--'} / ${
        sec2time(lineup[i].endTime) ?? '--:--'
      }`;
    } else if (producer.judge) {
      if (judging && i === focusIndex) {
        score = '...';
      } else if (athlete.score) {
        score = athlete.score;
      }
    } else if (producer.edit) {
      if (athlete.score) {
        score = athlete.score;
      }
    } else {
      if (athlete.score) {
        //score = athlete.score;
        return <ScoreCard team={team} index={i} lineup={lineup} />;
      }
    }

    return score;
  };

  let background = '#f8f8f8';

  if (left) {
    background = `linear-gradient(90deg, ${
      color ? color : background
    } 0% 3%, #f8f8f8 3% 100%)`;
  }
  if (right) {
    background = `linear-gradient(90deg, #f8f8f8 0% 97%, ${
      color ? color : background
    } 97% 100%)`;
  }

  //console.log(lineup)
  //console.log(typeaheadRef)
  //console.log(athletes)

  const numberButton = (i, athlete) => {
    const currentTeam = home ? teamA : teamB;
    const currentTeamLetter = home ? 'A' : 'B';
    let disabled = true;
    let evalAthlete = null;
    let buttonColor = i === -1 ? 'outline-secondary' : 'secondary';
    let blink =
      clipping && focusIndex === i
        ? 'blinkingGreen'
        : judging && focusIndex === i
        ? 'blinkingYellow'
        : null;
    let hoverButton = null;
    let toolTipMsg = 'Play';
    const defaultButton = <span className="lineupNumber"> {i + 1} </span>;
    let mirrorAdminClass = false; // used to fake non disabled looking

    if (producer.force) {
      disabled = false;
      toolTipMsg = 'Force';
      hoverButton = defaultButton;
    } else if (producer.live || session.status === LIVE) {
      // Button details for Live mode

      if (producer.live && session.role === ADMIN) {
        if (
          !session.competition &&
          !currentTeam?.onAir &&
          onEvalAthletes[currentTeamLetter] === null &&
          !producer.doneEvalAthletes[currentTeamLetter].includes(i + 1)
        ) {
          disabled = false;
        } else {
          disabled =
            onAirAthlete !== 2 * (i + 1) - (home ? 1 : 0) ||
            onEvalAthletes[currentTeamLetter] !== null ||
            producer.doneEvalAthletes[currentTeamLetter].includes(i + 1);
          // disabled also needs to be for when previous D has not completed yet...
        }
        toolTipMsg = currentTeam?.onAir ? 'End' : 'Start';
      }

      evalAthlete =
        onEvalAthletes[currentTeamLetter] === 2 * (i + 1) - (home ? 1 : 0);

      mirrorAdminClass =
        producer.live &&
        !(
          onAirAthlete !== 2 * (i + 1) - (home ? 1 : 0) ||
          onEvalAthletes[currentTeamLetter] !== null ||
          producer.doneEvalAthletes[currentTeamLetter].includes(i + 1)
        );

      if (currentTeam && currentTeam.onAir && i !== -1) {
        if (!disabled || (session.role !== ADMIN && mirrorAdminClass)) {
          buttonColor = 'success';
        }
      }
      if (currentTeam && currentTeam.onEval && evalAthlete && i !== -1) {
        buttonColor = 'warning';
      }
      if (currentTeam && currentTeam.lineup[i]?.score !== null && i !== -1) {
        buttonColor = 'danger';
      }
      hoverButton = defaultButton;
    } else if (producer.clip) {
      // Button details for VOD mode w/ routine clipping
      if (/*athlete.athleteId && */ !clipping) {
        buttonColor = 'success';
        disabled = false;
        hoverButton = <span className="lineupNumber">S</span>;
        toolTipMsg = 'Clip Start';
      } else if (/*athlete.athleteId && */ clipping && focusIndex === i) {
        buttonColor = 'success';
        disabled = false;
        hoverButton = <span className="lineupNumber">F</span>;
        toolTipMsg = 'Clip Finish';
      }
    } else if (producer.judge) {
      // Button details for VOD mode w/ routine judging, only allowed if clipped
      if (/*athlete.athleteId&&*/ !judging && athlete.startTime !== null) {
        buttonColor = 'warning';
        disabled = false;
        hoverButton = <div className="athletePlay">{playIcon}</div>;
        toolTipMsg = 'Play & Judge';
        //toolTipMsg = 'Judge';
      } else if (judging && focusIndex === i) {
        buttonColor = 'warning';
        hoverButton = <div className="athleteX">{xIcon}</div>;
        disabled = false;
        toolTipMsg = 'Cancel';
      } else {
        hoverButton = defaultButton;
      }
    } else {
      // Button details for VOD mode for basic playing
      if (
        athlete?.startTime !== null &&
        athlete?.startTime !== undefined &&
        athlete?.endTime !== null &&
        athlete?.endTime !== undefined
      ) {
        hoverButton = <div className="athletePlay">{playIcon}</div>;
        disabled = false;
        toolTipMsg = 'Play';
      } else {
        hoverButton = defaultButton;
      }
    }

    // Separate as it can be used in both live and non-live
    if (producer.edit) {
      if (i === -1) {
        // Add blank line button
        hoverButton = <div className="athletePlus">{plusIcon}</div>;
        toolTipMsg = 'Add Position';
      } else {
        // Button details for when editing to add or delete line
        hoverButton = <div className="athleteX">{xIcon}</div>;
        toolTipMsg = 'Delete Position';
      }
      disabled = false;
    }

    return (
      <OverlayTrigger
        placement="top"
        delay={{ show: 700, hide: 0 }}
        overlay={
          <Tooltip
            id="popover-contained"
            className={disabled ? 'hidden' : null}
          >
            {toolTipMsg}
          </Tooltip>
        }
        disabled={disabled}
        transition={false}
      >
        <Button
          className={[
            focusIndex === i ? blink : null,
            mirrorAdminClass ? 'forceVisible' : null,
          ].join(' ')}
          variant={buttonColor}
          disabled={disabled}
          onClick={(e) => handleClick(home, i)}
        >
          {disabled ? (
            defaultButton
          ) : (
            <Hover onHover={hoverButton}>
              {i === -1 ? hoverButton : defaultButton}
            </Hover>
          )}
        </Button>
      </OverlayTrigger>
    );
  };

  const addAthlete = () => {
    return (
      <li>
        <Row className={'competitorLine'}>
          <Col xs={2}>
            <div className="buttonContainer">{numberButton(-1, null)}</div>
          </Col>
          <Col xs={2} />
          <Col xs={5} />
          <Col xs={3} />
        </Row>
      </li>
    );
  };

  return (
    <div className="competitorList">
      <DragDropContext onDragEnd={handleOnDragEnd}>
        <Droppable
          droppableId={`team${home ? 'A' : 'B'}CompetitorList`}
          isDropDisabled={!producer.edit}
        >
          {(provided) => (
            <ul
              className={`team${home ? 'A' : 'B'}CompetitorList`}
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {lineup.map((athlete, i) => {
                // assign some keys at random to identify draggables, will be used to identify lineup items
                if (!athlete.key) {
                  athlete.key = nanoid();
                }

                return (
                  <Draggable
                    key={`team${home ? 'A' : 'B'}${athlete.key}`}
                    draggableId={`team${home ? 'A' : 'B'}${athlete.key}`}
                    index={i}
                    isDragDisabled={!producer.edit}
                  >
                    {(provided) => (
                      <li
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <Row className={['competitorLine'].join(' ')}>
                          {session.status === POSTGAME ? (
                            <HighlightShadow athlete={athlete} id={AorB} />
                          ) : null}
                          <Col
                            className="vCenter logo"
                            style={{ background: background }}
                            xs={2}
                          >
                            <div className="buttonContainer">
                              {numberButton(i, athlete)}
                            </div>
                          </Col>
                          <Col className="vCenter headshot" xs={2}>
                            {athlete.name.length === 0 ||
                            athlete.athleteId === null ? null : (
                              <img
                                src={
                                  athletes.byId[athlete.athleteId].profileImg
                                    ? `${MEDIAURL}${
                                        JSON.parse(
                                          athletes.byId[athlete.athleteId]
                                            .profileImg
                                        ).metaData.filename
                                      }`
                                    : `${process.env.PUBLIC_URL}/headshotM.svg`
                                }
                                alt={
                                  athlete.name === ''
                                    ? ''
                                    : athlete.name + "'s photo"
                                }
                              />
                            )}
                          </Col>
                          <Col className="vCenter athlete" xs={5}>
                            <form spellCheck="false">
                              <Typeahead
                                onClick={(e, i) => {
                                  e.stopPropagation();
                                }}
                                onFocus={(e) => e.target.select()}
                                ref={(a) => {
                                  if (a !== null) {
                                    typeaheadRef.current.push(a);
                                  }
                                }}
                                onChange={(selected, e) => {
                                  const items = JSON.parse(
                                    JSON.stringify(lineup)
                                  );
                                  items[i].name =
                                    selected.length === 0 || selected[0] === ''
                                      ? ''
                                      : selected[0].name;
                                  items[i].athleteId =
                                    selected.length === 0 || selected[0] === ''
                                      ? null
                                      : selected[0].id;
                                  changeLineup(items);
                                  // do not access typeaheadRef here because it resets on every render
                                }}
                                options={rosterAlloc}
                                id={`athleteTypeahead`}
                                className={[
                                  'typeahead',
                                  producer.edit ? 'editing' : '',
                                ].join(' ')}
                                disabled={!producer.edit}
                                labelKey="name"
                                clearButton
                                dropup={true}
                                placeholder={'Add athlete...'}
                                selected={
                                  athlete.athleteId ? [athlete.name] : []
                                }
                                onKeyDown={(e) => onKeyDown(e, i)}
                              />
                            </form>
                          </Col>
                          <Col
                            className={[
                              'vCenter',
                              'score',
                              athlete.score &&
                              !producer.clip &&
                              !producer.edit &&
                              !producer.judge
                                ? null
                                : 'pending',
                            ].join(' ')}
                            xs={3}
                          >
                            {scoreField(athlete, i)}
                          </Col>
                        </Row>
                      </li>
                    )}
                  </Draggable>
                );
              })}
              {provided.placeholder}
              {producer.edit ? addAthlete() : null}
            </ul>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
}

export default CompetitorList;
