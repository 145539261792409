import React from 'react';
import { Modal, Button, Row, Col, ButtonGroup } from 'react-bootstrap';
import { sessionType, genderType } from '../../redux/_constants';

function Create(props) {
  const { setSetup, setup, setGender, gender } = props;
  const { MALE, FEMALE } = genderType;
  const { DUAL, SOLO } = sessionType;

  const createSessionBody = () => {
    return (
      <>
        <Row>
          <Col className="vCenter">
            <Button
              variant="light"
              className={[
                'createSoloButton',
                setup === SOLO ? 'active' : null,
              ].join(' ')}
              onClick={() => setSetup(SOLO)}
            >
              <span className={'vBlue'}>S</span>
              <span className={'vGray'}>olo</span>
            </Button>
          </Col>
          <Col className="vCenter">
            <Button
              variant="light"
              className={[
                'createDualButton',
                setup === DUAL ? 'active' : null,
              ].join(' ')}
              onClick={() => setSetup(DUAL)}
            >
              <span className={'vBlue'}>D</span>
              <span className={'vGray'}>ual</span>
            </Button>
          </Col>
        </Row>
        <Row>
          <Col className="vCenter">
            <p className="soloButtonDesc">1 camera</p>
            <p className="soloButtonDesc">1 location</p>
          </Col>
          <Col className="vCenter">
            <p className="dualButtonDesc">2 cameras</p>
            <p className="dualButtonDesc">1 or 2 locations</p>
          </Col>
        </Row>
      </>
    );
  };

  const headerMsg = () => {
    const virtius = (
      <>
        <span className={'vBlue'}>V</span>
        <span className={'vGray'}>irtius</span>
      </>
    );

    return (
      <>
        <span>Create new&nbsp;</span>
        {virtius}
        <span>&nbsp;session</span>
      </>
    );
  };

  const footer = () => {
    return (
      <ButtonGroup className="genderSelect">
        <Button
          variant="light"
          onClick={() => setGender(FEMALE)}
          className={gender === FEMALE ? 'active' : null}
        >
          <span className={'vBlue'}>W</span>
          <span className={'vGray'}>omens</span>
        </Button>
        <Button
          variant="light"
          onClick={() => setGender(MALE)}
          className={gender === MALE ? 'active' : null}
        >
          <span className={'vBlue'}>M</span>
          <span className={'vGray'}>ens</span>
        </Button>
      </ButtonGroup>
    );
  };

  return (
    <>
      <Modal.Header className="createOrJoinHeader">{headerMsg()}</Modal.Header>
      <Modal.Body className="createOrJoinForm">
        {createSessionBody()}
      </Modal.Body>
      <Modal.Footer className="createOrJoinForm">{footer()}</Modal.Footer>
    </>
  );
}

export default Create;
