import React from 'react';
import { Row, Col } from 'react-bootstrap';
import DeltaIndicator from './deltaindicator';
import { useSelector } from 'react-redux';

function ScoreDeltas({ teamAScore, teamBScore }) {
  const teamA = useSelector((state) => state.producer.teamA);
  const teamB = useSelector((state) => state.producer.teamB);
  const scores = useSelector((state) => state.producer.scores);

  // Need to handle imbalanced lineups (i.e. lineupA > lineupB or lineupB > lineupA)
  const maxLength = Math.min(teamA?.lineup?.length, teamB?.lineup?.length);
  const deltas = [];

  for (let i = 0; i < maxLength; i++) {
    let delta =
      Number(teamA?.lineup[i]?.score ?? 0) -
      Number(teamB?.lineup[i]?.score ?? 0);
    deltas.push(scores ? delta : 0);
  }

  const teamDelta =
    teamAScore && teamBScore && scores ? teamAScore - teamBScore : 0;

  return (
    <div className="scoreDeltas">
      <Row className="sDeltaRow">
        <Col className="sDeltaCol">
          <ul>
            {deltas &&
              deltas.map((delta, i) => {
                return (
                  <li className="vCenter" key={i}>
                    {Math.abs(delta).toFixed(3)}
                    <DeltaIndicator
                      delta={delta}
                      colorA={teamA.color}
                      colorB={teamB.color}
                    />
                  </li>
                );
              })}
          </ul>
        </Col>
      </Row>
      {
        <Row className="rDeltaRow">
          <Col className="rDeltaCol vCenter">
            {Math.abs(teamDelta).toFixed(3)}
            <DeltaIndicator
              delta={teamDelta}
              colorA={teamA && teamA.color}
              colorB={teamB && teamB.color}
            />
          </Col>
        </Row>
      }
    </div>
  );
}

export default ScoreDeltas;
