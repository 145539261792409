import React, { useEffect, useState, useRef } from 'react';
import { Modal, Tabs, Tab, Button, Row, Spinner, Alert } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { alertActions } from '../../redux/_actions';
import { SessionSetup } from './sessionsetup';
import { SessionJudges } from './sessionjudges';
import { SessionClips } from './sessionclips';
import { SessionScores } from './sessionscores';
import { SessionStreams } from './sessionstreams';
import { statusType } from '../../utilities/constants';
import { Link } from 'react-router-dom';

function SessionDetail(props) {
  const { back, sessionId } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [key, setKey] = useState('setup');
  const { STANDBY, SAVING, EDITING } = statusType;
  const [mode, setMode] = useState(STANDBY);
  const [footerDisabled, setFooterDisabled] = useState(false);
  const { alert } = useSelector((state) => state);
  const { sessions } = useSelector((state) => state.admin);
  const dispatch = useDispatch();
  const tabRef1 = useRef();
  const tabRef2 = useRef();
  const tabRef3 = useRef();
  const tabRef4 = useRef();
  const tabRef5 = useRef();

  useEffect(() => {
    if (!alert.clear) {
      setIsLoading(false);
      setMode(STANDBY);
    }
  }, [alert.clear]);

  useEffect(() => {
    dispatch(alertActions.clear());
  }, [dispatch]);

  const handleClick = (e) => {
    switch (key) {
      case 'setup':
        if (isEditing) {
          setMode(SAVING);
          save(tabRef1);
          setIsEditing(false);
        } else {
          setMode(EDITING);
          setIsEditing(true);
        }
        break;
      case 'streams':
        //refresh(tabRef2);
        if (isEditing) {
          setMode(SAVING);
          save(tabRef2);
          setIsEditing(false);
        } else {
          setMode(EDITING);
          setIsEditing(true);
        }
        break;
      case 'judges':
        break;
      case 'clips':
        break;
      case 'scores':
        break;
      default:
        break;
    }
  };

  const changeTab = (k) => {
    setMode(STANDBY);
    setKey(k);
  };

  const buttonText = () => {
    if (isLoading) {
      return (
        <>
          {'Saving... '}
          <Spinner
            variant="primary"
            role="status"
            animation="border"
            size="sm"
          />
        </>
      );
    }

    if (isEditing) {
      return 'Save';
    }

    switch (key) {
      case 'setup':
        return 'Edit';
      case 'streams':
        return 'Edit';
      case 'judges':
        return 'Add';
      case 'clips':
        return 'Add';
      case 'scores':
        return 'Add';
      default:
        return 'Create';
    }
  };

  const save = (ref) => {
    ref && ref.current && ref.current.submit();
  };

  const tabback = (ref) => {
    setMode(STANDBY);
    setIsEditing(false);
    ref && ref.current && ref.current.back();
  };

  const refresh = (ref) => {
    ref && ref.current && ref.current.refresh();
  };

  const reset = () => {
    setMode(STANDBY);
    setIsEditing(false);
    setIsLoading(false);
  };

  const body = () => {
    return (
      <>
        {!alert.clear ? (
          <Row style={{ margin: '0 0 1rem 0' }}>
            <Alert
              dismissible
              onClose={() => dispatch(alertActions.clear())}
              variant={alert.type === 'alert-danger' ? 'danger' : 'success'}
              style={{ textAlign: 'center', width: '100%' }}
            >
              {alert.message}
            </Alert>
          </Row>
        ) : null}
        <Row>
          <Tabs
            id="sessionDetailTabs"
            transition={false}
            activeKey={key}
            onSelect={(k) => changeTab(k)}
            disabled={isLoading}
          >
            <Tab eventKey="setup" title="Setup">
              <SessionSetup
                ref={tabRef1}
                sessionId={sessionId}
                isEditing={isEditing}
                isSaving={isLoading}
                reset={reset}
                setIsLoading={setIsLoading}
                footerToggle={setFooterDisabled}
              />
            </Tab>
            <Tab eventKey="streams" title="Streams">
              <SessionStreams
                ref={tabRef2}
                sessionId={sessionId}
                isEditing={isEditing}
                isSaving={isLoading}
                reset={reset}
                setIsLoading={setIsLoading}
              />
            </Tab>
            <Tab eventKey="clips" title="Clips">
              <SessionClips
                ref={tabRef3}
                sessionId={sessionId}
                isEditing={isEditing}
                isSaving={isLoading}
                reset={reset}
                setIsLoading={setIsLoading}
              />
            </Tab>
            <Tab eventKey="judges" title="Judges">
              <SessionJudges
                ref={tabRef4}
                sessionId={sessionId}
                isEditing={isEditing}
                isSaving={isLoading}
                reset={reset}
                setIsLoading={setIsLoading}
              />
            </Tab>
            <Tab eventKey="scores" title="Scores">
              <SessionScores
                ref={tabRef5}
                sessionId={sessionId}
                isEditing={isEditing}
                isSaving={isLoading}
                reset={reset}
                setIsLoading={setIsLoading}
              />
            </Tab>
          </Tabs>
        </Row>
      </>
    );
  };

  const footer = () => {
    return (
      <>
        <Row className="vCenter" style={{ margin: '0 auto' }}>
          <Button
            variant="outline-primary"
            className="formButton"
            onClick={handleClick}
            disabled={isLoading || footerDisabled}
          >
            {buttonText()}
          </Button>
        </Row>
        <Row>
          <div className="modalLink">
            <span>Back to</span>
            <button
              type="button"
              className="linkButton"
              disabled={isLoading || footerDisabled}
              onClick={back}
            >
              Session list
            </button>
          </div>
        </Row>
      </>
    );
  };

  const headerMsg = () => {
    return (
      <>
        <span>Session Admin:&nbsp;</span>
        <span style={{ fontWeight: '400' }}>
          <Link
            to={{
              pathname: '/session',
              search: `?s=${sessions.byId[sessionId].sessionKey}`,
              state: { fromAdmin: true },
            }}
          >
            {sessions.byId[sessionId].name}
          </Link>
        </span>
      </>
    );
  };

  const modal = () => {
    return (
      <>
        <Modal.Header className="adminHeader">{headerMsg()}</Modal.Header>
        <Modal.Body className="adminForm">{body()}</Modal.Body>
        <Modal.Footer className="adminForm">{footer()}</Modal.Footer>
      </>
    );
  };

  return modal();
}

export default SessionDetail;
