/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const batchCreateRosterLinks = /* GraphQL */ `
  mutation BatchCreateRosterLinks($rosterLinks: [CreateBatchRosterLinkInput]) {
    batchCreateRosterLinks(rosterLinks: $rosterLinks) {
      id
      rosterId
      athleteId
      classYear
      position
      active
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      owner
      athlete {
        id
        teamAffiliations
        userId
        gender
        name
        firstName
        lastName
        birthday
        homeTown
        country
        profileImg
        altNames
        slug
        type
        colors
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        rosters {
          nextToken
          startedAt
        }
        owner
      }
      roster {
        id
        teamId
        activeDate
        archived
        title
        updatedAt
        createdAt
        _version
        _deleted
        _lastChangedAt
        athletes {
          nextToken
          startedAt
        }
        team {
          id
          name
          slug
          location
          altNames
          gender
          colors
          logos
          type
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        owner
      }
    }
  }
`;
export const batchUpdateRosterLinks = /* GraphQL */ `
  mutation BatchUpdateRosterLinks($rosterLinks: [UpdateBatchRosterLinkInput]) {
    batchUpdateRosterLinks(rosterLinks: $rosterLinks) {
      id
      rosterId
      athleteId
      classYear
      position
      active
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      owner
      athlete {
        id
        teamAffiliations
        userId
        gender
        name
        firstName
        lastName
        birthday
        homeTown
        country
        profileImg
        altNames
        slug
        type
        colors
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        rosters {
          nextToken
          startedAt
        }
        owner
      }
      roster {
        id
        teamId
        activeDate
        archived
        title
        updatedAt
        createdAt
        _version
        _deleted
        _lastChangedAt
        athletes {
          nextToken
          startedAt
        }
        team {
          id
          name
          slug
          location
          altNames
          gender
          colors
          logos
          type
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        owner
      }
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      email
      status
      athleteId
      judgeType
      createdAt
      updatedAt
      name
      firstName
      lastName
      location
      handle
      colors
      profileImg
      googleId
      facebookId
      appleId
      cognitoId
      _version
      _deleted
      _lastChangedAt
      owner
      coach {
        items {
          id
          teamId
          userId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        nextToken
        startedAt
      }
      judgeSessions {
        items {
          id
          sessionId
          userId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      email
      status
      athleteId
      judgeType
      createdAt
      updatedAt
      name
      firstName
      lastName
      location
      handle
      colors
      profileImg
      googleId
      facebookId
      appleId
      cognitoId
      _version
      _deleted
      _lastChangedAt
      owner
      coach {
        items {
          id
          teamId
          userId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        nextToken
        startedAt
      }
      judgeSessions {
        items {
          id
          sessionId
          userId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      email
      status
      athleteId
      judgeType
      createdAt
      updatedAt
      name
      firstName
      lastName
      location
      handle
      colors
      profileImg
      googleId
      facebookId
      appleId
      cognitoId
      _version
      _deleted
      _lastChangedAt
      owner
      coach {
        items {
          id
          teamId
          userId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        nextToken
        startedAt
      }
      judgeSessions {
        items {
          id
          sessionId
          userId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const createSession = /* GraphQL */ `
  mutation CreateSession(
    $input: CreateSessionInput!
    $condition: ModelSessionConditionInput
  ) {
    createSession(input: $input, condition: $condition) {
      id
      sessionKey
      name
      type
      gender
      judging
      judgingRequired
      competition
      apparatus
      access
      startAt
      endAt
      status
      createdAt
      updatedAt
      admins
      producers
      coaches
      judges
      producer
      _version
      _deleted
      _lastChangedAt
      owner
      rotations {
        items {
          id
          sessionId
          status
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        nextToken
        startedAt
      }
      sessionJudges {
        items {
          id
          sessionId
          userId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        nextToken
        startedAt
      }
      sessionTeams {
        items {
          id
          sessionId
          teamId
          order
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        nextToken
        startedAt
      }
      lineups {
        items {
          id
          teamId
          sessionId
          eventId
          order
          title
          updatedAt
          createdAt
          lineupData
          sessionTeamId
          _version
          _deleted
          _lastChangedAt
          owner
        }
        nextToken
        startedAt
      }
      streams {
        items {
          id
          streamId
          ingestURL
          outputURL
          tempOutputURL
          vodURL
          recordingURL
          sessionKey
          sessionId
          index
          meta
          stats
          error
          status
          vodMeta
          recordingMeta
          createdAt
          updatedAt
          owner
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const updateSession = /* GraphQL */ `
  mutation UpdateSession(
    $input: UpdateSessionInput!
    $condition: ModelSessionConditionInput
  ) {
    updateSession(input: $input, condition: $condition) {
      id
      sessionKey
      name
      type
      gender
      judging
      judgingRequired
      competition
      apparatus
      access
      startAt
      endAt
      status
      createdAt
      updatedAt
      admins
      producers
      coaches
      judges
      producer
      _version
      _deleted
      _lastChangedAt
      owner
      rotations {
        items {
          id
          sessionId
          status
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        nextToken
        startedAt
      }
      sessionJudges {
        items {
          id
          sessionId
          userId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        nextToken
        startedAt
      }
      sessionTeams {
        items {
          id
          sessionId
          teamId
          order
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        nextToken
        startedAt
      }
      lineups {
        items {
          id
          teamId
          sessionId
          eventId
          order
          title
          updatedAt
          createdAt
          lineupData
          sessionTeamId
          _version
          _deleted
          _lastChangedAt
          owner
        }
        nextToken
        startedAt
      }
      streams {
        items {
          id
          streamId
          ingestURL
          outputURL
          tempOutputURL
          vodURL
          recordingURL
          sessionKey
          sessionId
          index
          meta
          stats
          error
          status
          vodMeta
          recordingMeta
          createdAt
          updatedAt
          owner
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const deleteSession = /* GraphQL */ `
  mutation DeleteSession(
    $input: DeleteSessionInput!
    $condition: ModelSessionConditionInput
  ) {
    deleteSession(input: $input, condition: $condition) {
      id
      sessionKey
      name
      type
      gender
      judging
      judgingRequired
      competition
      apparatus
      access
      startAt
      endAt
      status
      createdAt
      updatedAt
      admins
      producers
      coaches
      judges
      producer
      _version
      _deleted
      _lastChangedAt
      owner
      rotations {
        items {
          id
          sessionId
          status
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        nextToken
        startedAt
      }
      sessionJudges {
        items {
          id
          sessionId
          userId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        nextToken
        startedAt
      }
      sessionTeams {
        items {
          id
          sessionId
          teamId
          order
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        nextToken
        startedAt
      }
      lineups {
        items {
          id
          teamId
          sessionId
          eventId
          order
          title
          updatedAt
          createdAt
          lineupData
          sessionTeamId
          _version
          _deleted
          _lastChangedAt
          owner
        }
        nextToken
        startedAt
      }
      streams {
        items {
          id
          streamId
          ingestURL
          outputURL
          tempOutputURL
          vodURL
          recordingURL
          sessionKey
          sessionId
          index
          meta
          stats
          error
          status
          vodMeta
          recordingMeta
          createdAt
          updatedAt
          owner
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const createRotation = /* GraphQL */ `
  mutation CreateRotation(
    $input: CreateRotationInput!
    $condition: ModelRotationConditionInput
  ) {
    createRotation(input: $input, condition: $condition) {
      id
      sessionId
      status
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      owner
      events {
        items {
          id
          rotationId
          status
          apparatus
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const updateRotation = /* GraphQL */ `
  mutation UpdateRotation(
    $input: UpdateRotationInput!
    $condition: ModelRotationConditionInput
  ) {
    updateRotation(input: $input, condition: $condition) {
      id
      sessionId
      status
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      owner
      events {
        items {
          id
          rotationId
          status
          apparatus
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const deleteRotation = /* GraphQL */ `
  mutation DeleteRotation(
    $input: DeleteRotationInput!
    $condition: ModelRotationConditionInput
  ) {
    deleteRotation(input: $input, condition: $condition) {
      id
      sessionId
      status
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      owner
      events {
        items {
          id
          rotationId
          status
          apparatus
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const createEvent = /* GraphQL */ `
  mutation CreateEvent(
    $input: CreateEventInput!
    $condition: ModelEventConditionInput
  ) {
    createEvent(input: $input, condition: $condition) {
      id
      rotationId
      status
      apparatus
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      owner
      lineups {
        items {
          id
          teamId
          sessionId
          eventId
          order
          title
          updatedAt
          createdAt
          lineupData
          sessionTeamId
          _version
          _deleted
          _lastChangedAt
          owner
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const updateEvent = /* GraphQL */ `
  mutation UpdateEvent(
    $input: UpdateEventInput!
    $condition: ModelEventConditionInput
  ) {
    updateEvent(input: $input, condition: $condition) {
      id
      rotationId
      status
      apparatus
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      owner
      lineups {
        items {
          id
          teamId
          sessionId
          eventId
          order
          title
          updatedAt
          createdAt
          lineupData
          sessionTeamId
          _version
          _deleted
          _lastChangedAt
          owner
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const deleteEvent = /* GraphQL */ `
  mutation DeleteEvent(
    $input: DeleteEventInput!
    $condition: ModelEventConditionInput
  ) {
    deleteEvent(input: $input, condition: $condition) {
      id
      rotationId
      status
      apparatus
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      owner
      lineups {
        items {
          id
          teamId
          sessionId
          eventId
          order
          title
          updatedAt
          createdAt
          lineupData
          sessionTeamId
          _version
          _deleted
          _lastChangedAt
          owner
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const createRosterLink = /* GraphQL */ `
  mutation CreateRosterLink(
    $input: CreateRosterLinkInput!
    $condition: ModelRosterLinkConditionInput
  ) {
    createRosterLink(input: $input, condition: $condition) {
      id
      rosterId
      athleteId
      classYear
      position
      active
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      owner
      athlete {
        id
        teamAffiliations
        userId
        gender
        name
        firstName
        lastName
        birthday
        homeTown
        country
        profileImg
        altNames
        slug
        type
        colors
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        rosters {
          nextToken
          startedAt
        }
        owner
      }
      roster {
        id
        teamId
        activeDate
        archived
        title
        updatedAt
        createdAt
        _version
        _deleted
        _lastChangedAt
        athletes {
          nextToken
          startedAt
        }
        team {
          id
          name
          slug
          location
          altNames
          gender
          colors
          logos
          type
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        owner
      }
    }
  }
`;
export const updateRosterLink = /* GraphQL */ `
  mutation UpdateRosterLink(
    $input: UpdateRosterLinkInput!
    $condition: ModelRosterLinkConditionInput
  ) {
    updateRosterLink(input: $input, condition: $condition) {
      id
      rosterId
      athleteId
      classYear
      position
      active
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      owner
      athlete {
        id
        teamAffiliations
        userId
        gender
        name
        firstName
        lastName
        birthday
        homeTown
        country
        profileImg
        altNames
        slug
        type
        colors
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        rosters {
          nextToken
          startedAt
        }
        owner
      }
      roster {
        id
        teamId
        activeDate
        archived
        title
        updatedAt
        createdAt
        _version
        _deleted
        _lastChangedAt
        athletes {
          nextToken
          startedAt
        }
        team {
          id
          name
          slug
          location
          altNames
          gender
          colors
          logos
          type
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        owner
      }
    }
  }
`;
export const deleteRosterLink = /* GraphQL */ `
  mutation DeleteRosterLink(
    $input: DeleteRosterLinkInput!
    $condition: ModelRosterLinkConditionInput
  ) {
    deleteRosterLink(input: $input, condition: $condition) {
      id
      rosterId
      athleteId
      classYear
      position
      active
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      owner
      athlete {
        id
        teamAffiliations
        userId
        gender
        name
        firstName
        lastName
        birthday
        homeTown
        country
        profileImg
        altNames
        slug
        type
        colors
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        rosters {
          nextToken
          startedAt
        }
        owner
      }
      roster {
        id
        teamId
        activeDate
        archived
        title
        updatedAt
        createdAt
        _version
        _deleted
        _lastChangedAt
        athletes {
          nextToken
          startedAt
        }
        team {
          id
          name
          slug
          location
          altNames
          gender
          colors
          logos
          type
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        owner
      }
    }
  }
`;
export const createAthlete = /* GraphQL */ `
  mutation CreateAthlete(
    $input: CreateAthleteInput!
    $condition: ModelAthleteConditionInput
  ) {
    createAthlete(input: $input, condition: $condition) {
      id
      teamAffiliations
      userId
      gender
      name
      firstName
      lastName
      birthday
      homeTown
      country
      profileImg
      altNames
      slug
      type
      colors
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      rosters {
        items {
          id
          rosterId
          athleteId
          classYear
          position
          active
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        nextToken
        startedAt
      }
      owner
    }
  }
`;
export const updateAthlete = /* GraphQL */ `
  mutation UpdateAthlete(
    $input: UpdateAthleteInput!
    $condition: ModelAthleteConditionInput
  ) {
    updateAthlete(input: $input, condition: $condition) {
      id
      teamAffiliations
      userId
      gender
      name
      firstName
      lastName
      birthday
      homeTown
      country
      profileImg
      altNames
      slug
      type
      colors
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      rosters {
        items {
          id
          rosterId
          athleteId
          classYear
          position
          active
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        nextToken
        startedAt
      }
      owner
    }
  }
`;
export const deleteAthlete = /* GraphQL */ `
  mutation DeleteAthlete(
    $input: DeleteAthleteInput!
    $condition: ModelAthleteConditionInput
  ) {
    deleteAthlete(input: $input, condition: $condition) {
      id
      teamAffiliations
      userId
      gender
      name
      firstName
      lastName
      birthday
      homeTown
      country
      profileImg
      altNames
      slug
      type
      colors
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      rosters {
        items {
          id
          rosterId
          athleteId
          classYear
          position
          active
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        nextToken
        startedAt
      }
      owner
    }
  }
`;
export const createTeamCoach = /* GraphQL */ `
  mutation CreateTeamCoach(
    $input: CreateTeamCoachInput!
    $condition: ModelTeamCoachConditionInput
  ) {
    createTeamCoach(input: $input, condition: $condition) {
      id
      teamId
      userId
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      user {
        id
        email
        status
        athleteId
        judgeType
        createdAt
        updatedAt
        name
        firstName
        lastName
        location
        handle
        colors
        profileImg
        googleId
        facebookId
        appleId
        cognitoId
        _version
        _deleted
        _lastChangedAt
        owner
        coach {
          nextToken
          startedAt
        }
        judgeSessions {
          nextToken
          startedAt
        }
      }
      owner
      team {
        id
        name
        slug
        location
        altNames
        gender
        colors
        logos
        type
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        coaches {
          nextToken
          startedAt
        }
        owner
        sessions {
          nextToken
          startedAt
        }
        leagues {
          nextToken
          startedAt
        }
        rosters {
          nextToken
          startedAt
        }
        lineups {
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const updateTeamCoach = /* GraphQL */ `
  mutation UpdateTeamCoach(
    $input: UpdateTeamCoachInput!
    $condition: ModelTeamCoachConditionInput
  ) {
    updateTeamCoach(input: $input, condition: $condition) {
      id
      teamId
      userId
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      user {
        id
        email
        status
        athleteId
        judgeType
        createdAt
        updatedAt
        name
        firstName
        lastName
        location
        handle
        colors
        profileImg
        googleId
        facebookId
        appleId
        cognitoId
        _version
        _deleted
        _lastChangedAt
        owner
        coach {
          nextToken
          startedAt
        }
        judgeSessions {
          nextToken
          startedAt
        }
      }
      owner
      team {
        id
        name
        slug
        location
        altNames
        gender
        colors
        logos
        type
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        coaches {
          nextToken
          startedAt
        }
        owner
        sessions {
          nextToken
          startedAt
        }
        leagues {
          nextToken
          startedAt
        }
        rosters {
          nextToken
          startedAt
        }
        lineups {
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const deleteTeamCoach = /* GraphQL */ `
  mutation DeleteTeamCoach(
    $input: DeleteTeamCoachInput!
    $condition: ModelTeamCoachConditionInput
  ) {
    deleteTeamCoach(input: $input, condition: $condition) {
      id
      teamId
      userId
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      user {
        id
        email
        status
        athleteId
        judgeType
        createdAt
        updatedAt
        name
        firstName
        lastName
        location
        handle
        colors
        profileImg
        googleId
        facebookId
        appleId
        cognitoId
        _version
        _deleted
        _lastChangedAt
        owner
        coach {
          nextToken
          startedAt
        }
        judgeSessions {
          nextToken
          startedAt
        }
      }
      owner
      team {
        id
        name
        slug
        location
        altNames
        gender
        colors
        logos
        type
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        coaches {
          nextToken
          startedAt
        }
        owner
        sessions {
          nextToken
          startedAt
        }
        leagues {
          nextToken
          startedAt
        }
        rosters {
          nextToken
          startedAt
        }
        lineups {
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const createSessionJudge = /* GraphQL */ `
  mutation CreateSessionJudge(
    $input: CreateSessionJudgeInput!
    $condition: ModelSessionJudgeConditionInput
  ) {
    createSessionJudge(input: $input, condition: $condition) {
      id
      sessionId
      userId
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      user {
        id
        email
        status
        athleteId
        judgeType
        createdAt
        updatedAt
        name
        firstName
        lastName
        location
        handle
        colors
        profileImg
        googleId
        facebookId
        appleId
        cognitoId
        _version
        _deleted
        _lastChangedAt
        owner
        coach {
          nextToken
          startedAt
        }
        judgeSessions {
          nextToken
          startedAt
        }
      }
      session {
        id
        sessionKey
        name
        type
        gender
        judging
        judgingRequired
        competition
        apparatus
        access
        startAt
        endAt
        status
        createdAt
        updatedAt
        admins
        producers
        coaches
        judges
        producer
        _version
        _deleted
        _lastChangedAt
        owner
        rotations {
          nextToken
          startedAt
        }
        sessionJudges {
          nextToken
          startedAt
        }
        sessionTeams {
          nextToken
          startedAt
        }
        lineups {
          nextToken
          startedAt
        }
        streams {
          nextToken
          startedAt
        }
      }
      owner
    }
  }
`;
export const updateSessionJudge = /* GraphQL */ `
  mutation UpdateSessionJudge(
    $input: UpdateSessionJudgeInput!
    $condition: ModelSessionJudgeConditionInput
  ) {
    updateSessionJudge(input: $input, condition: $condition) {
      id
      sessionId
      userId
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      user {
        id
        email
        status
        athleteId
        judgeType
        createdAt
        updatedAt
        name
        firstName
        lastName
        location
        handle
        colors
        profileImg
        googleId
        facebookId
        appleId
        cognitoId
        _version
        _deleted
        _lastChangedAt
        owner
        coach {
          nextToken
          startedAt
        }
        judgeSessions {
          nextToken
          startedAt
        }
      }
      session {
        id
        sessionKey
        name
        type
        gender
        judging
        judgingRequired
        competition
        apparatus
        access
        startAt
        endAt
        status
        createdAt
        updatedAt
        admins
        producers
        coaches
        judges
        producer
        _version
        _deleted
        _lastChangedAt
        owner
        rotations {
          nextToken
          startedAt
        }
        sessionJudges {
          nextToken
          startedAt
        }
        sessionTeams {
          nextToken
          startedAt
        }
        lineups {
          nextToken
          startedAt
        }
        streams {
          nextToken
          startedAt
        }
      }
      owner
    }
  }
`;
export const deleteSessionJudge = /* GraphQL */ `
  mutation DeleteSessionJudge(
    $input: DeleteSessionJudgeInput!
    $condition: ModelSessionJudgeConditionInput
  ) {
    deleteSessionJudge(input: $input, condition: $condition) {
      id
      sessionId
      userId
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      user {
        id
        email
        status
        athleteId
        judgeType
        createdAt
        updatedAt
        name
        firstName
        lastName
        location
        handle
        colors
        profileImg
        googleId
        facebookId
        appleId
        cognitoId
        _version
        _deleted
        _lastChangedAt
        owner
        coach {
          nextToken
          startedAt
        }
        judgeSessions {
          nextToken
          startedAt
        }
      }
      session {
        id
        sessionKey
        name
        type
        gender
        judging
        judgingRequired
        competition
        apparatus
        access
        startAt
        endAt
        status
        createdAt
        updatedAt
        admins
        producers
        coaches
        judges
        producer
        _version
        _deleted
        _lastChangedAt
        owner
        rotations {
          nextToken
          startedAt
        }
        sessionJudges {
          nextToken
          startedAt
        }
        sessionTeams {
          nextToken
          startedAt
        }
        lineups {
          nextToken
          startedAt
        }
        streams {
          nextToken
          startedAt
        }
      }
      owner
    }
  }
`;
export const createTeam = /* GraphQL */ `
  mutation CreateTeam(
    $input: CreateTeamInput!
    $condition: ModelTeamConditionInput
  ) {
    createTeam(input: $input, condition: $condition) {
      id
      name
      slug
      location
      altNames
      gender
      colors
      logos
      type
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      coaches {
        items {
          id
          teamId
          userId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        nextToken
        startedAt
      }
      owner
      sessions {
        items {
          id
          sessionId
          teamId
          order
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        nextToken
        startedAt
      }
      leagues {
        items {
          id
          leagueId
          teamId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        nextToken
        startedAt
      }
      rosters {
        items {
          id
          teamId
          activeDate
          archived
          title
          updatedAt
          createdAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        nextToken
        startedAt
      }
      lineups {
        items {
          id
          teamId
          sessionId
          eventId
          order
          title
          updatedAt
          createdAt
          lineupData
          sessionTeamId
          _version
          _deleted
          _lastChangedAt
          owner
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const updateTeam = /* GraphQL */ `
  mutation UpdateTeam(
    $input: UpdateTeamInput!
    $condition: ModelTeamConditionInput
  ) {
    updateTeam(input: $input, condition: $condition) {
      id
      name
      slug
      location
      altNames
      gender
      colors
      logos
      type
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      coaches {
        items {
          id
          teamId
          userId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        nextToken
        startedAt
      }
      owner
      sessions {
        items {
          id
          sessionId
          teamId
          order
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        nextToken
        startedAt
      }
      leagues {
        items {
          id
          leagueId
          teamId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        nextToken
        startedAt
      }
      rosters {
        items {
          id
          teamId
          activeDate
          archived
          title
          updatedAt
          createdAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        nextToken
        startedAt
      }
      lineups {
        items {
          id
          teamId
          sessionId
          eventId
          order
          title
          updatedAt
          createdAt
          lineupData
          sessionTeamId
          _version
          _deleted
          _lastChangedAt
          owner
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const deleteTeam = /* GraphQL */ `
  mutation DeleteTeam(
    $input: DeleteTeamInput!
    $condition: ModelTeamConditionInput
  ) {
    deleteTeam(input: $input, condition: $condition) {
      id
      name
      slug
      location
      altNames
      gender
      colors
      logos
      type
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      coaches {
        items {
          id
          teamId
          userId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        nextToken
        startedAt
      }
      owner
      sessions {
        items {
          id
          sessionId
          teamId
          order
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        nextToken
        startedAt
      }
      leagues {
        items {
          id
          leagueId
          teamId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        nextToken
        startedAt
      }
      rosters {
        items {
          id
          teamId
          activeDate
          archived
          title
          updatedAt
          createdAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        nextToken
        startedAt
      }
      lineups {
        items {
          id
          teamId
          sessionId
          eventId
          order
          title
          updatedAt
          createdAt
          lineupData
          sessionTeamId
          _version
          _deleted
          _lastChangedAt
          owner
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const createSessionTeam = /* GraphQL */ `
  mutation CreateSessionTeam(
    $input: CreateSessionTeamInput!
    $condition: ModelSessionTeamConditionInput
  ) {
    createSessionTeam(input: $input, condition: $condition) {
      id
      sessionId
      teamId
      order
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      session {
        id
        sessionKey
        name
        type
        gender
        judging
        judgingRequired
        competition
        apparatus
        access
        startAt
        endAt
        status
        createdAt
        updatedAt
        admins
        producers
        coaches
        judges
        producer
        _version
        _deleted
        _lastChangedAt
        owner
        rotations {
          nextToken
          startedAt
        }
        sessionJudges {
          nextToken
          startedAt
        }
        sessionTeams {
          nextToken
          startedAt
        }
        lineups {
          nextToken
          startedAt
        }
        streams {
          nextToken
          startedAt
        }
      }
      team {
        id
        name
        slug
        location
        altNames
        gender
        colors
        logos
        type
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        coaches {
          nextToken
          startedAt
        }
        owner
        sessions {
          nextToken
          startedAt
        }
        leagues {
          nextToken
          startedAt
        }
        rosters {
          nextToken
          startedAt
        }
        lineups {
          nextToken
          startedAt
        }
      }
      owner
    }
  }
`;
export const updateSessionTeam = /* GraphQL */ `
  mutation UpdateSessionTeam(
    $input: UpdateSessionTeamInput!
    $condition: ModelSessionTeamConditionInput
  ) {
    updateSessionTeam(input: $input, condition: $condition) {
      id
      sessionId
      teamId
      order
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      session {
        id
        sessionKey
        name
        type
        gender
        judging
        judgingRequired
        competition
        apparatus
        access
        startAt
        endAt
        status
        createdAt
        updatedAt
        admins
        producers
        coaches
        judges
        producer
        _version
        _deleted
        _lastChangedAt
        owner
        rotations {
          nextToken
          startedAt
        }
        sessionJudges {
          nextToken
          startedAt
        }
        sessionTeams {
          nextToken
          startedAt
        }
        lineups {
          nextToken
          startedAt
        }
        streams {
          nextToken
          startedAt
        }
      }
      team {
        id
        name
        slug
        location
        altNames
        gender
        colors
        logos
        type
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        coaches {
          nextToken
          startedAt
        }
        owner
        sessions {
          nextToken
          startedAt
        }
        leagues {
          nextToken
          startedAt
        }
        rosters {
          nextToken
          startedAt
        }
        lineups {
          nextToken
          startedAt
        }
      }
      owner
    }
  }
`;
export const deleteSessionTeam = /* GraphQL */ `
  mutation DeleteSessionTeam(
    $input: DeleteSessionTeamInput!
    $condition: ModelSessionTeamConditionInput
  ) {
    deleteSessionTeam(input: $input, condition: $condition) {
      id
      sessionId
      teamId
      order
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      session {
        id
        sessionKey
        name
        type
        gender
        judging
        judgingRequired
        competition
        apparatus
        access
        startAt
        endAt
        status
        createdAt
        updatedAt
        admins
        producers
        coaches
        judges
        producer
        _version
        _deleted
        _lastChangedAt
        owner
        rotations {
          nextToken
          startedAt
        }
        sessionJudges {
          nextToken
          startedAt
        }
        sessionTeams {
          nextToken
          startedAt
        }
        lineups {
          nextToken
          startedAt
        }
        streams {
          nextToken
          startedAt
        }
      }
      team {
        id
        name
        slug
        location
        altNames
        gender
        colors
        logos
        type
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        coaches {
          nextToken
          startedAt
        }
        owner
        sessions {
          nextToken
          startedAt
        }
        leagues {
          nextToken
          startedAt
        }
        rosters {
          nextToken
          startedAt
        }
        lineups {
          nextToken
          startedAt
        }
      }
      owner
    }
  }
`;
export const createLeague = /* GraphQL */ `
  mutation CreateLeague(
    $input: CreateLeagueInput!
    $condition: ModelLeagueConditionInput
  ) {
    createLeague(input: $input, condition: $condition) {
      id
      name
      altNames
      slug
      colors
      logos
      type
      gender
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      teams {
        items {
          id
          leagueId
          teamId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const updateLeague = /* GraphQL */ `
  mutation UpdateLeague(
    $input: UpdateLeagueInput!
    $condition: ModelLeagueConditionInput
  ) {
    updateLeague(input: $input, condition: $condition) {
      id
      name
      altNames
      slug
      colors
      logos
      type
      gender
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      teams {
        items {
          id
          leagueId
          teamId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const deleteLeague = /* GraphQL */ `
  mutation DeleteLeague(
    $input: DeleteLeagueInput!
    $condition: ModelLeagueConditionInput
  ) {
    deleteLeague(input: $input, condition: $condition) {
      id
      name
      altNames
      slug
      colors
      logos
      type
      gender
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      teams {
        items {
          id
          leagueId
          teamId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const createLeagueTeam = /* GraphQL */ `
  mutation CreateLeagueTeam(
    $input: CreateLeagueTeamInput!
    $condition: ModelLeagueTeamConditionInput
  ) {
    createLeagueTeam(input: $input, condition: $condition) {
      id
      leagueId
      teamId
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      team {
        id
        name
        slug
        location
        altNames
        gender
        colors
        logos
        type
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        coaches {
          nextToken
          startedAt
        }
        owner
        sessions {
          nextToken
          startedAt
        }
        leagues {
          nextToken
          startedAt
        }
        rosters {
          nextToken
          startedAt
        }
        lineups {
          nextToken
          startedAt
        }
      }
      league {
        id
        name
        altNames
        slug
        colors
        logos
        type
        gender
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        teams {
          nextToken
          startedAt
        }
      }
      owner
    }
  }
`;
export const updateLeagueTeam = /* GraphQL */ `
  mutation UpdateLeagueTeam(
    $input: UpdateLeagueTeamInput!
    $condition: ModelLeagueTeamConditionInput
  ) {
    updateLeagueTeam(input: $input, condition: $condition) {
      id
      leagueId
      teamId
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      team {
        id
        name
        slug
        location
        altNames
        gender
        colors
        logos
        type
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        coaches {
          nextToken
          startedAt
        }
        owner
        sessions {
          nextToken
          startedAt
        }
        leagues {
          nextToken
          startedAt
        }
        rosters {
          nextToken
          startedAt
        }
        lineups {
          nextToken
          startedAt
        }
      }
      league {
        id
        name
        altNames
        slug
        colors
        logos
        type
        gender
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        teams {
          nextToken
          startedAt
        }
      }
      owner
    }
  }
`;
export const deleteLeagueTeam = /* GraphQL */ `
  mutation DeleteLeagueTeam(
    $input: DeleteLeagueTeamInput!
    $condition: ModelLeagueTeamConditionInput
  ) {
    deleteLeagueTeam(input: $input, condition: $condition) {
      id
      leagueId
      teamId
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      team {
        id
        name
        slug
        location
        altNames
        gender
        colors
        logos
        type
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        coaches {
          nextToken
          startedAt
        }
        owner
        sessions {
          nextToken
          startedAt
        }
        leagues {
          nextToken
          startedAt
        }
        rosters {
          nextToken
          startedAt
        }
        lineups {
          nextToken
          startedAt
        }
      }
      league {
        id
        name
        altNames
        slug
        colors
        logos
        type
        gender
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        teams {
          nextToken
          startedAt
        }
      }
      owner
    }
  }
`;
export const createScore = /* GraphQL */ `
  mutation CreateScore(
    $input: CreateScoreInput!
    $condition: ModelScoreConditionInput
  ) {
    createScore(input: $input, condition: $condition) {
      id
      routineId
      sessionId
      createdAt
      value
      type
      userId
      locked
      _version
      _deleted
      _lastChangedAt
      updatedAt
      owner
      routine {
        id
        sessionId
        athleteId
        apparatus
        order
        rotation
        lineupId
        status
        score
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        scores {
          nextToken
          startedAt
        }
        lineup {
          id
          teamId
          sessionId
          eventId
          order
          title
          updatedAt
          createdAt
          lineupData
          sessionTeamId
          _version
          _deleted
          _lastChangedAt
          owner
        }
        clips {
          nextToken
          startedAt
        }
        owner
      }
    }
  }
`;
export const updateScore = /* GraphQL */ `
  mutation UpdateScore(
    $input: UpdateScoreInput!
    $condition: ModelScoreConditionInput
  ) {
    updateScore(input: $input, condition: $condition) {
      id
      routineId
      sessionId
      createdAt
      value
      type
      userId
      locked
      _version
      _deleted
      _lastChangedAt
      updatedAt
      owner
      routine {
        id
        sessionId
        athleteId
        apparatus
        order
        rotation
        lineupId
        status
        score
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        scores {
          nextToken
          startedAt
        }
        lineup {
          id
          teamId
          sessionId
          eventId
          order
          title
          updatedAt
          createdAt
          lineupData
          sessionTeamId
          _version
          _deleted
          _lastChangedAt
          owner
        }
        clips {
          nextToken
          startedAt
        }
        owner
      }
    }
  }
`;
export const deleteScore = /* GraphQL */ `
  mutation DeleteScore(
    $input: DeleteScoreInput!
    $condition: ModelScoreConditionInput
  ) {
    deleteScore(input: $input, condition: $condition) {
      id
      routineId
      sessionId
      createdAt
      value
      type
      userId
      locked
      _version
      _deleted
      _lastChangedAt
      updatedAt
      owner
      routine {
        id
        sessionId
        athleteId
        apparatus
        order
        rotation
        lineupId
        status
        score
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        scores {
          nextToken
          startedAt
        }
        lineup {
          id
          teamId
          sessionId
          eventId
          order
          title
          updatedAt
          createdAt
          lineupData
          sessionTeamId
          _version
          _deleted
          _lastChangedAt
          owner
        }
        clips {
          nextToken
          startedAt
        }
        owner
      }
    }
  }
`;
export const createRoster = /* GraphQL */ `
  mutation CreateRoster(
    $input: CreateRosterInput!
    $condition: ModelRosterConditionInput
  ) {
    createRoster(input: $input, condition: $condition) {
      id
      teamId
      activeDate
      archived
      title
      updatedAt
      createdAt
      _version
      _deleted
      _lastChangedAt
      athletes {
        items {
          id
          rosterId
          athleteId
          classYear
          position
          active
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        nextToken
        startedAt
      }
      team {
        id
        name
        slug
        location
        altNames
        gender
        colors
        logos
        type
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        coaches {
          nextToken
          startedAt
        }
        owner
        sessions {
          nextToken
          startedAt
        }
        leagues {
          nextToken
          startedAt
        }
        rosters {
          nextToken
          startedAt
        }
        lineups {
          nextToken
          startedAt
        }
      }
      owner
    }
  }
`;
export const updateRoster = /* GraphQL */ `
  mutation UpdateRoster(
    $input: UpdateRosterInput!
    $condition: ModelRosterConditionInput
  ) {
    updateRoster(input: $input, condition: $condition) {
      id
      teamId
      activeDate
      archived
      title
      updatedAt
      createdAt
      _version
      _deleted
      _lastChangedAt
      athletes {
        items {
          id
          rosterId
          athleteId
          classYear
          position
          active
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        nextToken
        startedAt
      }
      team {
        id
        name
        slug
        location
        altNames
        gender
        colors
        logos
        type
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        coaches {
          nextToken
          startedAt
        }
        owner
        sessions {
          nextToken
          startedAt
        }
        leagues {
          nextToken
          startedAt
        }
        rosters {
          nextToken
          startedAt
        }
        lineups {
          nextToken
          startedAt
        }
      }
      owner
    }
  }
`;
export const deleteRoster = /* GraphQL */ `
  mutation DeleteRoster(
    $input: DeleteRosterInput!
    $condition: ModelRosterConditionInput
  ) {
    deleteRoster(input: $input, condition: $condition) {
      id
      teamId
      activeDate
      archived
      title
      updatedAt
      createdAt
      _version
      _deleted
      _lastChangedAt
      athletes {
        items {
          id
          rosterId
          athleteId
          classYear
          position
          active
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        nextToken
        startedAt
      }
      team {
        id
        name
        slug
        location
        altNames
        gender
        colors
        logos
        type
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        coaches {
          nextToken
          startedAt
        }
        owner
        sessions {
          nextToken
          startedAt
        }
        leagues {
          nextToken
          startedAt
        }
        rosters {
          nextToken
          startedAt
        }
        lineups {
          nextToken
          startedAt
        }
      }
      owner
    }
  }
`;
export const createLineup = /* GraphQL */ `
  mutation CreateLineup(
    $input: CreateLineupInput!
    $condition: ModelLineupConditionInput
  ) {
    createLineup(input: $input, condition: $condition) {
      id
      teamId
      sessionId
      eventId
      order
      title
      updatedAt
      createdAt
      lineupData
      sessionTeamId
      _version
      _deleted
      _lastChangedAt
      owner
      routines {
        items {
          id
          sessionId
          athleteId
          apparatus
          order
          rotation
          lineupId
          status
          score
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const updateLineup = /* GraphQL */ `
  mutation UpdateLineup(
    $input: UpdateLineupInput!
    $condition: ModelLineupConditionInput
  ) {
    updateLineup(input: $input, condition: $condition) {
      id
      teamId
      sessionId
      eventId
      order
      title
      updatedAt
      createdAt
      lineupData
      sessionTeamId
      _version
      _deleted
      _lastChangedAt
      owner
      routines {
        items {
          id
          sessionId
          athleteId
          apparatus
          order
          rotation
          lineupId
          status
          score
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const deleteLineup = /* GraphQL */ `
  mutation DeleteLineup(
    $input: DeleteLineupInput!
    $condition: ModelLineupConditionInput
  ) {
    deleteLineup(input: $input, condition: $condition) {
      id
      teamId
      sessionId
      eventId
      order
      title
      updatedAt
      createdAt
      lineupData
      sessionTeamId
      _version
      _deleted
      _lastChangedAt
      owner
      routines {
        items {
          id
          sessionId
          athleteId
          apparatus
          order
          rotation
          lineupId
          status
          score
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const createStream = /* GraphQL */ `
  mutation CreateStream(
    $input: CreateStreamInput!
    $condition: ModelStreamConditionInput
  ) {
    createStream(input: $input, condition: $condition) {
      id
      streamId
      ingestURL
      outputURL
      tempOutputURL
      vodURL
      recordingURL
      sessionKey
      sessionId
      index
      meta
      stats
      error
      status
      vodMeta
      recordingMeta
      createdAt
      updatedAt
      owner
      _version
      _deleted
      _lastChangedAt
      session {
        id
        sessionKey
        name
        type
        gender
        judging
        judgingRequired
        competition
        apparatus
        access
        startAt
        endAt
        status
        createdAt
        updatedAt
        admins
        producers
        coaches
        judges
        producer
        _version
        _deleted
        _lastChangedAt
        owner
        rotations {
          nextToken
          startedAt
        }
        sessionJudges {
          nextToken
          startedAt
        }
        sessionTeams {
          nextToken
          startedAt
        }
        lineups {
          nextToken
          startedAt
        }
        streams {
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const updateStream = /* GraphQL */ `
  mutation UpdateStream(
    $input: UpdateStreamInput!
    $condition: ModelStreamConditionInput
  ) {
    updateStream(input: $input, condition: $condition) {
      id
      streamId
      ingestURL
      outputURL
      tempOutputURL
      vodURL
      recordingURL
      sessionKey
      sessionId
      index
      meta
      stats
      error
      status
      vodMeta
      recordingMeta
      createdAt
      updatedAt
      owner
      _version
      _deleted
      _lastChangedAt
      session {
        id
        sessionKey
        name
        type
        gender
        judging
        judgingRequired
        competition
        apparatus
        access
        startAt
        endAt
        status
        createdAt
        updatedAt
        admins
        producers
        coaches
        judges
        producer
        _version
        _deleted
        _lastChangedAt
        owner
        rotations {
          nextToken
          startedAt
        }
        sessionJudges {
          nextToken
          startedAt
        }
        sessionTeams {
          nextToken
          startedAt
        }
        lineups {
          nextToken
          startedAt
        }
        streams {
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const deleteStream = /* GraphQL */ `
  mutation DeleteStream(
    $input: DeleteStreamInput!
    $condition: ModelStreamConditionInput
  ) {
    deleteStream(input: $input, condition: $condition) {
      id
      streamId
      ingestURL
      outputURL
      tempOutputURL
      vodURL
      recordingURL
      sessionKey
      sessionId
      index
      meta
      stats
      error
      status
      vodMeta
      recordingMeta
      createdAt
      updatedAt
      owner
      _version
      _deleted
      _lastChangedAt
      session {
        id
        sessionKey
        name
        type
        gender
        judging
        judgingRequired
        competition
        apparatus
        access
        startAt
        endAt
        status
        createdAt
        updatedAt
        admins
        producers
        coaches
        judges
        producer
        _version
        _deleted
        _lastChangedAt
        owner
        rotations {
          nextToken
          startedAt
        }
        sessionJudges {
          nextToken
          startedAt
        }
        sessionTeams {
          nextToken
          startedAt
        }
        lineups {
          nextToken
          startedAt
        }
        streams {
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const createClip = /* GraphQL */ `
  mutation CreateClip(
    $input: CreateClipInput!
    $condition: ModelClipConditionInput
  ) {
    createClip(input: $input, condition: $condition) {
      id
      startTime
      endTime
      pgmStartTime
      pgmEndTime
      duration
      fileName
      originURL
      routineId
      owner
      streamId
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateClip = /* GraphQL */ `
  mutation UpdateClip(
    $input: UpdateClipInput!
    $condition: ModelClipConditionInput
  ) {
    updateClip(input: $input, condition: $condition) {
      id
      startTime
      endTime
      pgmStartTime
      pgmEndTime
      duration
      fileName
      originURL
      routineId
      owner
      streamId
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteClip = /* GraphQL */ `
  mutation DeleteClip(
    $input: DeleteClipInput!
    $condition: ModelClipConditionInput
  ) {
    deleteClip(input: $input, condition: $condition) {
      id
      startTime
      endTime
      pgmStartTime
      pgmEndTime
      duration
      fileName
      originURL
      routineId
      owner
      streamId
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createRoutine = /* GraphQL */ `
  mutation CreateRoutine(
    $input: CreateRoutineInput!
    $condition: ModelRoutineConditionInput
  ) {
    createRoutine(input: $input, condition: $condition) {
      id
      sessionId
      athleteId
      apparatus
      order
      rotation
      lineupId
      status
      score
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      scores {
        items {
          id
          routineId
          sessionId
          createdAt
          value
          type
          userId
          locked
          _version
          _deleted
          _lastChangedAt
          updatedAt
          owner
        }
        nextToken
        startedAt
      }
      lineup {
        id
        teamId
        sessionId
        eventId
        order
        title
        updatedAt
        createdAt
        lineupData
        sessionTeamId
        _version
        _deleted
        _lastChangedAt
        owner
        routines {
          nextToken
          startedAt
        }
      }
      clips {
        items {
          id
          startTime
          endTime
          pgmStartTime
          pgmEndTime
          duration
          fileName
          originURL
          routineId
          owner
          streamId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      owner
    }
  }
`;
export const updateRoutine = /* GraphQL */ `
  mutation UpdateRoutine(
    $input: UpdateRoutineInput!
    $condition: ModelRoutineConditionInput
  ) {
    updateRoutine(input: $input, condition: $condition) {
      id
      sessionId
      athleteId
      apparatus
      order
      rotation
      lineupId
      status
      score
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      scores {
        items {
          id
          routineId
          sessionId
          createdAt
          value
          type
          userId
          locked
          _version
          _deleted
          _lastChangedAt
          updatedAt
          owner
        }
        nextToken
        startedAt
      }
      lineup {
        id
        teamId
        sessionId
        eventId
        order
        title
        updatedAt
        createdAt
        lineupData
        sessionTeamId
        _version
        _deleted
        _lastChangedAt
        owner
        routines {
          nextToken
          startedAt
        }
      }
      clips {
        items {
          id
          startTime
          endTime
          pgmStartTime
          pgmEndTime
          duration
          fileName
          originURL
          routineId
          owner
          streamId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      owner
    }
  }
`;
export const deleteRoutine = /* GraphQL */ `
  mutation DeleteRoutine(
    $input: DeleteRoutineInput!
    $condition: ModelRoutineConditionInput
  ) {
    deleteRoutine(input: $input, condition: $condition) {
      id
      sessionId
      athleteId
      apparatus
      order
      rotation
      lineupId
      status
      score
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      scores {
        items {
          id
          routineId
          sessionId
          createdAt
          value
          type
          userId
          locked
          _version
          _deleted
          _lastChangedAt
          updatedAt
          owner
        }
        nextToken
        startedAt
      }
      lineup {
        id
        teamId
        sessionId
        eventId
        order
        title
        updatedAt
        createdAt
        lineupData
        sessionTeamId
        _version
        _deleted
        _lastChangedAt
        owner
        routines {
          nextToken
          startedAt
        }
      }
      clips {
        items {
          id
          startTime
          endTime
          pgmStartTime
          pgmEndTime
          duration
          fileName
          originURL
          routineId
          owner
          streamId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      owner
    }
  }
`;
export const createStreamWowza = /* GraphQL */ `
  mutation CreateStreamWowza($payload: String!) {
    createStreamWowza(payload: $payload) {
      id
      streamId
      ingestURL
      outputURL
      tempOutputURL
      vodURL
      recordingURL
      sessionKey
      sessionId
      index
      meta
      stats
      error
      status
      vodMeta
      recordingMeta
      createdAt
      updatedAt
      owner
      _version
      _deleted
      _lastChangedAt
      session {
        id
        sessionKey
        name
        type
        gender
        judging
        judgingRequired
        competition
        apparatus
        access
        startAt
        endAt
        status
        createdAt
        updatedAt
        admins
        producers
        coaches
        judges
        producer
        _version
        _deleted
        _lastChangedAt
        owner
        rotations {
          nextToken
          startedAt
        }
        sessionJudges {
          nextToken
          startedAt
        }
        sessionTeams {
          nextToken
          startedAt
        }
        lineups {
          nextToken
          startedAt
        }
        streams {
          nextToken
          startedAt
        }
      }
    }
  }
`;
