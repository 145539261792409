export const matchA = {
  sessionId: 'nUltZ7LgSH',
  teamA: 'Illinois',
  teamALogo: 't9wWLsOPF2bpVZHqjsogl',
  teamBLogo: 'U7yGlq6CiOy02Wqqwj9Sb',
  teamB: 'NIU',
  gameTime: '2021-01-15T23:30:00.000Z',
};

export const matchB = {
  sessionId: 'al5TXaUSVa',
  teamA: 'Army',
  teamALogo: 'wmICCck438BshXvBlPHV_',
  teamBLogo: '6Ju2q59cmDgc7NU-VCdEg',
  teamB: 'PSU',
  gameTime: '2021-01-16T18:00:00.000Z',
};

export const matchC = {
  sessionId: 'Ah4x-2OZzm',
  teamA: 'Michigan',
  teamALogo: '5UacbwAbMk1Md_YfRP801',
  teamBLogo: 'G1Vglwz4LRM2Yo1pc9NiT',
  teamB: 'UIC',
  gameTime: '2021-01-17T18:00:00.000Z',
};

export const matchD = {
  sessionId: 'eg2wDJN-YB',
  teamA: 'OSU',
  teamALogo: '1zvZAutg_lGASowMXuhnY',
  teamBLogo: 'QtMgsTKjUwl0sEQwnlwNT',
  teamB: 'Navy',
  gameTime: '2021-01-30T17:00:00.000Z',
};

export const matchE = {
  sessionId: 'PEvQ6AddE1',
  teamA: 'OU',
  teamALogo: 'hqph8HiyQRcSV8QF_G78t',
  teamBLogo: 'wmICCck438BshXvBlPHV_',
  teamB: 'Army',
  gameTime: '2021-01-30T21:00:00.000Z',
};

export const matchF = {
  sessionId: 'OI5mO6SqsO',
  teamA: 'Cal',
  teamALogo: 'cFqM7q75rvGOTzliayNOC',
  teamBLogo: 'du0YPpvxVn6sDVlayCUX0',
  teamB: 'ASU',
  gameTime: '2021-01-31T00:30:00.000Z',
};

export const matches = [
  [matchD, matchE, matchF],
  [matchA, matchB, matchC],
];
