import React, { useState, useEffect } from 'react';
import { Button, Tooltip, OverlayTrigger, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { producerActions } from '../../redux/_actions';
import { exportIcon } from '../helpers/icons';
import { exportAllData, copyToClipboard } from '../../utilities/export';

const defaultContent = 'Copy Data';
const clickedContent = 'Copied';

const ToolTip = React.forwardRef(
  ({ popper, children, show: _, ...props }, ref) => {
    useEffect(() => {
      popper.scheduleUpdate();
    }, [children, popper]);

    return (
      <Tooltip ref={ref} {...props}>
        {children}
      </Tooltip>
    );
  }
);

function ExportData() {
  const [content, setContent] = useState(defaultContent);
  const dispatch = useDispatch();
  const session = useSelector((state) => state.session);
  const teamA = useSelector((state) => state.producer.teamA);
  const teamB = useSelector((state) => state.producer.teamB);
  const exportHeader = useSelector((state) => state.producer.exportHeader);
  let timerId = null;

  const fullLineupA =
    teamA.lineupId &&
    session.sessionData.lineups.byId?.[teamA.lineupId].lineupData
      ? JSON.parse(
          session.sessionData.lineups.byId?.[teamA.lineupId].lineupData
        )
      : {};

  const fullLineupB =
    teamB.lineupId &&
    session.sessionData.lineups.byId?.[teamB.lineupId].lineupData
      ? JSON.parse(
          session.sessionData.lineups.byId?.[teamB.lineupId].lineupData
        )
      : {};

  const handleClick = () => {
    clearTimeout(timerId);
    setContent(clickedContent);
    timerId = setTimeout(() => {
      setContent(defaultContent);
    }, 1000);

    const data = exportAllData(
      fullLineupA,
      fullLineupB,
      teamA.name,
      teamB.name,
      null,
      exportHeader,
      session.gender,
      session.name,
      session.startAt
    );

    copyToClipboard(data);
    dispatch(producerActions.exportData(data));
  };

  const handleToggle = () => {
    if (content === clickedContent) {
      clearTimeout(timerId);
      timerId = setTimeout(() => {
        setContent(defaultContent);
      }, 1000);
    }
  };

  return (
    <div className="exportData vCenter">
      <Row>
        <OverlayTrigger
          placement="top"
          delay={{ show: 700, hide: 0 }}
          overlay={<ToolTip id="popover-contained">{content}</ToolTip>}
          onToggle={handleToggle}
        >
          <Button variant="light" onClick={handleClick} className="vCenter">
            {exportIcon}
          </Button>
        </OverlayTrigger>
      </Row>
    </div>
  );
}

export default ExportData;
