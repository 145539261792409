import React, { useState, useCallback, useImperativeHandle } from 'react';
import {
  Button,
  Row,
  Col,
  Image as ImageBS,
  Form,
  Popover,
  OverlayTrigger,
} from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { adminActions, alertActions } from '../../redux/_actions';
import { streamRequestType } from '../../redux/_constants';
import * as Yup from 'yup';
import { dateTime } from '../../utilities/conversions';

export const SessionStreams = React.forwardRef((props, ref) => {
  const { sessionId, isEditing, isSaving, reset, setIsLoading } = props;
  const { sessions, streams } = useSelector((state) => state.admin);
  const { CHECK, CHECKVOD, CHECKRECORDING } = streamRequestType;
  const session = sessions.byId[sessionId];
  const sessionStreams = session.streams.items
    .map((id) => streams.byId[id])
    .sort((a, b) => a.index - b.index);
  const dispatch = useDispatch();

  useImperativeHandle(ref, () => ({
    refresh() {
      sessionStreams.forEach((stream) => {
        dispatch(adminActions.checkStreamPostgame(stream.id, CHECKVOD));
      });
    },
    submit() {
      handleSubmit();
    },
  }));

  const emptyList = () => {
    return (
      <Row
        style={{ minHeight: '40vh', textAlign: 'center' }}
        className="vCenter"
      >
        <span>No streams created.</span>
      </Row>
    );
  };

  const validationSchema = Yup.object().shape({
    vodURLs: Yup.array().of(Yup.string()),
    recordingURLs: Yup.array().of(Yup.string()),
  });

  const initialValues = {
    vodURLs: sessionStreams.map((stream) => stream.vodURL ?? ''),
    recordingURLs: sessionStreams.map((stream) => stream.recordingURL ?? ''),
  };

  const {
    setFieldValue,
    handleSubmit,
    handleChange,
    values,
    errors,
  } = useFormik({
    initialValues,
    validationSchema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit(values) {
      setIsLoading(true);
      dispatch(alertActions.clear());

      const payload = sessionStreams.map((stream, i) => {
        return initialValues.vodURLs[i] !== values.vodURLs[i] ||
          initialValues.recordingURLs[i] !== values.recordingURLs[i]
          ? {
              id: stream.id,
              _version: stream._version,
              vodURL: values.vodURLs[i],
              recordingURL: values.recordingURLs[i],
            }
          : false;
      });

      if (payload.every((el) => el === false)) {
        dispatch(alertActions.success('No changes made.'));
        reset();
        return;
      }

      dispatch(adminActions.updateStreamURLs(payload));
    },
  });

  const setupBody = () => {
    if (sessionStreams.length === 0) {
      // no such thing yet
      return emptyList();
    } else {
      return (
        <ul>
          {sessionStreams.map((stream, i) => {
            return (
              <li key={stream.id}>
                <Row>
                  <Col className="title" sm={2}>
                    Index:
                  </Col>
                  <Col sm={3}>{stream.index}</Col>
                  <Col className="title" sm={2}>
                    Id:
                  </Col>
                  <Col sm={5}>{stream.streamId}</Col>
                </Row>
                <Row>
                  <Col className="title" sm={2}>
                    Status:
                  </Col>
                  <Col sm={3}>{stream.status}</Col>
                  <Col className="title" sm={2}>
                    Updated:
                  </Col>
                  <Col sm={5}>{dateTime(stream.updatedAt, true)}</Col>
                </Row>
                <Row>
                  <Col className="title" sm={2}>
                    Ingest:
                  </Col>
                  <Col>{stream.ingestURL}</Col>
                </Row>
                <Row>
                  <Col className="title" sm={2}>
                    Output:
                  </Col>
                  <Col>{stream.outputURL}</Col>
                </Row>
                <Row>
                  <Col className="title" sm={2}>
                    VOD:
                  </Col>
                  <Col>
                    <Form.Control
                      plaintext
                      name="vodURL"
                      type="text"
                      autoComplete="off"
                      disabled={isSaving || !isEditing}
                      value={values.vodURLs[i] ?? ''}
                      onChange={(e) =>
                        setFieldValue(
                          'vodURLs',
                          Object.assign([...values.vodURLs], {
                            [i]: e.target.value,
                          })
                        )
                      }
                      placeholder="Edit to add URL..."
                    />
                  </Col>
                </Row>
                <Row>
                  <Col className="title" sm={2}>
                    Recording:
                  </Col>
                  <Col>
                    <Form.Control
                      plaintext
                      name="recordingURL"
                      type="text"
                      autoComplete="off"
                      disabled={isSaving || !isEditing}
                      value={values.recordingURLs[i] ?? ''}
                      onChange={(e) =>
                        setFieldValue(
                          'recordingURLs',
                          Object.assign([...values.recordingURLs], {
                            [i]: e.target.value,
                          })
                        )
                      }
                      placeholder="Edit to add URL..."
                    />
                  </Col>
                </Row>
              </li>
            );
          })}
        </ul>
      );
    }
  };

  return (
    <Form onSubmit={null} className="sessionStreams">
      {setupBody()}
    </Form>
  );
});
