import React from 'react';
import { Container, Row, Col, ButtonGroup, Button } from 'react-bootstrap';
import LoginOrSignup from './login/loginorsignup';
import { useDispatch, useSelector } from 'react-redux';
import { alertActions } from '../redux/_actions';
import { uiState } from '../redux/_constants';
import { heroLogo } from './helpers/logos';
import LoginBubble from './helpers/loginbubble';
import MatchupGrid from './helpers/matchupgrid';
import Privacy from './helpers/privacy';
import Terms from './helpers/terms';
import Footer from './helpers/footer';
import { useHistory, useLocation } from 'react-router-dom';

function Home() {
  const alertClear = useSelector((state) => state.alert.clear);
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { PRIVACY, TERMS } = uiState;

  const handleMode = (mode) => {
    if (!alertClear) {
      dispatch(alertActions.clear());
    }
    history.push(mode);
  };

  const mainContent = () => {
    switch (location.pathname) {
      case PRIVACY:
        return <Privacy />;
      case TERMS:
        return <Terms />;
      default:
        return <MatchupGrid />;
    }
  };

  const mainSubtitle = () => {
    switch (location.pathname) {
      case PRIVACY:
        return 'Privacy policy';
      case TERMS:
        return 'Terms of service';
      default:
        return 'Select a match';
    }
  };

  return (
    <div className="main">
      <div className="header">
        <LoginBubble />
      </div>
      <LoginOrSignup />
      <Container>
        <Row style={{ height: 'calc(15vh - 72px)' }} />
        <Row
          style={{
            position: 'sticky',
            top: '72px',
            background: '#fff',
            padding: '1rem',
            zIndex: '1000',
          }}
        >
          <Col className="center" style={{ display: 'flex', flexWrap: 'wrap' }}>
            <div className="heroLogo"> {heroLogo} </div>
            <div className="heroMessage" style={{ fontStyle: 'italic' }}>
              {mainSubtitle()}
            </div>
          </Col>
        </Row>
        <Row style={{ height: '50vh' }}>
          <Col className="center" style={{ display: 'flex', flexWrap: 'wrap' }}>
            {mainContent()}
          </Col>
        </Row>
      </Container>
      <Footer />
    </div>
  );
}

export default Home;
