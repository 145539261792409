import React, { useEffect, useState, useRef } from 'react';
import { Modal, Tabs, Tab, Button, Row, Spinner, Alert } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { alertActions } from '../../redux/_actions';
import { TeamProfile } from './teamprofile';
import { TeamRoster } from './teamroster';
import { TeamLineup } from './teamlineup';
import { TeamCoaches } from './teamcoaches';
import { modeType, statusType } from '../../utilities/constants';

function TeamDetail(props) {
  const { back, teamId } = props;
  const { LIST, DETAIL, EDIT } = modeType;
  const { CREATING, SAVING, EDITING, STANDBY } = statusType;
  const [isLoading, setIsLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [key, setKey] = useState('profile');
  const [mode, setMode] = useState(STANDBY);
  const [tabMode, setTabMode] = useState(LIST);
  const { alert } = useSelector((state) => state);
  const { teams } = useSelector((state) => state.admin);
  const dispatch = useDispatch();
  const tabRef1 = useRef();
  const tabRef2 = useRef();
  const tabRef3 = useRef();
  const tabRef4 = useRef();

  useEffect(() => {
    if (!alert.clear) {
      setIsLoading(false);
      setMode(STANDBY);
    }
  }, [alert.clear, STANDBY]);

  const handleClick = (e) => {
    switch (key) {
      case 'profile':
        if (isEditing) {
          setMode(SAVING);
          save(tabRef1);
          setIsEditing(false);
        } else {
          setMode(EDITING);
          setIsEditing(true);
        }
        break;
      case 'roster':
        if (isEditing) {
          setMode(SAVING);
          save(tabRef2);
          setIsLoading(true);
          setIsEditing(false);
        } else {
          if (tabMode === LIST) {
            setMode(CREATING);
            setIsLoading(true);
            create(tabRef2);
            setIsEditing(true);
          }
          if (tabMode === DETAIL) {
            setMode(EDITING);
            setIsEditing(true);
            edit(tabRef2);
          }
        }
        break;
      case 'lineup':
        if (isEditing) {
          setMode(SAVING);
          save(tabRef3);
          setIsLoading(true);
          setIsEditing(false);
        } else {
          if (tabMode === LIST) {
            setMode(CREATING);
            setIsLoading(true);
            create(tabRef3);
            setIsEditing(true);
          }
          if (tabMode === DETAIL) {
            setMode(EDITING);
            setIsEditing(true);
            edit(tabRef3);
          }
        }
        break;
      case 'coaches':
        break;
      default:
        break;
    }
  };

  const changeTab = (k) => {
    setMode(STANDBY);
    setKey(k);
  };

  const buttonText = () => {
    if (isLoading) {
      return (
        <>
          {mode === CREATING ? 'Creating... ' : 'Saving ... '}
          <Spinner
            variant="primary"
            role="status"
            animation="border"
            size="sm"
          />
        </>
      );
    }

    if (isEditing) {
      return 'Save';
    }

    switch (key) {
      case 'profile':
        return 'Edit';
      case 'roster':
      case 'lineup':
        if (mode === STANDBY) {
          switch (tabMode) {
            case LIST:
              return 'Create';
            case EDIT:
              return 'Save';
            case DETAIL:
              return 'Edit';
            default:
              return 'Edit';
          }
        }
        break;
      case 'coaches':
        return 'Add';
      default:
        return 'Create';
    }
  };

  const create = (ref) => {
    ref && ref.current && ref.current.create();
  };

  const save = (ref) => {
    ref && ref.current && ref.current.submit();
  };

  const edit = (ref) => {
    ref && ref.current && ref.current.edit();
  };

  const tabback = (ref) => {
    setMode(STANDBY);
    setIsEditing(false);
    ref && ref.current && ref.current.back();
  };

  const reset = () => {
    setMode(STANDBY);
    setIsEditing(false);
    setIsLoading(false);
  };

  const body = () => {
    return (
      <>
        {!alert.clear ? (
          <Row style={{ margin: '0 0 1rem 0' }}>
            <Alert
              dismissible
              onClose={() => dispatch(alertActions.clear())}
              variant={alert.type === 'alert-danger' ? 'danger' : 'success'}
              style={{ textAlign: 'center', width: '100%' }}
            >
              {alert.message}
            </Alert>
          </Row>
        ) : null}
        <Row>
          <Tabs
            id="teamDetailTabs"
            transition={false}
            activeKey={key}
            onSelect={(k) => changeTab(k)}
            disabled={isLoading}
          >
            <Tab eventKey="profile" title="Profile">
              <TeamProfile
                ref={tabRef1}
                teamId={teamId}
                isEditing={isEditing}
                isSaving={isLoading}
                reset={reset}
                setIsLoading={setIsLoading}
              />
            </Tab>
            <Tab eventKey="roster" title="Roster">
              <TeamRoster
                ref={tabRef2}
                teamId={teamId}
                isEditing={isEditing}
                isSaving={isLoading}
                reset={reset}
                setIsLoading={setIsLoading}
                setIsEditing={setIsEditing}
                setTabMode={setTabMode}
                tabMode={tabMode}
              />
            </Tab>
            <Tab eventKey="lineup" title="Lineup">
              <TeamLineup
                ref={tabRef3}
                teamId={teamId}
                isEditing={isEditing}
                isSaving={isLoading}
                reset={reset}
                setIsLoading={setIsLoading}
                setIsEditing={setIsEditing}
                setTabMode={setTabMode}
                tabMode={tabMode}
              />
            </Tab>
            <Tab eventKey="coaches" title="Coaches">
              <TeamCoaches
                ref={tabRef4}
                teamId={teamId}
                isEditing={isEditing}
                isSaving={isLoading}
                reset={reset}
                setIsLoading={setIsLoading}
              />
            </Tab>
          </Tabs>
        </Row>
      </>
    );
  };

  const returnButton = () => {
    if (key === 'roster' && tabMode !== LIST) {
      return (
        <button
          type="button"
          className="linkButton"
          disabled={isLoading}
          onClick={() => tabback(tabRef2)}
        >
          Roster List
        </button>
      );
    }

    if (key === 'lineup' && tabMode !== LIST) {
      return (
        <button
          type="button"
          className="linkButton"
          disabled={isLoading}
          onClick={() => tabback(tabRef3)}
        >
          Lineup List
        </button>
      );
    }

    return (
      <button
        type="button"
        className="linkButton"
        disabled={isLoading}
        onClick={back}
      >
        Team list
      </button>
    );
  };

  const footer = () => {
    return (
      <>
        <Row className="vCenter" style={{ margin: '0 auto' }}>
          <Button
            variant="outline-primary"
            className="formButton"
            onClick={handleClick}
            disabled={isLoading}
          >
            {buttonText()}
          </Button>
        </Row>
        <Row>
          <div className="modalLink">
            <span>Back to</span>
            {returnButton()}
          </div>
        </Row>
      </>
    );
  };

  const headerMsg = () => {
    return (
      <>
        <span>Team Admin:&nbsp;</span>
        <span style={{ fontWeight: '400' }}>{teams.byId[teamId].name}</span>
      </>
    );
  };

  const modal = () => {
    return (
      <>
        <Modal.Header className="adminHeader">{headerMsg()}</Modal.Header>
        <Modal.Body className="adminForm">{body()}</Modal.Body>
        <Modal.Footer className="adminForm">{footer()}</Modal.Footer>
      </>
    );
  };

  return modal();
}

export default TeamDetail;
