import React, { useState, useEffect } from 'react';
import { Modal, Button, Row, Form, Spinner, Alert } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { alertActions, userActions } from '../../redux/_actions';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import InputAutofocus from '../helpers/inputautofocus';
import { useHistory } from 'react-router-dom';
import { useQueryParams } from '../../utilities/query';

function Verify() {
  const alert = useSelector((state) => state.alert);
  const user = useSelector((state) => state.user.user);
  const [code, setCode] = useState(null);
  const [resendCode, setResendCode] = useState(false);
  const [isVerifying, setIsVerifying] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const query = useQueryParams();

  useEffect(() => {
    if (!alert.clear) {
      setIsVerifying(false);
      setResendCode(false);
    }
  }, [alert.clear]);

  const handleResend = () => {
    setResendCode(!resendCode);
  };

  const handleChangeCode = (e) => {
    handleChange(e);
    setCode(e.target.value);
  };

  const validationSchema = Yup.object().shape({
    code: Yup.string()
      .required('Required')
      .matches(/^[0-9]{6}$/, 'Must be 6 digits'),
    email: Yup.string().email('Invalid email').required('Required'),
  });

  const resendSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email').required('Required'),
  });

  const {
    setFieldValue,
    handleSubmit,
    handleChange,
    values,
    errors,
  } = useFormik({
    initialValues: {
      code: code === null ? '' : code,
      email: user && user.username ? user.username : '',
    },
    validationSchema: resendCode ? resendSchema : validationSchema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit(values) {
      if (resendCode) {
        dispatch(userActions.requestVerificationCode(values.email));
      } else {
        setIsVerifying(true);
        dispatch(alertActions.clear());
        dispatch(userActions.verify(values.email, values.code, history));
      }
    },
  });

  // If comes with email parameter then means no user credentials
  useEffect(() => {
    if (query.get('email')) {
      setFieldValue('email', query.get('email'));
    }
  }, [setFieldValue, query]);

  const enterCodeBody = () => {
    return (
      <Form onSubmit={handleSubmit}>
        {!alert.clear ? (
          <Row>
            <Alert
              dismissible
              onClose={() => dispatch(alertActions.clear())}
              variant={alert.type === 'alert-danger' ? 'danger' : 'success'}
            >
              {alert.message}
            </Alert>
          </Row>
        ) : null}
        {user && user.username ? null : (
          <Row>
            <label>
              Email
              {errors.email ? (
                <span className="form-error">{errors.email}</span>
              ) : null}
            </label>
            {InputAutofocus({
              name: 'email',
              value: values.email,
              handler: handleChange,
              disabled: isVerifying,
            })}
          </Row>
        )}
        {
          <Row>
            <label>
              Verification Code
              {errors.code ? (
                <span className="form-error">{errors.code}</span>
              ) : null}
            </label>
            {InputAutofocus({
              name: 'code',
              value: resendCode ? 'Requesting new code...' : values.code,
              handler: handleChangeCode,
              disabled: isVerifying || resendCode,
              maxLength: '6',
              cancel: values.email === '',
            })}
            <div className="codeSpecs">Sent to your email.</div>
          </Row>
        }
        <Row>
          <Button
            variant="outline-primary"
            className="verifyButton"
            onClick={handleSubmit}
            disabled={isVerifying}
          >
            {isVerifying ? (
              <>
                {'Verifying code... '}
                <Spinner
                  variant="primary"
                  role="status"
                  animation="border"
                  size="sm"
                />
              </>
            ) : resendCode ? (
              'Resend Code'
            ) : (
              'Verify Code'
            )}
          </Button>
        </Row>
        <Row>
          <div className="modalLink">
            <span>{resendCode ? 'Back to' : 'Need to'}</span>
            <button
              type="button"
              className="linkButton"
              disabled={isVerifying}
              onClick={() => handleResend()}
            >
              {resendCode ? 'enter code' : 'resend code?'}
            </button>
          </div>
        </Row>
      </Form>
    );
  };

  const headerMsg = () => {
    return resendCode ? 'Resend code to email' : 'Verify your email';
  };

  return (
    <>
      <Modal.Header className="loginHeader">
        <span>{headerMsg()}</span>
      </Modal.Header>
      <Modal.Body className="loginForm">{enterCodeBody()}</Modal.Body>
    </>
  );
}

export default Verify;
