import { adminConstants, userConstants, uiState } from '../_constants';
import { alertActions } from '../_actions';
import { authService, analyticsService, adminService } from '../_services';
import { normalize, schema } from 'normalizr';
import { dataUtilities } from '../../utilities/data';

export const userActions = {
  login,
  logout,
  getPasswordResetCode,
  register,
  resetPassword,
  getUser,
  getUserProfile,
  fbLogin,
  googleLogin,
  verify,
  requestVerificationCode,
  getSessions,
  addSession,
};

// Normalized data schema
const session = new schema.Entity('sessions');

function fbLogin() {
  return (dispatch) => {
    dispatch(request());
    authService.fbLogin();
  };

  function request() {
    return { type: userConstants.FBLOGIN_REQUEST };
  }
}

function googleLogin() {
  return (dispatch) => {
    dispatch(request());
    authService.googleLogin();
  };

  function request() {
    return { type: userConstants.GOOGLELOGIN_REQUEST };
  }
}

function getUser() {
  return (dispatch) => {
    dispatch(request());
    authService
      .getUser()
      .then((user) => {
        dispatch(success(user));
        dispatch(userActions.getUserProfile(user.attributes.email));
      })
      .catch((error) => {
        dispatch(failure());
        dispatch(alertActions.error('Not Authenticated.'));
      });
  };

  function request() {
    return { type: userConstants.GETUSER_REQUEST };
  }
  function success(user) {
    return { type: userConstants.GETUSER_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.GETUSER_FAILURE };
  }
}

function getUserProfile(email) {
  return (dispatch) => {
    dispatch(request());
    authService
      .getUserByEmail(email)
      .then((res) => {
        if (res.data.UserByEmail.items.length > 0) {
          dispatch(success(res.data.UserByEmail.items[0]));
        } else {
          throw new Error(`Cannot find user profile for ${email}`);
        }
      })
      .catch((error) => {
        dispatch(failure());
        dispatch(alertActions.error('Error retrieving user profile.'));
      });
  };

  function request() {
    return { type: userConstants.USER_BY_EMAIL_REQUEST };
  }
  function success(profile) {
    return { type: userConstants.USER_BY_EMAIL_SUCCESS, profile };
  }
  function failure(error) {
    return { type: userConstants.USER_BY_EMAIL_FAILURE };
  }
}

function login(values, history) {
  return (dispatch) => {
    dispatch(request());
    authService
      .logIn(values)
      .then((user) => {
        analyticsService.login(values.email);
        dispatch(success(user));
        dispatch(userActions.getUserProfile(user.attributes.email));
      })
      .catch((error) => {
        console.log(error);
        dispatch(failure());
        switch (error.code) {
          case 'NotAuthorizedException':
            if (error.message === 'User is disabled') {
              error.message = 'Account is disabled.';
            } else if (error.message === 'Incorrect username or password.') {
              error.message = 'Password incorrect.';
            } else if (error.message === 'User does not exist.') {
              error.message = 'Account does not exist.';
            }
            break;
          case 'UserNotFoundException':
            error.message = 'Email not registered.';
            break;
          case 'UserNotConfirmedException':
            error.message = 'Email not verified.';
            history.push(`${uiState.VERIFY}?email=${values.email}`);
            return;
          default:
            error.message = 'Login error.';
            break;
        }

        dispatch(alertActions.error(error.message));
      });
  };

  function request() {
    return { type: userConstants.LOGIN_REQUEST };
  }
  function success(user) {
    return { type: userConstants.LOGIN_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.LOGIN_FAILURE, error };
  }
}

function logout() {
  return (dispatch) => {
    dispatch(request());
    authService
      .logOut()
      .then((res) => {
        dispatch(success());
      })
      .catch((error) => {
        dispatch(failure());
        dispatch(alertActions.error(error.message));
      });
  };

  function request() {
    return { type: userConstants.LOGOUT_REQUEST };
  }
  function success() {
    return { type: userConstants.LOGOUT_SUCCESS };
  }
  function failure(error) {
    return { type: userConstants.LOGOUT_FAILURE };
  }
}

function getPasswordResetCode(email, history) {
  return (dispatch) => {
    dispatch(request());
    authService
      .getPasswordResetCode(email)
      .then((res) => {
        dispatch(success());
        dispatch(alertActions.success('Reset password code has been sent.'));
        history.push(`${uiState.RESET_PW}?email=${email}`);
      })
      .catch((error) => {
        dispatch(failure());
        console.log(error);
        switch (error.code) {
          case 'UserNotFoundException':
            error.message = 'Account not found.';
            break;
          case 'LimitExceededException':
            error.message = 'Attempt limit exceeded, try again later.';
            break;
          case 'InvalidParameterException':
            error.message = 'Verify email first, then request code again.';
            break;
          default:
            error.message = 'Code request error.';
            break;
        }

        dispatch(alertActions.error(error.message));
      });
  };

  function request() {
    return { type: userConstants.CODE_REQUEST };
  }
  function success() {
    return { type: userConstants.CODE_SUCCESS };
  }
  function failure(error) {
    return { type: userConstants.CODE_FAILURE };
  }
}

function register(values, history) {
  return (dispatch) => {
    dispatch(request());
    authService
      .register(values)
      .then((res) => {
        console.log(res);
        analyticsService.signup(values.email);
        dispatch(success(res.user));
        history.push(uiState.VERIFY);
      })
      .catch((error) => {
        dispatch(failure());

        console.log(error);
        switch (error.code) {
          case 'UsernameExistsException':
            error.message = 'Email already registered.';
            break;
          default:
            error.message = 'Registration error.';
            break;
        }

        dispatch(alertActions.error(error.message));
      });
  };

  function request() {
    return { type: userConstants.REGISTER_REQUEST };
  }
  function success(user) {
    return { type: userConstants.REGISTER_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.REGISTER_FAILURE };
  }
}

function verify(username, code, history) {
  return (dispatch) => {
    dispatch(request());
    authService
      .verify(username, code)
      .then((res) => {
        console.log(res);
        dispatch(success(res));
        // Hard-refresh because there's something in the state machine
        // that's sending us back to /verify if we only history.push/replace
        //window.location = uiState.LOGIN;
        //dispatch(alertActions.success('Email verified. Please login.'));
        history.push(uiState.LOGIN);
      })
      .catch((error) => {
        dispatch(failure());

        console.log(error);
        switch (error.code) {
          case 'NotAuthorizedException':
            if (
              error.message ===
              'User cannot be confirmed. Current status is CONFIRMED'
            ) {
              error.message = 'Email already confirmed.';
            }
            break;
          case 'UserNotFoundException':
            error.message = 'Email not registered.';
            break;
          case 'CodeMismatchException':
            error.message = 'Invalid verification code.';
            break;
          default:
            error.message = 'Verification error.';
            break;
        }

        dispatch(alertActions.error(error.message));
      });
  };

  function request() {
    return { type: userConstants.VERIFY_REQUEST };
  }
  function success() {
    return { type: userConstants.VERIFY_SUCCESS };
  }
  function failure(error) {
    return { type: userConstants.VERIFY_FAILURE };
  }
}

function requestVerificationCode(username) {
  return (dispatch) => {
    dispatch(request());
    authService
      .requestVerificationCode(username)
      .then((res) => {
        console.log(res);
        dispatch(success(res));
        dispatch(alertActions.success('New verification code has been sent.'));
      })
      .catch((error) => {
        dispatch(failure());

        switch (error.code) {
          case 'UsernameExistsException':
            error.message = 'Email already registered.';
            break;
          case 'UserNotFoundException':
            error.message = 'Email not found. Please sign up.';
            break;
          default:
            error.message = 'Resend verification code error.';
            break;
        }

        dispatch(alertActions.error(error.message));
      });
  };

  function request() {
    return { type: userConstants.REQUEST_VERIFICATION_CODE_REQUEST };
  }
  function success() {
    return { type: userConstants.REQUEST_VERIFICATION_CODE_SUCCESS };
  }
  function failure(error) {
    return { type: userConstants.REQUEST_VERIFICATION_CODE_FAILURE };
  }
}

function resetPassword(username, password, code) {
  return (dispatch) => {
    dispatch(request());
    authService
      .resetPassword(username, password, code)
      .then((res) => {
        dispatch(success(res));
        dispatch(alertActions.success('Password reset.'));
      })
      .catch((error) => {
        dispatch(failure());

        switch (error.code) {
          case 'UserNotFoundException':
            error.message = 'Email not found. Please sign up.';
            break;
          case 'ExpiredCodeException':
            error.message = 'Invalid code. Request a new code.';
            break;
          default:
            error.message = 'Reset password error.';
            break;
        }

        dispatch(alertActions.error(error.message));
      });
  };

  function request() {
    return { type: userConstants.RESET_PASSWORD_REQUEST };
  }
  function success() {
    return { type: userConstants.RESET_PASSWORD_SUCCESS };
  }
  function failure(error) {
    return { type: userConstants.RESET_PASSWORD_FAILURE };
  }
}

function getSessions() {
  return (dispatch) => {
    dispatch(request());
    adminService
      .loadSessions()
      .then((res) => {
        console.log(res);
        const normalizedSessions = normalize(res.data.listSessions.items, [
          session,
        ]);
        //const { sessionTeams, streams } = normalizedSessions.entities;      // note teams here are SessionTeams
        //const normalizedSessionTeams = sessionTeams ? normalize(sessionTeams, [sessionTeam]) : null;
        //const normalizedStreams = streams ? normalize(streams, [stream]) : null;

        const sortedResult = dataUtilities.sort(
          adminConstants.SORT_NAME_DESC,
          normalizedSessions.entities.sessions,
          normalizedSessions.result
        );
        const sortedSessions = { ...normalizedSessions, result: sortedResult };
        dispatch(
          success({
            sessions: sortedSessions,
            //sessionTeams: normalizedSessionTeams,
            //streams: normalizedStreams,
          })
        );
      })
      .catch((error) => {
        console.log(error);
        dispatch(failure(error.toString()));
        dispatch(alertActions.error('Error loading sessions.'));
      });
  };

  function request() {
    return { type: userConstants.GET_SESSIONS_REQUEST };
  }
  function success(data) {
    return { type: userConstants.GET_SESSIONS_SUCCESS, data };
  }
  function failure() {
    return { type: userConstants.GET_SESSIONS_FAILURE };
  }
}

function addSession(data) {
  return { type: userConstants.ADD_SESSION_SUCCESS, data };
}
