import {
  producerConstants,
  exportDataHeaders,
  defaultEvaluatorConfig,
  emptyTeam,
  emptyLineup,
  sessionRoleType,
  sessionStatus,
} from '../_constants';
import { store } from '../store';
import { displayName } from '../../utilities/conversions';
import { colorSelector } from '../../utilities/session';
import { alertActions, sessionActions, videoPlayerActions } from '../_actions';

export const producerActions = {
  initialize,
  reset,
  onAir,
  onEval,
  cancelEval,
  offEval,
  setEnd,
  exportData,
  requestInquiry,
  resolveInquiry,
  live,
  changeRound,
  edit,
  updateLineup,
  judge,
  clip,
  showScores,
  sortLineup,
  syncProducer,
  force,
  forceSelect,
  resetLineup,
};

function reset(data) {
  const cleanData = {};
  Object.assign(cleanData, data);

  // Reset all scores
  cleanData.teamA.roster = data.teamA.roster.map((a) => {
    return {
      name: a.name,
      score: null,
      startTime: a.startTime,
      endTime: a.endTime,
      evaluation: null,
      id: a.id,
    };
  });
  cleanData.teamB.roster = data.teamB.roster.map((a) => {
    return {
      name: a.name,
      score: null,
      startTime: a.startTime,
      endTime: a.endTime,
      evaluation: null,
      id: a.id,
    };
  });

  // Configure export header
  const initHeaders = initExportHeader(data.evaluatorConfig);
  cleanData.exportHeader = initHeaders;

  return { type: producerConstants.RESET, data: cleanData };
}

function judge(judge) {
  return { type: producerConstants.JUDGE, judge };
}

function clip(clip) {
  return { type: producerConstants.CLIP, clip };
}

function edit(edit) {
  return { type: producerConstants.EDIT, edit };
}

function live(live) {
  const {
    doneEvalAthletes,
    teamA,
    teamB,
    completedRounds,
    round,
  } = store.getState().producer;

  // Check if this is completing a round
  const newCompletedRounds = [...completedRounds];

  if (
    !live && // turning off a live round
    teamA.lineup.length === doneEvalAthletes.A.length &&
    teamB.lineup.length === doneEvalAthletes.B.length &&
    !completedRounds.includes(round)
  ) {
    newCompletedRounds.push(round);
  }

  return (dispatch) => {
    dispatch(success());
    dispatch(
      sessionActions.updateProducer({
        live,
        completedRounds: newCompletedRounds,
      })
    );
  };

  function success(data) {
    return {
      type: producerConstants.LIVE,
      live,
      completedRounds: newCompletedRounds,
    };
  }
}

function onAir(home = true) {
  const data = { team: home ? 'teamA' : 'teamB' };

  return (dispatch) => {
    dispatch(success(data));
    dispatch(
      sessionActions.updateProducer({
        onAir: true,
        [data.team]: { onAir: true },
      })
    );
  };

  function success(data) {
    return { type: producerConstants.ON_AIR, data };
  }
}

function onEval(home = true, onAirAthlete, competition = false) {
  const { onEvalAthletes } = store.getState().producer;
  const newOnEvalAthletes = { ...onEvalAthletes };
  newOnEvalAthletes[home ? 'A' : 'B'] = onAirAthlete;

  const data = {
    team: home ? 'teamA' : 'teamB',
    onEvalAthletes: newOnEvalAthletes,
    onAirAthlete: onAirAthlete + (competition ? 1 : 0), // VOD makes both onAir/onEval athlete the same
  };

  return (dispatch) => {
    dispatch(success(data));
    dispatch(
      sessionActions.updateProducer({
        onAir: false,
        onAirAthlete: data.onAirAthlete,
        onEvalAthletes: data.onEvalAthletes,
        [data.team]: { onAir: false, onEval: true },
      })
    );
  };

  function success(data) {
    return { type: producerConstants.ON_EVAL, data };
  }
}

function cancelEval(home = true) {
  const { onEvalAthletes } = store.getState().producer;
  const newOnEvalAthletes = { ...onEvalAthletes };
  newOnEvalAthletes[home ? 'A' : 'B'] = null;

  const data = {
    team: home ? 'teamA' : 'teamB',
    onEvalAthletes: newOnEvalAthletes,
  };

  return (dispatch) => {
    dispatch(success(data));
    dispatch(
      sessionActions.updateProducer({
        onAir: false,
        onEvalAthletes: data.onEvalAthletes,
        [data.team]: { onAir: false, onEval: false },
      })
    );
  };

  function success(data) {
    return { type: producerConstants.CANCEL_EVAL, data };
  }
}

function offEval(
  home = true,
  scoreData,
  inquiry = false,
  competitionLive = false // from producer.live && session.competition
) {
  const {
    onEvalAthletes,
    teamA,
    teamB,
    doneEvalAthletes,
  } = store.getState().producer;
  const { evaluator, session, producer } = store.getState(); // TODO: eventaully get away from other state mutation
  const force = store.getState().producer.force;
  const { lineups } = session.sessionData;
  const evalPanel = evaluator[home ? 'evalPanelA' : 'evalPanelB'];
  const team = home ? teamA : teamB;
  const newOnEvalAthletes = { ...onEvalAthletes };
  const newDoneEvalAthletes = { ...doneEvalAthletes };
  // Need to handle case where B finishes before A
  const index = competitionLive
    ? parseInt(newOnEvalAthletes[home ? 'A' : 'B'] / 2) - (home ? 0 : 1)
    : onEvalAthletes[home ? 'A' : 'B'];
  newOnEvalAthletes[home ? 'A' : 'B'] = null; // Reset eval athlete

  const prevData = lineups.byId?.[team.lineupId].lineupData
    ? JSON.parse(lineups.byId?.[team.lineupId].lineupData)
    : {};
  const lineup =
    prevData[producer.round] ?? JSON.parse(JSON.stringify(emptyLineup));
  const newPanel = JSON.parse(JSON.stringify(evalPanel));

  if (inquiry) {
    newPanel.flags.resolved = true;
  }

  newPanel.finalScore[newPanel.finalScore.length - 1].locked = true;

  // TODO: Need to make sure in competition alternative index is ok

  /*
  console.log(lineups)
  console.log(team.lineupId)
  console.log(prevData)
  console.log(lineup)
  */
  //console.log(prevData);
  //console.log(lineup);

  lineup[index]['evaluation'] = { ...scoreData, evalPanel: newPanel };
  lineup[index]['score'] = scoreData.score;

  // Checklist of eval athletes only for non-inquiry & non-force
  if (!inquiry && !force) {
    newDoneEvalAthletes[home ? 'A' : 'B'].push(index);
  }

  const data = {
    team: home ? 'teamA' : 'teamB',
    lineup: lineup,
    onEvalAthletes: newOnEvalAthletes,
    doneEvalAthletes: newDoneEvalAthletes,
  };

  const newData = { ...prevData, [producer.round]: lineup };

  const lineupPayload = {
    id: team.lineupId,
    lineupData: JSON.stringify(newData),
    _version: session.sessionData.lineups.byId[team.lineupId]._version,
    order: session.sessionData.lineups.byId[team.lineupId].order,
  };

  return (dispatch) => {
    try {
      dispatch(success(data));
      dispatch(sessionActions.updateLineup(lineupPayload));
      dispatch(
        sessionActions.updateProducer({
          onEvalAthletes: data.onEvalAthletes,
          doneEvalAthletes: data.doneEvalAthletes,
          [data.team]: { onEval: false },
        })
      );
    } catch (error) {
      dispatch(alertActions.error(error));
    }
  };

  function success(data) {
    return { type: producerConstants.OFF_EVAL, data };
  }
}

// Data can be preloaded for client demo
function initialize(data = null, teamData = null, syncData = null) {
  // teamData format comes from session load/action
  const { session } = store.getState();
  const { ADMIN, JUDGE, PRODUCER, FAN, COACH, ANNOUNCER } = sessionRoleType;
  const normalizedData = session.sessionData; // normalizedData
  const startingRound =
    syncData && syncData.round && session.status === sessionStatus.LIVE
      ? syncData.round
      : session.apparatus.indexOf('1') + 1;

  let initData = null;
  const teamA = teamData.length > 0 ? teamData[0] : null;
  const teamB = teamData.length > 1 ? teamData[1] : null;

  // Roster assignment will either default to latest, or look for next previous roster with active date < event date
  // Possible no roster allocated if is completely open session, no teams assigned
  let rosterAIndex = 0;
  if (teamA?.rosters?.items) {
    for (let m = 0; m < teamA.rosters.items.length; m++) {
      if (
        session.startAt >=
        normalizedData.rosters.byId[teamA.rosters.items[m].id].activeDate
      ) {
        rosterAIndex = m;
        break;
      }
    }
  }

  let rosterBIndex = 0;
  if (teamB?.rosters?.items) {
    for (let n = 0; n < teamB.rosters.items.length; n++) {
      if (
        session.startAt >=
        normalizedData.rosters.byId[teamB.rosters.items[n].id].activeDate
      ) {
        rosterBIndex = n;
        break;
      }
    }
  }

  const teamARoster =
    teamA && teamA.rosters && teamA.rosters.items && teamA.rosters.items.length
      ? normalizedData.rosters.byId[
          teamA.rosters.items[rosterAIndex].id
        ].athletes.items
          .map(
            (el) =>
              normalizedData.athletes.byId[
                normalizedData.rosterLinks.byId[el].athleteId
              ]
          )
          .sort((a, b) => {
            if (a.name.toUpperCase() < b.name.toUpperCase()) {
              return -1;
            }
            if (a.name.toUpperCase() > b.name.toUpperCase()) {
              return 1;
            }
            return 0;
          })
      : null;
  const teamBRoster =
    teamB && teamB.rosters && teamB.rosters.items && teamB.rosters.items.length
      ? normalizedData.rosters.byId[
          teamB.rosters.items[rosterBIndex].id
        ].athletes.items
          .map(
            (el) =>
              normalizedData.athletes.byId[
                normalizedData.rosterLinks.byId[el].athleteId
              ]
          )
          .sort((a, b) => {
            if (a.name.toUpperCase() < b.name.toUpperCase()) {
              return -1;
            }
            if (a.name.toUpperCase() > b.name.toUpperCase()) {
              return 1;
            }
            return 0;
          })
      : null;

  // Need to handle case where both teams are the same
  const teamALineupId =
    session.lineups.items.find(
      (el) => normalizedData.sessionTeams.byId[el.sessionTeamId]?.order === 0
    )?.id ?? null;
  const teamBLineupId =
    session.lineups.items.find(
      (el) => normalizedData.sessionTeams.byId[el.sessionTeamId]?.order === 1
    )?.id ?? null;

  // Should load Solo/Dual details
  // Load team lineups for round
  // any changes for live vs vod session

  //console.log(teamData)
  //console.log(teamALineupId)
  //console.log(normalizedData.lineups.byId[teamALineupId])

  if (data !== null) {
    initData = {
      ...data,
      exportHeader: initExportHeader(defaultEvaluatorConfig),
    };
  } else {
    const teamAColor = teamA ? JSON.parse(teamA.colors)[0] : emptyTeam.color;

    initData = {
      teamA: {
        ...Object.assign({}, JSON.parse(JSON.stringify(emptyTeam))),
        home: true,
        index: 0,
        name: teamA ? displayName(teamA.name, teamA.altNames) : emptyTeam.name,
        color: teamAColor,
        logo: teamA
          ? JSON.parse(teamA.logos)?.metaData.filename
          : emptyTeam.logo,
        teamId: teamA ? teamA.id : null,
        roster: teamARoster ? teamARoster : [],
        lineup:
          (teamALineupId &&
            normalizedData.lineups.byId[teamALineupId].lineupData &&
            JSON.parse(normalizedData.lineups.byId[teamALineupId].lineupData)?.[
              startingRound
            ]) ??
          JSON.parse(JSON.stringify(emptyLineup)), // this lineup is just single round lineup
        lineupId: teamA ? teamALineupId : null,
      },
      teamB: {
        ...Object.assign({}, JSON.parse(JSON.stringify(emptyTeam))),
        index: 1,
        name: teamB ? displayName(teamB.name, teamB.altNames) : emptyTeam.name,
        color: teamB
          ? colorSelector(teamAColor, JSON.parse(teamB.colors))
          : emptyTeam.color,
        logo: teamB
          ? JSON.parse(teamB.logos).metaData.filename
          : emptyTeam.logo,
        teamId: teamB ? teamB.id : null,
        roster: teamBRoster ? teamBRoster : [],
        lineup:
          (teamBLineupId &&
            normalizedData.lineups.byId[teamBLineupId].lineupData &&
            JSON.parse(normalizedData.lineups.byId[teamBLineupId].lineupData)?.[
              startingRound
            ]) ??
          JSON.parse(JSON.stringify(emptyLineup)), // this lineup is just single round lineup
        lineupId: teamB ? teamBLineupId : null,
      },
      round: startingRound, // need to add to schema
      loaded: true,
      onAir: false,
      edit: false,
      clip: false,
      judge: false,
      perTeam: 5, // should be based on lineups?  or some format defaults
      onAirAthlete: 1,
      onEvalAthletes: { A: null, B: null },
      exportData: null,
      completedRounds: [],
      live: false,
      scores: session.status !== sessionStatus.POSTGAME,
      exportHeader: initExportHeader(defaultEvaluatorConfig),
    };
  }

  // Update and Tack on for live producer state
  if (syncData && session.status === sessionStatus.LIVE) {
    initData = {
      ...initData,
      ...syncData,
      teamA: {
        ...initData.teamA,
        ...syncData.teamA,
      },
      teamB: {
        ...initData.teamB,
        ...syncData.teamB,
      },
    };
  }

  return (dispatch) => {
    dispatch(success(initData));
    if (session.status === sessionStatus.POSTGAME) {
      const lineupA1 = initData?.teamA?.lineup[0]?.startTime;
      const lineupB1 = initData?.teamB?.lineup[0]?.startTime;
      const startATime = lineupA1 && lineupA1 - 30 > 0 ? lineupA1 - 30 : 0;
      const startBTime = lineupB1 && lineupB1 - 30 > 0 ? lineupB1 - 30 : 0;
      dispatch(videoPlayerActions.seek(startATime, 'A', false));
      dispatch(videoPlayerActions.seek(startBTime, 'B', false));
    }
  };

  function success(data) {
    return { type: producerConstants.INITIALIZE, data };
  }
}

// Internal function to configure export headers w/ evaluator config
function initExportHeader(evalConfig) {
  const dHeaders = evalConfig.dPanel.map(
    (item, i) => `D${evalConfig.dPanel.length > 1 ? i + 1 : ''}`
  );
  const eHeaders = evalConfig.ePanel.map(
    (item, i) => `E${evalConfig.ePanel.length > 1 ? i + 1 : ''}`
  );
  const initHeaders = [...exportDataHeaders, ...dHeaders, ...eHeaders];
  return initHeaders;
}

function setEnd(home, onAirAthlete) {
  const { producer, videoPlayer } = store.getState();
  const team = producer[`team${home ? 'A' : 'B'}`];
  /*
  const index = parseInt(onAirAthlete / 2) - (home ? 0 : 1);
  const time = videoPlayer[`player${ home ? 'A' : 'B'}`]?.ref?._player.currentTime;
  const lineup = [...team.lineup];
  const lineupEntry = {...lineup[index]};
  lineupEntry.endTime = time;
  lineup[index] = lineupEntry;
  */
  const data = {
    team: home ? 'teamA' : 'teamB',
    //lineup: lineup
  };

  return { type: producerConstants.SET_END, data };
}

function exportData(data) {
  return { type: producerConstants.EXPORT_DATA, data };
}

function requestInquiry(home, index) {
  const { producer } = store.getState();
  const team = producer[`team${home ? 'A' : 'B'}`];
  const lineup = JSON.parse(JSON.stringify(team.lineup));
  const lineupEntry = JSON.parse(JSON.stringify(lineup[index]));
  lineupEntry.evaluation.evalPanel.flags.inquiry = true;
  lineup[index] = lineupEntry;

  const data = {
    team: home ? 'teamA' : 'teamB',
    lineup: lineup,
  };

  return { type: producerConstants.REQUEST_INQUIRY, data };
}

function resolveInquiry(home, index) {
  // index is array index 0-4
  const { producer } = store.getState();
  const newOnEvalAthletes = { ...producer.onEvalAthletes };
  const newIndex = index * 2 + (home ? 1 : 2); // 1-10 by order
  newOnEvalAthletes[home ? 'A' : 'B'] = newIndex;

  const data = {
    team: home ? 'teamA' : 'teamB',
    onEvalAthletes: newOnEvalAthletes,
  };

  return { type: producerConstants.RESOLVE_INQUIRY, data };
}

function changeRound(newRound, complete = false) {
  const {
    round,
    teamA,
    teamB,
    onAirAthlete,
    doneEvalAthletes,
    completedRounds,
    live,
  } = store.getState().producer;
  const { sessionData, status } = store.getState().session;

  const data = {
    teamALineup: null,
    teamBLineup: null,
    round: newRound,
    onAirAthlete:
      !live && !completedRounds.includes(newRound) ? 1 : onAirAthlete, // reset on new round and when off live
    doneEvalAthletes:
      !live && !completedRounds.includes(newRound)
        ? { A: [], B: [] }
        : doneEvalAthletes,
  };

  // Change to new lineup for both teams
  if (teamA) {
    data.teamALineup =
      (teamA.lineupId &&
        sessionData.lineups.byId[teamA.lineupId].lineupData &&
        JSON.parse(sessionData.lineups.byId[teamA.lineupId].lineupData)?.[
          newRound
        ]) ??
      JSON.parse(JSON.stringify(emptyLineup));
  }

  if (teamB) {
    data.teamBLineup =
      (teamB.lineupId &&
        sessionData.lineups.byId[teamB.lineupId].lineupData &&
        JSON.parse(sessionData.lineups.byId[teamB.lineupId].lineupData)?.[
          newRound
        ]) ??
      JSON.parse(JSON.stringify(emptyLineup));
  }

  return (dispatch) => {
    dispatch(success(data));
    if (status === sessionStatus.POSTGAME) {
      const lineupA1 = data?.teamALineup?.[0]?.startTime;
      const lineupB1 = data?.teamBLineup?.[0]?.startTime;
      const startATime = lineupA1 && lineupA1 - 30 > 0 ? lineupA1 - 30 : 0;
      const startBTime = lineupB1 && lineupB1 - 30 > 0 ? lineupB1 - 30 : 0;
      dispatch(videoPlayerActions.seek(startATime, 'A', false));
      dispatch(videoPlayerActions.seek(startBTime, 'B', false));
    }
  };

  function success(data) {
    return { type: producerConstants.CHANGE_ROUND, data: data };
  }
}

function updateLineup(lineup) {
  // where team is 'A' or 'B'
  const { producer } = store.getState();
  const { teamA, teamB } = producer;
  const newLineup = JSON.parse(lineup.lineupData)[producer.round];

  // need to check if the updated lineup round is actually found, could be undefined

  return (dispatch) => {
    try {
      if (teamA && teamA.lineupId && teamA.lineupId === lineup.id) {
        dispatch(success({ team: 'A', newLineup })); // if newLineup is undefined don't change check in reducer
      } else if (teamB && teamB.lineupId && teamB.lineupId === lineup.id) {
        dispatch(success({ team: 'B', newLineup })); // if newLineup is undefined don't change check in reducer
      } else {
        throw new Error('LineupId not found in Team object.');
      }
    } catch (error) {
      dispatch(failure());
      dispatch(alertActions.error(error));
    }
  };

  function success(data) {
    return { type: producerConstants.UPDATE_LINEUP_SUCCESS, data };
  }
  function failure(error) {
    return { type: producerConstants.UPDATE_LINEUP_FAILURE };
  }
}

function showScores() {
  return { type: producerConstants.SHOW_SCORES };
}

function sortLineup() {
  return { type: producerConstants.SORT_LINEUP };
}

function resetLineup() {
  return { type: producerConstants.RESET_LINEUP };
}

function syncProducer(data) {
  // need to check update to round and thus lineup
  const round = store.getState().producer.round;

  return (dispatch) => {
    if (round !== data.round) {
      dispatch(producerActions.changeRound(data.round));
      dispatch(alertActions.sync(`Change to R${data.round}.`));
    }
    dispatch(success(data));
  };

  function success(data) {
    return { type: producerConstants.SYNC_PRODUCER, data };
  }
}

function force() {
  return { type: producerConstants.FORCE };
}

function forceSelect(side, index) {
  const data = {
    onAir: false,
    onEvalAthletes: { A: null, B: null },
    force: false,
    onAirAthlete: side === 'A' ? index * 2 + 1 : index * 2 + 2,
  };

  return { type: producerConstants.FORCE_SELECT, data };
}
