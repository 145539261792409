import { API, graphqlOperation, Storage } from 'aws-amplify';
import {
  getTeams,
  getLeagues,
  getUsers,
  updateLeague,
  getAthletes,
  getSessions,
  createLeague,
  createAthlete,
  updateUser,
  createTeam,
  updateTeam,
  updateAthlete,
  createRoster,
  updateRoster,
  createRosterLink,
  updateRosterLink,
  updateStream,
} from '../../graphql/graphql';
import { nanoid } from 'nanoid';
import { store } from '../store';
import { DataStore, Predicates } from '@aws-amplify/datastore';
import { RosterLink } from '../../models';

export const adminService = {
  loadTeams,
  loadLeagues,
  addLeague,
  addTeam,
  uploadPhoto,
  uploadPrivatePhoto,
  loadSessions,
  loadAthletes,
  loadUsers,
  editTeam,
  addAthlete,
  editAthlete,
  addRoster,
  editRoster,
  editLeague,
  updateStreams,
  editUser,
};

async function loadUsers() {
  return await API.graphql(graphqlOperation(getUsers, { limit: 1000 }));
}

async function loadAthletes() {
  return await API.graphql(graphqlOperation(getAthletes, { limit: 1000 }));
}

async function loadSessions() {
  return await API.graphql(
    graphqlOperation(getSessions, {
      limit: 1000,
      filter: { status: { ne: 'DELETED' } },
    })
  );
}

async function loadTeams(leagueId = null) {
  return await API.graphql(graphqlOperation(getTeams));
}

async function loadLeagues() {
  return await API.graphql(graphqlOperation(getLeagues));
}

async function addLeague(input) {
  const { altNames, logos, colors } = input;
  const { username } = store.getState().user;
  const id = nanoid(6);

  console.log('in addleague');
  console.log(input);

  let imgMeta = null;
  //var imgUploadRequest = null;

  if (logos !== null) {
    let imgId = nanoid();

    imgMeta = {
      metaData: {
        type: 'league',
        typeid: id,
        id: imgId,
        owner: username,
        originalname: logos.name,
        size: logos.size,
        imgtype: logos.type,
        filename: `upload/images/league/${imgId}`,
      },
    };
  }

  const JSONinput = {
    ...input,
    altNames: altNames.length > 0 ? JSON.stringify(altNames) : null,
    logos: logos ? JSON.stringify(imgMeta) : null,
    colors: colors ? JSON.stringify(colors) : null,
    id: id,
  };

  console.log(JSONinput);

  return await API.graphql(graphqlOperation(createLeague, { input: JSONinput }))
    .then((res) => {
      const imgRes = logos ? uploadPhoto(logos, imgMeta) : null;
      console.log('image uploading');
      return [res, imgRes];
    })
    .catch((error) => {
      return error;
    });
}

async function uploadPrivatePhoto(file, meta) {
  return await Storage.vault.put(meta.metaData.filename, file, meta);
}

async function uploadPhoto(file, meta) {
  return await Storage.put(meta.metaData.filename, file, meta);
}

async function addTeam(input) {
  const { altNames, logos, colors } = input;
  const { username } = store.getState().user;
  const id = nanoid();
  let imgMeta = null;
  //var imgUploadRequest = null;

  if (logos !== null) {
    let imgId = nanoid();

    imgMeta = {
      metaData: {
        type: 'team',
        typeid: id,
        id: imgId,
        owner: username,
        originalname: logos.name,
        size: logos.size,
        imgtype: logos.type,
        filename: `upload/images/team/${imgId}`,
      },
    };
  }

  const JSONinput = {
    ...input,
    altNames: altNames.length > 0 ? JSON.stringify(altNames) : null,
    logos: logos ? JSON.stringify(imgMeta) : null,
    colors: colors ? JSON.stringify(colors) : null,
    id: id,
  };

  return await API.graphql(graphqlOperation(createTeam, { input: JSONinput }))
    .then((res) => {
      const imgRes = logos ? uploadPhoto(logos, imgMeta) : null;
      console.log('image uploading');
      return [res, imgRes];
    })
    .catch((error) => {
      return error;
    });
}

async function editTeam(input, changedImg = false) {
  const { altNames, logos, colors, id, _version } = input;
  const { username } = store.getState().user;
  let imgMeta = null;

  if (changedImg && logos !== null) {
    let imgId = nanoid();

    imgMeta = {
      metaData: {
        type: 'team',
        typeid: id,
        id: imgId,
        owner: username,
        originalname: logos.name,
        size: logos.size,
        imgtype: logos.type,
        filename: `upload/images/team/${imgId}`,
      },
    };
  }

  const JSONinput = {
    ...input,
    altNames: altNames.length > 0 ? JSON.stringify(altNames) : null,
    logos: changedImg
      ? JSON.stringify(imgMeta)
      : logos
      ? JSON.stringify(logos)
      : null,
    colors: colors ? JSON.stringify(colors) : null,
    id: id,
    _version: _version,
  };

  return await API.graphql(graphqlOperation(updateTeam, { input: JSONinput }))
    .then((res) => {
      const imgRes = changedImg && logos ? uploadPhoto(logos, imgMeta) : null;
      console.log('image uploading');
      return [res, imgRes];
    })
    .catch((error) => {
      return error;
    });
}

async function addAthlete(input) {
  const { altNames, profileImg, colors, teamAffiliations } = input;
  const { username } = store.getState().user;
  const id = nanoid();
  let imgMeta = null;
  //var imgUploadRequest = null;

  if (profileImg !== null) {
    let imgId = nanoid();

    imgMeta = {
      metaData: {
        type: 'athlete',
        typeid: id,
        id: imgId,
        owner: username,
        originalname: profileImg.name,
        size: profileImg.size,
        imgtype: profileImg.type,
        filename: `upload/images/athlete/${imgId}`,
      },
    };
  }

  const JSONinput = {
    ...input,
    altNames: altNames.length > 0 ? JSON.stringify(altNames) : null,
    profileImg: profileImg ? JSON.stringify(imgMeta) : null,
    colors: colors ? JSON.stringify(colors) : null,
    teamAffiliations:
      teamAffiliations && teamAffiliations.length > 0
        ? JSON.stringify(teamAffiliations)
        : null,
    id: id,
  };

  return await API.graphql(
    graphqlOperation(createAthlete, { input: JSONinput })
  )
    .then((res) => {
      const imgRes = profileImg ? uploadPhoto(profileImg, imgMeta) : null;
      console.log('image uploading');
      return [res, imgRes];
    })
    .catch((error) => {
      return error;
    });
}

async function editAthlete(input, changedImg = false) {
  const {
    altNames,
    profileImg,
    colors,
    teamAffiliations,
    id,
    _version,
  } = input;
  const { username } = store.getState().user;
  let imgMeta = null;

  if (changedImg && profileImg !== null) {
    let imgId = nanoid();

    imgMeta = {
      metaData: {
        type: 'athlete',
        typeid: id,
        id: imgId,
        owner: username,
        originalname: profileImg.name,
        size: profileImg.size,
        imgtype: profileImg.type,
        filename: `upload/images/athlete/${imgId}`,
      },
    };
  }

  const JSONinput = {
    ...input,
    altNames: altNames.length > 0 ? JSON.stringify(altNames) : null,
    profileImg: changedImg
      ? JSON.stringify(imgMeta)
      : profileImg
      ? JSON.stringify(profileImg)
      : null,
    colors: colors ? JSON.stringify(colors) : null,
    teamAffiliations:
      teamAffiliations && teamAffiliations.length > 0
        ? JSON.stringify(teamAffiliations)
        : null,
    id: id,
    _version: _version,
  };

  return await API.graphql(
    graphqlOperation(updateAthlete, { input: JSONinput })
  )
    .then((res) => {
      const imgRes =
        profileImg && changedImg ? uploadPhoto(profileImg, imgMeta) : null;
      return [res, imgRes];
    })
    .catch((error) => {
      return error;
    });
}

async function addRoster(input) {
  return await API.graphql(graphqlOperation(createRoster, { input: input }));
}

async function editRoster(input) {
  // Check for title changes (activeDate / title)
  const { title, table, roster, newData } = input;

  // Save each item old fashioned way
  const createBatch =
    table &&
    table.creates &&
    Promise.all(
      table.creates.map((i) => {
        return API.graphql(
          graphqlOperation(createRosterLink, {
            input: {
              classYear: newData[i].classYear ? newData[i].classYear : null,
              position: newData[i].position,
              active: table.added.includes(i), // must be on the added list
              rosterId: roster.id,
              athleteId: newData[i].athleteId,
            },
          })
        );
      })
    );

  // Updates the old fashione way
  const updateBatch =
    table &&
    table.updates &&
    Promise.all(
      table.updates.map((i) => {
        return API.graphql(
          graphqlOperation(updateRosterLink, {
            input: {
              id: newData[i].rosterLinkId,
              classYear: newData[i].classYear ? newData[i].classYear : null,
              position: newData[i].position,
              active: table.removed.includes(i)
                ? false
                : table.added.includes(i)
                ? true
                : newData[i].active,
              _version: newData[i]._version, // perhaps need pull this from redux normalizr in case of subsequent changes?
            },
          })
        );
      })
    );

  // Update roster if updating/creating batches regardless to get the updatedAt time updated & add new athletes via rosterLink
  const rosterPayload = title
    ? title
    : { id: roster.id, _version: roster._version };
  const rosterReq = API.graphql(
    graphqlOperation(updateRoster, { input: rosterPayload })
  );

  // Think we need to sequence this to do the batches then do Roster so tha we can get newest roster back
  return await Promise.all([createBatch, updateBatch])
    .then(async (res) => {
      return [await rosterReq, ...res];
    })
    .catch((error) => {
      return error;
    });
}

async function editLeague(input, changedImg = false) {
  const { altNames, logos, colors, id, _version } = input;
  const { username } = store.getState().user;
  let imgMeta = null;

  if (changedImg && logos !== null) {
    let imgId = nanoid();

    imgMeta = {
      metaData: {
        type: 'league',
        typeid: id,
        id: imgId,
        owner: username,
        originalname: logos.name,
        size: logos.size,
        imgtype: logos.type,
        filename: `upload/images/league/${imgId}`,
      },
    };
  }

  const JSONinput = {
    ...input,
    altNames: altNames.length > 0 ? JSON.stringify(altNames) : null,
    logos: changedImg
      ? JSON.stringify(imgMeta)
      : logos
      ? JSON.stringify(logos)
      : null,
    colors: colors ? JSON.stringify(colors) : null,
    id: id,
    _version: _version,
  };

  return await API.graphql(graphqlOperation(updateLeague, { input: JSONinput }))
    .then((res) => {
      const imgRes = changedImg && logos ? uploadPhoto(logos, imgMeta) : null;
      console.log('image uploading');
      return [res, imgRes];
    })
    .catch((error) => {
      return error;
    });
}

async function updateStreams(input) {
  return await Promise.all(
    input.map((stream) => {
      return API.graphql(graphqlOperation(updateStream, { input: stream }));
    })
  );
}

async function editUser(input, changedImg = false) {
  const { profileImg, colors, id, _version } = input;
  const { username } = store.getState().user;
  let imgMeta = null;

  if (changedImg && profileImg !== null) {
    let imgId = nanoid();

    imgMeta = {
      metaData: {
        type: 'user',
        typeid: id,
        id: imgId,
        owner: username,
        originalname: profileImg.name,
        size: profileImg.size,
        imgtype: profileImg.type,
        filename: `upload/images/user/${imgId}`,
      },
    };
  }

  const JSONinput = {
    ...input,
    profileImg: changedImg
      ? JSON.stringify(imgMeta)
      : profileImg
      ? JSON.stringify(profileImg)
      : null,
    colors: colors ? JSON.stringify(colors) : null,
    id: id,
    _version: _version,
  };

  console.log(JSONinput);

  return await API.graphql(graphqlOperation(updateUser, { input: JSONinput }))
    .then((res) => {
      const imgRes =
        profileImg && changedImg ? uploadPhoto(profileImg, imgMeta) : null;
      return [res, imgRes];
    })
    .catch((error) => {
      return error;
    });
}
