// 1-6, short is abbreviated
export const mApparatusAbbv = ['FX', 'PH', 'SR', 'VT', 'PB', 'HB'];

export const wApparatusAbbv = ['VT', 'UB', 'BB', 'FX'];

export const soloDefault = {
  judging: true,
  judgingRequired: false,
  now: true,
  competition: false,
  lineup: false,
  name: '',
  judgeCount: 0,
  teamA: '',
  teamB: '',
  apparatus: '100000',
  startAt: new Date(),
  gender: 'MALE',
};

export const dualDefault = {
  judging: true,
  judgingRequired: true,
  now: false,
  competition: true,
  lineup: true,
  name: '',
  judgeCount: 3,
  teamA: '',
  teamB: '',
  apparatus: '111111',
  startAt: new Date(),
  gender: 'MALE',
};

export const rosterDefault = {
  title: 'Latest Roster',
  activeDate: new Date(),
};

export const lineupDefault = {
  title: 'Latest Lineup',
  session: 'Select session',
};

export const POLLING_INTERVAL = {
  SLOW: 10000,
  MED: 5000,
  FAST: 3000,
  SECOND: 1000,
  DOUBLE: 500,
  TENTH: 100,
};

export const CAMERA_DEFAULT_SETTINGS = {
  //url:
  port: '10000',
  name: 'Virtius Test Event',
  mode: 'va', // Video & Audio
  latency: '1000', // Needs to be tuned
  maxbw: '1500000',
  //pass:
  //streamId:
  camera: '0', // Rear camera
  //res: '1920x1080',
  res: '1280x720',
  fps: '60',
  bitrate: '4500', // Minimum
  format: 'avc',
  keyframe: '2',
  audiobit: '128',
  audiochannels: '1', // Requires mono for mobile device
  audiosamples: '48000',
};

export const MEDIAURL = 'https://media.virti.us/';

export const modeType = {
  ADD: 'ADD',
  LIST: 'LIST',
  DETAIL: 'DETAIL',
  CREATE: 'CREATE',
  EDIT: 'EDIT',
  DELETE: 'DELETE',
};

export const statusType = {
  CREATING: 'CREATING',
  SAVING: 'SAVING',
  EDITING: 'EDITING',
  LOADING: 'LOADING',
  STANDBY: 'STANDBY',
};
