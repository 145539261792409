// For Clipboard work
export const copyToClipboard = (data) => {
  if (!navigator.clipboard) {
    // Clipboard API not available
    let textField = document.createElement('textarea');
    textField.textContent = data;
    textField.innerHTML = textField.innerHTML.replace(/\n/g, '\n');
    document.body.appendChild(textField);
    textField.select();
    document.execCommand('copy');
    textField.remove();
  } else {
    navigator.clipboard.writeText(data);
  }

  console.log('Data copied to clipboard');
};

// Final score calculator function for team score, if round is null will calculate all rounds
export const exportAllData = (
  lineupA,
  lineupB,
  teamNameA,
  teamNameB,
  round = null,
  header,
  gender,
  eventName,
  eventDate
) => {
  let data = '';

  // Handle single round export
  if (round !== null) {
    return exportData(
      { lineup: lineupA[round] },
      { lineup: lineupB[round] },
      round,
      header,
      gender,
      eventName,
      eventDate,
      false
    );
  }

  // Handle full competition export
  let maxRoundA = Math.max(...Object.keys(lineupA));
  let maxRoundB = Math.max(...Object.keys(lineupB));
  let maxRound = Math.max(maxRoundA, maxRoundB);

  for (let i = 1; i <= maxRound; i++) {
    let nextData = exportData(
      {
        lineup: lineupA[i],
        name: teamNameA === teamNameB ? `${teamNameA} (A)` : teamNameA,
      },
      {
        lineup: lineupB[i],
        name: teamNameB === teamNameA ? `${teamNameB} (B)` : teamNameB,
      },
      i,
      header,
      gender,
      eventName,
      eventDate,
      i === 1 ? true : false
    );

    if (nextData) {
      data = data + nextData + '\n';
    }
  }

  return data;
};

// Allows for lazy input of single numeric characters, i.e. 5 -> 5.0
export const exportData = (
  teamA,
  teamB,
  round,
  header,
  gender,
  eventName,
  eventDate,
  showHeader = true
) => {
  const replacer = (key, value) => (value === null ? '' : value);
  const headerMapper = (key, team, home, index) => {
    const athlete = team.lineup[index];
    const evaluation = athlete.evaluation;

    switch (key) {
      case 'Round':
        return round;
      case 'Order':
        return home ? 2 * index + 1 : 2 * index + 2;
      case 'Name':
        return athlete.name;
      case 'ID':
        return athlete.athleteId;
      case 'Team':
        return team.name;
      case 'Score':
        return evaluation ? evaluation.score : null;
      case 'Difficulty':
        return evaluation ? evaluation.dScore : null;
      case 'Execution':
        return evaluation ? evaluation.eScore : null;
      case 'Neutral Deduction':
        return evaluation ? evaluation.ND : null;
      case 'Stick Bonus':
        return evaluation ? evaluation.SB : null;
      case 'Start Time':
        return athlete.startTime;
      case 'End Time':
        return athlete.endTime;
      case 'Rotation':
        if (gender === 'MALE') {
          return mGymAbbv(round);
        }
        if (gender === 'FEMALE') {
          return wGymAbbv(round);
        }
        return null;
      case 'Date':
        const date = eventDate ? new Date(eventDate) : new Date();
        return date.toLocaleDateString();
      case 'Event':
        return eventName ?? 'Virtius Event';
      case 'Inquiry':
        return evaluation
          ? evaluation.evalPanel.flags.inquiry
            ? 'Y'
            : null
          : null;
      default:
        // handle D and E subscores
        if (key[0] === 'D' || key[0] === 'E') {
          return evaluation &&
            evaluation.evalPanel.subScores.hasOwnProperty(key)
            ? evaluation.evalPanel.subScores[key][
                evaluation.evalPanel.subScores[key].length - 1
              ].score
            : null;
        } else {
          return null;
        }
    }
  };

  const teamAData =
    teamA.lineup &&
    teamA.lineup.length &&
    teamA.lineup
      .map((row, index) => {
        return header
          .map((key) => {
            return JSON.stringify(
              headerMapper(key, teamA, true, index),
              replacer
            );
          })
          .join('\t');
      })
      .join('\n');

  const teamBData =
    teamB.lineup &&
    teamB.lineup.length &&
    teamB.lineup
      .map((row, index) => {
        return header
          .map((key) => {
            return JSON.stringify(
              headerMapper(key, teamB, false, index),
              replacer
            );
          })
          .join('\t');
      })
      .join('\n');

  const headerData = showHeader ? header.map((key) => key).join('\t') : null;
  const data = teamAData && teamBData && [teamAData, teamBData].join('\n');
  const dataPlusHeader = headerData
    ? data
      ? [headerData, data].join('\n')
      : headerData
    : data;

  return dataPlusHeader;
};

export const mGymAbbv = (val) => {
  const abbv = {
    1: 'FX',
    2: 'PH',
    3: 'SR',
    4: 'VT',
    5: 'PB',
    6: 'HB',
  };

  const result = val >= 1 && val <= 6 ? abbv[val] : '';
  return result;
};

export const mGymEvent = (val) => {
  const apparatus = {
    1: 'Floor Exercise',
    2: 'Pommel Horse',
    3: 'Still Rings',
    4: 'Vault',
    5: 'Parallel Bars',
    6: 'Horizontal Bar',
  };

  const result = val >= 1 && val <= 6 ? apparatus[val] : '';
  return result;
};

export const wGymAbbv = (val) => {
  const abbv = {
    1: 'VT',
    2: 'UB',
    3: 'BB',
    4: 'FX',
  };

  const result = val >= 1 && val <= 4 ? abbv[val] : '';
  return result;
};

export const wGymEvent = (val) => {
  const apparatus = {
    1: 'Vault',
    2: 'Uneven Bars',
    3: 'Balance Beam',
    4: 'Floor Exercise',
  };

  const result = val >= 1 && val <= 4 ? apparatus[val] : '';
  return result;
};
