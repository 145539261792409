import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Main from './session/main';
import { sessionActions } from '../redux/_actions';
import { uiState } from '../redux/_constants';
import { useHistory } from 'react-router-dom';
import FullscreenLoading from './helpers/fullscreenloading';
import { useQueryParams } from '../utilities/query';

function Session() {
  const sessionKey = useSelector((state) => state.session.sessionKey);
  const sessionRequest = useSelector((state) => state.session.request);
  const sessionId = useSelector((state) => state.session.id);
  const streamAId = useSelector((state) => state.session.streamA?.id);
  const streamBId = useSelector((state) => state.session.streamB?.id);
  const producerLineupAId = useSelector(
    (state) => state.producer.teamA.lineupId
  );
  const producerLineupBId = useSelector(
    (state) => state.producer.teamB.lineupId
  );
  const sessionLineupAId = useSelector(
    (state) => state.session.lineups?.items[0]?.id
  );
  const sessionLineupBId = useSelector(
    (state) => state.session.lineups?.items[1]?.id
  );

  const history = useHistory();
  const query = useQueryParams();
  const dispatch = useDispatch();

  const querySessionKey = query.get('s');
  const isLoaded = querySessionKey === sessionKey;
  // The producer state is not currently cleared when we navigate away from a session
  // so the lineup IDs it has may not be the ones from the session we want to load for
  // the current page. We check just one of the producer lineup ids here since they're
  // set at the same time.
  const isProducerInitialized =
    isLoaded &&
    !!producerLineupAId &&
    (producerLineupAId === sessionLineupAId ||
      producerLineupAId === sessionLineupBId);
  const readyToRender = isLoaded && isProducerInitialized;

  if (!querySessionKey) {
    history.push(uiState.START);
  }

  useEffect(() => {
    if (!isLoaded && sessionRequest !== querySessionKey) {
      dispatch(sessionActions.load(querySessionKey));
    }
  }, [dispatch, isLoaded, sessionRequest, querySessionKey]);

  useEffect(() => {
    if (sessionKey === querySessionKey) {
      dispatch(sessionActions.subscribe(sessionId));
      return () => {
        dispatch(sessionActions.unsubscribe(sessionId));
      };
    }
  }, [dispatch, sessionId, sessionKey, querySessionKey]);

  useEffect(() => {
    if (isLoaded && !streamAId && !streamBId) {
      dispatch(sessionActions.subscribeNewStream(sessionId));
      return () => {
        dispatch(sessionActions.unsubscribeNewStream(sessionId));
      };
    }
  }, [dispatch, isLoaded, sessionId, streamAId, streamBId]);

  useEffect(() => {
    if (isLoaded && streamAId) {
      dispatch(sessionActions.subscribeStream(streamAId, 'A'));
      return () => {
        dispatch(sessionActions.unsubscribeStream('A'));
      };
    }
  }, [dispatch, isLoaded, streamAId]);

  useEffect(() => {
    if (isLoaded && streamBId) {
      dispatch(sessionActions.subscribeStream(streamBId, 'B'));
      return () => {
        dispatch(sessionActions.unsubscribeStream('B'));
      };
    }
  }, [dispatch, isLoaded, streamBId]);

  useEffect(() => {
    if (isProducerInitialized && producerLineupAId) {
      dispatch(sessionActions.subscribeLineup(producerLineupAId, 'A'));
      return () => {
        dispatch(sessionActions.unsubscribeLineup('A'));
      };
    }
  }, [dispatch, isProducerInitialized, producerLineupAId]);

  useEffect(() => {
    if (isProducerInitialized && producerLineupBId) {
      dispatch(sessionActions.subscribeLineup(producerLineupBId, 'B'));
      return () => {
        dispatch(sessionActions.unsubscribeLineup('B'));
      };
    }
  }, [dispatch, isProducerInitialized, producerLineupBId]);

  return readyToRender ? (
    <Main />
  ) : (
    <FullscreenLoading message={'Loading session...'} />
  );
}

export default Session;
