export const videoPlayerConstants = {
  LOAD: 'VIDEOPLAYER_LOAD',
  SEEK: 'VIDEOPLAYER_SEEK',
  INITIALIZE: 'VIDEOPLAYER_INITIALIZE',
  SYNC: 'VIDEOPLAYER_SYNC',
  PLAY: 'VIDEOPLAYER_PLAY',
  PAUSE: 'VIDEOPLAYER_PAUSE',
  CURRENT_TIME: 'VIDEOPLAYER_CURRENT_TIME',
  VOD: 'VIDEOPLAYER_VOD',
  SET_REF: 'VIDEOPLAYER_SET_REF',
};

export const initialPlayerState = {
  url: '',
  seekTime: null,
  autoplay: false,
  currentTime: 0,
  fullScreen: false,
  getTime: false,
  pip: false,
  play: false,
  mute: false,
  clips: {
    intro: null,
    ready10s: null,
    rotate90s: null,
    eval90s: null,
  },
  vod: true,
  ref: null,
};

export const playerType = {
  REACT_PLAYER: 'REACT_PLAYER',
  THEO_PLAYER: 'THEO_PLAYER',
};

export const initialVideoPlayerState = {
  controller: {
    sync: false,
    vod: true,
  },
  playerA: {
    ...initialPlayerState,
  },
  playerB: {
    ...initialPlayerState,
  },
};

export const resolutionType = {
  '160x90': '90p',
  '320x180': '180p',
  '352x240': '240p',
  '640x360': '360p',
  '854x480': '480p',
  '960x540': '540p',
  '1280x720': '720p',
  '1920x1080': '1080p',
  '2560x1440': '2K',
  '3840x2160': '4K',
  '7680x4320': '8K',
};
