import { sessionRoleType, evaluatorConfigs } from '../redux/_constants';

// Check user permissions for session to see what level of access they get
export const checkRole = (user, session) => {
  const { ADMIN, JUDGE, PRODUCER, FAN, COACH, ANNOUNCER } = sessionRoleType;

  if (!user || !session) {
    return;
  }

  const userId = user?.attributes.sub;
  const cognitoGroups =
    user.signInUserSession.idToken.payload['cognito:groups'] || [];

  if (cognitoGroups.includes('admin')) {
    return ADMIN;
  } else if (session?.admins?.includes(userId)) {
    return ADMIN;
  } else if (session?.producers?.includes(userId)) {
    return PRODUCER;
  } else if (session?.coaches?.includes(userId)) {
    return COACH;
  } else if (session?.judges?.includes(userId)) {
    return JUDGE;
  } else if (session?.announcers?.includes(userId)) {
    return ANNOUNCER;
  } else {
    return FAN;
  }
};

export const getEvalConfig = (role) => {
  const { ADMIN, JUDGE, PRODUCER, FAN, COACH, ANNOUNCER } = sessionRoleType;

  switch (role) {
    case FAN:
    case JUDGE:
    case ANNOUNCER:
    case ADMIN:
    case PRODUCER:
    case COACH:
    default:
      return JSON.parse(JSON.stringify(evaluatorConfigs.DEFAULT));
  }
};

// Given two teams, need to find the color for team 2 that is not the same
// Used for Same team intrasquad or two teams that have the same #1 color
// TODO: could make this more badass and choose colors based on RGB value
// Should be able to be overridden in PREGAME settings
export const colorSelector = (teamAColor, teamBOptions) => {
  return teamBOptions.find((el) => el !== teamAColor);
};
