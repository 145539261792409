import React, { useState, useRef, useEffect } from 'react';
import { Row, Col, Button, OverlayTrigger, Popover } from 'react-bootstrap';
import { checkIcon, lockIcon } from '../helpers/icons';
import { scoreCheckStrict, scoreValid } from '../../utilities/scoring';
import { useDispatch, useSelector } from 'react-redux';
import { evaluatorActions } from '../../redux/_actions';
import { sessionRoleType, sessionStatus } from '../../redux/_constants';

const InputComponent = (
  inputRef,
  score,
  type,
  changeHandler,
  keyHandler,
  inputLock
) => {
  useEffect(() => {
    if (inputRef && inputRef.current) {
      inputRef.current.focus();
    }
  }, [inputLock, inputRef]);

  return (
    <input
      ref={inputRef}
      type="number"
      value={score}
      name="score"
      min="0.0"
      max={type === 'SB' ? '0.1' : '10.0'}
      step="0.1"
      placeholder={type === 'SB' ? '0.1' : ''}
      onChange={changeHandler}
      onKeyPress={keyHandler}
      disabled={type === 'SB' ? true : inputLock}
      autoFocus
      onFocus={(e) => e.currentTarget.select()}
    />
  );
};

function EvaluatorInput(props) {
  const { role, status } = useSelector((state) => state.session);
  const { ADMIN, JUDGE, PRODUCER, FAN, COACH, ANNOUNCER } = sessionRoleType;
  const { team, type, value, inquiry } = props;
  const [score, setScore] = useState(type === 'SB' ? '0.1' : ''); // can be number or string
  const [inputString, setInputString] = useState('');
  const [inputKey, setInputKey] = useState('');
  const [showPopover, setShowPopover] = useState(false);
  const [inputLock, setInputLock] = useState(false);
  const disabled =
    !(team && team.onEval) ||
    (![ADMIN, JUDGE].includes(role) && status === sessionStatus.LIVE); // need to add some permission checks for role
  const dispatch = useDispatch();
  const inputRef = useRef();

  useEffect(() => {
    // Reset the fields
    if (disabled) {
      setInputLock(false);
      setScore(type === 'SB' ? '0.1' : '');
      setInputString('');
      setInputKey('');
    }

    // Load the fields (for inquiry)
    if (inquiry) {
      if (value !== null) {
        setInputLock(true);
        setScore(Number(value).toFixed(1));
        setInputString(Number(value).toFixed(1));
      } else {
        setInputLock(false);
        setScore(type === 'SB' ? '0.1' : '');
        setInputString('');
      }
      setInputKey('');
    }
  }, [disabled, inquiry, value, type]);

  const scoreHeading = () => {
    if (type === 'ND') {
      return 'Neutral Deduction';
    } else if (type[0] === 'E') {
      return 'Execution Deduction';
    } else if (type[0] === 'D') {
      return 'Difficulty Score';
    } else if (type === 'SB') {
      return 'Stick Bonus';
    }
  };

  const submitScore = (e) => {
    // Submit operation
    if (scoreValid(score) && !inputLock) {
      setInputLock(!inputLock);
      const evaluatorName =
        (['ND', 'SB'].includes(type) ? 'D' : type) +
        '_Panel_' +
        (team.home ? 'A' : 'B');
      const scoreData = {
        evaluator: evaluatorName,
        score: Number(score),
        type: type,
        time: Date.now(),
        panel: team.home ? 'A' : 'B',
        locked: true,
      };
      setScore(Number(score).toFixed(1)); // convert single digit input to fixed(1)
      dispatch(evaluatorActions.lockSubScore(scoreData));
      setShowPopover(!showPopover);
    }

    // Unlock operation (for optional fields should remove score)
    if (inputLock) {
      setInputLock(!inputLock);
      const evaluatorName =
        (['ND', 'SB'].includes(type) ? 'D' : type) +
        '_Panel_' +
        (team.home ? 'A' : 'B');
      const scoreData = {
        type: type,
        panel: team.home ? 'A' : 'B',
        time: Date.now(),
        evaluator: evaluatorName,
      };
      dispatch(evaluatorActions.unlockSubScore(scoreData));
    }
  };

  const changeHandler = (e) => {
    const val = e.target.value;
    let targetInput = '';

    /*
    console.log('value: ', val);
    console.log('input string: ', inputString)
    console.log('input key: ', inputKey)
    */

    // Edge cases
    // Scroll up / down to ends
    // Copy and paste random
    // Type input without .
    // Extra same character input
    // Delete doesn't change number
    // Start with period, need to auto add 0
    // Cancel out 'e' & '-' character
    // Scroll down to 0 from nothing yields 0.0
    // Delete 1 from 0.1 yields 0, track key & input

    // Inputs:
    // 10, 100, 10.0
    // Copy paste: 0, 0.0, 234
    // Arrows -> 0, -> 10
    // Delete digits of 1.0

    if (val === '') {
      targetInput = '';
    } else if (val === '1.00' || (val === '10' && inputString === '9.9')) {
      targetInput = '100';
    } else if (inputKey === '' && inputString === '' && val === '0') {
      targetInput = val + '0';
    } else if (
      '0123456789'.includes(val) &&
      Math.abs((Number(val) - Number(inputString)).toFixed(1)) === 0.1
    ) {
      targetInput = val + '0';
    } else if (scoreCheckStrict(val) !== scoreCheckStrict(inputString)) {
      targetInput = val;
    } else {
      targetInput = inputString;
    }

    if (val === '') {
      setInputKey('');
    } // Reset inputKey if value deleted
    setInputString(targetInput);
    const formattedScore = scoreCheckStrict(targetInput);

    //console.log('scoreCheck: ', formattedScore)
    //console.log('Number(scoreCheck): ', Number(formattedScore));

    if (formattedScore.slice(-2) === '.0' || val[0] === '.') {
      setScore(Number(formattedScore).toFixed(1));
    } else if (formattedScore.slice(-2, -1) === '.') {
      setScore(Number(formattedScore));
    } else {
      setScore(val);
    }
  };

  const keyHandler = (e) => {
    const exclude = [101, 45]; // Prevent chars: e:101, -:45

    if (exclude.includes(e.charCode)) {
      e.preventDefault();
    }

    if (e.charCode === 13) {
      // User presses return/enter
      submitScore();
    }

    setInputKey(e.key);

    /*
    console.log('key: ', e.key)
    const code = e.charCode;
    console.log(code)
    */
  };

  const popover = (
    <Popover id={`popover-eval`} show={false}>
      <Popover.Title as="h3">{scoreHeading()}</Popover.Title>
      <Popover.Content>
        <Row style={{ margin: '0' }}>
          <Col xs={9} className="vCenter">
            {InputComponent(
              inputRef,
              score,
              type,
              changeHandler,
              keyHandler,
              inputLock
            )}
          </Col>
          <Col xs={3} className="vCenter">
            <Button
              variant="light"
              className={[
                'vCenter',
                (score !== '' ? scoreValid(score) : false) ? 'valid' : null,
              ].join(' ')}
              disabled={score !== '' ? !scoreValid(score) : true}
              onClick={submitScore}
            >
              {inputLock ? lockIcon : checkIcon}
            </Button>
          </Col>
        </Row>
      </Popover.Content>
    </Popover>
  );

  return (
    <OverlayTrigger
      trigger="click"
      placement="top"
      key="top"
      overlay={popover}
      rootClose
    >
      <Button
        className={type}
        variant="secondary"
        disabled={disabled}
        onClick={() => setShowPopover(!showPopover)}
      >
        {type}
        {!inputLock ? null : <div className="lockIndicator" />}
      </Button>
    </OverlayTrigger>
  );
}

export default EvaluatorInput;
