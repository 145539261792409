import React from 'react';
import { Modal } from 'react-bootstrap';
import VideoSetup from './videosetup';

function SetupSession(props) {
  const { modeType, mode, handleMode } = props;

  const formType = () => {
    switch (mode) {
      case modeType.TEAM_A:
        break;
      //return <TeamRoster />
      case modeType.TEAM_B:
        break;
      //return <TeamRoster />
      case modeType.JUDGES:
        break;
      //return <JudgeRoster />
      case modeType.VIDEO:
        return <VideoSetup />;
      default:
        break;
    }
  };

  return (
    <Modal
      show={mode !== null}
      onHide={() => handleMode(null)}
      centered
      dialogClassName="setupModal"
      animation={false}
    >
      {formType()}
    </Modal>
  );
}

export default SetupSession;
