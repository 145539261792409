import React, { useState, useEffect } from 'react';
import {
  Modal,
  Button,
  Row,
  Col,
  Alert,
  Spinner,
  Form,
  ToggleButtonGroup,
  ToggleButton,
} from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { sessionType, genderType } from '../../redux/_constants';
import {
  sessionActions,
  alertActions,
  adminActions,
} from '../../redux/_actions';
import * as Yup from 'yup';
import * as Defaults from '../../utilities/constants';
import {
  strApparatusToArray,
  arrayToStrApparatus,
  /*dateTime,*/ displayName,
} from '../../utilities/conversions';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import TeamSelector from '../setup/teamselector';
import { dataUtilities } from '../../utilities/data';
import { useHistory } from 'react-router-dom';

function SessionForm(props) {
  const { setup, gender, reset } = props;
  const [isCreating, setIsCreating] = useState(false);
  const [footerDisabled, setFooterDisabled] = useState(false);
  const [teamSelector, setTeamSelector] = useState(null);
  const dispatch = useDispatch();
  const history = useHistory();
  const { alert, admin } = useSelector((state) => state);
  const { teams } = admin;
  const { MALE, FEMALE } = genderType;
  const { SOLO, DUAL } = sessionType;
  const {
    MEDIAURL,
    mApparatusAbbv,
    wApparatusAbbv,
    soloDefault,
    dualDefault,
  } = Defaults;

  const abbv =
    gender === MALE ? mApparatusAbbv : gender === FEMALE ? wApparatusAbbv : [];

  useEffect(() => {
    if (!alert.clear) {
      setIsCreating(false);
    }
  }, [alert.clear]);

  // TODO: refactor to move admin function to data function
  useEffect(() => {
    if (teams.allIds.length === 0) {
      dispatch(adminActions.getTeams());
    }
  }, [dispatch, teams]);

  const validationSchema = Yup.object().shape({
    judging: Yup.boolean(),
    judgingRequired: Yup.boolean(),
    now: Yup.boolean(),
    name: Yup.string(),
    judgeCount: Yup.number(),
    teamA: Yup.string().nullable(),
    teamB: Yup.string().nullable(),
    lineup: Yup.boolean(),
    competition: Yup.boolean(),
    apparatus: Yup.string(),
    startAt: Yup.string(),
  });

  const getDefault = () => {
    let result = null;

    if (setup === SOLO) {
      result = {
        ...soloDefault,
        gender: gender,
        apparatus: strApparatusToArray(
          soloDefault.apparatus.substring(0, abbv.length)
        ),
      };
    }

    if (setup === DUAL) {
      result = {
        ...dualDefault,
        gender: gender,
        apparatus: strApparatusToArray(
          dualDefault.apparatus.substring(0, abbv.length)
        ),
      };
    }

    return result;
  };

  const { setFieldValue, handleSubmit, handleChange, values } = useFormik({
    initialValues: getDefault(),
    validationSchema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit(values) {
      setIsCreating(true);
      const input = {
        ...values,
        type: setup,
        name: calcSessionName(),
        apparatus: arrayToStrApparatus(values.apparatus),
      };

      const teamChange = dataUtilities.checkSessionTeamChange(input);

      dispatch(alertActions.clear());
      dispatch(sessionActions.create(input, teamChange, history));
    },
  });

  const calcSessionName = () => {
    const { teamA, teamB, competition, /*startAt,*/ name } = values;
    let result =
      teamA === ''
        ? 'Team ?'
        : displayName(teams.byId[teamA].name, teams.byId[teamA].altNames);

    if (name === '') {
      if (setup === DUAL) {
        result += ' vs ';
        result +=
          teamB === ''
            ? 'Team ?'
            : displayName(teams.byId[teamB].name, teams.byId[teamB].altNames);
      }

      result += ` ${competition ? 'in Competition' : 'in Practice'}`;
      //result += ` ${dateTime(startAt)}`;
      return result;
    }

    return name;
  };

  const setTeam = (side, team = null) => {
    if (side !== null) {
      setFieldValue(`team${side}`, team ? team.id : '');
    }
    setTeamSelector(null);
    setFooterDisabled(false);
  };

  const getLogo = (side) => {
    const selectedTeamId = values[`team${side}`];
    let logo = '';

    if (selectedTeamId) {
      logo =
        teams.byId[selectedTeamId].logos &&
        JSON.parse(teams.byId[selectedTeamId].logos).metaData.filename;
    }

    return logo ? (
      <img
        className="img-thumbnail"
        src={`${MEDIAURL}${logo}`}
        alt={`${teams.byId[selectedTeamId].name} Logo`}
      />
    ) : (
      '?'
    );
  };

  const goTeamSelector = (side) => {
    setTeamSelector(side);
    setFooterDisabled(true);
  };

  const setupBody = () => {
    const logoA = getLogo('A');
    const logoB = getLogo('B');

    return (
      <>
        {!alert.clear ? (
          <Row>
            <Alert
              dismissible
              onClose={() => dispatch(alertActions.clear())}
              variant={alert.type === 'alert-danger' ? 'danger' : 'success'}
            >
              {alert.message}
            </Alert>
          </Row>
        ) : null}
        <Row>
          <div className="setupFormLine">
            <Col>
              <label>Team A</label>
              <Button
                variant={logoA === '?' ? 'light' : 'outline-light'}
                disabled={isCreating}
                className={'teamSelect'}
                onClick={() => goTeamSelector('A')}
                style={{ fontSize: logoA === '?' ? '5rem' : null }}
              >
                {logoA}
              </Button>
            </Col>
            <Col className="vsCol">
              <label>&nbsp;</label>
              <div
                className={[
                  'vs',
                  setup !== sessionType.DUAL ? 'disabled' : null,
                ].join(' ')}
              >
                <span className={'vBlue'}>V</span>
                <span className={'vGray'}>S</span>
              </div>
            </Col>
            <Col>
              <label className={setup !== sessionType.DUAL ? 'disabled' : null}>
                Team B
              </label>
              <Button
                variant={logoB === '?' ? 'light' : 'outline-light'}
                disabled={setup !== sessionType.DUAL || isCreating}
                className={'teamSelect'}
                onClick={() => goTeamSelector('B')}
                style={{ fontSize: logoB === '?' ? '5rem' : null }}
              >
                {logoB}
              </Button>
            </Col>
          </div>
        </Row>
        <Row>
          <div className="setupFormLine">
            <Col>
              <label>When</label>
              <Form.Check
                type="switch"
                label={'Now'}
                id="now"
                checked={values.now}
                onChange={handleChange}
                disabled={isCreating}
              />
            </Col>
            <Col>
              <label>Date / Time</label>
              <DatePicker
                name="startAt"
                disabled={values.now || isCreating}
                selected={values.startAt}
                onChange={(value) => setFieldValue('startAt', value)}
                showTimeSelect
                minDate={new Date()}
                dateFormat="MM/dd/yyyy h:mm aa"
                popperPlacement="auto"
              />
            </Col>
          </div>
        </Row>
        <Row>
          <div className="setupFormLine">
            <Col>
              <label>Format</label>
              <Form.Check
                type="switch"
                label={'Competition'}
                id="competition"
                checked={values.competition}
                onChange={handleChange}
                disabled={isCreating}
              />
            </Col>
            <Col>
              <label></label>
              <Form.Check
                type="switch"
                label={'Lineup'}
                id="lineup"
                checked={values.lineup}
                onChange={handleChange}
                disabled={isCreating}
              />
            </Col>
          </div>
        </Row>
        <Row>
          <div className="setupFormLine">
            <Col>
              <label>{`Apparatus (${values.apparatus.length}/${abbv.length})`}</label>
              <ToggleButtonGroup
                name="apparatus"
                className="apparatusToggle"
                type="checkbox"
                value={values.apparatus}
                onChange={(value) => {
                  if (value.length > 0) {
                    setFieldValue('apparatus', value);
                  }
                }}
              >
                {abbv.map((a, i) => {
                  return (
                    <ToggleButton
                      variant="outline-primary"
                      className="vCenter"
                      value={i}
                      key={i}
                      disabled={isCreating}
                    >
                      {a}
                    </ToggleButton>
                  );
                })}
              </ToggleButtonGroup>
            </Col>
          </div>
        </Row>
        <Row>
          <div className="setupFormLine">
            <Col>
              <label>Judging</label>
              <Form.Check
                type="switch"
                label={'Enabled'}
                id="judging"
                onChange={handleChange}
                checked={values.judging}
                disabled={isCreating}
              />
            </Col>
            <Col>
              <label></label>
              <Form.Check
                type="switch"
                label={'Required'}
                id="judgingRequired"
                onChange={handleChange}
                checked={values.judgingRequired}
                disabled={isCreating || !values.judging}
              />
            </Col>
          </div>
        </Row>
        <Row>
          <div className="setupFormLine">
            <Col>
              <label>Session Name</label>
              <input
                name="name"
                type="text"
                autoComplete="off"
                onChange={handleChange}
                value={calcSessionName()}
                disabled={isCreating}
              />
              <div className="codeSpecs">Autogenerated or custom name.</div>
            </Col>
          </div>
        </Row>
      </>
    );
  };

  const setupFooter = () => {
    return (
      <>
        <Row>
          <Button
            variant="outline-primary"
            className="createButton"
            onClick={handleSubmit}
            disabled={isCreating || footerDisabled}
          >
            {isCreating ? (
              <>
                {'Creating session... '}
                <Spinner
                  variant="primary"
                  role="status"
                  animation="border"
                  size="sm"
                />
              </>
            ) : (
              'Create session'
            )}
          </Button>
        </Row>
        <Row>
          <div className="modalLink">
            <span>Back to</span>
            <button
              type="button"
              className="linkButton"
              disabled={isCreating || footerDisabled}
              onClick={() => reset()}
            >
              select session
            </button>
          </div>
        </Row>
      </>
    );
  };

  const headerMsg = () => {
    const solo = (
      <>
        <span className={'vBlue'}>S</span>
        <span className={'vGray'}>olo</span>
      </>
    );

    const dual = (
      <>
        <span className={'vBlue'}>D</span>
        <span className={'vGray'}>ual</span>
      </>
    );

    const mens = (
      <>
        <span className={'vBlue'}>M</span>
        <span className={'vGray'}>ens</span>
      </>
    );

    const womens = (
      <>
        <span className={'vBlue'}>W</span>
        <span className={'vGray'}>omens</span>
      </>
    );

    const sessionLabel = () => {
      if (setup === SOLO) {
        return solo;
      }
      if (setup === DUAL) {
        return dual;
      }
    };

    const genderLabel = () => {
      if (gender === MALE) {
        return mens;
      }

      if (gender === FEMALE) {
        return womens;
      }
    };

    return (
      <>
        {genderLabel()}
        <span>&nbsp;</span>
        {sessionLabel()}
        <span>&nbsp;session setup</span>
      </>
    );
  };

  return (
    <>
      <Modal.Header className="createOrJoinHeader">{headerMsg()}</Modal.Header>
      <Form onSubmit={handleSubmit}>
        <Modal.Body className="createOrJoinForm">
          {setupBody()}
          {teamSelector ? (
            <TeamSelector
              side={teamSelector}
              setTeam={setTeam}
              values={values}
            />
          ) : null}
        </Modal.Body>
        <Modal.Footer className="createOrJoinForm">
          {setupFooter()}
        </Modal.Footer>
      </Form>
    </>
  );
}

export default SessionForm;
