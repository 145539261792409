import React, { useState } from 'react';
import { Container, Row, Col, ButtonGroup, Button } from 'react-bootstrap';
import SetupAdmin from './admin/setupadmin';
import Header from './helpers/header';
import { adminModeType } from '../redux/_constants';
import { heroLogo } from './helpers/logos';

function Admin() {
  const [mode, setMode] = useState(null);

  const handleMode = (mode) => {
    setMode(mode);
  };

  return (
    <div className="main">
      <Header />
      <SetupAdmin
        mode={mode}
        modeType={adminModeType}
        handleMode={handleMode}
      />
      <Container>
        <Row style={{ height: 'calc(40vh - 72px)' }} />
        <Row style={{ height: '15vh' }}>
          <Col className="center" style={{ display: 'flex', flexWrap: 'wrap' }}>
            <div className="heroLogo"> {heroLogo} </div>
            <div className="heroMessage">
              Admin panel to manage competition organizations and participants.
            </div>
          </Col>
        </Row>
        <Row style={{ height: '45vh' }}>
          <Col className="center" style={{ display: 'flex', flexWrap: 'wrap' }}>
            <div className="loginContainer">
              <ButtonGroup>
                <Button
                  variant="outline-secondary"
                  onClick={() => handleMode(adminModeType.ATHLETE)}
                >
                  Athlete
                </Button>
                <Button
                  variant="outline-secondary"
                  onClick={() => handleMode(adminModeType.JUDGE)}
                >
                  Judge
                </Button>
                <Button
                  variant="outline-secondary"
                  onClick={() => handleMode(adminModeType.USER)}
                >
                  User
                </Button>
                <Button
                  variant="outline-secondary"
                  onClick={() => handleMode(adminModeType.SESSION)}
                >
                  Session
                </Button>
                <Button
                  variant="outline-secondary"
                  onClick={() => handleMode(adminModeType.TEAM)}
                >
                  Team
                </Button>
                <Button
                  variant="outline-secondary"
                  onClick={() => handleMode(adminModeType.LEAGUE)}
                >
                  League
                </Button>
              </ButtonGroup>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Admin;
