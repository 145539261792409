import React, {
  useState,
  useMemo,
  useRef,
  useEffect,
  useImperativeHandle,
} from 'react';
import {
  Button,
  Row,
  Col,
  Image as ImageBS,
  Form,
  Popover,
  OverlayTrigger,
} from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { adminActions, alertActions } from '../../redux/_actions';
import {
  genderType,
  leagueType,
  imgType,
  FILE_SIZE,
} from '../../redux/_constants';
import * as Yup from 'yup';
import { dataUtilities } from '../../utilities/data';
import {
  modeType,
  lineupDefault,
  mApparatusAbbv,
  wApparatusAbbv,
} from '../../utilities/constants';

const LineupTitleForm = React.forwardRef((props, ref) => {
  const {
    isEditing,
    isSaving,
    mode,
    setMode,
    lineup,
    teamId,
    setIsLoading,
    reset,
  } = props;
  const { alert } = useSelector((state) => state);
  const { table } = useSelector((state) => state.admin.status);
  const dispatch = useDispatch();
  const { LIST, CREATE, DETAIL } = modeType;

  useImperativeHandle(ref, () => ({
    submit() {
      handleSubmit();
    },
  }));

  const validationSchema = Yup.object().shape({
    title: Yup.string().required('Required'),
    activeDate: Yup.string().required('Required'),
  });

  const {
    setFieldValue,
    handleSubmit,
    handleChange,
    values,
    errors,
    resetForm,
  } = useFormik({
    initialValues: {
      teamId: teamId,
      session:
        mode === CREATE || mode === LIST
          ? lineupDefault.session
          : lineup.sessionId,
      title:
        mode === CREATE || mode === LIST ? lineupDefault.title : lineup.title,
      id: lineup ? lineup.id : null,
      _version: lineup ? lineup._version : null,
    },
    //enableReinitialize: true,
    validationSchema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit(values) {
      const titleChanged =
        values.sessionId !== lineup.sessionId || values.title !== lineup.title;
      const dataChanges = dataUtilities.checkTableChange(
        table.initialData,
        table.newData,
        table.initialSelected,
        table.newSelected
      );
      const dataChanged =
        dataChanges.updates.length !== 0 || dataChanges.creates.length !== 0;

      if (!titleChanged && !dataChanged) {
        setMode(DETAIL);
        dispatch(alertActions.success('No changes made.'));
        reset();
        return;
      } else {
        dispatch(
          adminActions.editLineup({
            title: titleChanged ? values : null,
            table: dataChanged ? dataChanges : null,
            lineup: lineup,
            newData: table.newData,
          })
        );
        setIsLoading(true);
      }
    },
  });

  return (
    <Row className="lineupTitles">
      <Form onSubmit={handleSubmit}>
        <div className="setupFormLine">
          <Col sm={8}>
            <label>
              Title
              {errors.title ? (
                <span className="form-error">{errors.type}</span>
              ) : null}
            </label>
            <Form.Control
              plaintext
              name="title"
              type="text"
              autoComplete="off"
              value={values.title}
              onChange={handleChange}
              disabled={isSaving || !isEditing}
              placeholder="Enter Roster Title"
            />
          </Col>
          <Col>
            <label>
              Session
              {errors.session ? (
                <span className="form-error">{errors.name}</span>
              ) : null}
            </label>
            <Form.Control
              plaintext
              name="title"
              type="text"
              autoComplete="off"
              value={values.session}
              onChange={handleChange}
              disabled={isSaving || !isEditing}
              placeholder="Enter Session Key"
            />
          </Col>
        </div>
      </Form>
    </Row>
  );
});

export const TeamLineup = React.forwardRef((props, ref) => {
  const {
    teamId,
    isEditing,
    isSaving,
    reset,
    setIsLoading,
    setIsEditing,
    setTabMode,
    tabMode,
  } = props;
  const {
    teams,
    rosters,
    status,
    athletes,
    lineups,
    rosterLinks,
  } = useSelector((state) => state.admin);
  const team = teams.byId[teamId];
  const [lineup, setLineup] = useState(null);
  const { LIST, DETAIL, CREATE, EDIT } = modeType;
  let abbv = { MALE: mApparatusAbbv, FEMALE: wApparatusAbbv };
  const dispatch = useDispatch();
  const formRef = useRef();

  const lineupDetail = (e, row) => {
    if (!alert.clear) {
      dispatch(alertActions.clear());
    }
    setLineup(lineups.byId[row.id]);

    if (!lineups.byId[row.id].lineupData) {
      // need to check if this works
      setTabMode(EDIT);
      setIsEditing(true);
    } else {
      setTabMode(DETAIL);
      setIsEditing(false);
    }
  };

  useEffect(() => {
    if (!alert.clear && alert.type === 'alert-success') {
      // Update to see if need to add new roster or selected roster
      if (tabMode === CREATE) {
        // assign last on AllIds array since it was added by push
        const id = lineups.allIds[lineups.allIds.length - 1];
        setLineup(lineups.byId[id]);
        setTabMode(EDIT);
      }

      // Handle the effects of a save operation and data updates
      if (tabMode === EDIT) {
        setTabMode(DETAIL);
        setLineup(lineups.byId[lineup.id]); // need to set to new roster
        reset();
      }
    }
  }, [alert, lineups, tabMode]);

  useEffect(() => {
    if (teamId !== status.filterId) {
      dispatch(adminActions.getTeamAthletes(teamId));
    }
  }, [teamId, status.filterId]);

  useImperativeHandle(ref, () => ({
    submit() {
      if (!alert.clear) {
        dispatch(alertActions.clear());
      }
      formRef.current.submit();
    },
    create() {
      if (!alert.clear) {
        dispatch(alertActions.clear());
      }
      const input = { ...lineupDefault, teamId: teamId };
      dispatch(adminActions.createLineup(input));
      setTabMode(CREATE);
    },
    back() {
      if (!alert.clear) {
        dispatch(alertActions.clear());
      }
      setIsEditing(false);
      setTabMode(LIST);
    },
    edit() {
      if (!alert.clear) {
        dispatch(alertActions.clear());
      }
      setTabMode(EDIT);
    },
  }));

  const emptyList = () => {
    return (
      <Row
        style={{ minHeight: '40vh', textAlign: 'center' }}
        className="vCenter"
      >
        <span>No lineups created.</span>
      </Row>
    );
  };

  const setupBody = () => {
    if (team.lineups.items.length === 0) {
      return emptyList();
    } else {
      return null;
    }
  };

  return (
    <Form onSubmit={null} className="teamLineup">
      {setupBody()}
    </Form>
  );
});
