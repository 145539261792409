import React, { useMemo } from 'react';
import { Col, Row } from 'react-bootstrap';
import { MEDIAURL } from '../../utilities/constants';
import { virtiusV } from '../helpers/logos';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { sessionScore } from '../../utilities/scoring';

const getLineupData = (state, teamSide) => {
  const lineupId = state.producer[teamSide]?.lineupId;
  const lineupData =
    state.session.sessionData.lineups.byId?.[lineupId]?.lineupData;
  return lineupId && lineupData ? JSON.parse(lineupData) : {};
};

const getRound = (state) => state.producer.round;

const getScores = (state) => state.producer.scores;

const getTeamScoring = (state) => state.session.teamScoring;

const createScoreSelector = () =>
  createSelector(
    [getLineupData, getRound, getScores, getTeamScoring],
    (lineupData, round, scores, teamScoring) => {
      const score = scores ? sessionScore(lineupData, round, teamScoring) : 0;
      return score.toFixed(3);
    }
  );

function TeamBanner({ teamSide }) {
  const name = useSelector((state) => state.producer[teamSide].name);
  const logo = useSelector((state) => state.producer[teamSide].logo);
  const selectScore = useMemo(createScoreSelector, []);
  const score = useSelector((state) => selectScore(state, teamSide));

  return (
    <div className="teamBanner">
      <Row style={{ height: '100%' }}>
        <Col className="vCenter logo" xs={2}>
          {logo ? (
            <img src={`${MEDIAURL}${logo}`} alt={name ? `${name} logo` : ''} />
          ) : (
            virtiusV
          )}
        </Col>
        <Col className="vCenter team" xs={6}>
          {name ? name : 'Team ?'}
        </Col>
        <Col className="vCenter score">{score}</Col>
      </Row>
    </div>
  );
}

export default TeamBanner;
