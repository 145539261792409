import { adminConstants } from '../redux/_constants';

export const dataUtilities = {
  sort,
  filter,
  filterNested,
  checkTableChange,
  checkSessionTeamChange,
};

function filter(id, object, array, param_src, param_dst) {
  if (Object.keys(object).length === 0) {
    return {};
  }

  const filtered = array.filter((i) => {
    if (!object.hasOwnProperty(i)) {
      console.log(i);
      return false;
    }
    if (!object[i].hasOwnProperty(param_src)) {
      console.log(i);
      return false;
    }
    return object[i][param_src] === id;
  });
  const result = Object.assign(
    {},
    ...filtered.map((x) => ({ [object[x][param_dst]]: object[x]['id'] }))
  );
  return result;
}

// Filters an array of Ids/ arraof objects by checking JSON parameter for target filter
// Returns array of ids (not objects)
function filterNested(filter, object, array, param) {
  if (Object.keys(object).length === 0) {
    return {};
  }

  const filtered = array.filter((i) => {
    if (!object.hasOwnProperty(i)) {
      console.log(i);
      return false;
    }
    if (!object[i].hasOwnProperty(param)) {
      console.log(i);
      return false;
    }

    const processed = JSON.parse(object[i][param]);
    return Array.isArray(processed) && processed.includes(filter);
  });

  return filtered;
}

function sort(sortBy, object, idArray, newItem) {
  const allIds = Array.from(idArray);

  if (newItem && newItem.id) {
    allIds.push(newItem.id);
    object[newItem.id] = newItem;
  }

  allIds.sort((idA, idB) => {
    const a = object[idA];
    const b = object[idB];
    switch (sortBy) {
      case adminConstants.SORT_NAME_ASC:
        if (a.name.toLowerCase() < b.name.toLowerCase()) {
          return 1;
        }
        if (a.name.toLowerCase() > b.name.toLowerCase()) {
          return -1;
        }
        return 0;
      case adminConstants.SORT_NAME_DESC:
        if (a.name.toLowerCase() < b.name.toLowerCase()) {
          return -1;
        }
        if (a.name.toLowerCase() > b.name.toLowerCase()) {
          return 1;
        }
        return 0;
      default:
        return allIds;
    }
  });
  return allIds;
}

// Checks to see if the deep data changes and also selection changes in
function checkTableChange(initialData, newData, initialSelected, newSelected) {
  // Look for data lines with update true, then compare lines deeply
  // Assume data length does not change only internal elements (not handling deleting lines)

  if (
    !initialData ||
    !newData ||
    !initialSelected ||
    !newSelected ||
    initialData.length !== newData.length
  ) {
    return { updates: null, creates: null, added: null, removed: null };
  }

  const updatedRaw = newData.map((row, i) => {
    if (
      Object.keys(row).every((key) => {
        return (
          initialData[i].hasOwnProperty(key) && initialData[i][key] === row[key]
        );
      })
    ) {
      return null;
    } else {
      return i;
    }
  });

  const updated = updatedRaw.filter((x) => x !== null);

  // Compare selected object by converting to keys, removed are negative values, skip 0
  const initialKeys = Object.keys(initialSelected).map((e) => parseInt(e));
  const newKeys = Object.keys(newSelected).map((e) => parseInt(e));

  const removed = initialKeys.filter(
    (x) =>
      !newKeys.includes(x) ||
      (!newSelected[x] && initialSelected[x] !== newSelected[x])
  ); // Need to check T->F, F->T
  const added = newKeys.filter(
    (x) =>
      !initialKeys.includes(x) ||
      (newSelected[x] && initialSelected[x] !== newSelected[x])
  );

  //console.log({updated: updated, added: added, removed: removed});

  // Now create master list of create/updates
  const updates = [];
  const creates = [];

  for (let i = 0; i < newData.length; i++) {
    if (removed.includes(i) || added.includes(i) || updated.includes(i)) {
      if (newData[i].rosterLinkId) {
        updates.push(i);
      } else {
        creates.push(i);
      }
    }
  }

  console.log({ updates: updates, creates: creates });
  return { updates: updates, creates: creates, added: added, removed: removed };
}

// Checks changes for Team allocations to Sessions, should not be able to change after session is complete
function checkSessionTeamChange(
  newSession,
  prevSession = null,
  sessionTeams = null
) {
  // Handle cases:
  // prev: 0 assigned, new: 0 assigned = no change
  // prev: 0 assigned, new: 1 assigned A/B slot
  // prev: 0 assigned, new: 2 assigned A&B slot
  // prev: 1 assigned, new: 0 assigned
  // prev: 1 assigned, new: 1 assigned = no change
  // prev: 1 assigned, new: 1 assigned = changed
  // prev: 1 assigned, new: 2 assigned A&B slot (could be changed)
  // prev: 2 assigned, new: 0 assigned
  // prev: 2 assigned, new: 1 assigned remove 1
  // prev: 2 assigned, new: 1 assigned remove 1 and reorder
  // prev: 2 assigned, new: 1 assigned remove 1 and change other
  // prev: 2 assigned, new: 2 assigned = no change
  // prev: 2 assigned, new: 2 assigned = swap order
  // prev: 2 assigned, new: 2 assigned = swap order, change 1
  // prev: 2 assigned, new: 2 assigned = change 1 (A or B)
  // prev: 2 assigned, new: 2 assigned = change 2

  // Look for updates first, make sure the teams are sorted by "order" field
  // Need to figure out what to put for an deassigned sessionTeam
  const numTeams = newSession.type === 'SOLO' ? 1 : 2;
  const creates = [];
  const updates = [];

  if (prevSession && sessionTeams) {
    for (let i = 0; i < prevSession.sessionTeams.items.length; i++) {
      if (i >= numTeams) {
        break;
      } //For now only 2 teams, will need to increase later

      let newTeamId = newSession[`team${String.fromCharCode(i + 65)}`];
      //if (!newTeamId) { continue };     // Used to skip unassigned teams
      //compare to previous selection for changes, note: session does not usually contain teamA/teamB property
      if (
        newTeamId ===
        sessionTeams.byId[prevSession.sessionTeams.items?.[i]]?.teamId
      ) {
        continue;
      }

      updates.push({
        id: prevSession.sessionTeams.items[i],
        teamId: newTeamId, // note if this is '' need to check DB reaction
        order: i,
        _version: sessionTeams.byId[prevSession.sessionTeams.items[i]]._version,
      });
    }
  }

  for (let j = updates.length; j < numTeams; j++) {
    let newTeamId = newSession[`team${String.fromCharCode(j + 65)}`];

    // Skip if doesn't exist or if no change and is in the same order as previous
    if (!newTeamId) {
      continue;
    }
    if (
      sessionTeams &&
      prevSession &&
      newTeamId ===
        sessionTeams?.byId[prevSession.sessionTeams.items?.[j]]?.teamId
    ) {
      continue;
    }

    creates.push({
      teamId: newSession[`team${String.fromCharCode(j + 65)}`],
      order: j,
      sessionId: prevSession ? prevSession.id : null, // need to update in service function
    });
  }

  return { updates: updates, creates: creates };
}
