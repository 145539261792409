import { alertConstants } from '../_constants';

export function alert(state = { clear: true }, action) {
  switch (action.type) {
    case alertConstants.SUCCESS:
      return {
        type: 'alert-success',
        message: action.message,
        clear: false,
      };
    case alertConstants.ERROR:
      return {
        type: 'alert-danger',
        message: action.message,
        clear: false,
      };
    case alertConstants.NOTIFICATION:
      return {
        type: 'alert-warning',
        message: action.message,
        clear: false,
      };
    case alertConstants.SYNC:
      return {
        type: 'alert-light',
        message: action.message,
        clear: false,
      };
    case alertConstants.CLEAR:
      return { clear: true };
    default:
      return state;
  }
}
