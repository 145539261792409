import React, { useEffect, useState, useMemo } from 'react';
import { Modal, Button, Row, Spinner, Alert, Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { alertActions, adminActions } from '../../redux/_actions';
import UserForm from './userform';
import TableComponent from '../helpers/tablecomponent';
import { modeType } from '../../utilities/constants';
import { judgeType } from '../../redux/_constants';
import UserDetail from './userdetail';

function UserAdmin(props) {
  const { setIsForm, handleMode } = props;
  const [isLoading, setIsLoading] = useState(true);
  const { LIST, DETAIL, EDIT, DELETE, ADD } = modeType;
  const [mode, setMode] = useState(LIST);
  const [userId, setUserId] = useState(null);
  const { alert } = useSelector((state) => state);
  const { users } = useSelector((state) => state.admin);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!alert.clear) {
      setIsLoading(false);
    }
  }, [alert.clear]);

  useEffect(() => {
    dispatch(alertActions.clear());
  }, [dispatch]);

  useEffect(() => {
    if (users.byId.length === 0) {
      setIsLoading(true);
      dispatch(adminActions.getUsers());
    } else {
      setIsLoading(false);
    }
  }, [users, dispatch]);

  const userDetail = (e, row) => {
    if (!alert.clear) {
      dispatch(alertActions.clear());
    }
    setUserId(row.id);
    setMode(DETAIL);
    setIsForm(true);
  };

  const back = () => {
    setMode(LIST);
    setIsForm(false);
  };

  const add = () => {
    setMode(ADD);
    setIsForm(true);
  };

  const loading = () => {
    return (
      <Row
        style={{ margin: '1rem -15px', minHeight: '20vh', textAlign: 'center' }}
        className="vCenter"
      >
        <div className="vCenter" style={{ margin: '0 auto' }}>
          <Spinner
            variant="secondary"
            role="status"
            animation="border"
            size="md"
          />
        </div>
      </Row>
    );
  };

  const emptyList = () => {
    return (
      <Row
        style={{ margin: '1rem -15px', minHeight: '20vh', textAlign: 'center' }}
        className="vCenter"
      >
        <span>No users created.</span>
      </Row>
    );
  };

  const headers = useMemo(
    () => [
      { Header: '#', accessor: 'col1' },
      { Header: 'Name', accessor: 'col2' },
      { Header: 'Email', accessor: 'col3' },
      { Header: 'Athlete', accessor: 'col4' },
      { Header: 'Coach', accessor: 'col5' },
      { Header: 'Judge', accessor: 'col6' },
      { Header: 'Status', accessor: 'col7' },
    ],
    []
  );

  const data = useMemo(() => {
    return users.allIds.map((id, i) => {
      const { name, email, athlete, coach, judgeType, status } = users.byId[id];

      return {
        col1: i + 1,
        col2: name ? name : '',
        col3: email,
        col4: athlete ? 'Y' : '-',
        col5: coach && coach.items.length > 0 ? 'Y' : '-',
        col6: judgeType && judgeType.length > 0 ? 'Y' : '-',
        col7: status,
        id: id,
      };
    });
  }, [users]);

  const table = () => {
    const initialState = {
      sortBy: [{ id: 'col2', desc: false }],
    };

    return users.allIds.length === 0 ? (
      emptyList()
    ) : (
      <TableComponent
        columns={headers}
        data={data}
        textSort={true}
        onClick={userDetail}
        initialState={initialState}
      />
    );
  };

  const adminBody = () => {
    return (
      <>
        {!alert.clear ? (
          <Row style={{ padding: '0 15px', margin: '1rem -15px' }}>
            <Alert
              dismissible
              onClose={() => dispatch(alertActions.clear())}
              variant={alert.type === 'alert-danger' ? 'danger' : 'success'}
              style={{ textAlign: 'center', width: '100%' }}
            >
              {alert.message}
            </Alert>
          </Row>
        ) : null}
        {isLoading ? loading() : table()}
      </>
    );
  };

  const footer = () => {
    return (
      <>
        <Row className="vCenter" style={{ margin: '0 auto' }}>
          <Button
            variant="outline-primary"
            className="formButton"
            onClick={() => handleMode(null)}
            disabled={isLoading}
          >
            Close
          </Button>
        </Row>
        <Row />
      </>
    );
  };

  const headerMsg = () => {
    return <span>User Admin</span>;
  };

  const listModal = () => {
    return (
      <>
        <Modal.Header className="setupHeader">{headerMsg()}</Modal.Header>
        <Modal.Body className="setupForm">{adminBody()}</Modal.Body>
        <Modal.Footer className="setupFooter">{footer()}</Modal.Footer>
      </>
    );
  };

  const modal = () => {
    switch (mode) {
      case ADD:
        return <UserForm back={back} />;
      case EDIT:
        break;
      case DELETE:
        break;
      case LIST:
        return listModal();
      case DETAIL:
        return <UserDetail back={back} userId={userId} />;
      default:
        return null;
    }
  };

  return modal();
}

export default UserAdmin;
