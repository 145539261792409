export const sessionConstants = {
  CREATE_REQUEST: 'CREATE_SESSION_REQUEST',
  CREATE_SUCCESS: 'CREATE_SESSION_SUCCESS',
  CREATE_FAILURE: 'CREATE_SESSION_FAILURE',
  UPDATE_REQUEST: 'UPDATE_SESSION_REQUEST',
  UPDATE_SUCCESS: 'UPDATE_SESSION_SUCCESS',
  UPDATE_FAILURE: 'UPDATE_SESSION_FAILURE',
  JOIN_REQUEST: 'JOIN_SESSION_REQUEST',
  JOIN_SUCCESS: 'JOIN_SESSION_SUCCESS',
  JOIN_FAILURE: 'JOIN_SESSION_FAILURE',
  LOAD_REQUEST: 'LOAD_SESSION_REQUEST',
  LOAD_SUCCESS: 'LOAD_SESSION_SUCCESS',
  LOAD_FAILURE: 'LOAD_SESSION_FAILURE',
  START_STREAM_REQUEST: 'START_STREAM_SESSION_REQUEST',
  START_STREAM_SUCCESS: 'START_STREAM_SESSION_SUCCESS',
  START_STREAM_FAILURE: 'START_STREAM_SESSION_FAILURE',
  CREATE_STREAM_REQUEST: 'CREATE_STREAM_SESSION_REQUEST',
  CREATE_STREAM_SUCCESS: 'CREATE_STREAM_SESSION_SUCCESS',
  CREATE_STREAM_FAILURE: 'CREATE_STREAM_SESSION_FAILURE',
  DELETE_STREAM_REQUEST: 'DELETE_STREAM_SESSION_REQUEST',
  DELETE_STREAM_SUCCESS: 'DELETE_STREAM_SESSION_SUCCESS',
  DELETE_STREAM_FAILURE: 'DELETE_STREAM_SESSION_FAILURE',
  RESET_STREAM_REQUEST: 'RESET_STREAM_SESSION_REQUEST',
  RESET_STREAM_SUCCESS: 'RESET_STREAM_SESSION_SUCCESS',
  RESET_STREAM_FAILURE: 'RESET_STREAM_SESSION_FAILURE',
  STOP_STREAM_REQUEST: 'STOP_STREAM_SESSION_REQUEST',
  STOP_STREAM_SUCCESS: 'STOP_STREAM_SESSION_SUCCESS',
  STOP_STREAM_FAILURE: 'STOP_STREAM_SESSION_FAILURE',
  CHECK_STREAM_REQUEST: 'CHECK_STREAM_SESSION_REQUEST',
  CHECK_STREAM_SUCCESS: 'CHECK_STREAM_SESSION_SUCCESS',
  CHECK_STREAM_FAILURE: 'CHECK_STREAM_SESSION_FAILURE',
  SUBSCRIBE_REQUEST: 'SUBSCRIBE_SESSION_REQUEST',
  SUBSCRIBE_SUCCESS: 'SUBSCRIBE_SESSION_SUCCESS',
  SUBSCRIBE_FAILURE: 'SUBSCRIBE_SESSION_FAILURE',
  UNSUBSCRIBE_REQUEST: 'UNSUBSCRIBE_SESSION_REQUEST',
  UNSUBSCRIBE_SUCCESS: 'UNSUBSCRIBE_SESSION_SUCCESS',
  UNSUBSCRIBE_FAILURE: 'UNSUBSCRIBE_SESSION_FAILURE',
  SUBSCRIBE_STREAM_REQUEST: 'SUBSCRIBE_STREAM_SESSION_REQUEST',
  SUBSCRIBE_STREAM_SUCCESS: 'SUBSCRIBE_STREAM_SESSION_SUCCESS',
  SUBSCRIBE_STREAM_FAILURE: 'SUBSCRIBE_STREAM_SESSION_FAILURE',
  UNSUBSCRIBE_STREAM_REQUEST: 'UNSUBSCRIBE_STREAM_SESSION_REQUEST',
  UNSUBSCRIBE_STREAM_SUCCESS: 'UNSUBSCRIBE_STREAM_SESSION_SUCCESS',
  UNSUBSCRIBE_STREAM_FAILURE: 'UNSUBSCRIBE_STREAM_SESSION_FAILURE',
  SUBSCRIBE_NEW_STREAM_REQUEST: 'SUBSCRIBE_NEW_STREAM_SESSION_REQUEST',
  SUBSCRIBE_NEW_STREAM_SUCCESS: 'SUBSCRIBE_NEW_STREAM_SESSION_SUCCESS',
  SUBSCRIBE_NEW_STREAM_FAILURE: 'SUBSCRIBE_NEW_STREAM_SESSION_FAILURE',
  UNSUBSCRIBE_NEW_STREAM_REQUEST: 'UNSUBSCRIBE_NEW_STREAM_SESSION_REQUEST',
  UNSUBSCRIBE_NEW_STREAM_SUCCESS: 'UNSUBSCRIBE_NEW_STREAM_SESSION_SUCCESS',
  UNSUBSCRIBE_NEW_STREAM_FAILURE: 'UNSUBSCRIBE_NEW_STREAM_SESSION_FAILURE',
  SYNC_STREAM_STATUS_SUCCESS: 'SYNC_STREAM_STATUS_SESSION_SUCCESS',
  SYNC_NEW_STREAM_SUCCESS: 'SYNC_NEW_STREAM_SESSION_SUCCESS',
  SYNC_NEW_STREAM_BYPASS: 'SYNC_NEW_STREAM_SESSION_BYPASS',
  GET_TEAMS_REQUEST: 'GET_TEAMS_SESSION_REQUEST',
  GET_TEAMS_SUCCESS: 'GET_TEAMS_SESSION_SUCCESS',
  GET_TEAMS_FAILURE: 'GET_TEAMS_SESSION_FAILURE',
  CREATE_LINEUP_REQUEST: 'CREATE_LINEUP_SESSION_REQUEST',
  CREATE_LINEUP_SUCCESS: 'CREATE_LINEUP_SESSION_SUCCESS',
  CREATE_LINEUP_FAILURE: 'CREATE_LINEUP_SESSION_FAILURE',
  UPDATE_LINEUP_REQUEST: 'UPDATE_LINEUP_SESSION_REQUEST',
  UPDATE_LINEUP_SUCCESS: 'UPDATE_LINEUP_SESSION_SUCCESS',
  UPDATE_LINEUP_FAILURE: 'UPDATE_LINEUP_SESSION_FAILURE',
  SUBSCRIBE_LINEUP_REQUEST: 'SUBSCRIBE_LINEUP_SESSION_REQUEST',
  SUBSCRIBE_LINEUP_SUCCESS: 'SUBSCRIBE_LINEUP_SESSION_SUCCESS',
  SUBSCRIBE_LINEUP_FAILURE: 'SUBSCRIBE_LINEUP_SESSION_FAILURE',
  UNSUBSCRIBE_LINEUP_REQUEST: 'UNSUBSCRIBE_LINEUP_SESSION_REQUEST',
  UNSUBSCRIBE_LINEUP_SUCCESS: 'UNSUBSCRIBE_LINEUP_SESSION_SUCCESS',
  UNSUBSCRIBE_LINEUP_FAILURE: 'UNSUBSCRIBE_LINEUP_SESSION_FAILURE',
  SYNC_LINEUP_SUCCESS: 'SYNC_LINEUP_SESSION_SUCCESS',
  SYNC_SESSION_SUCCESS: 'SYNC_SESSION_SESSION_SUCCESS',
  UPDATE_PRODUCER_REQUEST: 'UPDATE_PRODUCER_SESSION_REQUEST',
  UPDATE_PRODUCER_SUCCESS: 'UPDATE_PRODUCER_SESSION_SUCCESS',
  UPDATE_PRODUCER_FAILURE: 'UPDATE_PRODUCER_SESSION_FAILURE',
  CHANGE_VIEW: 'CHANGE_VIEW_SESSION',
};

export const sessionType = {
  SOLO: 'SOLO',
  DUAL: 'DUAL',
  MULTI: 'MULTI',
};

export const streamStatus = {
  OFF: 'OFF',
  STARTING: 'STARTING',
  STARTED: 'STARTED',
  STOPPING: 'STOPPING',
  STOPPED: 'STOPPED',
  RESETTING: 'RESETTING',
  DELETING: 'DELETING',
  DELETED: 'DELETED',
  CREATING: 'CREATING',
  ERROR: 'ERROR',
  ARCHIVED: 'ARCHIVED',
};

export const streamRequestType = {
  CREATE: 'CREATE',
  START: 'START',
  RESET: 'RESET',
  DELETE: 'DELETE',
  STOP: 'STOP',
  CHECK: 'CHECK',
  METRICS: 'METRICS',
  CHECKVOD: 'CHECKVOD',
  CHECKRECORDING: 'CHECKRECORDING',
  ARCHIVE: 'ARCHIVE',
};

export const genderType = {
  FEMALE: 'FEMALE',
  MALE: 'MALE',
  COED: 'COED',
};

export const sessionRoleType = {
  ADMIN: 'ADMIN',
  JUDGE: 'JUDGE',
  PRODUCER: 'PRODUCER',
  FAN: 'FAN',
  COACH: 'COACH',
  ANNOUNCER: 'ANNOUNCER',
};

export const accessType = {
  PRIVATE: 'PRIVATE',
  PUBLIC: 'PUBLIC',
  KEY: 'KEY',
  GUESTLIST: 'GUESTLIST',
};

export const sessionStatus = {
  CREATED: 'CREATED',
  PREGAME: 'PREGAME',
  LIVE: 'LIVE',
  POSTGAME: 'POSTGAME',
  ARCHIVED: 'ARCHIVED',
  CANCELED: 'CANCELED',
  DELETED: 'DELETED',
};

export const sessionViewType = {
  FULLSCREEN: 'FUllSCREEN',
  SCOREBOARD: 'SCOREBOARD',
  DEFAULT: 'DEFAULT',
  THEATER: 'THEATER',
};

export const teamScoreType = {
  TOP5OF6: 'TOP5OF6',
  TOP5: 'TOP5',
  TOP4: 'TOP4',
  ALL: 'ALL',
};

export const initialSessionState = {
  type: null,
  startAt: null,
  endAt: null,
  access: accessType.PRIVATE,
  now: null,
  name: null,
  competition: null,
  gender: genderType.MALE,
  status: null,
  streamA: null,
  streamAStatus: streamStatus.OFF,
  streamBStatus: streamStatus.OFF,
  streamAsub: null,
  streamBsub: null,
  streamB: null,
  judging: null,
  judgingRequired: null,
  roster: null,
  apparatus: null,
  id: null,
  sessionKey: null,
  load: null,
  request: null,
  sessionSub: null,
  newStreamsSub: null,
  sessionData: null,
  streamData: null,
  lineupAsub: null,
  lineupBsub: null,
  role: sessionRoleType.FAN,
  producer: null,
  view: sessionViewType.DEFAULT,
  teamScoring: teamScoreType.TOP5OF6,
};
