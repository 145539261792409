import React from 'react';
import MatchUp from './matchup';
import { matches } from '../../utilities/homepage';

function MatchupGrid() {
  return (
    <div className="heroGrid">
      <ul>
        {matches.map((matchRow, i) => {
          let headText = '';

          if (i === 0) {
            headText = 'upcoming';
          }

          if (i === 1) {
            headText = 'recent';
          }

          return (
            <li key={i}>
              <h5>{headText}</h5>
              {matchRow.map((match, j) => {
                return <MatchUp match={match} key={j} />;
              })}
            </li>
          );
        })}
      </ul>
    </div>
  );
}

export default MatchupGrid;
