import React, { useState, useEffect } from 'react';
import { Modal, Button, Row, Spinner, Alert, Form } from 'react-bootstrap';
import LoginModalFooter from './loginmodalfooter';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { eye, eyeSlash } from '../helpers/icons';
import { useDispatch, useSelector } from 'react-redux';
import { userActions, alertActions } from '../../redux/_actions';
import { uiState } from '../../redux/_constants';
import InputAutofocus from '../helpers/inputautofocus';
import { useHistory } from 'react-router-dom';
import { useQueryParams } from '../../utilities/query';

function Login() {
  const alert = useSelector((state) => state.alert);
  const [isAuthenticating, setIsAuthenticating] = useState(false);
  const [showPW, setShowPW] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const query = useQueryParams();

  useEffect(() => {
    if (!alert.clear) {
      setIsAuthenticating(false);
    }
  }, [alert.clear]);

  const togglePW = () => {
    setShowPW(!showPW);
  };

  const handleMode = (mode) => {
    history.push(mode);
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email').required('Required'),
    password: Yup.string().required('Required'),
  });

  const {
    setFieldValue,
    handleSubmit,
    handleChange,
    values,
    errors,
  } = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit(values) {
      setIsAuthenticating(true);
      dispatch(alertActions.clear());
      dispatch(userActions.login(values, history));
    },
  });

  // If comes with email parameter then means no user credentials
  useEffect(() => {
    if (query.get('email')) {
      setFieldValue('email', query.get('email'));
    }
  }, [setFieldValue, query]);

  const loginBody = () => {
    return (
      <Form onSubmit={handleSubmit}>
        {!alert.clear ? (
          <Row>
            <Alert
              dismissible
              onClose={() => dispatch(alertActions.clear())}
              variant={alert.type === 'alert-danger' ? 'danger' : 'success'}
            >
              {alert.message}
            </Alert>
          </Row>
        ) : null}
        <Row>
          <label>
            Email
            {errors.email ? (
              <span className="form-error">{errors.email}</span>
            ) : null}
          </label>
          {InputAutofocus({
            name: 'email',
            value: values.email,
            handler: handleChange,
            disabled: isAuthenticating,
          })}
        </Row>
        <Row>
          <label>
            Password
            {errors.password ? (
              <span className="form-error">{errors.password}</span>
            ) : null}
          </label>
          <div className="password-container">
            <input
              name="password"
              type={showPW ? 'text' : 'password'}
              autoComplete="off"
              onChange={handleChange}
              values={values.password}
              disabled={isAuthenticating}
            />
            <i onClick={togglePW}>{showPW ? eyeSlash : eye}</i>
          </div>
          <button
            type="button"
            className="forgotPassword"
            disabled={isAuthenticating}
            onClick={() => handleMode(uiState.FORGOT_PW)}
          >
            Forgot your password?
          </button>
        </Row>
        <Row>
          <Button
            variant="outline-primary"
            className="loginButton"
            type="submit"
            disabled={isAuthenticating}
          >
            {isAuthenticating ? (
              <>
                {'Logging in... '}
                <Spinner
                  variant="primary"
                  role="status"
                  animation="border"
                  size="sm"
                />
              </>
            ) : (
              'Login'
            )}
          </Button>
        </Row>
        <Row>
          <div className="newToVirtius">
            <span>New to Virtius?</span>
            <button
              type="button"
              className="signUp"
              onClick={() => handleMode(uiState.SIGNUP)}
              disabled={isAuthenticating}
            >
              Sign Up
            </button>
          </div>
        </Row>
      </Form>
    );
  };

  const headerMsg = () => {
    const virtius = (
      <>
        <span className={'vBlue'}>V</span>
        <span className={'vGray'}>irtius</span>
      </>
    );

    return (
      <>
        <span>Welcome back to&nbsp;</span>
        {virtius}
        <span>!</span>
      </>
    );
  };

  return (
    <>
      <Modal.Header className="loginHeader">{headerMsg()}</Modal.Header>
      <Modal.Body className="loginForm">{loginBody()}</Modal.Body>
      <Modal.Footer className="loginSocial">
        <LoginModalFooter
          disabled={isAuthenticating}
          login={setIsAuthenticating}
        />
      </Modal.Footer>
    </>
  );
}

export default Login;
