import React, { useState } from 'react';
import { Toast, Row } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { alertActions } from '../../redux/_actions';
import { alertType } from '../../redux/_constants';

function ToastStack(props) {
  const { alert } = useSelector((state) => state);
  const dispatch = useDispatch();
  const { SUCCESS, ERROR, NOTIFICATION, SYNC } = alertType;

  const header = () => {
    switch (alert.type) {
      case ERROR:
        return 'Error';
      case SUCCESS:
        return 'Success';
      case NOTIFICATION:
        return 'Notification';
      case SYNC:
        return 'Sync';
      default:
        return 'Notification';
    }
  };

  const toastClass = () => {
    switch (alert.type) {
      case ERROR:
        return 'error';
      case SUCCESS:
        return 'success';
      case NOTIFICATION:
        return 'warning';
      default:
        return 'Notification';
    }
  };

  return (
    <div className="toastStack">
      <Row className="toastRow">
        <Toast
          onClose={() => dispatch(alertActions.clear())}
          show={!alert.clear}
          animation={false}
          className={toastClass()}
          delay={5000}
          autohide
        >
          <Toast.Header>
            <strong className="mr-auto">{header()}:&nbsp;</strong>
            <span className="mr-auto">{alert.message}</span>
          </Toast.Header>
        </Toast>
      </Row>
    </div>
  );
}

export default ToastStack;
