import React, { useRef, useEffect, useState } from 'react';
import ReactPlayer from 'react-player';
import { Button, Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { videoPlayerActions } from '../../redux/_actions';
import {
  streamStatus,
  sessionType,
  resolutionType,
  sessionStatus,
  sessionRoleType,
  playerType,
} from '../../redux/_constants';
import VideoSetup from '../setup/videosetup';
import { dateTime, timeWithSeconds } from '../../utilities/conversions';
import { winLoseTie } from '../../utilities/scoring';
import { POLLING_INTERVAL } from '../../utilities/constants';
import { useInterval } from '../../utilities/hooks';
import { inquiryIcon, gearIcon } from '../helpers/icons';
import Player from '../helpers/theoplayer';

function VideoPlayer(props) {
  const { THEO_PLAYER, REACT_PLAYER } = playerType;
  const { team } = props;
  const dispatch = useDispatch();
  const videoPlayer = useSelector((state) => state.videoPlayer);
  const producer = useSelector((state) => state.producer);
  const session = useSelector((state) => state.session);
  const [isReady, setIsReady] = useState(false);
  const [statsBtn, setStatsBtn] = useState(false);
  const [setupBtn, setSetupBtn] = useState(false);
  const [playerMsg, setPlayerMsg] = useState('Get ready for dual.');
  const [firstPlay, setFirstPlay] = useState(true);
  const [setup, setSetup] = useState(false);
  const { CREATED, PREGAME, LIVE, POSTGAME } = sessionStatus;
  const { ADMIN, JUDGE, PRODUCER, FAN, COACH, ANNOUNCER } = sessionRoleType;
  const [statsTimeout, setStatsTimeout] = useState(0);
  const [currentPlayerType, setCurrentPlayerType] = useState(
    session.status === POSTGAME ? REACT_PLAYER : THEO_PLAYER
  );
  const [stats, setStats] = useState({
    pgmtime: null,
    date: null,
    time: null,
    sn: null,
    duration: null,
    resolution: null,
    bitrate: null,
    bandwidth: null,
    now: Date.now(),
    delta: null,
    lastTime: null,
    lag: null,
  });
  const id = team && team.home ? 'A' : 'B';
  const thisPlayer = videoPlayer[`player${id}`];
  const playerRef = useRef();

  const isOn =
    producer.onAirAthlete === 0
      ? true
      : producer.onAirAthlete % 2 === 0
      ? id === 'A'
        ? false
        : true
      : id === 'B'
      ? false
      : true;

  useEffect(() => {
    // Probably could turn this into a state machine with some time/thinking
    let msg = '';

    switch (session.status) {
      case CREATED:
      case PREGAME:
        msg = 'Welcome to the Virtius pregame.';
        break;
      case LIVE:
        if (!url()) {
          msg = 'Stream not created.';
          break;
        }

        if (session.type === 'SOLO' && session[`streamAStatus`] !== 'STARTED') {
          msg = 'Stream offline.';
          break;
        }

        if (
          session.type === 'DUAL' &&
          session[`stream${id}Status`] !== 'STARTED'
        ) {
          msg = 'Stream offline.';
          break;
        }

        if (producer.live) {
          if (producer.doneEvalAthletes[id].length === team.lineup.length) {
            msg = 'Round complete.';
          } else if (isOn) {
            if (producer.onAir) {
              msg = '';
            } else {
              if (producer.onEvalAthletes[id]) {
                msg = 'Waiting for score.';
              } else {
                msg = 'Judges and athlete ready.';
              }
            }
          } else {
            if (producer.onEvalAthletes[id]) {
              msg = 'Waiting for score.';
            } else {
              msg = 'Standby.';
            }
          }
        } else {
          if (
            producer.completedRounds.length ===
            session.apparatus.split('').filter((e) => e === '1').length
          ) {
            const lineupA =
              producer.teamA.lineupId &&
              session.sessionData.lineups.byId?.[producer.teamA.lineupId]
                .lineupData
                ? JSON.parse(
                    session.sessionData.lineups.byId?.[producer.teamA.lineupId]
                      .lineupData
                  )
                : {};
            const lineupB =
              producer.teamB.lineupId &&
              session.sessionData.lineups.byId?.[producer.teamB.lineupId]
                .lineupData
                ? JSON.parse(
                    session.sessionData.lineups.byId?.[producer.teamB.lineupId]
                      .lineupData
                  )
                : {};

            if (id === 'A') {
              msg = winLoseTie(lineupA, lineupB);
            }

            if (id === 'B') {
              msg = winLoseTie(lineupB, lineupA);
            }
          } else if (
            producer.doneEvalAthletes[id].length === team.lineup.length
          ) {
            msg = 'Move to next round.';
          } else {
            msg = 'Round will begin soon.';
          }
        }
        break;
      case POSTGAME:
        if (firstPlay) {
          msg = 'Welcome to the Virtius postgame. ';
        } else {
          msg = '';
        }
        break;
      default:
        msg = 'Get ready for dual.';
        break;
    }

    setPlayerMsg(msg);
  }, [
    session.status,
    producer,
    isOn,
    session.streamAStatus,
    session.streamBStatus,
    firstPlay,
  ]);

  useEffect(() => {
    if (isReady && playerRef.current && thisPlayer.seekTime !== null) {
      if (currentPlayerType === THEO_PLAYER) {
        playerRef.current._player.currentTime = thisPlayer.seekTime;
      }
      if (currentPlayerType === REACT_PLAYER) {
        playerRef.current.seekTo(thisPlayer.seekTime, 'seconds');
      }

      if (thisPlayer.autoplay) {
        if (currentPlayerType === THEO_PLAYER) {
          playerRef.current._player.play();
        }
        if (currentPlayerType === REACT_PLAYER) {
          playerRef.current.playing = true;
          dispatch(videoPlayerActions.play(id));
        }
        if (session.status === sessionStatus.POSTGAME) {
          setFirstPlay(false);
        }
      }
      // Need to reset seekTime for next time
      dispatch(videoPlayerActions.seek(null, id));
    }
  }, [thisPlayer.seekTime, thisPlayer.autoplay, isReady, dispatch, id]);

  // Put the ref into Redux state for access?
  useEffect(() => {
    if (isReady && playerRef.current) {
      dispatch(videoPlayerActions.setRef(playerRef.current, id));
    }

    return () => {
      dispatch(videoPlayerActions.setRef(null, id));
    };
  }, [isReady, id, dispatch]);

  // Change player when going from live to postgame
  useEffect(() => {
    if (session.status === POSTGAME) {
      setCurrentPlayerType(REACT_PLAYER);
    } else {
      setCurrentPlayerType(THEO_PLAYER);
    }
  }, [session.status]);

  // Used to update the streaming video data if in debug mode via polling
  // handle polling for channel A and B for status update

  const updateStatsHLS = () => {
    const hls = playerRef?.current?.getInternalPlayer('hls');
    const streamCtlr = hls?.streamController;
    const frag = streamCtlr?.fragPlaying;
    const pgmTime = (frag && new Date(frag?.programDateTime)) ?? null;
    const lastTime = streamCtlr?.lastCurrentTime;
    const delta = (lastTime && lastTime - frag?.start) ?? null; // in seconds
    const duration = frag?.duration;
    const now = Date.now(); // in ms;
    const lag =
      (pgmTime && delta && (now - pgmTime - delta * 1000) / 1000) ?? null;

    //console.log(`HLS ${id}`, hls);

    if (stats.lastTime === lastTime) {
      if (statsTimeout < 2) {
        setStatsTimeout(statsTimeout + 1);
      } else {
        setStatsTimeout(-1);
      }
    }

    setStats({
      pgmtime: pgmTime,
      date: frag ? dateTime(pgmTime, false) : null,
      time: frag ? timeWithSeconds(pgmTime) : null,
      sn: frag ? frag?.sn : null,
      duration: frag ? duration.toFixed(3) : null,
      resolution: hls
        ? resolutionType[hls?.levels?.[hls?.loadLevel]?.attrs?.RESOLUTION]
        : null,
      bitrate:
        (hls &&
          (hls?.levels?.[hls?.loadLevel]?.bitrate / 1000000).toFixed(3)) ??
        null,
      bandwidth: hls ? (hls?.bandwidthEstimate / 1000000).toFixed(1) : null,
      now: now,
      delta:
        frag && streamCtlr && Math.abs(delta) <= duration
          ? delta.toFixed(3)
          : null,
      lastTime: streamCtlr ? streamCtlr.lastCurrentTime : null,
      lag:
        [LIVE, PREGAME, CREATED].includes(session.status) && lag !== null
          ? lag.toFixed(3)
          : null,
      // Note: lastCurrentTime is set right away even on seek, frag has lag to update
    });
  };

  const updateStatsTheo = () => {
    const hls = playerRef?.current?._player;
    const pgmTime = hls?.currentProgramDateTime ?? null;
    const lastTime = hls?.currentTime;
    const now = Date.now(); // in ms
    const lag =
      hls?.duration === Infinity ? pgmTime && (now - pgmTime) / 1000 : null;

    //console.log(hls)

    /*
    const delta = (lastTime && (lastTime - frag?.start)) ?? null;     // in seconds
    const duration = frag?.duration;
    const now = Date.now();  // in ms;
    const lag = (pgmTime && delta && (now - pgmTime - delta * 1000) / 1000) ?? null;

    console.log(`Theo ${id}`, hls);
    */

    if (stats.lastTime === lastTime) {
      if (statsTimeout < 2) {
        setStatsTimeout(statsTimeout + 1);
      } else {
        setStatsTimeout(-1);
      }
    }

    setStats({
      pgmtime: pgmTime,
      date: pgmTime ? dateTime(pgmTime, false) : null,
      time: pgmTime ? timeWithSeconds(pgmTime) : null,
      lastTime: pgmTime ? lastTime : null,
      resolution: hls
        ? resolutionType[`${hls?.videoWidth}x${hls?.videoHeight}`]
        : null,
      now: now,
      lag: session.status !== POSTGAME && lag !== null ? lag.toFixed(3) : null,
      bitrate:
        (hls &&
          (hls?.videoTracks?.[0]?.activeQuality?.bandwidth / 1000000).toFixed(
            3
          )) ??
        null,
      bandwidth: hls
        ? (hls?.metrics?.currentBandwidthEstimate / 1000000).toFixed(1)
        : null,
      //sn: frag ? frag?.sn : null,
    });
  };

  const updateStats = () => {
    switch (currentPlayerType) {
      case playerType.REACT_PLAYER:
        return updateStatsHLS();
      case playerType.THEO_PLAYER:
      default:
        return updateStatsTheo();
    }
  };

  //console.log(thisPlayer?.ref)

  useInterval(
    async () => {
      updateStats();
    },
    POLLING_INTERVAL.SECOND,
    ((currentPlayerType === REACT_PLAYER &&
      thisPlayer?.ref?.getInternalPlayer) ||
      (currentPlayerType === THEO_PLAYER && thisPlayer?.ref?._player)) &&
      statsBtn &&
      (statsTimeout !== -1 || thisPlayer.play)
  );

  const handlePlay = () => {
    dispatch(videoPlayerActions.play(id));
  };

  const handlePause = () => {
    dispatch(videoPlayerActions.pause(id));
  };

  const handleSeek = (seconds) => {
    updateStats();
    setStatsTimeout(0);
  };

  const handleError = (e) => {
    console.log('Player Error: ', e);
    let errorMessage = 'There was an error playing the stream.';

    switch (e.type) {
      case 'error': // general player error (e.g. client going offline)
        switch (e.errorObject.code) {
          case 5002: // Could not load manifest, probably pulled the internet connection
            // player handles this by displaying video based message
            errorMessage = 'Could not load stream.  Check internet connection.';
            break;
          default:
            errorMessage = 'There was a player error.';
            break;
        }
        break;
      case 'THEOnetworkerror': // network related error (e.g. next chunk 404 error)
        switch (e.detail.status) {
          case 404:
            errorMessage = 'Stream target not found.';
            break;
          default:
            errorMessage = 'There was a network error with the stream.';
            break;
        }
        break;
      default:
        errorMessage = 'There was an error playing the stream.';
        break;
    }

    // Display message in videoplayer itself
    /*
    var errorMsgBox = thisPlayer?.ref?._player.element.parentNode.querySelector('.vjs-error-display .vjs-modal-dialog-content');
    if (errorMessage && errorMsgBox) {
      errorMsgBox.innerText = errorMessage;
    }
    */
  };

  const url = () => {
    const stream = session[`stream${id}`];
    const status = session[`stream${id}Status`];

    // For live streaming
    if (session.type === 'SOLO' && session.status === 'LIVE') {
      if (session.streamA && session.streamA.outputURL) {
        return session.streamA.outputURL;
      }
      return null;
    }

    if (session.type === 'DUAL' && session.status === 'LIVE') {
      if (stream && stream.outputURL) {
        return stream.outputURL;
      }
      return null;
    }

    // For postgame VOD
    if (stream && stream.vodURL && videoPlayer.controller.vod) {
      return stream.vodURL;
    }

    // For SOLO mirroring A to B
    if (id === 'B' && !stream && videoPlayer.controller.vod) {
      return session?.[`streamA`]?.vodURL;
    }

    return null;
  };

  const playerReady = () => {
    setIsReady(true);
  };

  const handleOpenSetup = () => {
    setSetup(true);
  };

  const handleToggleStats = () => {
    // Update immediately
    if (!statsBtn) {
      updateStats();
      setStatsTimeout(0);
    }

    setStatsBtn(!statsBtn);
  };

  const handleToggleSetup = () => {
    setSetup(true);
    setSetupBtn(!setupBtn);
  };

  const handleClearModal = () => {
    if (session.status === POSTGAME) {
      setPlayerMsg('');
      setFirstPlay(false);
      dispatch(videoPlayerActions.play(id));
    }
  };

  const streamControls = () => {
    return (
      <>
        <div className="streamStatus">
          <span>Status: {session[`stream${id}Status`]}</span>
        </div>
        <div className="streamSetupButton">
          <Button variant="outline-secondary" onClick={handleOpenSetup}>
            Streaming Setup
          </Button>
        </div>
      </>
    );
  };

  const streamMetrics = () => {
    return (
      <div className={['streamMetrics', statsBtn ? 'max' : null].join(' ')}>
        <Button variant="dark" onClick={handleToggleStats}>
          {statsBtn ? (
            <>
              <p>Prgm Date: {stats.date ?? '- -'}</p>
              <p>Prgm Time: {stats.time ?? '- -'}</p>
              <p>
                Segm Info:{' '}
                {`${stats.sn ? '#' : ''}${stats.sn ?? '- -'} / ${
                  stats.duration ?? '- -'
                }${stats.duration ? 's' : ''}`}{' '}
              </p>
              <p>
                Segm ΔT: {`${stats.delta ?? '- -'}${stats.delta ? 's' : ''}`}{' '}
              </p>
              <p>HD @ FPS: {stats.resolution ?? '- -'} </p>
              <p>
                Bitrate:{' '}
                {`${stats.bitrate ?? '- -'}${stats.bitrate ? 'Mbps' : ''}`}{' '}
              </p>
              <p>
                DL Speed:{' '}
                {`${stats.bandwidth ?? '- -'}${stats.bandwidth ? 'Mbps' : ''}`}{' '}
              </p>
              <p>Real ΔT: {`${stats.lag ?? '- -'}${stats.lag ? 's' : ''}`} </p>
            </>
          ) : (
            inquiryIcon
          )}
        </Button>
      </div>
    );
  };

  const streamSetup = () => {
    return (
      <div className={'streamSetup'}>
        <Button variant="dark" onClick={handleToggleSetup}>
          {gearIcon}
        </Button>
      </div>
    );
  };

  const messagingModal = () => {
    return (
      <div
        className="playerModal"
        onClick={handleClearModal}
        style={{
          cursor: session.status === sessionStatus.POSTGAME ? 'pointer' : null,
        }}
      >
        <div className="playerMessage">{playerMsg}</div>
      </div>
    );
  };

  const theoPlayer = () => {
    return (
      <Player
        ref={playerRef}
        className="videoPlayer"
        source={{
          hlsDateRange: true,
          sources: [
            {
              src: url(),
              //src: "https://moctobpltc-i.akamaihd.net/hls/live/571329/eight/playlist.m3u8",
              //src: "https://cdn3.wowza.com/2/MmRWdkhpeG9DMzdm/MHpFQmVy/hls/dhzw0yff/playlist.m3u8",
              //src: "https://cdn3.wowza.com/1/SGZKbHNkdGhSUWdr/d3Nld1VZ/hls/live/playlist.m3u8",
              //src: "",
              type: 'application/x-mpegurl',
            },
          ],
          metaData: {
            wowza: {
              jsonUrl:
                'https://player.cloud.wowza.com/hosted/5xq4hvhc/wowza.json',
              retry: 3000,
              offlineText: 'The stream is currently not available. :(',
              placeholderImageUrl:
                'https://www.wowza.com/vodassets/get_started_wcs/get_started_wcs.jpg',
              datachangeCallback: console.log,
              statechangeCallback: console.log,
            },
          },
        }}
        onPlay={handlePlay}
        onPause={handlePause}
        onReady={playerReady}
        onError={handleError}
        onSeek={handleSeek}
        muted={true}
      />
    );
  };

  const hlsPlayer = () => {
    return (
      <ReactPlayer
        ref={playerRef}
        className="videoPlayer"
        url={url()}
        controls={true}
        width="100%"
        height="100%"
        playing={thisPlayer.play}
        onReady={playerReady}
        onStart={handlePlay}
        onPlay={handlePlay}
        //muted={videoPlayer.controller.sync && id === 'B'}
        muted={false}
        onPause={handlePause}
        progressInterval={1000}
        autoPlay={true}
        onProgress={(e) =>
          dispatch(videoPlayerActions.currentTime(id, e.playedSeconds))
        }
        //onProgress{(e) => }
        onSeek={handleSeek}
      />
    );
  };

  return (
    <div className="videoWrapper">
      {currentPlayerType === REACT_PLAYER ? hlsPlayer() : theoPlayer()}
      {playerMsg ? messagingModal() : null}
      {session.role === ADMIN ? streamMetrics() : null}
      {session.role === ADMIN && session.status !== POSTGAME
        ? streamSetup()
        : null}

      {
        <Modal
          show={setup}
          onHide={() => setSetup(false)}
          centered
          dialogClassName="setupModal"
        >
          <VideoSetup />
        </Modal>
      }
    </div>
  );
}

export default VideoPlayer;
