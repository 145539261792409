import React, { useEffect, useState, useMemo } from 'react';
import { Modal, Button, Row, Spinner, Alert } from 'react-bootstrap';
//import { plusIcon } from '../helpers/icons';
import { useDispatch, useSelector } from 'react-redux';
import { alertActions, adminActions } from '../../redux/_actions';
import AthleteForm from './athleteform';
import AthleteDetail from './athletedetail';
import TableComponent from '../helpers/tablecomponent';
import { displayName } from '../../utilities/conversions';

const modeType = {
  ADD: 'ADD',
  EDIT: 'EDIT',
  DELETE: 'DELETE',
  LIST: 'LIST',
  DETAIL: 'DETAIL',
};

function AthleteAdmin(props) {
  const { setIsForm } = props;
  const [isLoading, setIsLoading] = useState(true);
  const [athleteId, setAthleteId] = useState(null);
  const [mode, setMode] = useState(modeType.LIST);
  const { alert } = useSelector((state) => state);
  const { athletes, teams } = useSelector((state) => state.admin);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!alert.clear) {
      setIsLoading(false);
    }
  }, [alert.clear]);

  useEffect(() => {
    dispatch(alertActions.clear());
  }, [dispatch]);

  useEffect(() => {
    if (athletes.byId.length === 0) {
      setIsLoading(true);
      dispatch(adminActions.getAthletes());
    } else {
      setIsLoading(false);
    }
  }, [athletes, dispatch]);

  // Check to see if any Team Data has been loaded
  useEffect(() => {
    if (teams.allIds.length === 0) {
      dispatch(adminActions.getTeams());
    }
  }, [dispatch, teams]);

  const back = () => {
    if (!alert.clear) {
      dispatch(alertActions.clear());
    }
    setMode(modeType.LIST);
    setIsForm(false);
  };

  const add = () => {
    if (!alert.clear) {
      dispatch(alertActions.clear());
    }
    setMode(modeType.ADD);
    setIsForm(true);
  };

  const athleteDetail = (e, row) => {
    if (!alert.clear) {
      dispatch(alertActions.clear());
    }
    setAthleteId(row.id);
    setMode(modeType.DETAIL);
    setIsForm(true);
  };

  const loading = () => {
    return (
      <Row
        style={{ margin: '1rem -15px', minHeight: '20vh', textAlign: 'center' }}
        className="vCenter"
      >
        <div className="vCenter" style={{ margin: '0 auto' }}>
          <Spinner
            variant="secondary"
            role="status"
            animation="border"
            size="md"
          />
        </div>
      </Row>
    );
  };

  const emptyList = () => {
    return (
      <Row
        style={{ margin: '1rem -15px', minHeight: '20vh', textAlign: 'center' }}
        className="vCenter"
      >
        <span>No athletes added.</span>
      </Row>
    );
  };

  const headers = useMemo(
    () => [
      { Header: '#', accessor: 'col1' },
      { Header: 'Name', accessor: 'col2' },
      { Header: 'Gender', accessor: 'col3' },
      { Header: 'Teams', accessor: 'col4' },
      { Header: 'User', accessor: 'col5' },
      { Header: 'Type', accessor: 'col6' },
      { Header: 'Img', accessor: 'col7' },
    ],
    []
  );

  const data = useMemo(() => {
    return athletes.allIds.map((id, i) => {
      const {
        name,
        gender,
        teamAffiliations,
        userId,
        type,
        profileImg,
      } = athletes.byId[id];
      const teamsParsed = teamAffiliations
        ? JSON.parse(teamAffiliations)
        : null;

      return {
        col1: i + 1,
        col2: name,
        col3: gender,
        //col4: teamsParsed ? teamsParsed.length : "N/A",
        col4: teamsParsed
          ? teamsParsed
              .map((id) =>
                displayName(teams.byId[id].name, teams.byId[id].altNames)
              )
              .sort((a, b) => {
                if (a.type < b.type) {
                  return -1;
                }
                if (a.type > b.type) {
                  return 1;
                }
                return 0;
              })
              .join(', ')
          : 'N/A',
        col5: userId ? 'Yes' : 'No',
        col6: type ? type : 'N/A',
        col7: profileImg ? 'Yes' : 'No',
        id: id,
      };
    });
  }, [athletes]);

  const table = () => {
    const initialState = {
      sortBy: [{ id: 'col2', desc: false }],
    };

    return athletes.allIds.length === 0 ? (
      emptyList()
    ) : (
      <TableComponent
        columns={headers}
        data={data}
        onClick={athleteDetail}
        initialState={initialState}
        textSort={true}
      />
    );
  };

  const adminBody = () => {
    return (
      <>
        {!alert.clear ? (
          <Row style={{ padding: '0 15px', margin: '1rem -15px' }}>
            <Alert
              dismissible
              onClose={() => dispatch(alertActions.clear())}
              variant={alert.type === 'alert-danger' ? 'danger' : 'success'}
              style={{ textAlign: 'center', width: '100%' }}
            >
              {alert.message}
            </Alert>
          </Row>
        ) : null}
        {isLoading ? loading() : table()}
      </>
    );
  };

  const footer = () => {
    return (
      <>
        <Row className="vCenter" style={{ margin: '0 auto' }}>
          <Button
            variant="outline-primary"
            className="formButton"
            onClick={add}
            disabled={isLoading}
          >
            Add athlete
          </Button>
        </Row>
        <Row />
      </>
    );
  };

  const headerMsg = () => {
    return <span>Athlete Admin</span>;
  };

  const listModal = () => {
    return (
      <>
        <Modal.Header className="setupHeader">{headerMsg()}</Modal.Header>
        <Modal.Body className="setupForm">{adminBody()}</Modal.Body>
        <Modal.Footer className="setupFooter">{footer()}</Modal.Footer>
      </>
    );
  };

  const modal = () => {
    switch (mode) {
      case modeType.ADD:
        return <AthleteForm back={back} />;
      case modeType.EDIT:
        break;
      case modeType.DELETE:
        break;
      case modeType.LIST:
        return listModal();
      case modeType.DETAIL:
        return <AthleteDetail back={back} athleteId={athleteId} />;
      default:
        return null;
    }
  };

  return modal();
}

export default AthleteAdmin;
